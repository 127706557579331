<header id="main-header" [class.non-production-bg]="isStaging" [class.qa-bg]="isQA">
  <div class="navbar navbar-expand-sm">
    <a (click)="_authenticationService.routeUserByDivision()" title="Deloitte | D.Tax" [className]="isRootPage ? 'navbar-brand' : 'navbar-brand mr-auto'">
      <img src="assets/images/deloitte_logo.svg" class="logo" alt="Deloitte" />
      <span class="name">{{ isQA ? 'QA Environment D.Tax' : isStaging ? 'Stage Environment D.Tax' : 'D.Tax' }}</span>
    </a>

    <!-- Search Bar not function good - need to change in backend by user. -->
    <!-- <div class="searchbar-container d-none d-sm-none d-md-block" *ngIf="isRootPage">
      <form class="form-inline">
        <span title="Search projects" class="search-button"><i class="fas fa-search"></i></span>
        <input
          class="search-input"
          type="text"
          placeholder="Search projects"
          aria-label="Search projects"
          (keyup)="searchKeywordTyped($event.target.value)"
          autocomplete="off"
        />
      </form>
    </div> -->

    <div class="navbar-container">
      <div class="navbar-buttons">
        <ul class="navbar-nav">
          <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right" *ngIf="isFormEditor">
            <span class="tooltip-wrapper icon" placement="bottom" ngbTooltip="ניהול">
              <button value="Admin" class="navbar-button admin-panel" ngbDropdownToggle></button>
            </span>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="navigateToFormsEditPanel()">הוספה ועריכת טפסים</button>
            </div>
          </li>
          <li class="nav-item">
            <span class="tooltip-wrapper" placement="bottom" ngbTooltip="לוח תקשורים מקצועיים">
              <button value="Communications Blog" class="navbar-button blog" (click)="navigateToBlog()"></button>
            </span>
          </li>
          <li class="nav-item" ngbDropdown display="dynamic">
            <span class="tooltip-wrapper" placement="bottom" ngbTooltip="עזרה">
              <button value="Help" class="navbar-button help" ngbDropdownToggle></button>
            </span>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem>Help</button>
              <a href="https://deloitteus.service-now.com/israelsp?id=sc_cat_item_israel&sys_id=88a876afdb58555441aa8fd33996194e&business_service_sys_id=619e2e964f0aa788f7a9cf5d0210c73d" target="_blank" ngbDropdownItem>Support</a>
            </div>
          </li>
          <i class="fa-solid fa-right-from-bracket"></i>
          <li class="nav-item" *ngIf="isLoggedIn">
            <span class="tooltip-wrapper" placement="bottom" ngbTooltip="התנתקות">
              <button value="" class="navbar-button" (click)="logOut()">
                <i class="fa fa-sign-out icon" aria-hidden="true"></i>
              </button>
            </span>
          </li>
          <li class="nav-item" *ngIf="user">
            <div class="user-avatar">
              <div class="user-initials">
                {{ user.nameInitials }}
                <div class="badge-container" *ngIf="isAdmin || isFormEditor">
                  <span class="tooltip-wrapper" placement="bottom" ngbTooltip="Admin">
                    <span class="admin-badge">A</span>
                  </span>
                </div>
                <div class="badge-container" *ngIf="!(isAdmin || isFormEditor) && !isExternalUser">
                  <span class="tooltip-wrapper" placement="bottom" ngbTooltip="Moderator">
                    <span class="moderator-badge">M</span>
                  </span>
                </div>
                <div class="badge-container" *ngIf="isExternalUser">
                  <span class="tooltip-wrapper" placement="bottom" ngbTooltip="External">
                    <span class="external-badge">E</span>
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- </div> -->
</header>
<!-- <div style="height: 48px"></div> -->