<div class="btn-group btn-group-toggle" data-toggle="buttons">
    <label *ngFor="let operator of operators" 
           class="btn btn-primary" 
           [class.active]="isSelectedOperator(operator)">
      <input type="radio"
             [name]="operatorId"
             [value]="operator" 
             [formControl]="form"
             autocomplete="off">
      {{ operator }}
    </label>
</div>
