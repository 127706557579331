import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from '@digital/app/core/api/api.service';
import { Flow, RiskLevelType, FullUserDetails } from '@digital/app/_models/flow.model';
import { Scenario } from '@digital/app/flow/models';
import { CreateFlow } from '@digital/app/create-flow/models/create-flow.model';
import { Section } from '@digital/app/flow/models/section.model';
import { EmailerFlow } from '@digital/app/flow/models/emailer-flow.model';
import { Consultation } from '@digital/app/flow/models/consultation.model';
import { ID } from '@datorama/akita';
import { UserService } from './user.service';
import { FlowAPI } from '@digital/app/_apis/flow.api';
import { Observable } from 'rxjs';
import { FlowStage } from '@digital/app/_enums/flow-stages.enum';
import { InternalUser } from '@digital/app/_models/internal-users.model';
import { ExternalUser, ExternalUserType, FullExternalUser } from '@digital/app/_models/client-users';

// Only for update history tab
enum Stages {
  'עבודה על דוח מס' = 1,
  'אישור מנהל' = 2,
  'אישור שותף' = 3,
  'בקרת איכות' = 4,
  'חתימת לקוח' = 5,
  'הטמעת חתימות' = 6,
  'חתימת שותף' = 7,
  'אישור התקבל ממס הכנסה' = 8,
  'סיום תהליך' = 9
}

@Injectable({
  providedIn: 'root'
})
export class FlowService {
  updateFlowAfterSetStage: EventEmitter<any> = new EventEmitter();
  updateFlowAfterFileChange: EventEmitter<any> = new EventEmitter();
  updateFlowAfterChangeOnFooter: EventEmitter<any> = new EventEmitter();
  updateConsultationStage: EventEmitter<any> = new EventEmitter();
  updateUserEventsOnFlow: EventEmitter<any> = new EventEmitter();
  updateStageFiles: EventEmitter<any> = new EventEmitter();
  isChangingStage: boolean = false;

  constructor(private api: ApiService, private flowAPI: FlowAPI, private _userService: UserService) {}

  emitDataFromDynamicFooter(data: any) {
    this.updateFlowAfterChangeOnFooter.emit(data);
  }

  emitDataFromSectionTable(data: any) {
    this.updateFlowAfterFileChange.emit(data);
  }

  emitDataAfterSetStage(data: any) {
    this.updateFlowAfterSetStage.emit(data);
  }

  emitDataFromConsultationModel(data: any) {
    this.updateConsultationStage.emit(data);
  }

  emitDataFromDeleteRequest(data: any) {
    this.updateUserEventsOnFlow.emit(data);
  }

  emitDataOnUpdateStageFiles(data: any) {
    this.updateStageFiles.emit(data);
  }

  updateFlowAfterStageChange() {
    return this.updateFlowAfterSetStage;
  }

  updateFlowWhenFileChange() {
    return this.updateFlowAfterFileChange;
  }

  updateFlowWhenChangeOnFooter() {
    return this.updateFlowAfterChangeOnFooter;
  }

  updateStages() {
    return this.updateConsultationStage;
  }

  updateUserEvents() {
    return this.updateUserEventsOnFlow;
  }

  updateStageFile() {
    return this.updateStageFiles;
  }

  getStagesType() {
    return Object.entries(Stages)
      .filter((e) => !isNaN(e[0] as any))
      .map((e) => ({ stageName: e[1], stageId: e[0] }));
  }

  setNewStage(flow: Flow, type: string, emailerFlow: EmailerFlow, specificStage: FlowStage = undefined) {
    if (specificStage) {
      this.setStage(flow.id, specificStage, emailerFlow).then(async (response) => {
        if (response.code === 202) {
          try {
            const action = `העברה לשלב ${Stages[specificStage]}`;
            await this._userService.createNewUserEvent(flow, action);
          } catch (error) {
            throw error;
          }
          this._userService.emitDataForUpdateUserEvents('success');
          this.emitDataAfterSetStage(response.code);
        }
      });
      return;
    }

    switch (type) {
      case 'next':
        const nextPosition = flow.stage.state.position + 1;

        if (flow.stage.id === FlowStage.SIGNATURE_IMPLEMENTATION && nextPosition === FlowStage.CLIENT_SIGNATURE) {
          const externalUsers = flow.permissions.externalUsers.filter((user) => {
            return user.role === ExternalUserType.All || user.role === ExternalUserType.Signer;
          });

          if (externalUsers) {
            emailerFlow.externalUsers = externalUsers.map((a) => a.email);
          }
        }

        this.setStage(flow.id, nextPosition, emailerFlow).then(async (response) => {
          if (response.code === 202) {
            try {
              const action = `העברה לשלב ${Stages[nextPosition]}`;
              await this._userService.createNewUserEvent(flow, action);
            } catch (error) {
              throw error;
            }
            // emitter for update userEvents History without reloading the page
            this._userService.emitDataForUpdateUserEvents('success');
            this.emitDataAfterSetStage(response.code);
          }
        });
        break;
      case 'previous':
        const previousPosition = flow.stage.state.position - 1;

        if (flow.stage.id === FlowStage.MANAGER_APPROVAL && previousPosition === FlowStage.WORK_ON_TAX_FLOW) {
          const externalUsers = flow.permissions.externalUsers.filter((user) => {
            return user.role === ExternalUserType.All || user.role === ExternalUserType.Editor;
          });

          if (externalUsers) {
            emailerFlow.externalUsers = externalUsers.map((a) => a.email);
          }
        }

        this.setStage(flow.id, previousPosition, emailerFlow).then(async (response) => {
          if (response.code === 202) {
            try {
              const action = `החזרה לשלב ${Stages[previousPosition]}`;
              await this._userService.createNewUserEvent(flow, action);
            } catch (error) {
              throw error;
            }
            // emitter for update userEvents History without reloading the page
            this._userService.emitDataForUpdateUserEvents('success');
            this.emitDataAfterSetStage(response.code);
          }
        });
        break;
      default:
    }
  }

  async getFlows(page: number, completion: (pages: number, flows: Flow[]) => void) {
    try {
      const response = await this.api.getFlows(page);
      completion(response.pages, response.flows);
    } catch (error) {
      throw error;
    }
  }

  async sortFlows(
    page: number,
    orderBy: string,
    orderDirection: string,
    completion: (pages: number, flows: Flow[]) => void
  ) {
    try {
      const response = await this.api.sortFlows(page, orderBy, orderDirection);
      completion(response.pages, response.flows);
    } catch (error) {
      throw error;
    }
  }

  async filterFlows(page: number, type: string, completion: (pages: number, flows: Flow[]) => void) {
    try {
      const response = await this.api.filterFlows(page, type);
      completion(response.pages, response.flows);
    } catch (error) {
      throw error;
    }
  }

  async searchFlows(searchValue: string, completion: (pages: number, flows: Flow[]) => void) {
    try {
      const response = await this.api.searchFlows(searchValue);
      completion(response.pages, response.flows);
    } catch (error) {
      throw error;
    }
  }

  async deleteFlowRequest(id: number, isAwaitingDeletion: boolean, reason: string) {
    try {
      await this.api.deleteFlowRequest(id, isAwaitingDeletion, reason);
    } catch (error) {
      throw error;
    }
  }

  async getFlowByID(id: number, completion: (flow: Flow) => void) {
    try {
      const flow = await this.api.getFlowByID(id);
      completion(flow);
    } catch (error) {
      throw error;
    }
  }

  async getManagersAndPartners(companyID: number, taxYear: number, completion: (users: any) => void) {
    try {
      const response = await this.api.getManagersAndPartners(companyID, taxYear);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async getAllUsersForBanch(companyID: number, taxYear: number, completion: (users: any) => void) {
    try {
      const response = await this.api.getAllUsersForBanch(companyID, taxYear);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async getExternalUserByEmail(email: string, completion: (user: any) => void) {
    try {
      const response = await this.api.getExternalUserByEmail(email);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async getTaxYears(companyID: number, completion: (taxYears: any) => void) {
    try {
      const response = await this.api.getTaxYears(companyID);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async updateManagerOrPartner(userID: number, flowID: number, userIDType: number) {
    try {
      const response = await this.api.updateManagerOrPartner(userID, flowID, userIDType);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createInternalEditor(user: InternalUser, flowID: number, completion: (addedUser: FullUserDetails) => void) {
    try {
      const response = await this.api.createInternalEditor(user, flowID);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async deleteInternalEditor(userID: number, flowID: number, completion: (success: boolean) => void) {
    try {
      const response = await this.api.deleteInternalEditor(userID, flowID);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async createExternalUser(user: ExternalUser, flowID: number, completion: (addedUser: FullExternalUser) => void) {
    try {
      console.log(user);
      const response = await this.api.createExternalUser(user, flowID);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async deleteExternalUserPermission(userID: number, flowID: number, completion: (success: boolean) => void) {
    try {
      const response = await this.api.deleteExternalUserPermission(userID, flowID);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async updateRiskLevel(flow: Flow, riskLevel: RiskLevelType) {
    try {
      const response = await this.api.updateRiskLevel(flow.id, riskLevel);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteFlow(id: number, action: string) {
    try {
      return await this.api.deleteFlow(id, action);
    } catch (error) {
      throw error;
    }
  }

  async getCompanies(userID: ID, completion: (users: any) => void) {
    try {
      const response = await this.api.getCompanies(userID);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async createNewFlow(flow: CreateFlow, completion: (flow: Flow) => void) {
    try {
      const response = await this.api.createNewFlow(flow);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async getScenario(flowID: number, completion: (scenario: Scenario) => void) {
    try {
      const scenario = await this.api.getScenario(flowID);
      completion(scenario);
    } catch (error) {
      throw error;
    }
  }

  async getFlowSections(flowID: number, completion: (sections: Section[]) => void) {
    try {
      const sections = await this.api.getFlowSections(flowID);
      completion(sections);
    } catch (error) {
      throw error;
    }
  }

  async checkIfCurrentEditor(flowID: number, completion: (user: any) => void) {
    try {
      const user = await this.api.checkIfCurrentEditor(flowID);
      completion(user);
    } catch (error) {
      throw error;
    }
  }

  async setStage(flowID: number, stageID: number, flow: EmailerFlow) {
    try {
      return this.api.setStage(flowID, stageID, flow);
    } catch (error) {
      throw error;
    }
  }

  async disapproveSignatures(flowID: number) {
    try {
      const userId = this._userService.getCurrentUser().id;
      return this.api.disapproveSignaturesApi(flowID, userId);
    } catch (error) {
      throw error;
    }
  }

  async createNewConsultation(consultation: Consultation) {
    try {
      return this.api.createNewConsultation(consultation);
    } catch (error) {
      throw error;
    }
  }

  async getConsultationForFlow(flowID: number, completion: (consultation: Consultation) => void) {
    try {
      const consultations = await this.api.getConsultationsForFlow(flowID);
      completion(consultations);
    } catch (error) {
      throw error;
    }
  }

  async deleteConsultation(flowID: number, stageID: number) {
    try {
      return await this.api.deleteConsultation(flowID, stageID);
    } catch (error) {
      throw error;
    }
  }

  async getFlowsYearsByID(companyID: number, completion: (years) => void) {
    try {
      const years = await this.api.getFlowsYearsByID(companyID);
      completion(years);
    } catch (error) {
      throw error;
    }
  }

  isDue(date: Date) {
    return new Date(date).getTime() > new Date().getTime();
  }

  // Current editors service

  setAsCurrentEditor(flowID: number): Observable<boolean> {
    return this.flowAPI.setCurrentEditor(flowID);
  }

  setAsCurrentEditorSync(flowID: number): Promise<Response> {
    return this.flowAPI.setCurrentEditorSync(flowID);
  }

  removeAsCurrentEditor(flowID: number): Observable<boolean> {
    return this.flowAPI.removeCurrentEditor(flowID);
  }

  removeCurrentEditorSync(flowID: number): Promise<Response> {
    return this.flowAPI.removeCurrentEditorSync(flowID);
  }

  removeAsCurrentEditorFromAll(): Observable<boolean> {
    return this.flowAPI.removeCurrentEditorFromAll();
  }
}
