import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LeadCriterionFormMappingService } from './lead-criterion-form-mapping.service';

@Component({
  selector: 'app-lead-query-criterion',
  templateUrl: './lead-query-criterion.component.html',
  styleUrls: ['./lead-query-criterion.component.scss']
})
export class LeadQueryCriterionComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() id: string;
  identifiers = [
    { controlName: 'year', placeholder: 'שנה' },
    { controlName: 'formName', placeholder: 'טופס' },
    { controlName: 'fieldName', placeholder: 'שדה' }
  ];
  private textOperators = [
    { option: 'מכיל', value: 'CONTAINS' },
    { option: 'שונה מ', value: 'NE' },
    { option: 'מתחיל ב', value: 'STARTS_WITH' },
    { option: 'מסתיים ב', value: 'ENDS_WITH' }
  ];
  private numberOperators = [
    { option: 'גדול מ', value: 'GT' },
    { option: 'גדול שווה מ', value: 'GTE' },
    { option: 'שווה', value: 'E' },
    { option: 'לא שווה', value: 'NE' },
    { option: 'קטן שווה מ', value: 'LTE' },
    { option: 'קטן מ', value: 'LT' }
  ];
  private booleanOperators = [
    { option: 'TRUE', value: 'TRUE'},
    { option: 'FALSE', value: 'FALSE'}
  ];
  private dateOperators = [
    { option: 'לפני', value: 'BEFORE' },
    { option: 'שווה ל', value: 'EQUALS_DATE' },
    { option: 'אחרי', value: 'AFTER' }
  ];

  constructor(private mappingService: LeadCriterionFormMappingService) { }

  ngOnInit(): void {
  }

  idOf(label: string): string {
    return `${this.id}-${label}`;
  }

  get valueType(): string {
    return this.form
      .get('valueType')
      .value;
  }

  get comparisonOperators() {
    const type = this.valueType;
    let operators = null;

    if (type === 'text') {
      operators = this.textOperators;
    } else if (type === 'number') {
      operators = this.numberOperators;
    } else if (type === 'boolean' || type === 'checkbox' || type === 'radio') {
      operators = this.booleanOperators;
    } else if (type === 'date') {
      operators = this.dateOperators;
    }

    return operators;
  }

  get hasValueField(): boolean {
    return this.mappingService.valueTypeHasValue(this.valueType);
  }

  get isInitialized(): boolean {
    return this.form?.get('fieldId')?.value;
  }
}
