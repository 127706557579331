import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { DigitalitRoutes } from '@digital/app/_constants';
import { BrowserGuard } from '@libs/guards/browser.guard';
import { ExternalUserGuard } from '@libs/guards/external-user-guard.guard';
import { CreateFlowComponent } from './pages/create-flow/create-flow.component';

const createFlowRoutes: Routes = [
  {
    path: DigitalitRoutes.flow.create,
    component: CreateFlowComponent,
    canActivate: [BrowserGuard, ExternalUserGuard, MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(createFlowRoutes)],
  exports: [RouterModule]
})
export class CreateFlowRoutingModule {}
