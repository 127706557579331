import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface CustomFieldOption {
  name: string;
  value: string;
  isSelected: boolean;
}

@Component({
  selector: 'app-field-custom-options',
  template: `
    <ng-container>
      <div class="field-custom-options">
        <ng-container *ngIf="title">
          <div class="title">
            <p>{{ title }}</p>
          </div>
        </ng-container>
        <div class="form-check" *ngFor="let option of options">
          <div class="radiobox">
            <input
              class="form-check-input"
              type="radio"
              name="custom-field-option"
              id="option-{{ option.value }}"
              [value]="option.value"
              [checked]="option.isSelected"
              (change)="didChooseOption.emit(option)"
              required
            />
            <span class="radio-check"></span>
          </div>
          <label class="form-check-label" for="custom-field-option">{{ option.name }}</label>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./field-custom-options.component.scss']
})
export class FieldCustomOptionsComponent implements OnInit {
  @Input() options: CustomFieldOption[];
  @Input() title?: string;

  @Output() didChooseOption = new EventEmitter<CustomFieldOption>();

  constructor() {}
  ngOnInit(): void {}
}
