<section class="manager-approval">
  <div class="row">
    <div class="col text-right">
      <div class="title-container">
        <h4>אישור מנהל/ת התיק</h4>
      </div>
    </div>
  </div>
    <div class="row">
      <div class="col">
        <p>
          ערכת המס של החברה הנ"ל הועברה לאישורך.<br>
          באפשרותך לערוך את הטפסים שמולאו דיגיטלית על ידי לחיצה על הטופס הרצוי ומעבר למסך מילוי הטפסים.<br> 
          ניתן לאשר את הערכה ולהעבירה ל"אישור שותף" ע"י לחיצה על "אשר והמשך תהליך", או להחזיר לעריכה ע"י יוצר/ת התהליך בלחיצה על "דחייה וחזרה לשלב הקודם בתהליך".
        </p>
      </div>
    </div>
</section>
