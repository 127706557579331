import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UAParser } from 'ua-parser-js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisclaimerAlertModalComponent } from '@libs/views/modals/disclaimer-alert-modal/disclaimer-alert-modal.component';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { ApiService } from '@digital/app/core/api/api.service';

@Injectable({ providedIn: 'root' })
export class BrowserGuard implements CanActivate {
  private readonly parser = new UAParser();
  private readonly blockedBrowsers = ['IE', 'IEMobile', 'MSIE'];
  constructor(private modalService: NgbModal, private api: ApiService, private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(): boolean {
    const browser = this.parser.getBrowser();
    const isBlockedBrowser = this.blockedBrowsers.includes(browser.name);
    //todo: need to uncomment
    const currentUser = this.authenticationService.getCurrentUser();
    if (currentUser.role === 'external' && !currentUser.isDisclaimed) {//(isExternal && !isDisclaimer) {
      const modelRef = this.modalService.open(DisclaimerAlertModalComponent, {
        windowClass: 'alert-modal',
        centered: true,
        size: 'xl', // You can also use 'sm' (small) or 'xl' (extra large) or provide custom dimensions
        backdrop: 'static' // Prevents closing on backdrop click
      });
      modelRef.result.then(async (approved) => {
        if (approved) {
          try {
            return await this.api.markAsIsDisclaimerAPI();
            //change the local value of the isDisclaimer and also on backend 
          } catch (error) {
            throw error;
          }
        }
      });
    }
    if (isBlockedBrowser) {
      this.router.navigate(['unsupported-browser']);
      return false;
    }

    return true;
  }
}
