import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPageBaseComponent } from '../error-page-base/error-page-base.component';

@Component({
  selector: 'app-error-page-forbidden',
  templateUrl: './error-page-forbidden.component.html',
  styleUrls: ['../error-page-base/error-page-base.component.scss', './error-page-forbidden.component.scss']
})
export class ErrorPageForbiddenComponent extends ErrorPageBaseComponent implements OnInit {
  constructor(protected router: Router) {
    super(router);
  }
  ngOnInit(): void { }
}
