<section
  class="flow-section table"
  *ngFor="let section of sections"
  [ngClass]="{ blueline: section.id === sectionType.QualityControl_Tax }"
>
  <div class="title-container">
    <div class="row">
      <div class="col-6">
        <h4 class="title">{{ section.name }}</h4>
      </div>
      <div class="col-6 text-left">
        <div class="button-container" *ngIf="!isLockedForEditing && !flow.isAwaitingDeletion">
          <input hidden type="file" #fileInputs (change)="uploadFiles($event.target.files, section.id)" multiple />
          <input hidden type="file" #fileInput (change)="uploadFiles($event.target.files, section.id)" />
          <button
            class="btn btn-borderless"
            *ngIf="section.canUploadCustomFiles && section.maxFiles !== 1"
            (click)="fileInputs.click()"
          >
            <app-svg-icon name="plus_special"></app-svg-icon>
            {{ buttonsText.MultipleFiles }}
          </button>
          <button
            class="btn btn-borderless"
            *ngIf="
              (section.canUploadCustomFiles &&
                section.maxFiles === 1 &&
                section.id !== sectionType.QualityControl_Tax) ||
              ((isAdmin || isFormEditor) && section.id === sectionType.QualityControl_Tax)
            "
            (click)="fileInput.click()"
          >
            <app-svg-icon name="plus_special"></app-svg-icon>
            {{ buttonsText.SingleFile }}
          </button>
          <span class="tooltip-wrapper" placement="top">
            <button (click)="openKitModal()" class="btn btn-borderless" *ngIf="section.canAddDigitalForms">
              <app-svg-icon name="plus_special"></app-svg-icon>
              הוספת טפסים דיגיטלים
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="table-content">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">שם הטופס</th>
          <th scope="col">עודכן לאחרונה</th>
          <th scope="col">סטטוס</th>
        </tr>
      </thead>
      <tbody>
        <tr class="empty-state" *ngIf="!section.items || section.items.length === 0">
          <td colspan="3">
            <p class="text">יש להוסיף טפסים/קבצים לאיזור זה</p>
          </td>
        </tr>
        <tr *ngFor="let item of section.items" class="item-row">
          <td class="file-preview">
            <button class="file-button" (click)="openSectionItem(item)">
              <app-svg-icon
                name="pdf-file_special"
                *ngIf="item.kind === SectionItemKind.PDF"
                ngbTooltip="לצפיה במסמך"
              ></app-svg-icon>
              <app-svg-icon
                name="doc-file_special"
                *ngIf="item.kind === SectionItemKind.Word"
                ngbTooltip="להורדת המסמך"
              ></app-svg-icon>
              <app-svg-icon
                name="xls-file_special"
                *ngIf="item.kind === SectionItemKind.Excel"
                ngbTooltip="להורדת המסמך"
              ></app-svg-icon>
              <app-svg-icon
                name="file-empty_special"
                *ngIf="item.type === SectionItemType.Form"
                ngbTooltip="לעריכת המסמך"
              ></app-svg-icon>
            </button>
            <button class="file-button text-truncate" (click)="openSectionItem(item)">
              {{ titleForSectionItem(item) }}
            </button>
            <span class="title-tag" *ngIf="item.type === SectionItemType.Form">דיגיטלי</span>
          </td>
          <td>{{ item.createdAt | parseDatePipe : 'time' }} ע"י {{ item.lastUpdatedBy }}</td>
          <td>
            <span
              *ngIf="
                item.type === SectionItemType.File ||
                (item.type === SectionItemType.Form && item.state === FormState.Published)
              "
              class="status-tag published"
            >
              סגור
            </span>

            <span *ngIf="item.type === SectionItemType.Form && item.state === FormState.Draft" class="status-tag">
              בעבודה
            </span>
          </td>
          <td class="delete-file">
            <button
              class="delete-file-button"
              *ngIf="
                (!isLockedForEditing && section.id !== sectionType.QualityControl_Tax) ||
                (section.id === sectionType.QualityControl_Tax && (isAdmin || isFormEditor) && !isLockedForEditing)
              "
              [ngbTooltip]="'מחיקה'"
              (click)="deleteSectionItemClicked(item)"
            >
              <app-svg-icon name="bin-red_special"></app-svg-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-content select="[content]"></ng-content>
  </div>
</section>
