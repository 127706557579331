<div class="top-bar-container flow-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col title-container">
        <button type="button" class="back-button" [disabled]="isDisabled" (click)="onClickBack()">
          <app-svg-icon name="back_outline"></app-svg-icon>
        </button>
        <h4 class="title">יצירת שאילתה</h4>
      </div>
      <div class="col text-left">
        <ng-container>
          <button type="button" class="flow-header-button" [disabled]="isDisabled" (click)="onClickSave()">
            <app-svg-icon name="save_outline"></app-svg-icon>
            שמור
          </button>
        </ng-container>
        <ng-container>
          <span ngbDropdown placement="bottom-left">
            <button type="button" class="flow-header-button" [disabled]="isDisabled" ngbDropdownToggle>
              <app-svg-icon name="settings-v2_outline"></app-svg-icon>
              הגדרות
            </button>
            <div ngbDropdownMenu>
              <button type="button" ngbDropdownItem (click)="onClickClone()" [disabled]="isNew">
                שכפול שאילתה
              </button>
              <button type="button" ngbDropdownItem (click)="onClickShare()" [disabled]="isNew">
                שיתוף שאילתה
              </button>
              <button
                type="button"
                [ngClass]="{'destructive': !isNew}"
                ngbDropdownItem
                (click)="onClickDelete()"
                [disabled]="isNew"
              >
                מחיקת שאילתה
              </button>
            </div>
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
