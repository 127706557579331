import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackButtonComponent } from './back-button/back-button.component';
import { TagViewComponent } from './tag-view/tag-view.component';

// Dependencies
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Header & Footer
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

// Error Pages
import { ErrorPageBaseComponent } from './pages/errors/error-page-base/error-page-base.component';
import { ErrorPageNotFoundComponent } from './pages/errors/error-page-not-found/error-page-not-found.component';
import { ErrorPageForbiddenComponent } from './pages/errors/error-page-forbidden/error-page-forbidden.component';
import { ErrorPageUnauthorizedComponent } from './pages/errors/error-page-unauthorized/error-page-unauthorized.component';
import { ErrorPageBrowserComponent } from './pages/errors/error-page-browser/error-page-browser.component';
import { DisclaimerAlertModalComponent } from './modals/disclaimer-alert-modal/disclaimer-alert-modal.component';

@NgModule({
  declarations: [
    BackButtonComponent,
    TagViewComponent,

    HeaderComponent,
    FooterComponent,

    // Error Pages
    ErrorPageBaseComponent,
    ErrorPageNotFoundComponent,
    ErrorPageForbiddenComponent,
    ErrorPageUnauthorizedComponent,
    ErrorPageBrowserComponent,
    DisclaimerAlertModalComponent
  ],
  exports: [
    BackButtonComponent,
    TagViewComponent,

    // Header & Footer
    HeaderComponent,
    FooterComponent,

    // Error Pages
    ErrorPageNotFoundComponent
  ],
  imports: [CommonModule, NgbModule]
})
export class ViewsModule {}
