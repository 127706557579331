import { FormState } from '@digital/app/_models/form.model';

export enum SectionItemType {
  Form = 'form',
  File = 'file'
}

export enum SectionItemKind {
  PDF = 'pdf',
  Excel = 'xlsx',
  Word = 'docx'
}

export interface SectionItem {
  id: number;
  sectionID: number;
  type: SectionItemType;
  kind: SectionItemKind;
  name: string;
  state: FormState;
  defaultPosition: number;
  position: number;
  disablePositionChange?: boolean;
  lastUpdatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  originalName?: string;
  url?: string;
  taxID?: string;
  instanceID?: number;
  includedInExport: boolean;
}

export interface UpdateSectionItemOrderDTO {
  id: number;
  position: number;
  type: SectionItemType;
  instance: number;
}

export interface UpdateSectionItemIncludedDTO {
  formID: number;
  includedInExport: boolean;
  type: SectionItemType;
  instanceID?: number;
}

export interface UpdateSectionFormInstancesDTO {
  formID: number;
  instanceID: number;
  name?: string;
}

export interface UpdateSectionFileIncludedDTO {
  file:UpdateFile[];
}

export interface UpdateFile {
  id: number;
  name: string;
  state: FormState;
  filePath: string;
  createdAt: Date;
  position: number;
  includedInExport: boolean;
  sectionID: number;
  userName: {
    id: number;
    fullName: { english: string , hebrew: string };
    rank: string;
  };
}

export interface Section {
  id: number;
  name: string;
  position: number;
  canAddDigitalForms: boolean;
  canUploadCustomFiles: boolean;
  maxFiles: number;
  items?: [SectionItem];
}
