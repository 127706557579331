import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormField } from '../../../_models/form-field.model';
import { Dimensions } from '@libs/interfaces/dimensions.interface';
import { FormsService } from '../../forms.service';

interface GridField {
  id: number;
  dimensions: Dimensions;
}

@Component({
  selector: 'app-fields-grid-old',
  templateUrl: './fields-grid-old.component.html',
  styleUrls: ['./fields-grid-old.component.scss']
})
export class FieldsGridComponentOld implements OnInit {
  @Input() formPage: number;

  @Output() clickedField = new EventEmitter<FormField>();

  currentFormFields$ = this.formsService.currentFormFields$;
  fields: FormField[] = [];

  constructor(private formsService: FormsService) {}

  ngOnInit() {
    this.currentFormFields$.subscribe((fields) => {
      const flattenedFields = this.flattenFieldsArray(fields);
      const filteredFieldsByPage = flattenedFields.filter((field) => field.formPage === this.formPage);
      this.fields = [...this.fields, ...filteredFieldsByPage];
    });
  }

  private flattenFieldsArray(fields: FormField[]): FormField[] {
    let flatFields: FormField[] = [];

    for (const field of fields) {
      flatFields = [...flatFields, field];
      if (field.fields && field.fields.length > 0) {
        flatFields = [...flatFields, ...this.flattenFieldsArray(field.fields)];
      }
    }

    return flatFields;
  }

  onFieldClicked(field: FormField) {
    this.clickedField.emit(field);
  }
}
