<section class="consultation-state" *ngIf="isConsultation?.consult.isActive">
  <div class="row">
    <div class="col text-right">
      <div class="title-container" *ngIf="consultationUserName">
        <h4> בהתייעצות מול {{consultationUserName}}</h4>
      </div>
    </div>
  </div>
    <div class="row">
      <div class="col">
        <p>
          סיבת ההתייעצות:
        {{isConsultation?.consult.body}}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="button-container" *ngIf="isConsultation && isConsultation.consult.consultationUserID === currentUserID">
          <button class="btn btn-primary-white" (click)="approveConsultation()" >
            <app-svg-icon name="checkmark-blue_special" class="checkmark-icon"></app-svg-icon>
            אשר התייעצות
          </button>
        </div>
      </div>
    </div>
</section>
