import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as allComponents from './index';
import { FormGroup, FormControl } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ReactiveFormsModule } from '@angular/forms';

import { ParseDatePipe } from '@libs/core/pipes/parse-date.pipe';
import { DragDropDirective } from '@libs/directives/drag-drop.directive';
import { SortableHeaderDirective } from '@libs/directives/sortable-header.directive';
import { UploaderComponent } from '@digital/app/shared/components/uploader/uploader.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { TooltipIndicatorComponent } from './components/tooltip-indicator/tooltip-indicator.component';
import { ActionRequiredModalComponent } from '../forms/components/action-required-modal/action-required-modal.component';
import { AlertOpenNotesModalComponent } from './components/alert-open-notes-modal/alert-open-notes-modal.component';
import { CompanyInfoFormComponent } from '../forms/components/company-info-form/company-info-form.component';

@NgModule({
  declarations: [
    allComponents.components,
    ParseDatePipe,
    DragDropDirective,
    SortableHeaderDirective,
    UploaderComponent,
    ConfirmationModalComponent,
    TooltipIndicatorComponent,
    ActionRequiredModalComponent,
    AlertOpenNotesModalComponent,
    CompanyInfoFormComponent
  ],
  imports: [CommonModule, NgbModule, FormsModule, HttpClientModule, AngularSvgIconModule, ReactiveFormsModule],
  exports: [allComponents.components, ParseDatePipe, UploaderComponent, SortableHeaderDirective, CompanyInfoFormComponent],
  entryComponents: [ConfirmationModalComponent]
})
export class SharedModule {}
