<div class="content-container">
  <h1 class="title">401</h1>
  <h2 class="subtitle">גישה לעמוד זה אינה מאושרת</h2>
  <div class="container text-center action">
    <h4 class="subtitle-body">אין לך הרשאה להיכנס לתהליך זה...</h4>
  </div>
  <p class="action-body">אם זו טעות, אנא פתח/י קריאה.</p>
  <p class="action">
    <button class="btn btn-primary" (click)="openTicketServiceNow()">פתיחת קריאה</button>
    <button class="btn btn-secondary" (click)="navigateToHomePage()">נסה/י שוב</button>
  </p>
</div>
