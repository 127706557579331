import { IFlowStageFile } from '../_interfaces/flow-stage-file.interface';
import { FullExternalUser } from './client-users';
import { Deserializable } from './deserializable.model';

export enum FlowType {
  Tax = 1,
  Financial = 2,
  SpecialPermit = 3
}

export enum RiskLevelType {
  Undefined = 'undefined',
  Low = 'low',
  Medium = 'medium',
  High = 'high'
}

export enum PermissionType {
  Manager = 'מנהל',
  Partner = 'שותף',
  Creator = 'יוצר התהליך',
  Editor = 'עורך'
}

export enum ExternalPermissionType {
  signer = 'חותם',
  all = 'עורך וחותם',
  editor = 'עורך'
}

interface Company {
  id: number;
  nameHebrew: string;
  nameEnglish: string;
}

interface Stage {
  id: number;
  isLockedForEditing: boolean;
  name: string;
  state?: {
    position?: number;
    isCurrent?: boolean;
    isCompleted?: boolean;
  };
}

interface User {
  email?: string;
  id: number;
  fullName: {
    english: string;
    hebrew: string;
  };
  rank: {
    english: string;
    hebrew: string;
  };
}

export class Permissions implements Deserializable {
  internalUsers: FullUserDetails[];
  externalUsers: FullExternalUser[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export interface FullUserDetails {
  id: number;
  fullName: {
    english: string;
    hebrew: string;
  };
  email: string;
  rank: string;
  companies?: [];
  status: string;
}

export class Flow implements Deserializable {
  id: number;
  year: number;
  deadline: Date;
  createdAt: Date;
  updatedAt: Date;
  company: Company;
  type: {
    id: 1;
    name: string;
  };
  riskLevel: RiskLevelType;
  stage: Stage;
  manager: FullUserDetails;
  partner: FullUserDetails;
  creator: FullUserDetails;
  currentEditor?: User;
  lastUpdatedBy: User;
  assignedUser: User;
  isAwaitingDeletion: boolean;
  isDone: boolean;
  stageFiles: IFlowStageFile[];
  permissions: Permissions;
  adminUsers?: User[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export interface FlowRealTimeData {
  stage: Stage;
  currentEditor: User;
  lastUpdatedBy: User;
  updatedAt: Date;
}

export class DeloitteTeam {
  permissionType: PermissionType;
  data: FullUserDetails;
}
