import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from '@digital/app/core/api/api.service';
import { User } from '@digital/app/_models/user.model';
import jwt_decode from 'jwt-decode';

import { CreateUserEvent } from '@libs/core/network/model/user-event.model';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { Flow } from '@digital/app/_models/flow.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _currentUser = this._authenticationService.getCurrentUser();

  updateUserEventsOnFlow: EventEmitter<any> = new EventEmitter();

  constructor(private _authenticationService: AuthenticationService, private api: ApiService) {}

  emitDataForUpdateUserEvents(data: any) {
    this.updateUserEventsOnFlow.emit(data);
  }

  updateUserEvents() {
    return this.updateUserEventsOnFlow;
  }

  getCurrentUser() {
    return this._currentUser;
  }

  decodeUserFromJWT() {
    try {
      const jwt = localStorage.getItem('token');
      return new User().deserialize(jwt_decode(jwt).user); // TODO: Did kombina with the jwt_decode().user for now
    } catch (error) {
      return null;
    }
  }

  saveUserSession(token: any) {
    localStorage.setItem('token', token.data.token);
    // this.store.dispatch(userActions.loadUser({
    //   isAuthenticated: true,
    //   loading: false,
    //   id: this.decodeUserFromJWT().id,
    //   fullName: this.decodeUserFromJWT().fullName,
    //   email: this.decodeUserFromJWT().email,
    //   rank: this.decodeUserFromJWT().rank,
    //   companies: this.decodeUserFromJWT().companies
    // }));
  }

  async createNewUserEvent(flow: Flow, action: string) {
    if (!this._currentUser) {
      return;
    }

    const userEvent = new CreateUserEvent();
    userEvent.flowID = flow.id;
    userEvent.companyID = flow.company.id;
    userEvent.actionTaken = action;
    try {
      await this.api.createNewUserEvent(this._currentUser.id, userEvent);
    } catch (error) {
      throw error;
    }
  }

  async getUserDetails(userID: number) {
    try {
      return await this.api.getUserDetails(userID);
    } catch (error) {
      throw error;
    }
  }

  async resendActivationEmail(email: string) {
    try {
      return await this.api.resendActivationEmail(email);
    } catch (error) {
      throw error;
    }
  }
}
