import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LeadQuery } from '@digital/app/_models/lead-query';
import { LeadPropertiesFormMappingService } from '@digital/app/lead/lead-query-properties/lead-properties-form-mapping.service';
import { LeadCriteriaFormMappingService } from '@digital/app/lead/lead-query-criteria/lead-criteria-form-mapping.service';

@Injectable({
  providedIn: 'root'
})
export class LeadFormMappingService {

  constructor(private fb: FormBuilder,
              private propertiesMappingService: LeadPropertiesFormMappingService,
              private criteriaMappingService: LeadCriteriaFormMappingService) { }

  toForm(years: number[], query?: LeadQuery): FormGroup {
    const propertiesForm = this.propertiesMappingService.toForm(years, query);
    const criteriaForm = this.criteriaMappingService.toForm(query?.queryCriterias);

    return this.fb.group({ properties: propertiesForm, criteria: criteriaForm });
  }

  fromForm(form: FormGroup): LeadQuery {
    const { properties: propertiesFormValue, criteria: criteriaFormValue } = form.getRawValue();
    const properties = this.propertiesMappingService.fromFormValue(propertiesFormValue);
    const criteria = this.criteriaMappingService.fromFormValue(criteriaFormValue);

    return { ... properties, queryCriterias: criteria };
  }
}
