import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UAParser } from 'ua-parser-js';
import { ErrorPageBaseComponent } from '../error-page-base/error-page-base.component';

@Component({
  selector: 'app-error-page-browser',
  template: `
    <div class="content-container">
      <img src="assets/images/browsers/{{ browserImageName }}.svg" alt="Internet Explorer Icon" class="browser-icon" />
      <h1 class="title">אופס...</h1>
      <h4 class="subtitle">דפדפן זה אינו נתמך</h4>
      <p class="action">
        אתם מנסים להכנס למערכת זו באמצעות דפדפן שאינו נתמך.<br />אנא השתמשו בדפדפן Google Chrome או Mozilla Firefox.
      </p>
      <p class="action">אם זו טעות, אנא התייעצו עם מנהלת/ת המערכת.</p>
      <p class="action">
        <button class="btn btn-primary" (click)="openTicketServiceNow()">פתיחת פניה</button>
      </p>
    </div>
  `,
  styleUrls: ['../error-page-base/error-page-base.component.scss'],
  styles: [
    `
      .title {
        margin-top: 1rem;
      }
      .subtitle {
        margin-top: 0.5rem;
      }
      .browser-icon {
        width: 100px;
        height: 100px;
      }
    `
  ]
})
export class ErrorPageBrowserComponent extends ErrorPageBaseComponent implements OnInit {
  private readonly parser = new UAParser();
  browserImageName: string;

  constructor(protected router: Router) {
    super(router);
    this.browserImageName = this.parser.getResult().browser.name.toLowerCase();
  }
  ngOnInit(): void {}
}
