    <div class="content-container">
        <h1 class="title">404</h1>
        <h2 class="subtitle">אופס! העמוד שחיפשת לא נמצא</h2>
        <div class="container">
            <h4 class="subtitle-body">יש לפעול ע״פ השלבים הבאים:</h4>
            <p >1.	יש למחוק ב-URL את המילה "not-found"</p>
            <p >2.	להיכנס מחדש למערכת דרך הקישור / דרך הפורטל</p>
            <p class="longrow">3.	מחיקת CACHE: לחיצה על ctrl+shift+J -> לחיצה על כפתור זה <<   בחלק העליון של החלון שנפתח -> Application -> בצד שמאל ללחוץ על Storage -> לגלול קצת למטה וללחוץ על clear site data </p>
        </div>
        <p class="action-body">אם זו טעות, אנא פתח/י קריאה.</p>
        <p class="action">
            <button class="btn btn-primary" (click)="openTicketServiceNow()">פתיחת קריאה</button>
            <button class="btn btn-secondary" (click)="navigateToHomePage()">חזרה לעמוד ראשי</button>
        </p>

    </div>
<app-footer></app-footer>
