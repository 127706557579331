import { Component, OnInit } from '@angular/core';
import { environment } from '@digital/environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  get currentYear(): number {
    const now = new Date();
    return now.getFullYear();
  }

  get appVersion(): string {
    return environment.appVersion;
  }

  constructor() {}
  ngOnInit() {}
}
