import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import * as Constants from './shared/constants/index';
import { HomeComponent } from './form/pages/home/home.component';
import { EditFormComponent } from './form/pages/edit-form/edit-form.component';
import { CreateNewFormComponent } from './form/pages/create-new-form/create-new-form.component';
import { DuplicateFormComponent } from './form/pages/duplicate-form/duplicate-form.component';



const routes: Routes = [
  {
    path: Constants.AdminPanelRoutes.home,
    component: HomeComponent,
  },
  {
    path: Constants.AdminPanelRoutes.home + Constants.AdminPanelRoutes.editForm,
    component: EditFormComponent,
  },
  {
    path: Constants.AdminPanelRoutes.home + Constants.AdminPanelRoutes.createForm,
    component: CreateNewFormComponent,
  },
  {
    path: Constants.AdminPanelRoutes.home + Constants.AdminPanelRoutes.duplicate,
    component: DuplicateFormComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
