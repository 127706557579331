import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SignaturesAPI } from '../apis/signatures.api';
import { Signature } from '../models/signature.model';

@Injectable({ providedIn: 'root' })
export class SignaturesService {
  signatures$: BehaviorSubject<Signature[]> = new BehaviorSubject([]);
  signDate$: BehaviorSubject<Date> = new BehaviorSubject(null);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private _signaturesAPI: SignaturesAPI) {}

  getAll(flowID: number, fileID: number): Observable<any> {
    this.isLoading$.next(true);
    return this._signaturesAPI.getAll(flowID, fileID).pipe(
      tap((response) => {
        this.isLoading$.next(false);
        this.signatures$.next(response.signatures);
        if (response.signDate) {
          this.signDate$.next(response.signDate);
        }
      })
    );
  }

  create(signature: Signature) {
    return this._signaturesAPI.create(signature);
  }

  update(signature: Signature) {
    return this._signaturesAPI.update(signature);
  }

  delete(signature: Signature) {
    return this._signaturesAPI.delete(signature.id);
  }

  createSignDate(date: string, flowID: number, fileID: number) {
    return this._signaturesAPI.createSignDate(date, flowID, fileID);
  }

  //   getSignDate(flowID: number, fileID: number) {
  //     return this._signaturesAPI.getSignDate(flowID, fileID);
  //   }
}
