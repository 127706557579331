import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { Router } from '@angular/router';

// Constants
import { GlobalRoutes } from '@libs/constants';
import { DigitalitRoutes } from '@digital/app/_constants';
import { AdminPanelRoutes } from '@admin/app/shared/constants';

// Services
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { HeaderService } from '@libs/views/header/header.service';

// Utilities
import { AutoSearchTimer } from '@libs/utils/auto-search-timer';

// Models
import { User, UserRole } from '@libs/authentication/models/user.model';

// Envirnoment

import { APP_ENV, Environment, EnvironmentType } from '@libs/environment';
import { FlowAPI } from '@digital/app/_apis/flow.api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: User;

  @Output() searchKeywordChanged = new EventEmitter<string>();

  private _searchedKeyword = '';
  autoSearchTimer = new AutoSearchTimer(() => this.performSearch());

  get isStaging(): boolean {
    return this.environment.type === EnvironmentType.Staging;
  }

  get isLoggedIn() {
    if (this._authenticationService.getCurrentUser() == null) {
      return false;
    }
    return true;
  }

  get isAdmin() {
    if (this.user == null) {
      return false;
    }
    return this.user.role === UserRole.ADMIN;
  }

  get isFormEditor() {
    if (this._authenticationService.getCurrentUser() == null) {
      return false;
    }
    return this._authenticationService.getCurrentUser().role === UserRole.FORMEDITOR;
  }

  get isExternalUser() {
    if (this.user == null) {
      return false;
    }
    return this.user.role === UserRole.EXTERNAL;
  }

  get isQA(): boolean {
    return this.environment.type === EnvironmentType.QA;
  }

  EnvironmentType = EnvironmentType;

  constructor(
    @Inject(APP_ENV) private environment: Environment,
    public router: Router,
    public _authenticationService: AuthenticationService,
    private headerService: HeaderService,
    private _flowAPI: FlowAPI
  ) {}

  ngOnInit() {
    this._authenticationService.isLoggedIn$.subscribe((isLogged) => {
      if (isLogged) {
        this.user = this._authenticationService.getCurrentUser();
      }
    });
  }

  navigateToBlog() {
    return this.router.navigate([DigitalitRoutes.blog.main]);
  }

  navigateToFormsEditPanel() {
    return this.router.navigate([AdminPanelRoutes.home]);
  }

  get flowlUrl() {
    return DigitalitRoutes.flow.main;
  }

  get isRootPage() {
    return this.router.url === GlobalRoutes.root;
  }

  searchKeywordTyped(keyword: string) {
    const txt = keyword.trim().toLowerCase();
    // if (keyword.length >= 3 || keyword.length === 0) {
    this.startSearchingFor(txt);
    this.searchKeywordChanged.emit(txt);
    // }
  }

  startSearchingFor(keyword: string) {
    this._searchedKeyword = keyword;
    this.autoSearchTimer.activate();
  }

  performSearch() {
    this.headerService.searchKeywordChange(this._searchedKeyword);
  }

  logOut() {
    if (this._authenticationService.getIsDeloitteUser()) {
      this._flowAPI.removeCurrentEditorFromAll();
      this._authenticationService.signOutMsal();
    }
  }
}
