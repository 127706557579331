<div class="modal-header">
    <div class="title-container">
      <h5 class="title">פעולה נדרשת</h5>
    </div>
</div>

<div class="modal-body">
    <p [innerHTML]="action.message"></p>
</div>

<div class="modal-footer">
    <div class="buttons-container">
        <button class="btn btn-primary" *ngFor="let button of action.buttons" (click)="buttonClicked(button)">{{ button.title }}</button>
    </div>
</div>
