import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormField, FormFieldType } from '@digital/app/_models/form-field.model';
import { FormState, FormType } from '@digital/app/_models/form.model';
import { IFlowEditingPermissions } from '@digital/app/_interfaces/flow-editing-permissions.interface';
import { CreateCustomFormFieldDTO } from '@digital/app/_dtos/create-custom-field.dto';
import { LeadFormService } from '../lead-form.service';
import { LeadFormField } from '@digital/app/_models/lead-form-field';

@Component({
  selector: 'lead-app-fields-tree',
  templateUrl: './lead-fields-tree.component.html',
  styleUrls: ['./lead-fields-tree.component.scss']
})
export class LeadFieldsTreeComponent implements OnInit {
  @Input() fields: FormField[];
  @Input() editingPermissions: IFlowEditingPermissions;
  @Input() shouldLockFieldsForEditing = false;

  @Output() didUpdateFormFieldValue = new EventEmitter<FormField>();
  @Output() mouseDidEnterFormField = new EventEmitter<FormField>();
  @Output() mouseDidLeaveFormField = new EventEmitter<FormField>();
  @Output() createCustomField = new EventEmitter<CreateCustomFormFieldDTO>();
  @Output() editFieldButtonClicked = new EventEmitter<FormField>();
  @Output() deleteFieldButtonClicked = new EventEmitter<FormField>();

  formType = FormType;
  fieldType = FormFieldType;
  formState = FormState;

  private _mouseEnterEvent: (event: MouseEvent) => void;
  private _mouseLeaveEvent: (event: MouseEvent) => void;

  constructor(public formsService: LeadFormService, public _elementRef: ElementRef) {}

  ngOnInit(): void {
    this._addMouseEvents();
  }

  ngOnDestroy() {
    this._removeMouseEvents();
  }

  onClickInput(field: FormField) {
    console.log({ field });
    this.formsService.selectField(field);
  }

  ngAfterViewInit() {
    for (const field of this.fields) {
      const elseCondition = field.conditionalLogic ? (field.conditionalLogic.else ? true : false) : false;
      if (elseCondition) {
        if (field.conditionalLogic.else.code && field.value) {
          field.newValue = field.value;
          this.formsService.insertFormFieldValue(field);
        }
      }
    }
  }

  isVisible(field: FormField): boolean {
    let returnValue: boolean = false;

    if (!field.isVisible && field.type !== this.fieldType.CustomFieldPlaceholder) {
      returnValue = true;
    }

    return returnValue;
  }

  private _addMouseEvents() {
    for (const field of this.fields) {
      this._mouseEnterEvent = (event: MouseEvent) => {
        this.mouseDidEnterFormField.emit(field);
      };

      this._mouseLeaveEvent = (event: MouseEvent) => {
        this.mouseDidLeaveFormField.emit(field);
      };

      this._elementRef.nativeElement.addEventListener('mouseenter', this._mouseEnterEvent);
      this._elementRef.nativeElement.addEventListener('mouseleave', this._mouseLeaveEvent);
    }
  }

  private _removeMouseEvents() {
    this._elementRef.nativeElement.removeEventListener('mouseenter', this._mouseEnterEvent);
    this._elementRef.nativeElement.removeEventListener('mosueleave', this._mouseLeaveEvent);
  }
}
