import { Component, OnInit } from '@angular/core';
import { FormsFacade } from '../../form.facade';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  forms$ = this._formsFacade.forms$;

  constructor(private _formsFacade: FormsFacade) {
  }

  ngOnInit(): void {
    this._formsFacade.getAllForms();
  }

}
