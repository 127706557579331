<div class="field-container" [formGroup]="createFlowForm">
  <div class="row fields-container" formGroupName="permissions">
    <div class="col-12">
      <div formGroupName="managers">
        <div class="input-group row">
          <div class="col-4">
            <label for="partner">
              בטיפול שותף/ה
              <app-svg-icon
                name="help-indicator-glyph_special"
                ngbTooltip="נא לבחור מהרשימה הנ״ל, ניתן לשנות בהמשך במידת הצורך תחת הגדרות התהליך"
              ></app-svg-icon>
            </label>
          </div>
          <div class="col-5" [ngClass]="{ inv: getValidationAfterSubmit('managers', 'partner') }">
            <ng-template #customTemplate let-r="result" class="check-test">
              <img [src]="'assets/images/user.png'" class="img-template" />
              <ngb-highlight [result]="r.name" class="highlight-title"></ngb-highlight>
            </ng-template>
            <input
              type="text"
              class="form-control user-auto-complete"
              placeholder="בחירת שותף/ה"
              [ngbTypeahead]="deloittePartnersAutoComplete"
              #instance="ngbTypeahead"
              [inputFormatter]="formatter"
              (selectItem)="selectedValuePartner($event.item)"
              formControlName="partner"
              [resultTemplate]="customTemplate"
              placement="bottom-right"
            />
          </div>
        </div>
        <!--  -->
        <div class="input-group row">
          <div class="col-4">
            <label for="manager">
              בטיפול מנהל/ת התיק
              <app-svg-icon
                name="help-indicator-glyph_special"
                ngbTooltip="נא לבחור מהרשימה הנ״ל, ניתן לשנות בהמשך במידת הצורך תחת הגדרות התהליך"
              ></app-svg-icon>
            </label>
          </div>
          <div class="col-5" [ngClass]="{ inv: getValidationAfterSubmit('managers', 'manager') }">
            <ng-template #customTemplate let-r="result" class="check-test">
              <img [src]="'assets/images/user.png'" class="img-template" />
              <ngb-highlight [result]="r.name" class="highlight-title"></ngb-highlight>
            </ng-template>
            <input
              type="text"
              class="form-control user-auto-complete"
              placeholder="בחירת מנהל/ת"
              [ngbTypeahead]="deloitteManagersAutoComplete"
              #instance="ngbTypeahead"
              [inputFormatter]="formatter"
              (selectItem)="selectedValueManager($event.item)"
              formControlName="manager"
              [resultTemplate]="customTemplate"
              placement="bottom-right"
            />
          </div>
        </div>
      </div>
      <!-- manager close-->
      <div class="input-group row">
        <div class="col-4">
          <label for="internalUsers" class="premission-title"> הרשאות עריכה נוספות </label>
        </div>
      </div>
      <!--end title -->
      <div formArrayName="internalUsers">
        <div class="input-group row" *ngFor="let deloitteUserNumber of internalUsers.controls; index as i">
          <div class="col-5 deloitte-user">
            <ng-template #customTemplate let-r="result" class="check-test">
              <img [src]="'assets/images/user.png'" class="img-template" />
              <ngb-highlight [result]="r.name" class="highlight-title"></ngb-highlight>
              <div class="details">
                <label id="title">{{ r.rankDesc }}</label>
              </div>
            </ng-template>
            <input
              type="text"
              class="form-control user-auto-complete"
              placeholder="הכנס שם משתמש / מייל / שם בעברית"
              [ngbTypeahead]="deloitteUsersAutoComplete"
              #instance="ngbTypeahead"
              [inputFormatter]="formatter"
              (selectItem)="selectedValueDeloitteUser($event.item, i)"
              (blur)="onInternalUserEmailBlur(i)"
              [formControlName]="i"
              [resultTemplate]="customTemplate"
              placement="bottom-right"
            />
          </div>
          <div class="col-1">
            <button class="delete-user-button" [ngbTooltip]="'מחיקה'" (click)="deleteDeloitteUser(i)">
              <app-svg-icon name="bin-red_special"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
      <!-- -->
      <div class="row input-group">
        <div class="col-3">
          <button type="button" class="btn btn-secondary" (click)="addDeloitteUser()">הוסף משתמש נוסף</button>
        </div>
      </div>
      <!-- -->
      <div class="input-group row">
        <div class="col-4">
          <label for="internalUsers" class="form-check-label" for="defaultCheck1"> הוספת משתמשים מצד לקוח </label>
          <div class="checkbox">
            <input type="checkbox" (change)="didChangeExternalUserCheckBox()" />
            <span class="checkmark"></span>
          </div>
        </div>
      </div>
      <!-- -->
      <div formArrayName="externalUsers">
        <div
          class="input-group row"
          *ngFor="let clientNumber of externalUsers.controls; index as i"
          [formGroupName]="i"
        >
          <ng-container *ngIf="externalUserCheckBox">
            <div class="col-3" name="externalUserTypeContainer">
              <select
                class="custom-select rounded-0"
                formControlName="externalUserType"
                name="externalUserType"
                [ngClass]="{ inv: getValidationAfterSubmitForArray('externalUserType', i) }"
              >
                <option selected value="" disabled>בחירת הרשאה</option>
                <option *ngFor="let option of externalUserOptions" [value]="ExternalUserType[option]">
                  {{ getHebrewUserType(option) }}
                </option>
              </select>
            </div>
            <div class="col-3">
              <input
                type="email"
                class="form-control"
                name="externalUserEmail"
                formControlName="externalUserEmail"
                (blur)="onExternalUserEmailBlur(i)"
                [placeholder]="'הכנס כתובת מייל'"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="isEnteredClientEmail[i] && externalUserCheckBox">
            <div class="col-3">
              <input
                type="input"
                class="form-control"
                name="externalUserFirstName"
                formControlName="externalUserFirstName"
                [placeholder]="'הכנס שם פרטי'"
                [ngClass]="{ inv: getValidationAfterSubmitForArray('externalUserFirstName', i) }"
              />
            </div>
            <div class="col-3">
              <input
                type="input"
                class="form-control"
                name="externalUserLastName"
                formControlName="externalUserLastName"
                [placeholder]="'הכנס שם משפחה'"
                [ngClass]="{ inv: getValidationAfterSubmitForArray('externalUserLastName', i) }"
              />
            </div>
          </ng-container>
          <div class="col-1 deleteUserButtonContainer">
            <button
              class="delete-user-button"
              name="deleteUserButton"
              *ngIf="externalUserCheckBox"
              [ngbTooltip]="'מחיקה'"
              (click)="deleteExternalUser(i)"
            >
              <app-svg-icon name="bin-red_special"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
      <!-- -->
      <div class="row input-group" *ngIf="externalUserCheckBox">
        <div class="col-3">
          <button type="button" class="btn btn-secondary" (click)="addExternalUser()">הוסף משתמש נוסף</button>
        </div>
      </div>
      <!-- -->
    </div>
  </div>
</div>
