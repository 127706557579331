import { Deserializable } from '@digital/app/_models/deserializable.model';

export enum ExternalUserType {
  Editor = 'editor',
  Signer = 'signer',
  All = 'all'
}

export interface ExternalUser {
  firstName: string;
  lastName: string;
  email: string;
  role: ExternalUserType;
}

export interface FullExternalUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: ExternalUserType;
  status: string;
  mobile?: string;
}

export class ExternalUsers implements Deserializable {
  externalUsers: ExternalUser[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
