import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Flow } from '@digital/app/_models/flow.model';
import { FlowService } from '@digital/app/core/services/flow.service';
import { Stage } from '@digital/app/flow/models';
import { UserService } from '@digital/app/core/services/user.service';
import { User } from '@digital/app/_models/user.model';

@Component({
  selector: 'app-consultation-state',
  templateUrl: './consultation-state.component.html',
  styleUrls: ['./consultation-state.component.scss']
})
export class ConsultationStateComponent implements OnChanges {
  @Input() isConsultation: Stage;
  @Input() currentUserID: any;
  @Input() flow: Flow;
  consultationUserName: string;
  constructor(private _flowService: FlowService, private _userService: UserService) {}

  async ngOnChanges() {
    if (this.isConsultation) {
      const user: User = await this._userService.getUserDetails(this.isConsultation.consult.consultationUserID);
      this.consultationUserName = user.fullName.hebrew;
    }
  }

  async approveConsultation() {
    const deleteRequest = await this._flowService.deleteConsultation(this.flow.id, this.flow.stage.id);
    if (deleteRequest.code === 200) {
      this._flowService.emitDataFromDynamicFooter(202);
    }
  }
}
