import { Injectable } from '@angular/core';
import { LeadFormField } from '@digital/app/_models/lead-form-field';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeadSelectedFormField {
  private field: Subject<LeadFormField> = new Subject();
  private waitingField: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  selectedField(): Observable<LeadFormField> {
    return this.field;
  }

  selectField(field: LeadFormField) {
    this.field.next(field);
  }

  isWaitingField(): Observable<boolean> {
    return this.waitingField;
  }

  waitField(waitingField: boolean): void {
    this.waitingField.next(waitingField);
  }
}
