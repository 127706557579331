<!-- Start Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light container" *ngIf="form$ | async as form">
  <button type="button" class="btn btn-outline-secondary back-to-home" routerLink="{{homeRoute}}">
    <app-svg-icon name="angle-right_special"></app-svg-icon>
    חזרה לעמוד ראשי
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav add-new-field">
      <button class="btn btn-primary" (click)="addAnnotationFields()">
        <app-svg-icon name="plus_special"></app-svg-icon>
        הוסף סימון
      </button>
    </div>
    <div class="navbar-nav">
      <button class="btn btn-danger" (click)="onDeleteForm()">
        <app-svg-icon name="close_special"></app-svg-icon>
        מחיקת טופס
      </button>
    </div>
  </div>
  <div class="nav-item nav-link" *ngIf="pdfSrc && form.type === formType.Form">
    דף {{ currentPage }} מתוך {{ totalPages }}
  </div>
  <button
    type="button"
    [value]="form.state"
    class="btn"
    [class.btn-success]="form.state === 'Draft'"
    [class.btn-danger]="form.state === 'Published'"
    (click)="onStateButtonClicked($event.target.value)"
  >
    {{ form.state === 'Draft' ? 'Publish' : 'Unpublish' }}
  </button>
</nav>
<!-- End Navbar -->

<div class="container">
  <div class="title-container">
    <h2>{{ (form$ | async).name }} {{ (form$ | async).formYear }}</h2>
  </div>
</div>


<div class="edit-form">
  <div class="container">
    
    <!-- Start Empty View -->
    <!-- <div class="empty-container" *ngIf="(form$ | async).type !== formType.Form">
      <h1 class="title">טופס זה אינו טופס PDF. ניתן לערוך את השדות בטופס זה ישירות בדטאבייס.</h1>
    </div> -->
    <!-- End Empty View -->

    <!-- Start PDF Form -->
    <div class="pdf-container" *ngIf="pdfSrc">
      <pdf-viewer
        src="{{ pdfSrc }}"
        (page-rendered)="onPageRendered($event)"
        (after-load-complete)="onLoadComplete($event)"
        [(page)]="currentPage"
        [render-text]="false"
        [original-size]="true"
        [show-all]="true"
        [show-borders]="false"
      >
      </pdf-viewer>
    </div>
    <!-- End PDF Form -->
  </div>
</div>
