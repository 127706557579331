import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserGuard } from '@libs/guards/browser.guard';
import { LeadHomeComponent } from './lead-home/lead-home.component';
import { LeadQueriesResultsPage } from './lead-queries-results-page/lead-queries-results-page.component';
import { LeadQueryComponent } from './lead-query/lead-query.component';
import { LeadQueryGuard } from './lead-query/lead-query.guard';
import { GlobalRoutes } from '@libs/constants';

const routes: Routes = [
  {
    path: GlobalRoutes.qtax.base,
    component: LeadHomeComponent,
    canActivate: [MsalGuard, BrowserGuard],
    data: { returnUrl: GlobalRoutes.qtax.base }
  },
  {
    path: `${GlobalRoutes.qtax.base}/query-results/:id`,
    component: LeadQueriesResultsPage,
    canActivate: [MsalGuard, BrowserGuard]
  },
  {
    path: `${GlobalRoutes.qtax.base}/${GlobalRoutes.qtax.query}`,
    component: LeadQueryComponent,
    canActivate: [MsalGuard, BrowserGuard],
    canDeactivate: [LeadQueryGuard]
  },
  {
    path: `${GlobalRoutes.qtax.base}/${GlobalRoutes.qtax.query}/:id`,
    component: LeadQueryComponent,
    canActivate: [MsalGuard, BrowserGuard],
    canDeactivate: [LeadQueryGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadRoutingModule {}
