import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Note } from '../../models/note';
import { FormsService } from '../../forms.service';
import { NoteComment } from '../../models/note-comment';
import { UserMention } from '../../models/user-mention';
import { AlertModalComponent } from '@digital/app/shared/components/alert-modal/alert-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@digital/app/core/services/user.service';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss']
})
export class NotesListComponent implements OnInit {
  user = null
  notes: Note[];
  showAddNoteComponent: boolean = false;
  selectedNoteId: number | null = null;
  previousStatus: string | null = null;
  @Input() mentionUsers: UserMention[] = null;
  @Output() hideRightClickMenu: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideAddNewNote: EventEmitter<any> = new EventEmitter<any>();
  constructor(private formsService: FormsService,  private modalService: NgbModal, private userService: UserService ) {}

  ngOnInit(): void {
    this.formsService.notes$.subscribe((notes) => {
      this.notes = notes;
    });
    this.user = this.userService.getCurrentUser();
  }

  onStatusChange(noteId: number, newStatus: string): void {
    const circleElement = document.getElementById('circle-' + noteId);
    if (newStatus === 'delete') {
      const ref = this.modalService.open(AlertModalComponent, { centered: true }).componentInstance;
      ref.title = 'מחיקת הערה מספר ' + noteId;
      ref.message = 'האם את.ה בטוח.ה למחוק הערה זו?';
      ref.positiveAction('אישור', () => {
        this.notes = this.notes.filter((note) => note.id !== noteId);
        //this.formsService.notes$.next(formData.notes);
        this.formsService.onStatusChange(newStatus, noteId);
        circleElement.remove();
      });
      ref.negativeAction('ביטול', () => {
        const noteToRevert = this.notes.find((note) => note.id === noteId);
        noteToRevert.status = this.previousStatus;
      });
    }
    else {
      this.formsService.onStatusChange(newStatus, noteId);
      circleElement.className = '';
      circleElement.classList.add('circle', newStatus);
    }
    this.formsService.updateNotes(this.notes);
  }

  isNotExternalUserOrCreator(noteCreatorId: string): boolean {
    return this.user.role !== 'external' || this.user.id === noteCreatorId;
  }

  //Todo: need to be on utils service
  localDateTime(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    // const hours = date.getHours().toString().padStart(2, '0');
    // const minutes = date.getMinutes().toString().padStart(2, '0');
    // const seconds = date.getSeconds().toString().padStart(2, '0');
    const formattedDateTime = `${day}/${month}/${year}`;// ${hours}:${minutes}:${seconds}

    return formattedDateTime;
  }

  onAddNote(noteId: number) {
    this.selectedNoteId = noteId;
    this.showAddNoteComponent = true;
  }

  public toggleAccordion(id: number, fromCircle: boolean = false) {
    this.previousStatus = this.notes.filter((note) => note.id === id)[0].status;
    this.hideRightClickMenu.emit();
    this.showAddNoteComponent = false;
    if (this.selectedNoteId === id) {
      this.selectedNoteId = null; // Close the clicked accordion if it's already open
    } else {
      this.hideAddNewNote.emit();
      if (fromCircle) {
        document
            .getElementById('note-button-' + id)
            .scrollIntoView({
              behavior: 'smooth',
              block: this.selectedNoteId > id || this.selectedNoteId == null ? 'start' : 'nearest'
            })
      }
      else {
        const circleToFocus = document.getElementById('circle-' + id);
        circleToFocus.scrollIntoView({ behavior: 'smooth', block: 'start' });
        // circleToFocus.className = '';
        circleToFocus.classList.add('circleToFocus');
        // Reset the border after 3 seconds using setTimeout
        setTimeout(() => {
          // circleToFocus.className = '';
          circleToFocus.classList.remove('circleToFocus');
        }, 3000);
      }
      this.selectedNoteId = id; // Open the clicked accordion
    }
  }

  formatText(text: string) {
    let formattedText = text.replace(/@/g, "<span class='mention-mark-text'>@</span>");
    this.mentionUsers.forEach(user => {
      if(formattedText.includes(user.name))
        formattedText = formattedText.replace(user.name, `<span class='mention-mark-text'>${user.name}</span>`);
    });
    return formattedText;
  }

  getUserNameById(id: number): string {
    return this.mentionUsers.find((user) => user.id === id).name;
  }
}
