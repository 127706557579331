<section
  *ngIf="!currentUserEditor"
  class="flow-section content"
  [class.file]="type === 'file'"
  [class.eqcr]="type === 'eqcr'"
>
  <div class="title-container">
    <h4 class="title">{{ title }}</h4>
  </div>
  <div class="content-container">
    <p class="description" [innerHTML]="description"></p>
    <ng-content select="[content]"></ng-content>
  </div>
  <app-uploader
    *ngIf="flow.stage.id === FlowStage.CLIENT_SIGNATURE || flow.stage.id === FlowStage.IRS_CONFIRMATION"
    (containsUploadedFiles)="containsUploadedFiles.emit($event)"
    [flow]="flow"
    [maxFiles]="1"
    instructions="נא להעלות קובץ בפורמט PDF בלבד."
  >
  </app-uploader>
</section>
