import { Component, OnInit, Input, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Flow } from '@digital/app/_models/flow.model';
import { FlowFormsAPI } from '@digital/app/_apis/flow-forms.api';
import { Categories, CategoryForm } from '@digital/app/flow/models/forms-categories';
import { ConfirmationModalComponent } from '@digital/app/shared/components/confirmation-modal/confirmation-modal.component';
import { UpdateSectionFormInstancesDTO } from '@digital/app/flow/models/section.model';
import { ErrorDialogComponent } from '@libs/views/error-dialog/error-dialog.component';

@Component({
  selector: 'app-kit-modal',
  templateUrl: './kit-modal.component.html',
  styleUrls: ['./kit-modal.component.scss']
})
export class KitModalComponent implements OnInit {
  @Input() flow: Flow;
  @Output() dataUpdated: EventEmitter<boolean> = new EventEmitter();

  categories: Categories[];
  uncheckedAllForms = false;
  formInstances = 0;
  formInstancesOfPreviousValue = 0;

  isLoading = false;
  hasFormsAvailable = false;
  performingChanges = false;
  finishedPerformingChanges = false;

  addedForms: CategoryForm[] = [];
  removedForms: CategoryForm[] = [];
  addFormInstancesDTO: UpdateSectionFormInstancesDTO[] =[];
  removeInstanceFormDTO: UpdateSectionFormInstancesDTO[] =[];

  // Lifecycle
  constructor(
    private _activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private flowFormsApi: FlowFormsAPI,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.flowFormsApi.getDigitalForms(this.flow.id).subscribe((categories) => {
      this.categories = categories;
      this._checkIfAnyFormsAvailableInCategories(categories);
      this.isLoading = false;
    });

  }

  private _checkIfAnyFormsAvailableInCategories(categories: Categories[]) {
    for (const category of categories) {
      if (!this.hasFormsAvailable && category.forms.length > 0) {
        this.hasFormsAvailable = true;
        break;
      }
    }
  }

  //get


  // Actions

  didChangeFormCheckState(checkbox: any, form: CategoryForm) {
    if (checkbox.checked) {
      this.addForm(form);
      form.instanceCount = this.formInstancesOfPreviousValue!== 0? this.formInstancesOfPreviousValue: 1;
      this.formInstancesOfPreviousValue = 0;
    } else {
      this.formInstancesOfPreviousValue = form.instanceCount;
      form.instanceCount = 0;
      this.removeForm(form);
    }

    console.log('Added:', this.addedForms);
    console.log('Removed:', this.removedForms);
  }

  didChangeFormInstances(event: any, form: CategoryForm){
    const value = parseInt(event.value);

    if(value > 50 || value < 0){
      const modalRef = this.modalService.open(ErrorDialogComponent, {
        windowClass: 'confirmation-modal',
        centered: true
      });

      modalRef.componentInstance.title = 'שגיאה';

      if (value > 50) {
        modalRef.componentInstance.body = 'לא ניתן להכניס יותר מ 50 מופעים';
        event.value = '50';
      } else if (value < 0) {
        modalRef.componentInstance.body = `לא ניתן להכניס מספר שלילי`;
        event.value = '0';
      }

      return;
    }

    if(form.isSelected === false){
      form.isSelected = true;
      this.addForm(form);
    }

    const dto: UpdateSectionFormInstancesDTO = {
      formID: form.id,
      instanceID: value - 1,
      name: form.name
    };

    //delete instance of form without delete it all
    if(form.instanceCount - value > 0){
      dto.instanceID = value;
      this.removeInstance(dto);
    }
    else if(form.instanceCount - value !== 0 && value - 1 !== 0){
      this.addInstance(dto);
    }else {
      this.addFormInstancesDTO = [];
      this.removeInstanceFormDTO = [];
    }

    if(value === 0){
      form.isSelected = false;
      this.addFormInstancesDTO = [];
      this.removeInstanceFormDTO = [];
      this.removeForm(form);
    }

    console.log('Added:', this.addedForms);
    console.log('Removed:', this.removedForms);
    console.log('added instances:', this.addFormInstancesDTO);
    console.log('Removed instances:', this.removeInstanceFormDTO);
  }

  private addForm(form: CategoryForm) {
    if (this.removedForms.some((f) => f.id === form.id)) {
      const index = this.removedForms.findIndex((f) => f.id === form.id);
      this.removedForms.splice(index, 1);
    } else if ((this.addedForms.some((f) => f.id !== form.id))|| this.addedForms.length === 0){
      this.addedForms.push(form);
    }
  }

  private addInstance(formInstance: UpdateSectionFormInstancesDTO) {
    if (this.removeInstanceFormDTO.some((f) => f.formID === formInstance.formID)) {
      const index = this.removeInstanceFormDTO.findIndex((f) => f.formID === formInstance.formID);
      this.removeInstanceFormDTO.splice(index, 1);
    } else {
      this.addFormInstancesDTO.push(formInstance);
    }
  }

  private removeInstance(formInstance: UpdateSectionFormInstancesDTO) {
    if (this.addFormInstancesDTO.some((f) => f.formID === formInstance.formID)) {
      const index = this.addFormInstancesDTO.findIndex((f) => f.formID === formInstance.formID);
      this.addFormInstancesDTO.splice(index, 1);
    } else {
      this.removeInstanceFormDTO.push(formInstance);
    }
  }

  private removeForm(form: CategoryForm) {
    if (this.addedForms.some((f) => f.id === form.id)) {
      const index = this.addedForms.findIndex((f) => f.id === form.id);
      this.addedForms.splice(index, 1);
    } else {
      this.removedForms.push(form);
    }

  }

  checkPastDocuments() {
    // this.categories.forEach(category => {
    //   category.forms.forEach(form => {
    //     if (form.usedLastYear) {
    //       form.isSelected = true;
    //       if (this.addFormsIDs.includes(form.id)) {
    //         this.addFormsIDs.push(form.id);
    //       }
    //     }
    //   });
    // });
  }

  disableAllCheckedForms() {
    this.categories.forEach((category) => {
      category.forms.forEach((form) => {
        if (form.isSelected === true) {
          form.isSelected = false;
          this.uncheckedAllForms = true;
          this.removeForm(form);
        }
      });
    });
  }

  async saveButtonClicked() {
    if (this.removedForms.length > 0 || this.removeInstanceFormDTO.length > 0) {
      this.showWarningModalBeforeRemovingForms();
    } 
    else if(this.addedForms.length > 0 || this.addFormInstancesDTO.length > 0){
      this.addOrDeleteDigitalForms();
    }
  }

  private showWarningModalBeforeRemovingForms() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      windowClass: 'confirmation-modal',
      scrollable: true,
      centered: true
    });
    modalRef.componentInstance.flow = this.flow;
    modalRef.componentInstance.actionType = 'digitalForms';
    modalRef.componentInstance.title = 'מחיקת טפסים';
    modalRef.componentInstance.subtitle = 'שימו לב! הטפסים הבאים וכל המידע שלהם ימחק:';
    modalRef.componentInstance.content = this.buildFormListIntHTML(this.removedForms, this.removeInstanceFormDTO);
    modalRef.result.then((isApproved) => {
      if (isApproved) {
        this.addOrDeleteDigitalForms();
      }
    });
  }

  private buildFormListIntHTML(forms: CategoryForm[], removeInstance: UpdateSectionFormInstancesDTO[]): string {
    let html = '<ol>';
    forms.forEach((f) => {
      html += `<li>${f.name}</li>`;
    });
    removeInstance.forEach((f) => {
      html += `<li>${f.name} - (${f.instanceID})</li>`;
    });
    html += '</ol>';
    return html;
  }

  private addOrDeleteDigitalForms() {
    const addFormsIDs = this.addedForms.map((form) => form.id);
    const removeFormsIDs = this.removedForms.map((form) => form.id);

    this.performingChanges = true;
    this.flowFormsApi.addOrDeleteDigitalForms(this.flow.id, addFormsIDs, removeFormsIDs, 
      this.removeInstanceFormDTO, this.addFormInstancesDTO).subscribe(() => {
      this.dataUpdated.emit(true);
      setTimeout(() => {
        this.finishedPerformingChanges = true;
      }, 2000);
    });
  }

  closeModal() {
    this._activeModal.close();
  }
}
