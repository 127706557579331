<form [formGroup]="form">
  <app-lead-query-top-bar
    [isNew]="isNew"
    [isDisabled]="isLoading"
    (clickBack)="onClickBack()"
    (clickSave)="onClickSave()"
    (clickDelete)="onClickDelete()"
    (clickClone)="onClickClone()"
    (clickShare)="onClickShare()"
  >
  </app-lead-query-top-bar>
  <ng-container *ngIf="isLoading; then loading; else loaded"></ng-container>
</form>

<ng-template #loading>
  <app-loading class="loading"></app-loading>
</ng-template>

<ng-template #loaded>
  <div class="container-fluid">
    <div class="digital-form">
      <div class="title-container">
        <div class="row h-100 align-items-center">
          <div class="col text-right">
            <h3>הזן את הנתונים הבאים:</h3>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <app-lead-query-properties
          #propertiesComp
          [form]="properties"
          [years]="years$ | async"
          (yearChange)="onYearChange($event)">
        </app-lead-query-properties>
      </div>
      <div class="mt-4 mb-4">
        <app-lead-query-criteria [form]="criteria"> </app-lead-query-criteria>
      </div>
      <div [id]="pdfFormElementId" class="mt-4 mb-4">
        <lead-query-pdf-form-wrapper [year]="year"></lead-query-pdf-form-wrapper>
      </div>
      <div class="mt-4">
        <lead-query-results-table [state]="resultsState$ | async"> </lead-query-results-table>
      </div>
    </div>
  </div>
</ng-template>
