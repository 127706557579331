<main>
  <div class="container-fluid action-toolbar-container">
    <button [routerLink]="queryRoute" class="btn btn-primary">
      <app-svg-icon name="plus_special"></app-svg-icon>
      צור שאילתה חדשה
    </button>

    <app-lead-queries-table [state]="state | async"></app-lead-queries-table>
  </div>
</main>

<app-footer></app-footer>
