import { environment } from '@digital/environments/environment';

// Global app settings
export class Constants {
  public static languageDirection = 'rtl';
}

// App routes
export class AdminPanelRoutes {
  public static home = 'forms-edit-panel';
  public static editForm = '/edit-form/:id';
  public static createForm = '/create-form';
  public static duplicate = '/duplicate-form';
  public static login = 'login';
}

// tslint:disable-next-line: variable-name
export const GlobalAPI = {
  baseURL: environment.apiUrl,
  endpoints: {
    users: {
      base: 'users',
      auth: 'users/auth',
      refreshToken: 'users/auth/refresh'
    },
    forms: {
      base: 'adminPanel/forms',
    },
    duplicate: {
      base: 'duplicate',
    },
    formsFields: {
      base: 'fields'
    },
    fields: {
      base: 'fields'
    },
    option: {
      base: 'options'
    }
  }
};

