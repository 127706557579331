import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { LeadService } from '@digital/app/core/services/lead.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription, Observable, merge } from 'rxjs';
import { User } from '@digital/app/_models/user.model';

@Component({
  selector: 'app-lead-share-query-modal',
  templateUrl: './lead-share-query-modal.component.html',
  styleUrls: ['./lead-share-query-modal.component.scss']
})
export class LeadShareQueryModalComponent implements OnInit {
  @Input() queryId: number;

  shareFlowForm: FormGroup;
  shareWithField = new FormControl('');

  deloitteTaxUsers: User[] = [];
  selectedUserId: number;
  selectedUser: User;

  isLoading = false;
  private subscriptions = new Subscription();

  // Viewchilds
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  constructor(
    private _activeModal: NgbActiveModal,
    private _leadService: LeadService,
    private _formBuilder: FormBuilder
  ) {}

  flowPartners: User[];

  ngOnInit(): void {
    this._setupFormBuilder();
    this._getAllDeloitteUsers();
  }

  private _getAllDeloitteUsers() {
    const subscription = this._leadService
      .getUsersOfTaxDivision()
      .pipe(map((response) => this._onGetAllDeloitteTaxUsers(response.data)))
      .subscribe((x) => console.log(x));

    this.subscriptions.add(subscription);
  }

  private _setupFormBuilder() {
    this.shareFlowForm = new FormGroup({
      shareWithField: new FormControl('', [Validators.required, Validators.min(1), Validators.maxLength(60)])
    });
  }

  submitOnShare(): void {
    this.isLoading = true;

    if (this.selectedUserId) {
      const subscription = this._leadService
        .shareQuery(this.queryId, this.selectedUserId)
        .pipe(map((response) => ({ results: response.data })))
        .subscribe((x) => console.log(x));

      this.subscriptions.add(subscription);
    }
    this._activeModal.close();
  }

  formatter = (user: { name: string }) => user.name;

  private _onGetAllDeloitteTaxUsers(users: User[]) {
    this.deloitteTaxUsers = users
      .map((users) => users)
      .sort((a, b) => a.fullName.english.localeCompare(b.fullName.english));
  }

  deloitteUsersAutoComplete = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$).pipe(
      map((term) =>
        (term === ''
          ? this.deloitteTaxUsers.map((user: any) => user)
          : this.deloitteTaxUsers
              .filter(
                (user: User) =>
                  user.fullName.english.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                  user.fullName.hebrew.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                  user.email.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .map((user: any) => user)
        ).slice(0, 7)
      )
    );
  };

  onSelectUser(selectedValue: User) {
    this.selectedUserId = selectedValue.id;
    this.selectedUser = selectedValue;
  }

  closeModal(approved: boolean) {
    this._activeModal.close(approved);
  }
}
