import { Deserializable } from '@digital/app/_models/deserializable.model';

export enum CommentType {
  Comments = 'comments',
  Replies = 'replies'
}

export interface User {
  id: number;
  fullName: {
    english: string;
    hebrew: string;
  };
  nameInitials: string;
  rank: {
    english: string;
    hebrew: string;
  };
}

export interface Stage {
  id: number;
  name: string;
}

export class Comment implements Deserializable {
  id: number;
  message: string;
  user: User;
  stage: Stage;
  parentID?: number;
  replyCount: number;
  replies?: Comment[];
  createdAt: Date;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  get type(): CommentType {
    return this.parentID === null ? CommentType.Comments : CommentType.Replies;
  }
}
