import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AuthenticationStore, AuthenticationState } from './authentication.store';
import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationQuery extends Query<AuthenticationState> {
  isLoggedIn$ = this.select((state) => !!state.accessToken);
  id$ = this.select((state) => state.id);
  username$ = this.select((state) => state.username);
  nameInitials$ = this.select((state) => state.nameInitials);
  rank$ = this.select((state) => state.rank);
  role$ = this.select((state) => state.role);
  accessToken$ = this.select((state) => state.accessToken);
  isAuthenticated$ = this.select((state) => state.isAuthenticated);
  division$ = this.select((state) => state.division);

  // Lifecycle
  constructor(protected store: AuthenticationStore) {
    super(store);
  }

  // Returns true if access token exists (token can be expired)
  isLoggedIn(): boolean {
    return !!this.getValue().accessToken;
  }

  // Returns true if access token exists and is not expired
  isAuthenticated(): boolean {
    return !!this.getValue().isAuthenticated;
  }

  getCurrentUser(): User {
    return {
      id: this.getValue().id,
      username: this.getValue().username,
      nameInitials: this.getValue().nameInitials,
      rank: this.getValue().rank,
      role: this.getValue().role,
      accessToken: this.getValue().accessToken,
      refreshToken: this.getValue().refreshToken,
      isAuthenticated: this.getValue().isAuthenticated,
      division: this.getValue().division,
      isDisclaimed: this.getValue().isDisclaimed
    };
  }
}
