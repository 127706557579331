<div id="form-field-{{ field.id }}" class="form-field">
    <h5 class="form-field-title">
        <span class="field-identifier field-number" *ngIf="field.number">{{ field.number }}.</span>
        <span class="field-identifier field-taxCode" *ngIf="field.taxCode">[{{ field.taxCode }}]</span>
        {{ field.name }}
        <app-tooltip-indicator [tooltipTitle]="field.note"></app-tooltip-indicator>
    </h5>
    <p class="form-field-description" [innerHTML]="field.description"></p>
    <hr>
</div>
