import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormsApi } from '../core/api/forms.api';
import { DuplicateForm, Form } from './models/form.model';
import { Router } from '@angular/router';
import { Section } from './models/sections.model';
import { AdminPanelRoutes } from '@admin/app/shared/constants';

@Injectable({ providedIn: 'root' })
export class CreateFormFacade {
  createdFields$: BehaviorSubject<any> = new BehaviorSubject(new Form());
  sections$: BehaviorSubject<Section[]> = new BehaviorSubject([new Section()]);

  // Lifecycle
  constructor(private _formsAPI: FormsApi, private _router: Router) {
  }

  // Methods
  async createNewForm(form: Form) {
    this._formsAPI.createNewForm(form)
      .subscribe(createdForm => {
        this.createdFields$.next(createdForm);
        this.createdFields$.subscribe(newForm => {
          this._router.navigate([AdminPanelRoutes.home + '/edit-form', newForm.data.id]);
        });
      });
  }

  async duplicateForm(form: DuplicateForm) {
    this._formsAPI.duplicateForm(form)
      .subscribe(createdForm => {
        this.createdFields$.next(createdForm);
        this.createdFields$.subscribe(newForm => {
          this._router.navigate([AdminPanelRoutes.home + '/edit-form', newForm.data.form.id]);
        });
      });
  }

  getSections() {
    this._formsAPI.getSections()
    .subscribe(section => {
      this.sections$.next(section);
    });
  }
}
