import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalRoutes } from '@libs/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, map, shareReplay } from 'rxjs/operators';
import { LeadDeleteQueryModalComponent } from '../lead-delete-query-modal/lead-delete-query-modal.component';
import { Observable, Subscription, forkJoin, merge, of } from 'rxjs';
import { FormArray, FormGroup } from '@angular/forms';
import { LeadService } from '@digital/app/core/services/lead.service';
import { LeadFormMappingService } from '../services/lead-form-mapping.service';
import { LeadQueryResultsState } from '@digital/app/_models/lead-query-result-state';
import { LeadQueryResultsService } from '@digital/app/lead/services/lead-query-results.service';
import { LeadShareQueryModalComponent } from '@digital/app/lead/lead-share-query-modal/lead-share-query-modal.component';
import { LeadCopyQueryModalComponent } from '@digital/app/lead/lead-copy-query-modal/lead-copy-query-modal.component';
import { LeadQuery } from '@digital/app/_models/lead-query';
import { LeadSelectedFormField } from '../services/lead-selected-form-field.service';
import { LeadInvalidQueryModalComponent } from '../lead-invalid-query-modal/lead-invalid-query-modal.component';

@Component({
  selector: 'app-lead-query',
  templateUrl: './lead-query.component.html',
  styleUrls: ['./lead-query.component.scss']
})
export class LeadQueryComponent implements OnInit, OnDestroy {
  queryId?: number;
  years$: Observable<number[]>;
  year: number;
  form: FormGroup = new FormGroup({});
  resultsState$: Observable<LeadQueryResultsState>;
  isLoading: boolean;
  pdfFormElementId = 'pdf-form';
  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private leadService: LeadService,
    private modal: NgbModal,
    private mappingService: LeadFormMappingService,
    private resultsService: LeadQueryResultsService,
    private selectedFieldService: LeadSelectedFormField
  ) {}

  ngOnInit(): void {
    this.years$ = this.leadService.getYears();

    const idParam = this.route.snapshot.paramMap.get('id');
    let query$: Observable<LeadQuery> = null;
    let form$ = this.years$.pipe(map((years) => this.mappingService.toForm(years)));
    
    if (idParam) {
      const id = +idParam;

      if (!isNaN(id)) {
        this.queryId = id;
        query$ = this.leadService.getQuery(id);
        form$ = forkJoin([this.years$, query$]).pipe(map(([years, query]) => this.mappingService.toForm(years, query)));
      } else {
        this.router.navigate([GlobalRoutes.errors.notFound]);
      }
    }

    form$ = form$.pipe(
      finalize(() => (this.isLoading = false)),
      shareReplay(1)
    );

    this.isLoading = true;

    const formSubscription = form$.subscribe((form) => this.form = form);
    this.subscriptions.add(formSubscription);

    const criteriaForm$ = form$.pipe(map((form) => form.get('criteria') as FormArray));
    this.resultsState$ = this.resultsService.getResultsState(criteriaForm$, query$);

    const waitingFieldSubscription = this.selectedFieldService
      .isWaitingField()
      .pipe(filter(waitingField => waitingField))
      .subscribe(() => document.getElementById(this.pdfFormElementId).scrollIntoView({ behavior: 'smooth', block: 'center' }));
    this.subscriptions.add(waitingFieldSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onClickBack(): void {
    this.router.navigate([GlobalRoutes.qtax.base]);
  }

  onClickSave(): void {
    if (this.form.valid) {
      const query = this.mappingService.fromForm(this.form);
      let subscription = null;
      this.isLoading = true;

      if (this.isNew) {
        subscription = this.leadService
          .addQuery(query)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe((id) => (this.queryId = id));
      } else {
        subscription = this.leadService
          .updateQuery(this.queryId, query)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe();
      }

      this.subscriptions.add(subscription);
    } else {
      const modal = this.modal.open(LeadInvalidQueryModalComponent, { centered: true });
      modal.componentInstance.form = this.form;
    }
  }

  onClickDelete(): void {
    const modal = this.modal.open(LeadDeleteQueryModalComponent);

    modal.result.then(this.delete.bind(this), () => null);
  }

  delete(): void {
    this.isLoading = true;

    const subscription = this.leadService
      .deleteQuery(this.queryId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => this.router.navigate([GlobalRoutes.qtax.base]));

    this.subscriptions.add(subscription);
  }

  onClickClone(): void {
    const modal = this.modal.open(LeadCopyQueryModalComponent);

    modal.componentInstance.queryId = this.queryId;
    modal.componentInstance.queryNameValue = this.queryName + ' (עותק)';
  }

  onClickShare(): void {
    const modal = this.modal.open(LeadShareQueryModalComponent);

    modal.componentInstance.queryId = this.queryId;
  }

  onYearChange(year: number): void {
    this.year = year;
  }

  get isNew(): boolean {
    return this.queryId == null;
  }

  get properties(): FormGroup {
    return this.form.get('properties') as FormGroup;
  }

  get criteria(): FormArray {
    return this.form.get('criteria') as FormArray;
  }

  get queryName(): string {
    return this.properties?.get('queryName')?.value;
  }
}
