import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { FormsService } from '@digital/app/forms/forms.service';
import { FieldBaseComponent } from '../field-base/field-base.component';

@Component({
  selector: 'app-field-boolean',
  templateUrl: './field-boolean.component.html',
  styleUrls: ['../field-base/field-base.component.scss', './field-boolean.component.scss']
})
export class FieldBooleanComponent extends FieldBaseComponent implements OnInit {
  
  get isChecked(): boolean {
    return this.field.value;
  }

  /* #region Lifecycle */

  constructor(public _elementRef: ElementRef, public formsService: FormsService) {
    super(_elementRef, formsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /* #endregion */

  /* #region Methods */

  updateFieldValue($target: any) {
    const isChecked = $target.checked;
    this.field.newValue = isChecked;
    this.didUpdateFormFieldValue.emit(this.field);

    // After the new value has been emitted, set the new value as the current value so the next field update would sent as a PATCH instead of POST
    this.field.value = isChecked;
  }

  /* #endregion */
}
