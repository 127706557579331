<main>
  <div class="action-toolbar-container">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="create-flow-button-container" ngbDropdown>
            <button class="btn btn-primary create-flow-button" ngbDropdownToggle *ngIf="!isExternalUser">
              <app-svg-icon name="plus_special"></app-svg-icon>
              יצירת תהליך חדש
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem [routerLink]="[createFlowRoute]" [queryParams]="{ type: 'Tax' }">דוח מס</button>
              <button
                ngbDropdownItem
                [routerLink]="[createFlowRoute]"
                [queryParams]="{ type: 'Financial' }"
                [disabled]="true"
              >
                דוח כספי
              </button>
              <button
                ngbDropdownItem
                [routerLink]="[createFlowRoute]"
                [queryParams]="{ type: 'SpecialPermit' }"
                [disabled]="true"
              >
                אישור מיוחד
              </button>
            </div>
          </div>
          <span class="tooltip-wrapper" placement="top">
            <button class="btn btn-outline-primary download-flow-button" [disabled]="true" *ngIf="!isExternalUser">
              <app-svg-icon name="download_special"></app-svg-icon>
              הורדת התייעצויות שבוצעו
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <app-table-flows *ngIf="flows$" [isLoading]="isLoading$ | async" [data]="flows$ | async" [searchValue]="searchValue">
  </app-table-flows>
</main>
<app-footer></app-footer>
