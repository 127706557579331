import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BlogPostComponent } from '@digital/app/blog/pages/blog-post/blog-post.component';
import { BlogComponent } from '@digital/app/blog/pages/blog/blog.component';
import { DigitalitRoutes } from '@digital/app/_constants';
import { BrowserGuard } from '@libs/guards/browser.guard';

const blogRoutes: Routes = [
  {
    path: DigitalitRoutes.blog.main,
    component: BlogComponent,
    canActivate: [BrowserGuard, MsalGuard]
  },
  {
    path: DigitalitRoutes.blog.main + '/' + DigitalitRoutes.blog.single + '/:id',
    component: BlogPostComponent,
    canActivate: [BrowserGuard, MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(blogRoutes)],
  exports: [RouterModule]
})
export class BlogRoutingModule {}
