import { Injectable } from '@angular/core';
import { API } from './api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Networking
import { HttpParams } from '@angular/common/http';

// Constants
import { GlobalAPI } from '@digital/app/_constants';

// Enums
import { UploadFileType } from '@libs/enums/upload-file-type.enum';
import { UploadFileStageType } from '@libs/enums/upload-file-stage-type.enum';

export interface GetFilesDTO {
  flowID: number;
  type: UploadFileType;
  stageType?: UploadFileStageType;
  sectionID?: number;
  stageID?: number;
}
export interface UploadFileDTO {
  files: File[];
  flowID: number;
  type: UploadFileType;
  stageType?: UploadFileStageType;
  sectionID?: number;
  stageID?: number;
}
export interface DownloadFileDTO {
  fileID?: number;
  flowID?: number;
  stageID?: number;
  stageType?: UploadFileStageType;
}
export interface DeleteFileDTO {
  flowID: number;
  originalName: string;
}
export interface StageFile {
  id: number;
  title: string;
  fileName: string;
  kind: string;
  type: string;
  stageType: UploadFileStageType;
  flowStageID: number;
  createdAt: Date;
  updatedAt: Date;
}

@Injectable({ providedIn: 'root' })
export class FilesAPI extends API {
  upload(dto: UploadFileDTO): Observable<boolean> {
    const endpoint = `${GlobalAPI.endpoints.flows.base}/${dto.flowID}/${GlobalAPI.endpoints.flows.uploadFiles}`;
    console.log(dto);
    const formData = new FormData();
    dto.files.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });
    formData.append('uploadType', dto.type);
    formData.append('flowSectionID', `${dto.sectionID}`);
    formData.append('flowStageID', `${dto.stageID}`);

    if (dto.stageType) {
      formData.append('stageType', dto.stageType);
    }

    return this._network.post(GlobalAPI.baseURL, endpoint, formData).pipe(map((response) => response.code === 201));
  }

  download(dto: DownloadFileDTO): Observable<ArrayBuffer> {
    let params = new HttpParams();

    if (dto.fileID) {
      params = params.append('fileID', dto.fileID.toString());
    }

    if (dto.stageID) {
      params = params.append('stageID', dto.stageID.toString());
    }

    if (dto.flowID) {
      params = params.append('flowID', dto.flowID.toString());
    }

    return this._network.getArrayBuffer(GlobalAPI.baseURL, `${GlobalAPI.endpoints.files.download}`, params);
  }

  delete(dto: DeleteFileDTO): Observable<boolean> {
    const endpoint = `${GlobalAPI.endpoints.flows.base}/${dto.flowID}/${GlobalAPI.endpoints.flows.uploadFiles}`;

    let params = new HttpParams();
    params = params.append('original_name', dto.originalName);

    return this._network.delete(GlobalAPI.baseURL, endpoint, params).pipe(map((response) => response.code === 202));
  }

  getFiles(dto: GetFilesDTO): Observable<StageFile[]> {
    const endpoint = `${GlobalAPI.endpoints.flows.base}/${dto.flowID}/${GlobalAPI.endpoints.flows.uploadFiles}`;

    let params = new HttpParams();
    params = params.append('flowID', dto.flowID.toString());
    params = params.append('type', dto.type);

    if (dto.stageID) {
      params = params.append('stageID', dto.stageID.toString());
    }

    if (dto.sectionID) {
      params = params.append('sectionID', dto.sectionID.toString());
    }

    return this._network.get(GlobalAPI.baseURL, endpoint, params).pipe(map((response) => response.data.files));
  }
}
