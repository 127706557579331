import { Injectable } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';

export enum IconType {
  Glyph = 'glyph',
  Outline = 'outline',
  Special = 'special'
}

@Injectable()
export class IconsService {
  // Lifecycle
  constructor(private _iconReg: SvgIconRegistryService) {
    this._registerGlyphIcons();
    this._registerOutlineIcons();
    this._registerSpecialIcons();
  }

  // Methods
  private _registerGlyphIcons() {
    // TODO: No glyphs implemented yet
  }

  private _registerOutlineIcons() {
    this._loadIcon(IconType.Outline, 'settings');
    this._loadIcon(IconType.Outline, 'back');
    this._loadIcon(IconType.Outline, 'settings-v2');
    this._loadIcon(IconType.Outline, 'save');
  }

  private _registerSpecialIcons() {
    this._loadIcon(IconType.Special, 'plus');
    this._loadIcon(IconType.Special, 'angle-down');
    this._loadIcon(IconType.Special, 'angle-right');
    this._loadIcon(IconType.Special, 'angle-left');
    this._loadIcon(IconType.Special, 'angle-up');
    this._loadIcon(IconType.Special, 'angle-up-bold');
    this._loadIcon(IconType.Special, 'arrow-left');
    this._loadIcon(IconType.Special, 'arrow-circle-left');
    this._loadIcon(IconType.Special, 'arrow-right');
    this._loadIcon(IconType.Special, 'filter');
    this._loadIcon(IconType.Special, 'filter-v2');
    this._loadIcon(IconType.Special, 'search');
    this._loadIcon(IconType.Special, 'history');
    this._loadIcon(IconType.Special, 'bookmark');
    this._loadIcon(IconType.Special, 'inbox');
    this._loadIcon(IconType.Special, 'list');
    this._loadIcon(IconType.Special, 'sort');
    this._loadIcon(IconType.Special, 'sort-amount-ascending');
    this._loadIcon(IconType.Special, 'sort-amount-descending');
    this._loadIcon(IconType.Special, 'sort-alphabet-ascending');
    this._loadIcon(IconType.Special, 'sort-alphabet-descending');
    this._loadIcon(IconType.Special, 'download');
    this._loadIcon(IconType.Special, 'folder');
    this._loadIcon(IconType.Special, 'help-indicator-glyph');
    this._loadIcon(IconType.Special, 'help-indicator-outline');
    this._loadIcon(IconType.Special, 'file');
    this._loadIcon(IconType.Special, 'bin-red');
    this._loadIcon(IconType.Special, 'bin-white');
    this._loadIcon(IconType.Special, 'file-empty');
    this._loadIcon(IconType.Special, 'checkmark');
    this._loadIcon(IconType.Special, 'checkmark-green');
    this._loadIcon(IconType.Special, 'checkmark-blue');
    this._loadIcon(IconType.Special, 'close');
    this._loadIcon(IconType.Special, 'close-red');
    this._loadIcon(IconType.Special, 'close-blue');
    this._loadIcon(IconType.Special, 'edit-profile');
    this._loadIcon(IconType.Special, 'edit-profile-blue');
    this._loadIcon(IconType.Special, 'print');
    this._loadIcon(IconType.Special, 'edit');
    this._loadIcon(IconType.Special, 'xls-file');
    this._loadIcon(IconType.Special, 'doc-file');
    this._loadIcon(IconType.Special, 'pdf-file');
    this._loadIcon(IconType.Special, 'check-box');
    this._loadIcon(IconType.Special, 'move');
    this._loadIcon(IconType.Special, 'read-only');
    this._loadIcon(IconType.Special, 'digital');
    this._loadIcon(IconType.Special, 'calender');
    this._loadIcon(IconType.Special, 'claperboard');
    this._loadIcon(IconType.Special, 'link');
    this._loadIcon(IconType.Special, 'paperclip');
    this._loadIcon(IconType.Special, 'picture');
    this._loadIcon(IconType.Special, 'quote-marks-down');
    this._loadIcon(IconType.Special, 'quote-marks');
    this._loadIcon(IconType.Special, 'tick-list');
    this._loadIcon(IconType.Special, 'team');
    this._loadIcon(IconType.Special, 'user-active');
    this._loadIcon(IconType.Special, 'user-not-active');
    this._loadIcon(IconType.Special, 'user-mail');
    this._loadIcon(IconType.Special, 'user-pending');
    this._loadIcon(IconType.Special, 'mail');
    this._loadIcon(IconType.Special, 'key');
  }

  private _getIconPath(iconType: IconType, iconName: string) {
    return `assets/images/icons/${iconType}/${iconName}.svg`;
  }

  private _loadIcon(iconType: IconType, iconName: string) {
    this._iconReg.loadSvg(this._getIconPath(iconType, iconName), `${iconName}_${iconType}`);
  }
}
