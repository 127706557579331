<div
  class="annotation-block"
  [class.subannotation]="isOptionField"
  [style.width.px]="this.field?.width"
  [style.height.px]='this.field?.height'
  cdkDrag
  [cdkDragDisabled]='this.isResizing'
  cdkDragBoundary='.page'
  [cdkDragData]="field"
  [cdkDragFreeDragPosition]="this.position" (cdkDragStarted)="this.isDragging = true"
  (cdkDragEnded)="onDragEnded($event)">
  <!--  -->
  <div class="controls" *ngIf="!isOptionField">
    <ul class="list-inline info">
      <li class="list-inline-item type">
        <span>{{ field.type }}</span>
      </li>
      <li class="list-inline-item position">
        מיקום: <span>{{ field.position }}</span>
      </li>
      <li class="list-inline-item id">
        מזהה: <span>{{ field.id }}</span>
      </li>
    </ul>
    <ul class="list-inline">
      <li class="list-inline-item">
        <button class="btn btn-danger" (click)="deleteButtonClicked()">מחיקה</button>
      </li>
      <li class="list-inline-item">
        <button class="btn btn-secondary" (click)="duplicateButtonClicked()">שכפול</button>
      </li>
      <li class="list-inline-item">
        <button class="btn btn-primary" (click)="editButtonClicked()">עריכה</button>
      </li>
    </ul>
  </div>
  <!--  -->
  <div class='resizers'>
    <div class='resizer top-left'></div>
    <div class='resizer top-right'></div>
    <div class='resizer bottom-left'></div>
    <div class='resizer bottom-right'></div>
  </div>
  <!--  -->
  <div class="inner-container" *ngIf="!this.isDragging" placement="top" [ngbTooltip]="(this.isOptionField) ? (this.getFieldName) : ''"></div>
</div>
