import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FlowService } from '@digital/app/core/services/flow.service';
import { Stage } from '@digital/app/flow/models';
import { Section, SectionItem, SectionItemKind, SectionItemType } from '@digital/app/flow/models/section.model';
import { SectionFiles } from '@digital/app/flow/models/sections-files.model';
import { PreviewFormModalComponent } from '@digital/app/flow/pages/flow/components/_general/preview-form-modal/preview-form-modal.component';
import { ConfirmationModalComponent } from '@digital/app/shared/components/confirmation-modal/confirmation-modal.component';
import { FlowFormsAPI } from '@digital/app/_apis/flow-forms.api';
import { DigitalitRoutes } from '@digital/app/_constants';
import { SectionType } from '@digital/app/_enums/section-type.enum';
import { IFlowEditingPermissions } from '@digital/app/_interfaces/flow-editing-permissions.interface';
import { Flow } from '@digital/app/_models/flow.model';
import { FormState } from '@digital/app/_models/form.model';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { UserRole } from '@libs/authentication/models/user.model';
import { FileService } from '@libs/core/services/file.service';
import { UploadFileType } from '@libs/enums/upload-file-type.enum';
import { NgbAlertConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { KitModalComponent } from '../../_tax/kit-modal/kit-modal.component';

export interface File {
  fileName: string;
  fileID: number;
}

export enum SecitonButtonText {
  SingleFile = 'העלאת קובץ',
  MultipleFiles = 'העלאת קבצים'
}

@Component({
  selector: 'app-flow-section-table',
  templateUrl: './flow-section-table.component.html',
  styleUrls: ['./flow-section-table.component.scss'],
  providers: [NgbAlertConfig]
})
export class FlowSectionTableComponent implements OnChanges {
  @Input() editingPermissions: IFlowEditingPermissions;
  @Input() sections: Section[];
  @Input() flow: Flow;
  @Input() currentUserEditor: number;
  @Input() isConsultation: Stage;
  @Input() alerts: Array<string> = [];
  @Input() managerID: number;
  @Input() partnerID: number;
  @Input() currentUserID: number;
  @Input() isTaxQuestionnaire: boolean = false;

  @Output() sectionFilesUpdated = new EventEmitter();

  sectionsFiles: SectionFiles[];
  pdfFile: any;
  file: File;
  buttonsText = SecitonButtonText;
  sectionType = SectionType;
  arr = Array;

  isLockedForEditing = true;

  FormState = FormState;
  SectionItemType = SectionItemType;
  SectionItemKind = SectionItemKind;

  get digitalFormsURL(): string {
    return `${this.router.url}/${DigitalitRoutes.flow.digitalForms}`;
  }

  get isAdmin() {
    return this._authenticationService.getCurrentUser().role === UserRole.ADMIN;
  }

  get isFormEditor() {
    return this._authenticationService.getCurrentUser().role === UserRole.FORMEDITOR;
  }

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private filesService: FileService,
    private flowFormsAPI: FlowFormsAPI,
    private router: Router,
    private flowService: FlowService,
    private _authenticationService: AuthenticationService
  ) {
    this.toastr.toastrConfig.preventDuplicates = true;
  }

  ngOnChanges() {
    this.isLockedForEditing = this.editingPermissions.isLockedForEditing;
    if(this.sections){
      this.sections.sort((a,b) => a.position - b.position);
    }
  }

  onGetFiles(files: SectionFiles[]) {
    const filteredFiles = [];
    this.sectionsFiles = files;
    this.sectionsFiles.map((section) => {
      section.files.map((file) => {
        filteredFiles.push(file);
      });
    });
    const sortedFiles = filteredFiles.sort((a, b) => a.params.position - b.params.position);
    this.sectionFilesUpdated.emit(sortedFiles);
  }

  previewSectionItemClicked(item: SectionItem) {
    if (!item.url) {
      return;
    }
    if (item.kind === SectionItemKind.PDF) {
      this.filesService.downloadFile(item.url).then((response) => {
        const blobFile = new Blob([response], { type: 'blob' });
        this.pdfFile = URL.createObjectURL(blobFile);
        const modalRef = this.modalService.open(PreviewFormModalComponent, {
          windowClass: 'preview-form-modal',
          scrollable: true,
          centered: true,
          size: 'xl'
        });
        modalRef.componentInstance.pdfFile = this.pdfFile;
        modalRef.componentInstance.pdfName = item.name;
      });
    } else {
      this.filesService.downloadFile(item.url).then((response) => {
        const blobFile = new Blob([response], { type: 'blob' });
        saveAs(blobFile, item.name);
      });
    }
  }

  downloadFile(fileName: string) {
    this.filesService.downloadFile(fileName).then((response) => {
      const file = new Blob([response], { type: 'blob' });
      saveAs(file, fileName);
    });
  }

  uploadFiles(files: FileList, sectionID: number) {
    const currentSection = this.sections.find((section) => section.id === sectionID);

    if (currentSection.maxFiles < files.length) {
      // Show error
    }

    this.filesService
      .upload({
        files: Array.from(files),
        flowID: this.flow.id,
        type: UploadFileType.FORM,
        sectionID
      })
      .subscribe(async (success) => {
        if (success) {
          this.sectionFilesUpdated.emit();
        }
      });
  }

  deleteSectionItemClicked(item: SectionItem) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      windowClass: 'confirmation-modal',
      scrollable: true,
      centered: true
    });
    modalRef.componentInstance.flow = this.flow;
    modalRef.componentInstance.fileName = item.name;
    modalRef.componentInstance.fileID = item.id;
    modalRef.componentInstance.isDigitalForm = item.type === SectionItemType.Form;
    modalRef.componentInstance.actionType = 'deleteFile';
    modalRef.componentInstance.title = 'שימו לב! מחיקת קובץ זה הינה סופית.';
    modalRef.componentInstance.content = 'הקובץ לא ניתן לשחזור לאחר ביצוע הפעולה';
    modalRef.result.then((approve) => {
      if (approve) {
        switch (item.type) {
          case SectionItemType.File:
            this.deleteFile(item);
            break;
          case SectionItemType.Form:
            this.deleteDigitalForm(item);
            break;
          default:
            break;
        }
      }
    });
  }

  private deleteDigitalForm(item: SectionItem) {
    const deleteForm = {formID: item.id, instanceID: item.instanceID}
    this.flowFormsAPI.addOrDeleteDigitalForms(this.flow.id, [], [deleteForm], [], []).subscribe((response) => {
      if (response.code === 201) {
        this.removeSectionItem(item);
      }
    });
  }

  private deleteFile(item: SectionItem) {
    this.filesService.delete({ originalName: item.originalName, flowID: this.flow.id }).subscribe((success) => {
      if (success) {
        this.removeSectionItem(item);
      }
    });
  }

  private removeSectionItem(item: SectionItem) {
    const sectionItems = this.sections.find((s) => s.id === item.sectionID).items;
    const itemIndex = sectionItems.findIndex((f) => f.id === item.id && f.instanceID === item.instanceID);
    sectionItems.splice(itemIndex, 1);
  }

  successAlertPopup() {
    this.toastr.success('הקובץ הועלה בהצלחה');
  }

  // Event emitter for update flow data (updateAt).
  updateFlowData() {
    const responseCode = 202;
    this.flowService.emitDataFromSectionTable(responseCode);
  }

  openKitModal() {
    const modalRef = this.modalService.open(KitModalComponent, {
      windowClass: 'kit-modal',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.flow = this.flow;
    modalRef.componentInstance.dataUpdated.subscribe((dataUpdated) => {
      if (dataUpdated) {
        this.sectionFilesUpdated.emit();
      }
    });
  }

  titleForSectionItem(item: SectionItem) {
    switch (item.type) {
      case SectionItemType.File:
        return item.name;
      case SectionItemType.Form:
        let name = `${item.taxID} - ${item.name}`
        if(item.instanceID > 0){
          name += ` (${item.instanceID})`;
        }
        return name;
      default:
        break;
    }
  }

  openSectionItem(item: SectionItem) {
    switch (item.type) {
      case SectionItemType.File:
        this.downloadSectionItem(item);
        break;
      case SectionItemType.Form:
        this.openSectionDigitalForm(item, item.instanceID);
        break;
      default:
        break;
    }
  }

  private async downloadSectionItem(item: SectionItem) {
    this.filesService.download({ fileID: item.id }).subscribe((buffer) => {
      const file = new Blob([buffer], { type: 'blob' });
      if (item.kind === SectionItemKind.PDF) {
        const modalRef = this.modalService.open(PreviewFormModalComponent, {
          windowClass: 'preview-form-modal',
          scrollable: true,
          centered: true,
          size: 'xl'
        });
        modalRef.componentInstance.file = URL.createObjectURL(file);
        modalRef.componentInstance.title = item.name;
        modalRef.componentInstance.kind = item.kind;
        return;
      }
      saveAs(file, `${item.name}.${item.kind}`);
    });
  }

  private openSectionDigitalForm(item: SectionItem, instanceOfForm:number) {
    this.router.navigate([this.digitalFormsURL], {
      queryParams: { formID: item.id, sectionID: item.sectionID, instance: instanceOfForm}
    });
  }
}
