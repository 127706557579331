import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IFlowEditingPermissions } from '@digital/app/_interfaces/flow-editing-permissions.interface';
import { CompanyInfo } from '@digital/app/_models/company-info.model';
import { Flow } from '@digital/app/_models/flow.model';

interface CompanyType {
  key?: number;
  name: string;
}

@Component({
  selector: 'app-company-info-form',
  templateUrl: './company-info-form.component.html',
  styleUrls: ['./company-info-form.component.scss']
})
export class CompanyInfoFormComponent implements OnInit, OnChanges {
  @Input() showAll: boolean = true;
  @Input() flow: Flow;
  @Input() companyInfo: CompanyInfo;
  @Input() editingPermissions: IFlowEditingPermissions;

  @Output() didUpdateCompanyInfoField = new EventEmitter<{ companyID: number; year: number; fieldObject: any }>();

  companyInfoForm: FormGroup;

  get companyTypes(): CompanyType[] {
    return [
      {
        key: 2,
        name: 'מפעל מאושר/מוטב/מועדף/טכנולוגי'
      },
      {
        key: 3,
        name: 'מוסד כספי'
      },
      {
        key: 4,
        name: 'אחר'
      },
      {
        key: 5,
        name: 'מפעל מאושר/מוטב בשנת הטבה אחרונה'
      },
      {
        key: 6,
        name: 'מפעל מאושר/מוטב בשנה שלפני שנת הטבה האחרונה'
      },
      {
        key: 7,
        name: 'חברת בית'
      }
    ];
  }

  get fieldPlaceholder(): string {
    return 'הקלד/י ערך';
  }

  // Lifecycle
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.setupFormBuilder();
  }

  ngOnChanges() {
    if (this.companyInfoForm) {
      if (this.editingPermissions.isLockedForEditing) {
        this.companyInfoForm.disable();
      } else {
        this.companyInfoForm.enable();
      }
    }
  }

  // Setup
  private setupFormBuilder() {
    const data = this.companyInfo;
    if (!data) {
      return;
    }

    this.companyInfoForm = this.formBuilder.group(
      {
        taxCaseNumber: [data.taxCaseNumber, Validators.nullValidator],
        address: [data.address, Validators.nullValidator],
        mailingAddress: [data.mailingAddress, Validators.nullValidator],
        fax: [data.fax, Validators.nullValidator],
        bankAccountNumber: [data.bankAccountNumber, Validators.nullValidator],
        bankBranchNumber: [data.bankBranchNumber, Validators.nullValidator],
        bankNumber: [data.bankNumber, Validators.nullValidator],
        vatCaseNumber: [data.vatCaseNumber, Validators.nullValidator],
        accountancyAddress: [data.accountancyAddress, Validators.nullValidator],
        assessmentOfficeAddress: [data.assessmentOfficeAddress, Validators.nullValidator],
        email: [data.email, Validators.email],
        occupation: [data.occupation, Validators.nullValidator],
        phone: [data.phone, Validators.nullValidator],
        companyType: [data.companyType, Validators.nullValidator],
        publicOrPrivate: [data.publicOrPrivate, Validators.nullValidator],
        companyClassification: [data.publicOrPrivate, Validators.nullValidator],
        secondaryClassification: [data.publicOrPrivate, Validators.nullValidator],
        nameSigningCustomer: [data.nameSigningCustomer, Validators.nullValidator],
        roleSigningCustomer: [data.roleSigningCustomer, Validators.nullValidator],

        firmName: [data.firmName, Validators.nullValidator],
        firmExpertise: [data.firmExpertise, Validators.nullValidator],
        firmCompanyID: [data.firmCompanyID, Validators.nullValidator],
        firmAddress: [data.firmAddress, Validators.nullValidator],
        firmPhone: [data.firmPhone, Validators.nullValidator]
      },
      { updateOn: 'blur' }
    );
    this.companyInfoForm.disable();
  }

  getCompanyTypeValue(type: CompanyType): string {
    return `${type.key} - ${type.name}`;
  }

  // Detect changes on form when blur
  updateCompanyInfo(formControlName: string) {
    if (this.editingPermissions.isLockedForEditing) {
      return;
    }

    const newValue = this.companyInfoForm.get(formControlName).value;
    const newValueEqualsToPrevious = this.companyInfo[formControlName] === newValue;

    if (newValueEqualsToPrevious || newValue === undefined) {
      return;
    }

    this.companyInfo[formControlName] = newValue;

    this.didUpdateCompanyInfoField.emit({
      companyID: this.flow.company.id,
      year: this.flow.year,
      fieldObject: {
        [formControlName]: newValue
      }
    });
  }
}
