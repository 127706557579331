<div class="stage">
  <div class="indicator" [class.active]="stage.state.isCurrent" [class.completed]="stage.state.isCompleted"></div>
  <div class="name">
    {{ stage.name }}
    <span class="assigned-user" *ngIf="assignedUserName">{{ assignedUserName }}</span>
  </div>
</div>
<div class="stage-separator" *ngIf="isLast == false">
  <ng-container *ngIf="stage.consult">
    <div class="consultation-circle">
      <hr />
      <div class="consultation">
        <p class="consultation-with">בהתייעצות מול {{ consultationUserName }}</p>
      </div>
    </div>
  </ng-container>
</div>
