<div class="flow-header">
  <div class="container-fluid">
    <div class="title">
      <div class="row">
        <!-- Title & tags -->
        <div class="col-8">
          <div class="title-container">
            <button (click)="backButtonClicked.emit()" class="back-button">
              <app-svg-icon name="back_outline"></app-svg-icon>
            </button>
            <h4 class="company-name">{{ flow.company.nameHebrew }}</h4>
            <span class="title-tag">{{ flow.type.name }}</span>
            <span class="title-tag">{{ flow.year }}</span>
            <span *ngIf="!flow.isDone" class="title-tag" [class.attention]="deadlinePassed">
              צפי לסיום:
              {{ flow.deadline | parseDatePipe : 'year' }}
            </span>
            <span class="title-tag">{{ translateRiskLevel(flow.riskLevel) }}</span>
            <span *ngIf="flow?.isDone" class="title-tag" [class.success]="flow?.isDone"> תהליך הסתיים </span>
            <span *ngIf="flow.isAwaitingDeletion" class="title-tag attention" style="cursor: pointer"
              >ממתין לאישור מחיקה</span
            >
          </div>
        </div>
        <div class="col-4 text-left side-buttons">
          <div class="side-buttons">
            <ng-container>
              <button class="flow-header-button" (click)="generateKitButtonClicked.emit()">
                <app-svg-icon name="settings_outline"></app-svg-icon>
                סידור והפקת ערכה
              </button>
            </ng-container>
            <ng-container *ngIf="!isExternalUser">
              <span ngbDropdown placement="bottom-left">
                <button class="flow-header-button" [disabled]="isSettingsMenuDisabled" ngbDropdownToggle>
                  <app-svg-icon name="settings-v2_outline"></app-svg-icon>
                  הגדרות
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem (click)="openChangeFlowInfoModal(flowUserType.riskLevel)">
                    שינוי רמת סיכון
                  </button>
                  <button class="destructive" ngbDropdownItem (click)="deleteFlowRequest()">מחיקת התהליך</button>
                  <button ngbDropdownItem (click)="openTicketServiceNow()">פתיחת קריאה</button>
                </div>
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="dates">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <!-- Dates -->
        <span class="date-tag">
          נוצר:
          {{ flow.createdAt | parseDatePipe : 'time' }}
        </span>
        <span class="date-tag">
          נערך לאחרונה:
          {{ flow.updatedAt | parseDatePipe : 'time' }}
          ע״י
          {{ flow?.lastUpdatedBy.fullName.hebrew }}
        </span>
      </div>
    </div>
  </div>
</div>
