<div class="modal-container">
  <div class="modal-content">
    <div class="scroll-container">
      <h1>כתב ויתור והסכמה לשימוש בפלטפורמת DTAX</h1>
      <p class="hebrew-text">
        דלויט ישראל ושות' ובריטמן אלמגור זהר ושות' ("דלויט") מאפשרות ללקוחותיהן לחלוק מידע של הלקוח באמצעות פלטפורמת
        DTAX הפועלת בענן של דלויט ("הפלטפורמה").<br />
        הלקוח מאשר בזאת כי כל העברת מידע סודי ואישי ("המידע") לדלויט נעשה בהתאם להוראות הדין, וכי בידיו כל האישורים,
        ההסכמות וההיתרים הנדרשים המאפשרים לו להעביר לדלויט מידע כאמור.<br />
        בשימוש בפלטפורמה הלקוח מסכים להעברת המידע לדלויט ובאחריותו למלא באופן תקין ונכון את הפרטים.<br />
        דלויט תסתמך על המידע שהועבר אליה בפלטפורמה ולא תהיה מחויבת לאמת או לתקף אותו.<br />
        הלקוח פוטר בזאת את דלויט מכל אחריות בקשר לטעויות ו/או שגיאות שבוצעו על ידי הלקוח בהקלדת המידע בפלטפורמה.<br />
        הלקוח מצהיר כי קרא את מדיניות הפרטיות של דלויט בקישור להלן:<br />
        <a href="https://www2.deloitte.com/il/en/legal/privacy.html?icid=bn_privacy" target="_blank">
          https://www2.deloitte.com/il/en/legal/privacy.html?icid=bn_privacy
        </a>
        <br />בברכה,<br />
        דלויט ישראל ושות'<br />
        בריטמן אלמגור זהר ושות'<br />
      </p>
      <h1>Waiver and Consent Disclaimer to use the DTAX Platform</h1>
      <p class="english-text">
        Deloitte Israel & Co. and Brightman Almagor Zohar & Co. ("Deloitte") allow their clients to share client
        information through Deloitte's cloud-based DTAX platform (the "Platform").<br />
        Client hereby confirms that any transfer of confidential and personal information ("the information") to
        Deloitte is done in accordance with the provisions of the law, and that it has all the required approvals,
        consents and permits that allow it to transfer to Deloitte such information.<br />
        By using the platform, client agrees to transfer the information to Deloitte and is responsible for filling in
        the details properly and correctly.<br />
        Deloitte will rely on the information transmitted to it on the platform and will not be obligated to verify or
        validate it.<br />
        Client hereby releases Deloitte from any liability in connection with mistakes and/or errors made by client in
        typing the information on the platform.<br />
        Client declares that it has read Deloitte's privacy policy at the link below:<br />
        <a href="https://www2.deloitte.com/il/en/legal/privacy.html?icid=bn_privacy" target="_blank">
          https://www2.deloitte.com/il/en/legal/privacy.html?icid=bn_privacy
        </a>
        <br />Regards,<br />
        Deloitte Israel & Co.<br />
        Brightman Almagor Zohar & Co.<br />
      </p>
    </div>
    <button class="close-button" (click)="close(true)">אישור</button>
  </div>
</div>
