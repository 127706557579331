import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { Company } from '@digital/app/_models/company.model';
import { FlowService } from '@digital/app/core/services/flow.service';
import { Observable, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, ControlContainer } from '@angular/forms';
import { RiskLevelType } from '@digital/app/_models/flow.model';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { Router } from '@angular/router';
import { CreateFlow } from '../../models/create-flow.model';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from '@libs/views/datepicker/datepicker-adapter';

@Component({
  selector: 'app-process-details-tab',
  templateUrl: './process-details-tab.component.html',
  styleUrls: ['./process-details-tab.component.scss'],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatter
    }
  ]
})
export class ProcessDetailsComponent implements OnInit {
  @Output() isTaxYearSet = new EventEmitter<boolean>();
  @Input() newFlow: CreateFlow;
  @Input() isSubmitClick: boolean;
  @Input() changeFormFieldState;

  //getCurrentUser
  private _currentUser = this._authenticationService.getCurrentUser();

  //Risk Type
  RiskLevelType = RiskLevelType;
  flowRiskLevel = [];

  // Form group
  createFlowForm: FormGroup;

  // Class members
  companies: Company[];
  taxYears: number[] = [];

  //boolean indicating
  dataPickerValidation = false;
  isLoading = false;

  //for dataPicker
  minDate: NgbDateStruct;

  // Lifecycle
  constructor(
    private _authenticationService: AuthenticationService,
    private _flowService: FlowService,
    private _router: Router,
    private _controlContainer: ControlContainer
  ) {
    const today = new Date();
    this.minDate = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };
    this.flowRiskLevel = Object.keys(RiskLevelType);
  }

  ngOnInit() {
    this._setupInitialData();
    this._setupFormBuilder();
  }

  // Navigation
  navigateToHomePage() {
    this._router.navigate(['/']);
  }

  openTicketServiceNow() {
    window.open(
      'https://deloitteus.service-now.com/israelsp?id=sc_cat_item_israel&sys_id=88a876afdb58555441aa8fd33996194e&business_service_sys_id=619e2e964f0aa788f7a9cf5d0210c73d'
    );
  }

  // Setup
  private _setupFormBuilder() {
    this.createFlowForm = <FormGroup>this._controlContainer.control;
  }

  private _setupInitialData() {
    this.isLoading = true;
    this._flowService
      .getCompanies(this._currentUser.id, (companies: Company[]) => this._onGetCompanies(companies))
      .then(() => {
        this.isLoading = false;
      });
  }

  // Networking
  private _getTaxYears() {
    this._flowService.getTaxYears(this.newFlow.companyID, (taxYears) => this._onGetTaxYears(taxYears));
  }

  private _onGetTaxYears(taxYears: number[]) {
    this.taxYears = taxYears.sort((a, b) => a - b);
    this.changeFormFieldState('taxYear', 'details', '', false);
  }

  private _onGetCompanies(companies: Company[]) {
    this.companies = companies;
  }

  // Actions

  resetSelectedCompanyIfNeeded() {
    this.changeFormFieldState('company.name', 'details', '', false);
    this.changeFormFieldState('company.id', 'details', '', false);
    this.changeFormFieldState('taxYear', 'details', '', true);
    this.changeFormFieldState('managers', 'permissions', '', true);
  }

  riskLevel(riskLevelName) {
    switch (riskLevelName) {
      case 'Undefined':
        return 'לא מוגדר';
      case 'Low':
        return 'נמוך';
      case 'Medium':
        return 'בינוני';
      case 'High':
        return 'גבוה';
      default:
        return '';
    }
  }

  selectedCompanyName(companyName: string) {
    const company = this.companies.find(({ nameHebrew }) => nameHebrew === companyName);
    this.newFlow.companyID = parseInt(company.id, 10);
    this._getTaxYears();
    this.changeFormFieldState('company.id', 'details', company.id, true);
  }

  selectedCompanyID(companyID: string) {
    const company = this.companies.find(({ id }: any) => id === parseInt(companyID, 10));
    this.newFlow.companyID = parseInt(company.id, 10);
    this._getTaxYears();
    this.changeFormFieldState('company.name', 'details', company.nameHebrew, true);
  }

  selectedValueTaxYear(selectedValue: number) {
    this.newFlow.year = selectedValue;
    this.isTaxYearSet.emit(true);
    this.changeFormFieldState('managers', 'permissions', '', false);
  }

  selectedValueRiskLevel(selectedValue: RiskLevelType) {
    this.newFlow.riskLevel = selectedValue;
  }

  private _formatDatePicker(picker: any) {
    const newDate = new Date(picker.year, picker.month - 1, picker.day);
    return newDate;
  }

  dataPickerClicked() {
    const dataPicker = this.createFlowForm.get('details').get('deadline').value;
    if (dataPicker.length < 1) {
      this.dataPickerValidation = true;
    } else {
      this.dataPickerValidation = false;
    }
    this.newFlow.deadline = this._formatDatePicker(this.createFlowForm.get('details').get('deadline').value);
  }

  //Auto Complete Form
  companyNameAutoComplete = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    debouncedText$.subscribe((value) => {
      if (value === '') {
        this.resetSelectedCompanyIfNeeded();
      }
    });

    return merge(debouncedText$).pipe(
      map((term) =>
        term === ''
          ? this.companies.map((company) => company.nameHebrew)
          : this.companies
              .map((company) => company.nameHebrew)
              .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
      )
    );
  };

  companyIDAutoComplete = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    debouncedText$.subscribe((value) => {
      if (value === '') {
        this.resetSelectedCompanyIfNeeded();
      }
    });

    return merge(debouncedText$).pipe(
      map((term) =>
        (term === ''
          ? this.companies.map((company) => company.id)
          : this.companies
              .map((company: any) => company.id.toString())
              .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
        ).sort((a, b) => a - b)
      )
    );
  };

  getValidationAfterSubmit(controlName: string) {
    let invalid = false;
    if (this.isSubmitClick) {
      invalid = this.createFlowForm.get('details').get(controlName).invalid;
    }

    return invalid;
  }
}
