import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FlowStage } from '@digital/app/_enums/flow-stages.enum';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { UserRole } from '@libs/authentication/models/user.model';
import { GlobalRoutes } from '@libs/constants';
import { BehaviorSubject } from 'rxjs';
import { KitAPI } from '../flow/apis/kit.api';
import { FlowAPI } from '../_apis/flow.api';
import { FlowState } from '../_enums/flow-state.enum';
import { IFlowEditingPermissions } from '../_interfaces/flow-editing-permissions.interface';
import { ExternalUserType } from '../_models/client-users';
import { Flow } from '../_models/flow.model';
import * as saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FlowShellService {
  private readonly currentUser = this.authService.getCurrentUser();

  flow$ = new BehaviorSubject<Flow>(undefined);
  flowState$ = new BehaviorSubject<FlowState>(FlowState.AwaitingState);

  editingPermissions$ = new BehaviorSubject<IFlowEditingPermissions>({
    isLockedForEditing: true,
    canLockForEditing: false,
    canTakeOverEditing: false
  });

  /* #region Getters */

  get isCurrentEditorMe(): boolean {
    const flow = this.flow$.value;

    if (!flow) {
      return false;
    }

    const currentUser = this.currentUser;
    const currentEditor = flow.currentEditor;

    if (!currentEditor) {
      return false;
    }

    return currentUser.id === currentEditor.id;
  }

  /* #endregion */

  /* #region Lifecycle */

  constructor(
    private authService: AuthenticationService,
    private flowAPI: FlowAPI,
    private kitAPI: KitAPI,
    private router: Router
  ) {
    this.flow$.subscribe((flow) => this.decideFlowState(flow));
  }

  /* #endregion */

  /* #region Flow state */

  isExternalUserAndPermitted(flow: Flow): boolean {
    let isValid = true;
    if (this.currentUser.role === UserRole.EXTERNAL) {
      const userPermissions = flow.permissions.externalUsers.find((user) => {
        return user.id === this.currentUser.id;
      });
      if (flow.stage.id === FlowStage.CLIENT_SIGNATURE && userPermissions.role === ExternalUserType.Editor) {
        this.router.navigate([GlobalRoutes.dtax]);
        isValid = false;
      } else if (flow.stage.id === FlowStage.WORK_ON_TAX_FLOW && userPermissions.role === ExternalUserType.Signer) {
        this.router.navigate([GlobalRoutes.dtax]);
        isValid = false;
      } else if (flow.stage.id !== FlowStage.WORK_ON_TAX_FLOW && flow.stage.id !== FlowStage.CLIENT_SIGNATURE) {
        this.router.navigate([GlobalRoutes.dtax]);
        isValid = false;
      }
    }

    return isValid;
  }

  private decideFlowState(flow: Flow) {
    if (!flow || !this.currentUser) {
      return;
    }

    const isLockedByAnotherUser = flow.currentEditor && flow.currentEditor?.id !== this.currentUser.id;
    const canLockForEditing = this.editingPermissions$.value.canLockForEditing;

    if (isLockedByAnotherUser && canLockForEditing) {
      this.setLockedByAnotherUserState();
      return;
    }

    switch (flow.stage.id) {
      case FlowStage.WORK_ON_TAX_FLOW:
        this.setOpenForAllState();
        break;
      case FlowStage.WORK_ON_FINANCIAL_FLOW:
        this.setOpenForAllState();
        break;
      case FlowStage.CLIENT_SIGNATURE:
        this.setOpenForAllState();
        break;
      case FlowStage.SIGNATURE_IMPLEMENTATION:
        this.setOpenForAllState();
        break;
      case FlowStage.IRS_CONFIRMATION:
        this.setLockedForAllState();
        break;
      case FlowStage.MANAGER_APPROVAL:
        this.setOpenForManagerAndAboveState(flow);
        break;
      case FlowStage.PARTNER_APPROVAL:
        this.setOpenForPartnerAndAboveState(flow);
        break;
      case FlowStage.PARTNER_SIGNATURE:
        this.setOpenForPartnerAndAboveState(flow);
        break;
      case FlowStage.FINISHED:
        this.setLockedForAllState();
        break;
      case FlowStage.QUALITY_CONTROL:
        this.setOpenForAdminOnly();
    }

    const iAmCurrentEditorButNotPermitted =
      flow.currentEditor &&
      flow.currentEditor.id === this.currentUser.id &&
      !this.editingPermissions$.value.canLockForEditing;

    if (iAmCurrentEditorButNotPermitted) {
      this.removeAsCurrentEditorIfNeeded();
    }
  }

  private setOpenForAllState() {
    this.flowState$.next(FlowState.OpenForAll);
    this.editingPermissions$.next({
      isLockedForEditing: false,
      canLockForEditing: true,
      canTakeOverEditing: true
    });
  }

  private setOpenForManagerAndAboveState(flow: Flow) {
    this.flowState$.next(FlowState.OpenForManagerAndAbove);

    const currentUser = this.currentUser;
    const manager = flow.manager;
    const partner = flow.partner;

    const isPermitted =
      currentUser.id === manager.id ||
      currentUser.id === partner.id ||
      currentUser.role === UserRole.ADMIN ||
      currentUser.role === UserRole.FORMEDITOR;

    if (isPermitted) {
      this.editingPermissions$.next({
        isLockedForEditing: false,
        canLockForEditing: true,
        canTakeOverEditing: true
      });
    } else {
      this.editingPermissions$.next({
        isLockedForEditing: true,
        canLockForEditing: false,
        canTakeOverEditing: false
      });
    }
  }

  private setOpenForPartnerAndAboveState(flow: Flow) {
    this.flowState$.next(FlowState.OpenForPartnerAndAbove);

    const currentUser = this.currentUser;
    const partner = flow.partner;

    const isPermitted = currentUser.id === partner.id || currentUser.role === UserRole.ADMIN;

    if (isPermitted) {
      this.editingPermissions$.next({
        isLockedForEditing: false,
        canLockForEditing: true,
        canTakeOverEditing: true
      });
    } else {
      this.editingPermissions$.next({
        isLockedForEditing: true,
        canLockForEditing: false,
        canTakeOverEditing: false
      });
    }
  }

  private setLockedForAllState() {
    this.flowState$.next(FlowState.LockedForAll);
    this.editingPermissions$.next({
      isLockedForEditing: true,
      canLockForEditing: false,
      canTakeOverEditing: false
    });
  }

  private setLockedByAnotherUserState() {
    this.flowState$.next(FlowState.LockedByAnotherUser);
    this.editingPermissions$.next({
      isLockedForEditing: true,
      canLockForEditing: true,
      canTakeOverEditing: true
    });
  }

  private setOpenForAdminOnly() {
    this.flowState$.next(FlowState.OpenForAdminOnly);

    const currentUser = this.currentUser;
    const isPermitted = currentUser.role === UserRole.ADMIN || currentUser.role === UserRole.FORMEDITOR;

    if (isPermitted) {
      this.editingPermissions$.next({
        isLockedForEditing: false,
        canLockForEditing: true,
        canTakeOverEditing: true
      });
    } else {
      this.editingPermissions$.next({
        isLockedForEditing: true,
        canLockForEditing: false,
        canTakeOverEditing: false
      });
    }
  }

  /* #endregion */

  /* #region Networking requests */

  getFlowByID(id: number) {
    this.flowAPI.getFlowByID(id).subscribe((flow) => {
      this.flow$.next(flow);
    });
  }

  getFlowRealTimeData(flowID: number) {
    this.flowAPI.getFlowRealTimeData(flowID).subscribe((data) => {
      const flow = this.flow$.value;
      flow.stage = data.stage;
      flow.currentEditor = data.currentEditor;
      flow.lastUpdatedBy = data.lastUpdatedBy;
      flow.updatedAt = data.updatedAt;
      this.flow$.next(flow);
    });
  }

  setAsCurrentEditorIfNeeded(force: boolean = false) {
    if (!this.editingPermissions$.value.canLockForEditing) {
      return;
    }

    const flow = this.flow$.value;

    if (!flow) {
      return;
    }

    if (!flow.currentEditor || force) {
      this.flowAPI
        .setCurrentEditorSync(flow.id)
        .then((response) => {
          if (response.ok) {
            flow.currentEditor = {
              id: this.currentUser.id as number,
              fullName: {
                english: this.currentUser.username,
                hebrew: this.currentUser.username
              },
              rank: {
                english: this.currentUser.rank,
                hebrew: this.currentUser.rank
              }
            };
            this.decideFlowState(flow);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  removeAsCurrentEditorIfNeeded(force: boolean = false) {
    const flow = this.flow$.value;

    if (!flow) {
      return;
    }

    if ((flow && this.isCurrentEditorMe) || force) {
      this.flowAPI
        .removeCurrentEditorSync(flow.id)
        .then((response) => {
          if (response.ok) {
            flow.currentEditor = undefined;
            this.decideFlowState(flow);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  /* #endregion */

  /* #region Actions  */

  resetFlowData() {
    this.flow$.next(undefined);
  }

  downloadSignedKit() {
    const flow = this.flow$.value;

    if (!flow) {
      return;
    }

    this.kitAPI.generateSigned(flow.id).subscribe((buffer) => {
      const blob = new Blob([buffer], { type: 'blob' });
      const fileName = `${flow.year}_${flow.company.nameHebrew}_חתום.pdf`;
      saveAs(blob, fileName);
    });
  }

  /* #endregion */
}
