import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Constants
import { GlobalAPI } from '@digital/app/_constants';

// Models
import { API } from './api';
import { Form, FormState } from '@digital/app/_models/form.model';
import { FormData } from '@digital/app/_models/form-data.model';
import { FormField, FormFieldUpdate } from '../_models/form-field.model';
import { CreateCustomFormFieldDTO } from '../_dtos/create-custom-field.dto';
import { UpdateCustomFormFieldDTO } from '../_dtos/update-custom-field.dto';
import { HttpParams } from '@angular/common/http';
import { Note } from '../forms/models/note';
import { NoteComment } from '../forms/models/note-comment';

@Injectable({ providedIn: 'root' })
export class FormsAPI extends API {
  getFlowForms(flowID: number): Observable<Form[]> {
    const endpoints = GlobalAPI.endpoints;
    const apiRoute = `${endpoints.forms.base}/${flowID}/${endpoints.forms.allForms}`;
    return this._network.get(GlobalAPI.baseURL, apiRoute).pipe(map((response) => response.data.forms));
  }

  getFormData(flowID: number, formID: number, instance: number): Observable<FormData> {
    const params = new HttpParams().append('instance', instance.toString());
    const endpoints = GlobalAPI.endpoints;
    const apiRoute = `${endpoints.forms.base}/${flowID}/${endpoints.forms.allForms}/${formID}/${endpoints.forms.fields}`;
    return this._network.get(GlobalAPI.baseURL, apiRoute, params).pipe(
      map((response) => response.data),
      tap((data: FormData) => (data.fieldsMap = new Map<number, FormField>(data.fields.map((f) => [f.id, f]))))
    );
  }

  insertFormFieldValue(
    flowID: number,
    formID: number,
    field: FormField,
    instance: number
  ): Observable<FormFieldUpdate> {
    const endpoints = GlobalAPI.endpoints;
    const apiRoute = `${endpoints.forms.base}/${flowID}/${endpoints.forms.formField}/${field.id}`;

    const body = {
      formID,
      instance,
      isCustom: field.isCustom,
      value: {
        value: field.newValue
      }
    };

    return this._network.post(GlobalAPI.baseURL, apiRoute, body).pipe(map((response) => response.data));
  }

  updateFormState(state: FormState, flowID: number, formID: number, instanceID: number) {
    const endpoints = GlobalAPI.endpoints;
    const apiRoute = `${endpoints.forms.base}/${flowID}/${endpoints.forms.allForms}`;

    const body = {
      formID,
      state,
      instanceID
    };

    return this._network.patch(GlobalAPI.baseURL, apiRoute, body).pipe(map((response) => response.data));
  }

  createCustomFormField(
    flowID: number,
    formID: number,
    dto: CreateCustomFormFieldDTO,
    instance: number
  ): Observable<FormField> {
    const endpoints = GlobalAPI.endpoints;
    const apiRoute = `${endpoints.forms.base}/${flowID}/${endpoints.forms.allForms}/${formID}/${endpoints.forms.createCustomFormField}`;

    return this._network.post(GlobalAPI.baseURL, apiRoute, { dto, instance }).pipe(map((response) => response.data));
  }

  deleteCustomFormField(flowID: number, formID: number, fieldID: number): Observable<FormFieldUpdate> {
    const endpoints = GlobalAPI.endpoints;
    const apiRoute = `${endpoints.forms.base}/${flowID}/${endpoints.forms.allForms}/${formID}/${endpoints.forms.customField}/${fieldID}`;

    return this._network.delete(GlobalAPI.baseURL, apiRoute).pipe(map((response) => response.data));
  }

  updateCustomFormField(
    flowID: number,
    formID: number,
    fieldID: number,
    dto: UpdateCustomFormFieldDTO
  ): Observable<boolean> {
    const endpoints = GlobalAPI.endpoints;
    const apiRoute = `${endpoints.forms.base}/${flowID}/${endpoints.forms.allForms}/${formID}/${endpoints.forms.customField}/${fieldID}`;

    return this._network.patch(GlobalAPI.baseURL, apiRoute, dto).pipe(map((response) => response.code === 202));
  }

  getAllFormsForSpecifiedYear(year: number): Observable<Form[]> {
    const apiRoute = `leadQuery/forms/${year}`;
    return this._network.get(GlobalAPI.baseURL, apiRoute).pipe(map((response) => response.data));
  }

  getAllFieldsForFormByFormId(formId: number): Observable<FormField[]> {
    const apiRoute = `leadQuery/forms/fields/${formId}`;
    return this._network.get(GlobalAPI.baseURL, apiRoute).pipe(map((response) => response.data));
  }
  
  addNoteAPI(eventData: Note, sendEmailTo: string[]) {
    const endpoints = GlobalAPI.endpoints;
    const apiRoute = `${endpoints.forms.base}/${eventData.flowId}/${endpoints.forms.formField}/${eventData.formId}/${endpoints.forms.notes}/`;
    const postNote = {
      ...eventData,
      sendEmailTo: sendEmailTo
    }
    return this._network.post(GlobalAPI.baseURL, apiRoute, postNote).pipe(map((response) => response.data));
  }

  addCommentToNoteAPI(eventData: NoteComment, sendEmailTo: string[], flowId: number, formId: number) {
    const endpoints = GlobalAPI.endpoints;
    const apiRoute = `${endpoints.forms.base}/${flowId}/${endpoints.forms.formField}/${formId}/${endpoints.forms.commentToNote}/`;
    const body = {
      ...eventData,
      sendEmailTo: sendEmailTo
    }
    return this._network.post(GlobalAPI.baseURL, apiRoute, body).pipe(map((response) => response.data));
  }

  onStatusChangeAPI(body) {
    const endpoints = GlobalAPI.endpoints;
    const apiRoute = `${endpoints.forms.base}/${body.flowId}/${endpoints.forms.formField}/${body.formId}/${endpoints.forms.statusOnNote}/`;
    if (body.status==='delete') {
      let params = new HttpParams();
      params = params.append('noteId', body.noteId);
      params = params.append('status', body.status);
      return this._network.delete(GlobalAPI.baseURL, apiRoute, params).pipe(map((response) => response.data));
    }
    else {
      return this._network.post(GlobalAPI.baseURL, apiRoute, body).pipe(map((response) => response.data));
    }
  }

  onGetNotClosedNotesByFlowIdAPI(body) {
    const endpoints = GlobalAPI.endpoints;
    const apiRoute = `${endpoints.forms.base}/${body.flowId}/${endpoints.forms.formField}/${body.formId}/${endpoints.forms.getNotClosedNotesByFlowId}/`;
    return this._network.post(GlobalAPI.baseURL, apiRoute, body).pipe(map((response) => response.data));
  }
}
