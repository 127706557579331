import { CdkDragMove } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Signature, SignatureEvent, SignatureType } from '@digital/app/flow/models/signature.model';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {
  @ViewChild('resizeBox', { static: false }) resizeBox: ElementRef<HTMLImageElement>;
  @Input() signature: Signature;
  @Input() isEditable: boolean;
  @Input() date: Date;

  @Output() signatureUpdated = new EventEmitter<Signature>();
  @Output() signatureDeleted = new EventEmitter<SignatureEvent>();
  @Output() signatureApproved = new EventEmitter<SignatureEvent>();

  private _pdfContainer = document.querySelector('.ng2-pdf-viewer-container') as HTMLElement;

  get pdfContainer() {
    return this._pdfContainer;
  }

  get resizeBoxElement() {
    return this.resizeBox.nativeElement;
  }

  get isIdentification() {
    return this.signature.type === SignatureType.Identification;
  }

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    console.log(`Signature created: [${this.signature.id}] ${this.signature.type}`);
  }

  onDragEnded(event) {
    const position = event.source.getFreeDragPosition();
    this.signature.position = position;
    this.signatureUpdated.emit(this.signature);
    console.log(
      `Signature dragged: [${this.signature.id}] ${this.signature.type} | position: ${this.signature.position.x}, ${this.signature.position.y}`
    );
  }

  getPosition(element) {
    let x = 0;
    let y = 0;
    while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
      x += element.offsetLeft - element.scrollLeft;
      y += element.offsetTop - element.scrollTop;
      element = element.offsetParent;
    }
    return { top: y, left: x };
  }

  deleteSignatureClicked() {
    this.signatureDeleted.emit({
      component: this.elementRef,
      signature: this.signature
    });
    console.log(`Signature deleted: [${this.signature.id}] ${this.signature.type}`);
  }

  didChangeCheckStatus() {
    if (!this.isEditable) {
      this.signature.isApproved = !this.signature.isApproved;
      this.signatureUpdated.emit(this.signature);
    }
  }

  changeSignatureSize(isIncrease: boolean) {
    let widthChange = 0;
    let heightChange = 0;
    if (this.isIdentification) {
      widthChange = isIncrease ? 20 : -20;
      heightChange = isIncrease ? 20 : -20;
    } else {
      widthChange = isIncrease ? 20 : -20;
      heightChange = isIncrease ? 4 : -4;
    }

    const newWidth = this.resizeBoxElement.offsetWidth + widthChange;
    const newHeight = this.resizeBoxElement.offsetHeight + heightChange;

    this.resizeBoxElement.style.width = newWidth + 'px';
    this.resizeBoxElement.style.height = newHeight + 'px';

    this.signature.size = { width: newWidth, height: newHeight };
    this.signatureUpdated.emit(this.signature);
  }

  isDateAfterImplementation() {
    return this.signature.type === SignatureType.Date && !this.isEditable;
  }

  isDate() {
    return this.signature.type === SignatureType.Date;
  }
}
