import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { LeadSelectedFormField } from '@digital/app/lead/services/lead-selected-form-field.service';
import { Observable } from 'rxjs';
import { LeadCriteriaFormMappingService } from './lead-criteria-form-mapping.service';

@Component({
  selector: 'app-lead-query-criteria',
  templateUrl: './lead-query-criteria.component.html',
  styleUrls: ['./lead-query-criteria.component.scss']
})
export class LeadQueryCriteriaComponent implements OnInit {
  @Input() form: FormArray;
  isWaitingField: Observable<boolean>;

  constructor(private mappingService: LeadCriteriaFormMappingService,
              private selectedFieldService: LeadSelectedFormField,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.isWaitingField = this.selectedFieldService.isWaitingField();

    if (this.form.length === 0) {
      this.addCriterionGroup();
    }
  }

  onAddCriterionGroup(): void {
    this.addCriterionGroup();
    this.changeDetectorRef.detectChanges();
  }
  
  addCriterionGroup(): void {
    this.form.push(this.mappingService.toLogicalCriterionGroupForm());
  }

  onCriterionGroupDeleted(i: number): void {
    this.form.removeAt(i);

    if (this.form.length === 0) {
      this.addCriterionGroup();
    }
  }

  get criteria(): FormGroup[] {
    return this.form.controls as FormGroup[];
  }

  getForm(i: number, name: string): AbstractControl {
    return this.criteria[i].get(name);
  }

  criterionGroup(i: number): FormArray {
    return this.getForm(i, 'criterion') as FormArray;
  }

  logicalOperator(i: number): FormControl {
    return this.getForm(i, 'logicalOperator') as FormControl;
  }

  operatorId(i: number): string {
    return `criterion-group-${i}`;
  }

  get hasSingleGroup(): boolean {
    return this.form.length === 1;
  }

}
