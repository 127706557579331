<ng-container *ngFor="let field of fields; let i = index">
  <section [hidden]="isVisible(field)" id="section-{{ field.id }}" class="testclass">
    <app-field-title
      [field]="field"
      [fieldIndex]="i"
      *ngIf="field.type === fieldType.Title"
      (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
      (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
      [isLockedForEditing]="editingPermissions.isLockedForEditing || shouldLockFieldsForEditing"
    >
    </app-field-title>

    <app-field-subtitle
      [field]="field"
      [fieldIndex]="i"
      *ngIf="field.type === fieldType.Subtitle"
      (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
      (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
      [isLockedForEditing]="editingPermissions.isLockedForEditing || shouldLockFieldsForEditing"
    >
    </app-field-subtitle>

    <div class="field-container" (click)="field.type === fieldType.PullData || field.type === fieldType.Calculated ? null : onClickInput(field)">
    <app-field-text
      [field]="field"
      [fieldIndex]="i"
      [side]="'right'"
      *ngIf="
        field.type === fieldType.Text ||
        field.type === fieldType.Number ||
        field.type === fieldType.Calculated ||
        field.type === fieldType.PullData
      "
      (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
      (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
      (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
      (editFieldButtonClicked)="editFieldButtonClicked.emit($event)"
      (deleteFieldButtonClicked)="deleteFieldButtonClicked.emit($event)"
      [isLockedForEditing]="true"
      [isLeadButtonVariant]="true"
      [isPulledDataField]="field.type === fieldType.PullData || field.type === fieldType.Calculated"
    >
    </app-field-text>

    <app-field-date
      [field]="field"
      [fieldIndex]="i"
      [side]="'right'"
      *ngIf="field.type === fieldType.Date"
      (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
      (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
      (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
      [isLockedForEditing]="true"
      [isLeadButtonVariant]="true"
      [isPulledDataField]="field.type === fieldType.PullData || field.type === fieldType.Calculated"
    >
    </app-field-date>

    <app-field-boolean
      [field]="field"
      [fieldIndex]="i"
      [side]="'right'"
      *ngIf="field.type === fieldType.Boolean"
      (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
      (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
      (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
      [isLockedForEditing]="true"
      [isLeadButtonVariant]="true"
      [isPulledDataField]="field.type === fieldType.PullData || field.type === fieldType.Calculated"
    >
    </app-field-boolean>

    <div *ngIf="field.type !== fieldType.PullData && field.type !== fieldType.Calculated" class="field-overlay"></div>
    </div>

    <app-field-single-selection
      [field]="field"
      [fieldIndex]="i"
      [side]="'right'"
      *ngIf="field.type === fieldType.SingleSelection"
      (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
      (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
      (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
      [isLockedForEditing]="editingPermissions.isLockedForEditing || shouldLockFieldsForEditing"
    >
    </app-field-single-selection>

    <app-field-multiple-selection
      [field]="field"
      [fieldIndex]="i"
      [side]="'right'"
      *ngIf="field.type === fieldType.MultipleSelection"
      (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
      (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
      (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
      [isLockedForEditing]="editingPermissions.isLockedForEditing || shouldLockFieldsForEditing"
    >
    </app-field-multiple-selection>

    <app-field-free-options
      [field]="field"
      [fieldIndex]="i"
      [side]="'right'"
      *ngIf="field.type === fieldType.FreeOptions"
      (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
      (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
      (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
      [isLockedForEditing]="editingPermissions.isLockedForEditing || shouldLockFieldsForEditing"
    >
    </app-field-free-options>

    <app-field-custom-placeholder
      [field]="field"
      [placeholderData]="field"
      fieldIndex="i"
      *ngIf="field.type === fieldType.CustomFieldPlaceholder && !shouldLockFieldsForEditing"
      [isLockedForEditing]="editingPermissions.isLockedForEditing || shouldLockFieldsForEditing"
      (createCustomField)="createCustomField.emit($event)"
    ></app-field-custom-placeholder>

    <!-- Nested fields if applicable -->
    <ng-container *ngIf="field.fields && field.fields.length > 0">
      <app-fields-tree
        [fields]="field.fields"
        [shouldLockFieldsForEditing]="shouldLockFieldsForEditing"
        [editingPermissions]="editingPermissions"
        (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
        (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
        (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
        (createCustomField)="createCustomField.emit($event)"
      ></app-fields-tree>
    </ng-container>
    <!-- End nested fields -->
  </section>
</ng-container>
