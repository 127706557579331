import { Component, OnInit, Input } from '@angular/core';
import { Stage } from '@digital/app/flow/models';
import { UserService } from '@digital/app/core/services/user.service';
import { User } from '@digital/app/_models/user.model';

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss']
})
export class StageComponent implements OnInit {
  @Input() stage: Stage;
  @Input() isLast = false;
  @Input() assignedUserName?: string;
  consultationUserName: string;

  constructor(private _userService: UserService) {}

  async ngOnInit() {
    if (this.stage.consult) {
      const user: User = await this._userService.getUserDetails(this.stage.consult.consultationUserID);
      this.consultationUserName = user.fullName.hebrew;
    }
  }
}
