import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { HomeRoutingModule } from './home-routing.module';
import { ViewsModule as GlobalViewsModule } from '@libs/views/views.module';
import { SharedModule } from '@digital/app/shared/shared.module';

// Components
import { components } from './index';

//Kendo
import "@progress/kendo-angular-intl/locales/he/all";
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { ParseDatePipe } from '@libs/core/pipes/parse-date.pipe';


@NgModule({
  declarations: [
    components,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    GlobalViewsModule,
    SharedModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    DropDownsModule
  ], 
  providers:[
    ParseDatePipe
  ]
})
export class HomeModule { }
