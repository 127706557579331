import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// APIs
import { FlowsAPI } from '@digital/app/_apis/flows.api';

// Models
import { Flows } from '@digital/app/_models/flows.model';
import { FlowType } from '@digital/app/_enums/flow-type.enum';

// Directives
import { SortEvent } from '@libs/directives/sortable-header.directive';
import { Flow } from '../_models/flow.model';

@Injectable({ providedIn: 'root' })
export class FlowsFacade {
  flows$: BehaviorSubject<Flows> = new BehaviorSubject(new Flows());
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentPage = 1;

  // Lifecycle
  constructor(private _flowsAPI: FlowsAPI) {
    this.getAllFlows(this.currentPage);
  }

  // Methods
  getAllFlows(page: number, type?: FlowType, sort?: SortEvent) {
    this.isLoading$.next(true);
    this._flowsAPI.getAllFlows(page, undefined, type, sort).subscribe((flows) => {
      this.isLoading$.next(false);
      this.flows$.next(flows);
    });
  }

  addNewFlow(flow: Flow) {
    const currentValue = this.flows$.value;
    currentValue.flows = [...currentValue.flows, flow];
    this.flows$.next(currentValue);
  }

  searchAllFlows(keyword: string, page: number) {
    this.isLoading$.next(true);
    this._flowsAPI.searchFlows(keyword, page).subscribe((flows) => {
      this.isLoading$.next(false);
      this.flows$.next(flows);
    });
  }
}
