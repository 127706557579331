import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlowStage } from '@digital/app/_enums/flow-stages.enum';
import { Flow } from '@digital/app/_models/flow.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConsultationModalComponent } from '../../_tax/consultation-modal/consultation-modal.component';

enum ContentType {
  File = 'file',
  Eqcr = 'eqcr'
}

enum TitleAndDescription {
  ClientSignatureTitle = 'העלאת קובץ חתום על ידי הלקוח',
  ClientSignatureDescription = 'יש להוריד את הערכה ע"י לחיצה על "סידור והפקת ערכה" ולהעבירה ללקוח במייל לחתימה.</br> לאחר קבלת הערכה החתומה ע"י הלקוח, יש להעלות לכאן את הקובץ החתום וללחוץ "אשר והמשך תהליך" למעבר לשלב הטמעת חתימות. </br> באפשרותך גם לחזור לשלב הראשון "עבודה על דוח מס" באמצעות לחיצה על "דחייה וחזרה לשלב הראשון."',
  IrsApprovalTitle = 'העלאת אישור ״התקבל״ ממס הכנסה',
  IrsApprovalDescription = 'יש להוריד את הערכה החתומה בלחיצה על "הורדת ערכה חתומה ע"י שותף" ולשלוח לשידור למס הכנסה כרגיל.</br> לאחר קבלת אישור "התקבל" ממס הכנסה יש להעלות לכאן את האישור וללחוץ על "אשר והמשך תהליך" לסיום התהליך.</br> בסיום התהליך לא יהיה ניתן לערוך יותר את התהליך. באפשרותך גם לחזור לשלב הקודם "חתימת שותף" באמצעות לחיצה על "דחייה וחזרה לשלב הקודם בתהליך"'
}

@Component({
  selector: 'app-flow-section-content',
  templateUrl: './flow-section-content.component.html',
  styleUrls: ['./flow-section-content.component.scss']
})
export class FlowSectionContentComponent implements OnInit {
  @Input() type: ContentType;
  @Input() flow?: Flow;
  @Input() currentUserEditor: string;

  @Output() containsUploadedFiles = new EventEmitter<boolean>();

  title: string;
  description: string;

  readonly FlowStage = FlowStage;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    switch (this.flow.stage.id) {
      case FlowStage.CLIENT_SIGNATURE:
        this.title = TitleAndDescription.ClientSignatureTitle;
        this.description = TitleAndDescription.ClientSignatureDescription;
        break;
      case FlowStage.IRS_CONFIRMATION:
        this.title = TitleAndDescription.IrsApprovalTitle;
        this.description = TitleAndDescription.IrsApprovalDescription;
        break;
      default:
        break;
    }
  }

  openConsultationModal() {
    const modalRef = this.modalService.open(ConsultationModalComponent, {
      windowClass: 'consultation-modal',
      centered: true
    });
    modalRef.componentInstance.flow = this.flow;
  }
}
