export enum FormState {
  Draft = 'Draft',
  Published = 'Published'
}

export enum FormType {
  Form = 'form',
  Reconcile = 'reconcile',
  Reconciliation = 'reconciliation',
  ContentLetter = 'contentLetter'
}

export class Form {
  id: number;
  type: FormType;
  formTaxID?: number;
  name: string;
  subtitle: string;
  state: FormState;
  includedInExport: boolean;
  instanceID: number;
  instance: number;
  sectionID?: number;
  filePath?: string;
}
