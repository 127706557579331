import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import APIResponse from './model/api-response.model';

@Injectable({ providedIn: 'root' })
export class NetworkService {
  constructor(private _http: HttpClient) {}

  private _formatErrors(error: any) {
    return throwError(error);
  }

  get(environment: string, path: string, params: HttpParams = new HttpParams()): Observable<APIResponse> {
    return this._http.get<APIResponse>(`${environment}${path}`, { params }).pipe(catchError(this._formatErrors));
  }

  getArrayBuffer(environment: string, path: string, params?: HttpParams): Observable<ArrayBuffer> {
    return this._http
      .get(`${environment}${path}`, { params, responseType: 'arraybuffer' })
      .pipe(catchError(this._formatErrors));
  }

  put(environment: string, path: string, body: object = {}): Observable<APIResponse> {
    return this._http.put<APIResponse>(`${environment}${path}`, body).pipe(catchError(this._formatErrors));
  }

  post(environment: string, path: string, body: object = {}): Observable<APIResponse> {
    return this._http.post<APIResponse>(`${environment}${path}`, body).pipe(catchError(this._formatErrors));
  }

  delete(environment: string, path: string, params: HttpParams = new HttpParams()): Observable<APIResponse> {
    return this._http.delete<APIResponse>(`${environment}${path}`, { params }).pipe(catchError(this._formatErrors));
  }

  patch(environment: string, path: string, body: object = {}): Observable<APIResponse> {
    return this._http.patch<APIResponse>(`${environment}${path}`, body).pipe(catchError(this._formatErrors));
  }
}
