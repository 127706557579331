<div class="table-container">
  <div class="title-container">
    <h4 class="title">תוצאות שאילתה</h4>
  </div>

  <ng-container *ngIf="state.isLoading; then loading; else loaded"></ng-container>
</div>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

<ng-template #loaded>
  <kendo-grid
    class="k-rtl"
    [style.height.%]="100"
    [sortable]="true"
    filterable="menu"
    [kendoGridBinding]="state.companies"
  >
    <kendo-grid-column
      *ngFor="let column of columns"
      class="text-center Center"
      [field]="column.field"
      [title]="column.title"
      [width]="column.width"
      [headerStyle]="{ 'font-weight': 'bold', 'padding-right': '12px' }"
    >
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filterService="filterService"
        [ngSwitch]="column.filter"
      >
        <kendo-grid-numeric-filter-menu
          *ngSwitchCase="'numeric'"
          [column]="column"
          [filterService]="filterService"
          [decimals]="0"
          format="#"
          [extra]="false"
        >
        </kendo-grid-numeric-filter-menu>
        <kendo-grid-string-filter-menu
          *ngSwitchCase="'text'"
          [column]="column"
          [filterService]="filterService"
          [extra]="false"
        >
        </kendo-grid-string-filter-menu>
      </ng-template>
      <ng-template *ngIf="column.field === 'downloadKit'" kendoGridCellTemplate let-result>
        <a class="text-primary pointer" (click)="openOrganizeKitModal(result.flowId)">לחץ להורדת ערכה</a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages noRecords="אין תוצאות"> </kendo-grid-messages>
  </kendo-grid>
</ng-template>
