<div class="message-bar-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-5 text-right align-self-center">
        <p class="title">
          <app-svg-icon name="{{ titleIcon }}"></app-svg-icon>
          {{ title }}
          <button
            *ngIf="showTakeOverButton"
            class="btn btn-primary-white btn-take-over"
            (click)="takeOverButtonClicked($event)"
          >
            השתלטות
          </button>
        </p>
      </div>
      <div class="col-7 text-left align-self-center" *ngIf="buttons">
        <div class="buttons-container">
          <button class="btn btn-primary-white" *ngFor="let button of buttons" (click)="buttonClicked(button)">
            <app-svg-icon name="download_special"></app-svg-icon>
            {{ button.title }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
