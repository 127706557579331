import { Inject, Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthenticationAPI } from '../apis/authentication.api';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MsalAuthService {
  public authenticated: boolean;
  public user?: any;
  public accounts: AccountInfo[];

  constructor(
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration
  ) {
    this.accounts = this.msalService.instance.getAllAccounts();
    this.authenticated = this.accounts.length > 0;
    if (this.authenticated) {
      this.msalService.instance.setActiveAccount(this.accounts[0]);
    }
  }

  // Prompt the user to sign in and
  // grant consent to the requested permission scopes
  signIn(): Observable<any> {
    try {
      if (this.msalGuardConfig.authRequest) {
        return this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        return this.msalService.loginRedirect();
      }
    } catch (reason: any) {
      console.log(reason);
      return;
    }
  }

  // Sign out
  async signOut(): Promise<void> {
    await this.msalService.logoutRedirect();
    this.authenticated = false;
  }

  forceSignOut() {
    this.msalService.instance['browserStorage'].clear();
  }

  handleRedirects(): Observable<AuthenticationResult> {
    return this.msalService.handleRedirectObservable();
  }

  getActiveAccount() {
    return this.msalService.instance.getActiveAccount();
  }

  getAllAccounts() {
    return this.msalService.instance.getAllAccounts();
  }
}
