<div class="organize-kit-files-modal">
  <div *ngIf="isDownloading" class="loader">
    <div class="content">
      <app-loading [description]="{ text: 'מפיק ערכה...' }"></app-loading>
    </div>
  </div>
  <div class="container-fluid">
    <button class="modal-close-button" (click)="closeModal()">x</button>
    <div class="modal-header">
      <div class="title-container">
        <div class="row">
          <div class="col text-right vertical-middle">
            <h4 class="title">סידור הטפסים</h4>
            <p>ניתן לסדר את הטפסים באמצעות גרירה והזזת כל טופס על-פי הסדר הרצוי מלמעלה למטה ולהיפך.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-body" *ngIf="items && items.draggable.length !== 0">
      <div class="row" *ngIf="isLoading$ | async">
        <div class="col-12">
          <app-loading></app-loading>
        </div>
      </div>
      <div class="container-fluid" *ngIf="!(isLoading$ | async)">
        <div class="row text-right">
          <div class="col-7">
            <label class="title-info" style="margin-right: 90px">שם הטופס</label>
          </div>
          <div class="col-2" style="text-align: center">
            <label class="title-info">ייצא בערכה</label>
          </div>
          <div class="col-3">
            <label class="title-info">תאריך עדכון אחרון</label>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row not-draggable text-right" *ngFor="let item of items.nonDraggable">
          <div class="col-1">
            <app-svg-icon
              name="move_special"
              class="move-icon align-middle"
              *ngIf="!item.disablePositionChange"
            ></app-svg-icon>
          </div>
          <div class="col-6 file-container">
            <app-svg-icon name="pdf-file_special" *ngIf="item.kind === SectionItemKind.PDF"></app-svg-icon>
            <app-svg-icon name="doc-file_special" *ngIf="item.kind === SectionItemKind.Word"></app-svg-icon>
            <app-svg-icon name="xls-file_special" *ngIf="item.kind === SectionItemKind.Excel"></app-svg-icon>
            <app-svg-icon name="file-empty_special" *ngIf="item.type === SectionItemType.Form"></app-svg-icon>
            <label class="file-name align-middle"
              >{{ item.taxID }} <span *ngIf="item.taxID">-</span> {{ item.name }}</label
            >
          </div>
          <div class="col-2 include-in-kit">
            <div class="checkbox">
              <input type="checkbox" [(ngModel)]="item.includedInExport" (change)="updateIncludedInExport(item)" />
              <span class="checkmark align-middle"></span>
            </div>
          </div>
          <div class="col-3">
            <label class="file-date align-middle">{{ item.createdAt | parseDatePipe: 'time' }}</label>
          </div>
        </div>
        <!-- Draggable items start -->
        <div #autoscroll [dragula]="dropGroupName" [(dragulaModel)]="items.draggable" *ngIf="!(isLoading$ | async)">
          <div
            class="row draggable text-right"
            *ngFor="let item of items.draggable"
            [class.not-draggable]="item.disablePositionChange"
          >
            <div class="col-1">
              <div class="drag-handle-container">
                <app-svg-icon
                  name="move_special"
                  class="move-icon align-middle"
                  *ngIf="!item.disablePositionChange"
                ></app-svg-icon>
              </div>
            </div>
            <div class="col-6 file-container">
              <app-svg-icon name="pdf-file_special" *ngIf="item.kind === SectionItemKind.PDF"></app-svg-icon>
              <app-svg-icon name="doc-file_special" *ngIf="item.kind === SectionItemKind.Word"></app-svg-icon>
              <app-svg-icon name="xls-file_special" *ngIf="item.kind === SectionItemKind.Excel"></app-svg-icon>
              <app-svg-icon name="file-empty_special" *ngIf="item.type === SectionItemType.Form"></app-svg-icon>
              <label class="file-name align-middle">
                {{ item.taxID }} 
                <span *ngIf="item.taxID">-</span>
                <span *ngIf="item.instanceID > 0">({{item.instanceID}})</span>
                {{ item.name }}
              </label>
            </div>
            <div class="col-2 include-in-kit">
              <div class="checkbox">
                <input type="checkbox" [(ngModel)]="item.includedInExport" (change)="updateIncludedInExport(item)" />
                <span class="checkmark align-middle"></span>
              </div>
            </div>
            <div class="col-3">
              <label class="file-date align-middle">{{ item.createdAt | parseDatePipe: 'time' }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Draggable items end -->
    </div>
    <div class="modal-body" *ngIf="!items">
      <div class="container-fluid">
        <p class="empty-params">לא הועלו עדיין טפסים לערכה</p>
      </div>
    </div>
    <div class="modal-footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <button class="btn btn-primary" (click)="restoreInitialOrder()" [disabled]="disableRestoreButton">
              <app-svg-icon name="bookmark_special"></app-svg-icon>
              שחזור סדר ערכה קודם
            </button>
          </div>
          <div class="col text-right">
            <button class="btn btn-primary strong-blue" (click)="generateKit()">
              <app-svg-icon name="download_special"></app-svg-icon>
              הורדת הערכה
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
