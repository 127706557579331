import { Component, OnDestroy, OnInit } from '@angular/core';
import { Utils } from '@digital/app/shared/utils';
import { Form, FormState } from '@digital/app/_models/form.model';
import { Subscription } from 'rxjs';
import { LeadFormService } from '../lead-form.service';

@Component({
  selector: 'lead-app-main-form-view',
  templateUrl: './main-form-view.component.html',
  styleUrls: ['./main-form-view.component.scss']
})
export class LeadMainFormViewComponent implements OnInit, OnDestroy {
  forms$ = this.formsService.forms$;
  currentForm$ = this.formsService.currentForm$;

  private formsSubscription: Subscription;
  private currentFormSubscription: Subscription;

  private initialFormID?: number = this.formsService.initialFormID;
  private initialInstance?: number = this.formsService.initialInstance;
  private initialsectionID?: number = this.formsService.initialSectionID;

  filteredForms: Form[];
  isSearching = false;

  showTabs = true;

  readonly FormState = FormState;

  // Lifecycle
  constructor(private formsService: LeadFormService) {}

  ngOnInit() {
    this.loadInitialForm();
  }

  ngOnDestroy() {
    this.formsSubscription.unsubscribe();
  }

  // Setup
  private loadInitialForm() {
    console.log('in setup');
    this.currentFormSubscription = this.currentForm$.subscribe();

    this.formsSubscription = this.forms$.subscribe((forms) => {
      console.log({ forms });
      this.filteredForms = forms;

      // Load only section 7 or other(given by queryParameter in the URL ?sectionID={id}).
      if (this.initialsectionID !== 7) {
        this.filteredForms = this.filteredForms.filter((form) => form.sectionID !== 7);
      } else {
        this.filteredForms = this.filteredForms.filter((form) => form.sectionID === 7);
      }

      // Load the initial form if given (given by queryParameter in the URL ?formID={id} and ?instance={id}).
      if (this.initialFormID) {
        const initialForm = this.filteredForms.filter((form) => form.id === this.initialFormID);
        if (initialForm[0]) {
          initialForm[0].instance = this.initialInstance;
          this.currentForm$.next(initialForm[0]);
          return;
        }
      }

      // If reached here, it means no initial form was found. Let's load the first available form.
      if (forms[0]) {
        forms[0].instance = 0;
        this.currentForm$.next(forms[0]);
      }
    });
    this.formsSubscription.add(this.currentFormSubscription);
  }

  // Actions
  formTabClicked(form: Form) {
    if (form.id === this.currentForm$.value.id && form.instanceID === this.currentForm$.value.instance) {
      return;
    }
    form.instance = form.instanceID;
    this.formsService.currentFormPdfURL$.next(form.filePath);
    this.formsService.getFieldsForForm(form.id);
    this.currentForm$.next(form);
  }

  collapseBarClicked() {
    this.showTabs = !this.showTabs;
  }

  searchFormsKeywordTyped(keyword: string) {
    if (Utils.isNullOrEmpty(keyword)) {
      this.isSearching = false;
      this.filteredForms = this.forms$.value;
      return;
    }

    this.isSearching = true;
    this.filteredForms = this.forms$.value.filter(
      (form) =>
        form.name.toLowerCase().indexOf(keyword.toLowerCase().trim()) > -1 || // Search by name
        form.formTaxID?.toString().trim().indexOf(keyword) > -1 // Search by formTaxID
    );
  }

  searchFormFieldsKeywordTyped(keyword: string) {
    // TODO: Implement
  }
}
