import { ID } from '@datorama/akita';
import { ExternalUser } from '@digital/app/_models/client-users';
import { InternalUser } from '@digital/app/_models/internal-users.model';
import { RiskLevelType } from '@digital/app/_models/flow.model';

export class CreateFlow {
  companyID: number;
  year: number;
  creatorUserID: ID;
  typeID: number;
  managerID: number;
  partnerID: number;
  deadline: Date;
  riskLevel: RiskLevelType;
  isEQCR?: number;
  requiresConsultation?: boolean;
  internalUsers: InternalUser[];
  externalUsers: ExternalUser[];
}
