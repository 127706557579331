import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NetworkService } from '@libs/core/network/network.service';
import { GlobalAPI } from '@digital/app/_constants';
import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationAPI {
  // Lifecycle
  constructor(private _network: NetworkService) {}

  // API Methods
  login(data?): Observable<User> {
    if (data.email) {
      return this.loginExternal(data);
    } else {
      return this._network
        .post(GlobalAPI.baseURL, GlobalAPI.endpoints.auth.base, data)
        .pipe(map((response) => response.data));
    }
  }

  loginExternal(data?): Observable<User> {
    return this._network
      .post(GlobalAPI.baseURL, GlobalAPI.endpoints.auth.external, data)
      .pipe(map((response) => response.data));
  }

  refreshToken(userName: string, refreshToken: string): Observable<User> {
    return this._network
      .post(GlobalAPI.baseURL, GlobalAPI.endpoints.auth.refreshToken, { userName, refreshToken })
      .pipe(map((response) => response.data.user));
  }
}
