<div class="create-new-form">
  <div class="form-container">
    <div class="container">
      <div class="row justify-content-center title-container">
          <h1>שכפול טופס</h1>
      </div>

      <form [formGroup]="duplicateForm" (submit)="onSubmitduplicateFormButtonClicked()" class="duplicate-form-details" *ngIf="!isLoading">
        <div class="form-group">
          <label for="id">מספר הטופס שרוצים לשכפל עפ״י מס הכנסה</label>
          <input type="text" class="form-control" formControlName="formTaxID">
        </div>

        <div class="form-group">
          <label for="year">שנת המס של הטופס שרוצים לשכפל</label>
          <input type="number" class="form-control" formControlName="year">
        </div>

        <div class="form-group">
          <label for="newFormYear">שנת המס הרצויה</label>
          <input type="number" class="form-control" formControlName="newFormYear">
        </div>

        <div class="form-group">
          <label for="exampleFormControlFile1">העלאת קובץ PDF</label>
          <input type="file" class="form-control" formControlName='file' id="file" (change)="handleFileUploadChange($event.target.files)">
        </div>

        <div class="row justify-content-center duplicate-form-buttons">
          <div class="col text-right">
            <button type="button" class="btn btn-outline-secondary" routerLink="{{homeRoute}}">
              <app-svg-icon name="angle-right_special"></app-svg-icon>
              חזרה לעמוד ראשי
            </button>
          </div>
          <div class="col text-left">
            <button type="submit" class="btn btn-primary">
              <app-svg-icon name="plus_special"></app-svg-icon>
              שכפול הטופס
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="loading-state" *ngIf="isLoading">
  <app-loading></app-loading>
</div>
