import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from '@libs/core/api/api.service';
import { StageFiles } from '@digital/app/flow/models/stage-files.model';
import { SectionFiles, Files, FixedFile } from '@digital/app/flow/models/sections-files.model';
import Documentation from '@digital/app/flow/models/documentation';
import { DeleteFileDTO, DownloadFileDTO, FilesAPI, GetFilesDTO, UploadFileDTO } from '@digital/app/_apis/files.api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  deleteFileFromSectionEvent: EventEmitter<any> = new EventEmitter();
  updateSectionsFilesEvent: EventEmitter<any> = new EventEmitter();

  constructor(private api: ApiService,
              private _filesAPI: FilesAPI) { }

  uploadFilesToStage(companyID: string, flowID: string, stageID: string, uploadedFile: any) {
    try {
      return this.api.uploadFilesToStage(companyID, flowID, stageID, uploadedFile);
    } catch (error) {
      throw error;
    }
  }

  emitDataFromModalConfrimation(data: any) {
    this.deleteFileFromSectionEvent.emit(data);
  }

  emitDataFromOrganizeKitFilesModal(data: any) {
    this.updateSectionsFilesEvent.emit(data);
  }

  updateFilesEvent() {
    return this.updateSectionsFilesEvent;
  }

  deleteRequestEvent() {
    return this.deleteFileFromSectionEvent;
  }

  uploadFilesToSection(companyID: string, flowID: string, uploadedFile: any, sectionID: string) {
    try {
      return this.api.uploadFilesToSection(companyID, flowID, uploadedFile, sectionID);
    } catch (error) {
      throw error;
    }
  }

  async getStageFiles(flowID: number, stageID: number, completion: ((files: StageFiles) => void)) {
    try {
      const files = await this.api.getStagesFiles(flowID, stageID);
      completion(files);
    } catch (error) {
      throw error;
    }
  }

  async getSectionsFiles(flowID: number, completion: ((files: SectionFiles[]) => void)) {
    try {
      const files = await this.api.getSectionsFiles(flowID);
      completion(files);
    } catch (error) {
      throw error;
    }
  }

  async getFixedFiles(sectionID: number, completion: ((files: FixedFile[]) => void)) {
    try {
      const files = await this.api.getFixedFiles(sectionID);
      completion(files);
    } catch (error) {
      throw error;
    }
  }

  async updateFlowFilesOrder(files: Files[], flowID: number) {
    try {
      return await this.api.updateFlowFilesOrder(files, flowID);
    } catch (error) {
      throw error;
    }
  }

  async deleteFileFromStage(filePath: string, flowID: number, stageID: number, fileID: number) {
    return await this.api.deleteFileFromStage(filePath, flowID, stageID, fileID);
  }

  downloadFileFromStage(filePath: string, companyID: number, flowID: number) {
    try {
      return this.api.downloadFileFromStage(filePath, companyID, flowID);
    } catch (error) {
      throw error;
    }
  }

  downloadFixedFiles(fileName: string) {
    try {
      return this.api.downloadFixedFile(fileName);
    } catch (error) {
      throw error;
    }
  }

  downloadFile(url: string) {
    try {
      return this.api.downloadFile(url);
    } catch (error) {
      throw error;
    }
  }

  getFiles(dto: GetFilesDTO) {
    return this._filesAPI.getFiles(dto);
  }

  upload(dto: UploadFileDTO) {
    return this._filesAPI.upload(dto);
  }

  download(dto: DownloadFileDTO): Observable<ArrayBuffer> {
    return this._filesAPI.download(dto);
  }

  delete(dto: DeleteFileDTO): Observable<boolean> {
    return this._filesAPI.delete(dto);
  }

  async downloadFinalKitFile(signatures: any) {
    try {
      return this.api.downloadFinalKitFile(signatures);
    } catch (error) {
      throw error;
    }
  }

  async deleteFileFromSection(filePath: string, companyID: number, flowID: number, fileID: number) {
    try {
      return this.api.deleteFileFromSection(filePath, companyID, flowID, fileID);
    } catch (error) {
      throw error;
    }
  }

  async combinePdfFiles(flowID: number) {
    try {
      return this.api.combinePdfFiles(flowID);
    } catch (error) {
      throw error;
    }
  }

  async getDocumentationFiles(flowID: number, completion: ((files: Documentation[]) => void)) {
    try {
      const files = await this.api.getDocumentationFiles(flowID);
      completion(files);
    } catch (error) {
      throw error;
    }
  }

  uploadDocument(flowID: string, companyID: string, uploadedFile: any) {
    try {
      return this.api.uploadDocument(flowID, companyID, uploadedFile);
    } catch (error) {
      throw error;
    }
  }
}
