<ng-container *ngIf="!isLoading">
  <span class="tooltip-wrapper" placement="top">
    <a
      [routerLink]="this.digitalFormsRoute"
      class="btn btn-primary edit-forms-button"
      *ngIf="canShowDigitalFormsEditButton"
    >
      <app-svg-icon name="edit_special"></app-svg-icon>
      עריכת טפסים דיגיטלים
    </a>
  </span>

  <!--  -->
  <app-consultation-state [flow]="flow" [isConsultation]="isConsultation" [currentUserID]="currentUserID">
  </app-consultation-state>
  <!--  -->
  <app-manager-approve-stage
    *ngIf="
      (stage?.id === FlowStage.MANAGER_APPROVAL && (isAdmin || isFormEditor)) ||
      (stage?.id === FlowStage.MANAGER_APPROVAL &&
        !isConsultation?.consult.isActive &&
        flow.manager.id === currentUserID &&
        !this.currentUserEditor) ||
      (stage?.id === FlowStage.MANAGER_APPROVAL &&
        !isConsultation?.consult.isActive &&
        flow.partner.id === currentUserID &&
        !this.currentUserEditor)
    "
    [flow]="flow"
  >
  </app-manager-approve-stage>
  <!--  -->
  <app-partner-approve-stage
    *ngIf="
      (stage?.id === FlowStage.PARTNER_APPROVAL && (isAdmin || isFormEditor)) ||
      (stage?.id === FlowStage.PARTNER_APPROVAL &&
        !isConsultation?.consult.isActive &&
        flow.partner.id === currentUserID &&
        !this.currentUserEditor)
    "
    [flow]="flow"
  >
  </app-partner-approve-stage>
  <!--  -->
  <app-financial-consultation-stage *ngIf="stage?.id === FlowStage.PRO_CONSULTATION"></app-financial-consultation-stage>
  <!--  -->
  <app-flow-section-content
    *ngIf="stage?.id === FlowStage.IRS_CONFIRMATION"
    (containsUploadedFiles)="uploaderFileUpdated($event)"
    type="file"
    [flow]="flow"
    [currentUserEditor]="this.currentUserEditor"
  >
  </app-flow-section-content>
  <!--  -->
  <app-signature-verification-stage
    *ngIf="
      (stage?.id === FlowStage.PARTNER_SIGNATURE && (isAdmin || isFormEditor)) ||
      (stage?.id === FlowStage.CLIENT_SIGNATURE && (isAdmin || isFormEditor)) ||
      (stage?.id === FlowStage.CLIENT_SIGNATURE && !editingPermissions.isLockedForEditing) ||
      (stage?.id === FlowStage.PARTNER_SIGNATURE && !isConsultation?.consult.isActive)
    "
    [flow]="flow"
    (allSignaturesApproved)="onAllSignaturesApproved($event)"
  >
  </app-signature-verification-stage>
  <!--  -->
  <app-signature-verification-stage
    *ngIf="
      (stage?.id === FlowStage.SIGNATURE_IMPLEMENTATION && (isAdmin || isFormEditor)) ||
      (stage?.id === FlowStage.SIGNATURE_IMPLEMENTATION && !isConsultation?.consult.isActive && !this.currentUserEditor)
    "
    [flow]="flow"
    (enteredSignDate)="onEnteredSignDate($event)"
  >
  </app-signature-verification-stage>
  <!--  -->
  <app-end-flow-stage
    *ngIf="(stage?.id === FlowStage.FINISHED && (isAdmin || isFormEditor)) || stage?.id === FlowStage.FINISHED"
  ></app-end-flow-stage>
  <!--  -->
  <app-flow-section-table
    [editingPermissions]="editingPermissions"
    [managerID]="flow.manager.id"
    [partnerID]="flow.partner.id"
    [currentUserID]="currentUserID"
    (sectionFilesUpdated)="fetchFlowsSections()"
    [sections]="sectionsUntilQuality"
    [flow]="flow"
    [currentUserEditor]="currentUserEditor"
    *ngIf="showFlowSectionTable()"
  ></app-flow-section-table>
  <!--  -->
  <!-- <app-flow-section-action
    *ngIf="flow.type.id === 1"
    [editingPermissions]="editingPermissions"
    [flow]="flow"
    [flowID]="flowID"
    [sections]="sectionsFromQuality"
    title="שאלון חטיבת המס"
    body="מלאו את שאלון חטיבת המס לפני העברה לאישור מנהל/ת"
    [currentUserEditor]="currentUserEditor"
  >
  </app-flow-section-action> -->
  <!--  -->
  <app-flow-section-table
    [editingPermissions]="editingPermissions"
    [managerID]="flow.manager.id"
    [partnerID]="flow.partner.id"
    [currentUserID]="currentUserID"
    (sectionFilesUpdated)="fetchFlowsSections()"
    [sections]="sectionsFromQuality"
    [flow]="flow"
    [currentUserEditor]="currentUserEditor"
    *ngIf="showFlowSectionTable()"
  ></app-flow-section-table>
  <!--  -->
  <app-flow-comment [flowID]="flowID" [stage]="stage"> </app-flow-comment>
  <!--  -->
  <app-dynamic-footer
    *ngIf="
      !editingPermissions.isLockedForEditing ||
      isAdmin ||
      isFormEditor ||
      (flow.stage.id === FlowStage.IRS_CONFIRMATION && isUploaderHasFile)
    "
    [isUploaderHasFile]="isUploaderHasFile"
    [isConsultation]="isConsultation"
    [managerID]="flow.manager.id"
    [partnerID]="flow.partner.id"
    [currentUserID]="currentUserID"
    [flow]="flow"
    [currentUserEditor]="currentUserEditor"
    [isAllSignaturesApproved]="isAllSignaturesApproved"
    [isEnteredSignDate]="isEnteredSignDate"
  >
  </app-dynamic-footer>
</ng-container>
