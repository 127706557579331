<div *ngIf="titleText === 'הטמעת חתימות'" class="container-fluid">
  <div  class="digital-form">
    <div class="title-container">
      <div class="row h-100 row align-items-center">
        <div class="col text-right">
          <h3>עריכת טפסים דיגיטלים</h3>
        </div>
      </div>
    </div>
    <app-company-info-form
      [showAll]="false"
      [flow]="flow"
      [companyInfo]="companyInfo$ | async"
      [editingPermissions]="editingPermissions$ | async"
      (didUpdateCompanyInfoField)="didUpdateCompanyInfoField($event)"
    >
    </app-company-info-form>
  </div>
  <div class="bottom-scroll-margin"></div>
</div>
<section class="signature-verification">
  <div *ngIf="isDownloading" class="loader">
    <div class="content">
      <app-loading [description]="{ text: 'מפיק ערכה...' }"></app-loading>
    </div>
  </div>
  <div class="verification-container">
    <div class="row">
      <div class="col">
        <div class="title-container">
          <h4 class="title">
            {{ titleText }}
          </h4>
        </div>
      </div>
    </div>
    <!-- Signature option -->
    <div class="content" *ngIf="!isPartnerViewMode && !isClientViewMode">
      <div class="row">
        <div class="col-10">
          <p class="description">יש לבחור האם הלקוח יחתום באופן דיגטלי או להעלות ערכה חתומה על ידי הלקוח:</p>
        </div>
      </div>
      <div class="row">
        <div class="col-10 mb-2">
          <div class="col-5">
            <input
              class="form-check-input radio-input"
              type="radio"
              name="digitalSignature"
              id="digitalSignature"
              value="digitalSignature"
              (change)="updateSignatureOption()"
              (click)="clickSignatureOption($event)"
              [(ngModel)]="selectedSignatureOption"
            />
            <label class="form-check-label radio-label" for="digitalSignature"> חתימה דיגיטלית </label>
          </div>
          <div class="col-5">
            <input
              class="form-check-input radio-input"
              type="radio"
              name="uploadedSignature"
              id="uploadedSignature"
              value="uploadedSignature"
              (change)="updateSignatureOption()"
              (click)="clickSignatureOption($event)"
              [(ngModel)]="selectedSignatureOption"
            />
            <label class="form-check-label radio-label" for="uploadedSignature">העלאת קובץ חתום על ידי לקוח</label>
          </div>
        </div>
      </div>
      <!-- -->
      <!-- Digital option -->
      <div class="row" *ngIf="isDigitalSignature || (!isDigitalSignature && isFileUploaded)">
        <div class="col-10">
          <p class="description" [innerHTML]="textForDescription()"></p>
        </div>
      </div>
      <div
        class="row"
        *ngIf="selectedSignatureOption === 'uploadedSignature' && !isPartnerViewMode && !isClientViewMode"
      >
        <div class="col-2">
          <div class="datepicker mr-3 mt-2">
            <input
              class="form-control"
              placeholder="בחירת תאריך"
              name="uploadedSignature-date"
              id="uploadedSignature-date"
              ngbDatepicker
              #datepicker="ngbDatepicker"
              firstDayOfWeek="0"
              [minDate]="{ year: 2019, month: 1, day: 1 }"
              [maxDate]="{ year: 2035, month: 12, day: 31 }"
              [value]="signDate"
              (dateSelect)="updateDateSign($event)"
            />
            <button class="calendar-btn" (click)="datepicker.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- Upload signatured file option  -->
    <app-uploader
      *ngIf="selectedSignatureOption === 'uploadedSignature' && !isPartnerViewMode && !isClientViewMode"
      (containsUploadedFiles)="containsUploadedFiles.emit($event)"
      [flow]="flow"
      [maxFiles]="1"
      instructions="נא להעלות קובץ בפורמט PDF בלבד."
    >
    </app-uploader>
    <!--  -->
    <!-- Partner and Client view only mode -->
    <div class="content" *ngIf="isPartnerViewMode || isClientViewMode">
      <!--Upload client signature-->
      <ng-container *ngIf="this.isClientViewMode">
        <p class="explain-text">יש לחתום במיקום הרלוונטי ולעלות תמונה של חותמת החברה אותה תרצה לצרף לערכה וללחוץ אישור. לאחר מכן יש לסמן אישור בתוך הצ'ק בוקס בכל מקום בו מופיע חותמת שלך על מנת לאשר את חתימתך. לא ניתן לעבור לשלב הבא ללא אישור כלל חתימות. יש לוודא כי החתימה ממוקמת כראוי. בסיום המעבר על כלל החתימות יש ללחוץ "העבר לשלב הבא". באפשרותך גם להחזיר את הערכה לצוות הביקורת על ידי לחיצה על "העבר לצוות הביקורת" </p>
        <div class="row">
          <div *ngIf="!isSignatureClientEntered" class="upload-part">
            <p class="description">צייר את חתימתך כאן ולחץ אישור על מנת להמשיך בתהליך</p>
            <signature-pad
              #signature
              class="signature-pad"
              [options]="signaturePadOptions"
              (drawEnd)="drawComplete()"
            ></signature-pad>
            <!-- <div class="row"> -->
              <!-- <div class="col-6"> -->
                <div class="upload-signature-button-container">
                  <button class="btn btn-primary-white upload-signature-button btn-left" (click)="clearSignaturePad()">
                    נקה
                  </button>
                  <button class="btn btn-primary-white btn-left" (click)="ApplySignaturePad()" [disabled]="isEmptyCanvas">
                    אישור
                  </button>
                </div>
              </div>
            <!-- </div> -->
            <app-uploader
            class="upload-part"
            *ngIf="isClientViewMode"
            (containsUploadedFiles)="containsUploadedFiles.emit($event)"1
            [flow]="flow"
            [maxFiles]="1"
            instructions="נא להעלות קובץ בפורמט PNG עם רקע שקוף בלבד."
            >
            </app-uploader>
          <!-- </div> -->
        </div>
      </ng-container>
      <!--  -->
      <div class="row" *ngIf="isSignatureClientEntered || this.isPartnerViewMode">
        <div class="col-7">
          <p class="description">
            יש לעבור על כל החתימות וללחוץ עליהם כדי לאשר.<br />
            באפשרותך לעבור בין העמודים בהם נדרשת חתימתך ע"י לחיצה על "לחתימה הבאה" / "לחתימה הקודמת" או לגלול מטה
            בטופס.<br />
            בסיום המעבר על כלל החתימות יש ללחוץ "אשר והמשך תהליך".
          </p>
        </div>
        <div class="col-5">
          <div class="partner-pager">
            <ul class="list-inline">
              <li class="list-inline-item">
                <button
                  class="btn btn-primary-white"
                  (click)="isPartnerViewMode ? showPreviousPartnerSignature() : showPreviousClientSignature()"
                >
                  <app-svg-icon name="angle-up_special"></app-svg-icon>
                  לחתימה הקודמת
                </button>
              </li>
              <li class="list-inline-item">
                <button
                  class="btn btn-primary-white"
                  (click)="isPartnerViewMode ? showNextPartnerSignature() : showNextClientSignature()"
                >
                  <app-svg-icon name="angle-down_special"></app-svg-icon>
                  לחתימה הבאה
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- Show pdf with signthers-->
    <div
      class="pdf-container"
      *ngIf="
        (pdfSrc && isDigitalSignature && !isClientViewMode) ||
        (!isDigitalSignature && isFileUploaded) ||
        isPartnerViewMode ||
        (isClientViewMode && isSignatureClientEntered)
      "
    >
      <div class="row" *ngIf="!isPartnerViewMode && !isClientViewMode">
        <div class="col">
          <div ngbDropdown class="test">
            <button type="button" class="btn btn-primary-white" id="dropdownBasic1" ngbDropdownToggle>
              הוספת חתימה
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <label class="signature-label">בחירת חתימה:</label>
              <button
                ngbDropdownItem
                class="identification-signet"
                (click)="addSignature(SignatureType.Identification)"
              >
                חותמת לשם זיהוי
              </button>
              <button
                ngbDropdownItem
                class="btn btn-special-blue partner-signet"
                (click)="addSignature(SignatureType.Partner)"
              >
                חותמת שותף
              </button>
              <button
                *ngIf="isDigitalSignature && pdfSrc"
                ngbDropdownItem
                class="btn btn-info client-signet"
                (click)="addSignature(SignatureType.Client)"
              >
                חותמת לקוח
              </button>
              <button
              *ngIf="isDigitalSignature && pdfSrc"
              ngbDropdownItem
              class="btn btn-info company-signet"
              (click)="addSignature(SignatureType.Company)"
            >
              חותמת חברה
            </button>
              <button ngbDropdownItem class="btn btn-info date-signet" (click)="addSignature(SignatureType.Date)">
                תאריך חתימה
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="page-tracker col">עמוד {{ currentPage }} מתוך {{ totalPages }}</div>
      </div>
      <pdf-viewer
        src="{{ pdfSrc }}"
        (page-rendered)="onPageRendered($event)"
        (after-load-complete)="onLoadComplete($event)"
        [(page)]="currentPage"
        [render-text]="false"
        [original-size]="true"
        [show-all]="true"
        [show-borders]="false"
      ></pdf-viewer>
    </div>
    <!-- -->
  </div>
</section>
