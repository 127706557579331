import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from '@libs/core/services/file.service';
import { Flow } from '@digital/app/_models/flow.model';
import { FlowService } from '@digital/app/core/services/flow.service';
import { Router } from '@angular/router';
import { FlowFormsAPI } from '@digital/app/_apis/flow-forms.api';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() flow: Flow;
  @Input() fileName: string;
  @Input() fileID: number;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() content: string;
  @Input() sectionID: string;
  @Input() fileToUpload: string;
  @Input() actionType: string;
  @Input() digitalForms: any;
  @Input() isDigitalForm: boolean;

  constructor(
    private _activeModal: NgbActiveModal,
    private _filesService: FileService,
    private _flowService: FlowService,
    private flowFormsApi: FlowFormsAPI,
    private _modalService: NgbModal
  ) {}

  ngOnInit() {}

  approveDelete() {
    this._activeModal.close(true);

    // if (this.isDigitalForm) {
    //   this.flowFormsApi.addOrDeleteDigitalForms(this.flow.id, [], [this.fileID])
    //     .subscribe(response => {
    //       if (response.code === 201) {
    //         this._flowService.emitDataFromSectionTable(202);
    //         this._modalService.dismissAll();
    //       }
    //     });
    // }
  }

  async approveReplaceFile() {
    await this.approveDelete();
    this._filesService
      .uploadFilesToSection(this.flow.company.id.toString(), this.flow.id.toString(), this.fileToUpload, this.sectionID)
      .subscribe((response) => {
        this._filesService.emitDataFromModalConfrimation(response.code);
      });
  }

  async deleteWithoutPermission() {
    this._flowService.deleteFlow(this.flow.id, 'approve');
    this._activeModal.close();
    location.href = '/';
  }

  async approveReplaceStageFile() {
    const deleteFile = await this.deleteAttachment(this.fileName, this.fileID);
    if (deleteFile.code === 200) {
      this._filesService
        .uploadFilesToStage(
          this.flow.company.id.toString(),
          this.flow.id.toString(),
          this.flow.stage.id.toString(),
          this.fileToUpload
        )
        .subscribe(() => {
          this._flowService.emitDataOnUpdateStageFiles(deleteFile.code);
          this._activeModal.close();
        });
    }
  }

  approveDeleteDigitalForms() {
    this._activeModal.close(true);
  }

  cancelDeleteFlowRequest() {
    this._activeModal.close(false);
  }

  cancelDelete() {
    this._activeModal.close(false);
  }

  async deleteAttachment(filePath: string, fileID: number) {
    return await this._filesService.deleteFileFromStage(filePath, this.flow.id, this.flow.stage.id, fileID);
  }
}
