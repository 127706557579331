import { Component, OnInit } from '@angular/core';
import { SectionItem } from '@digital/app/flow/models/section.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-preview-form-modal',
  templateUrl: './preview-form-modal.component.html',
  styleUrls: ['./preview-form-modal.component.scss']
})
export class PreviewFormModalComponent implements OnInit {
  name: string;
  kind: string;
  file: any;

  constructor(private _activeModal: NgbActiveModal) { }
  ngOnInit() { }

  closeModal() {
    this._activeModal.close();
  }

  downloadFile() {
    saveAs(this.file, `${this.name}.${this.kind}`);
  }

  printFile() {}
}
