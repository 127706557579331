import { Component, OnInit, ElementRef, Input, ApplicationRef, ComponentFactoryResolver, Injector, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormFieldsFacade } from '../../form-fields.facade';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnnotationComponent } from '../annotation/annotation.component';
import { FormFields, OptionField, FieldEvent } from '../../models/form-fields.model';
import { FormsFacade } from '../../form.facade';
import { Conditions } from '../../models/conditions.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-form-fields-modal',
  templateUrl: './form-fields-modal.component.html',
  styleUrls: ['./form-fields-modal.component.scss']
})

export class FormFieldsModalComponent implements OnInit {
  @Input() fieldID: number;
  @Output() fieldDeleted = new EventEmitter<FieldEvent>();
  field: FormFields;
  optionFormFields$ = this._formFieldsFacade.optionFormFields$;
  forms$ = this._formsFacade.forms$;
  formFields$ = this._formFieldsFacade.formFields$;
  formFieldsImport$ = this._formFieldsFacade.formFieldsImport$;
  pullDataParams$ = this._formFieldsFacade.pullDataParams$;
  fieldsGroup: FormGroup;
  showOptionFields: boolean;
  selectedFormID: number;
  selectedFieldID: number;
  conditionalLogic: Conditions;
  operation = [
    {
      name: '===',
      value: 'ערך שווה ל'
    },
    {
      name: '!===',
      value: 'ערך לא שווה ל',
    },
    {
      name: '<',
      value: 'ערך קטן מ',
    },
    {
      name: '>',
      value: 'ערך גדול מ',
    },
    {
      name: '< || ===',
      value: 'ערך קטן או שווה ל',
    },
    {
      name: '> || ===',
      value: 'ערך גדול או שווה ל'
    }
  ];

  get sortedOptions(): OptionField[] {
    return this.field?.options.sort((a, b) => b.position - a.position);
  }

  get lastOptionPosition(): number {
    if (!this.sortedOptions || !this.sortedOptions[0]) {
      return null;
    }
    return this.sortedOptions[0].position;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _formFieldsFacade: FormFieldsFacade,
    private _activeModal: NgbActiveModal,
    private _app: ApplicationRef,
    private _factoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _formsFacade: FormsFacade,
    private elementRef: ElementRef) { }

  ngOnInit(): void {
    this._setupFormBuilder();
    this.getFieldData();
    this._formsFacade.getAllForms();
  }

  // Setup
  private _setupFormBuilder() {
    this.fieldsGroup = this._formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      description: ['', Validators.required],
      note: ['', Validators.required],
      position: ['', Validators.required],
      number: ['', Validators.required],
      taxCode: ['', Validators.required],
      isRequired: [null, Validators.required],
      selectedFormID: [null],
      selectedFieldID: [null],
      selectedFieldFromCompanyInfo: [null],
      selectedFieldFromFlowInfo: [null],
      calculatedFieldIDs: [''],
      calculatedFieldFormula: [''],
      calculatedFieldDefaultValue: [''],
      calculatedFieldDefaultWhen: [''],
      operationsSelect: [''],
      operationsNumber: [null],
      mustHaveValue: [null],
      addFormsToFlow: [null]
    });
  }


  onSaveFormButtonClicked(text: string, $event?: any) {
    if ($event) {
      if ($event.target.name === 'position') {
        // TODO: Update the last used position
      }
    }

    let description2br = this.fieldsGroup.get('description').value;
    if (description2br) {
      description2br = description2br.replace(/\r\n|\r|\n/g, '<br />');
    }

    const field = new FormFields();
    field.name = this.fieldsGroup.get('name').value || undefined;
    field.type = this.fieldsGroup.get('type').value || undefined;
    field.description = description2br || undefined;
    field.note = this.fieldsGroup.get('note').value || undefined;
    field.position = this.fieldsGroup.get('position').value || undefined;
    field.number = this.fieldsGroup.get('number').value || undefined;
    field.taxCode = this.fieldsGroup.get('taxCode').value || undefined;
    field.isRequired = this.fieldsGroup.get('isRequired').value || undefined;
    const selectedOperations = this.fieldsGroup.get('operationsSelect').value;
    const operationsNumber = this.fieldsGroup.get('operationsNumber').value;
    if (operationsNumber && selectedOperations) {
      field.conditionalLogic = this.convertOperations(selectedOperations, operationsNumber);
      this.conditionalLogic = field.conditionalLogic;
    }
    const mustHaveValue = this.fieldsGroup.get('mustHaveValue').value;
    if (mustHaveValue) {
      const mustValueToArray = mustHaveValue.split(',');
      if (!this.conditionalLogic) {
        this.conditionalLogic = {
          conditions: {
            operations: [
            ],
            mustHaveValue: [],
            alertIfFilled: '',
            addFormsToFlow: [],
            fileUploadMandatory: false
          }
        };
        this.conditionalLogic.conditions.mustHaveValue = mustValueToArray;
        field.conditionalLogic = this.conditionalLogic;
      } else {
        this.conditionalLogic.conditions.mustHaveValue = mustValueToArray;
        field.conditionalLogic = this.conditionalLogic;
      }
    }
    const addFormsToFlow = this.fieldsGroup.get('addFormsToFlow').value;
    if (addFormsToFlow) {
      if (!this.conditionalLogic) {
        this.conditionalLogic = {
          conditions: {
            operations: [
            ],
            mustHaveValue: [],
            alertIfFilled: '',
            addFormsToFlow: [],
            fileUploadMandatory: false
          }
        };
        this.conditionalLogic.conditions.addFormsToFlow = [addFormsToFlow];
        field.conditionalLogic = this.conditionalLogic;
      } else {
        this.conditionalLogic.conditions.addFormsToFlow = [addFormsToFlow];
        field.conditionalLogic = this.conditionalLogic;
      }
    }
    this._formFieldsFacade.updateFormFields(field, this.field.id).subscribe(() => {
      if (text === 'change') {
        return;
      }
      this.getFieldData();
      this._formFieldsFacade.resetFields(this.field.formID);
      this.closeModal();
    });
  }

  closeModal() {
    this._activeModal.close();
  }

  onFieldTypeChange(event) {
    const field = new FormFields();
    field.type = event;
    if (field.type === 'calculated') {
      field.pulledDataType = 'calculated';
    }
    this._formFieldsFacade.updateFormFields(field, this.field.id).subscribe(() => {
      this.getFieldData();
    });
  }

  addOptionField() {
    const container = document.querySelector('.ng2-pdf-viewer-container') as HTMLElement;
    const currentPage = document.querySelector(`.page[data-page-number='${this.field.formPage}']`) as HTMLElement;
    if (container === null || currentPage === null) {
      return;
    }
    // Create a annotation object
    const optionField = new OptionField();
    optionField.position = (this.lastOptionPosition + 1) || 1;
    optionField.positionX = this.field.positionX;
    optionField.positionY = this.field.positionY;
    optionField.width = 15;
    optionField.height = 15;
    if (this.field.type === 'multipleSelection') {
      optionField.type = 'checkbox';
    } else if (this.field.type === 'singleSelection') {
      optionField.type = 'radio';
    }
    this._formFieldsFacade.createOptionFields(optionField, this.field.id).subscribe(field => {
      optionField.id = field.id;
      this._appendOptionField(optionField);
      this.field.options.push(optionField);
      this.showOptionFields = true;
    });
  }

  private _appendOptionField(optionField: OptionField) {
    const factory = this._factoryResolver.resolveComponentFactory(AnnotationComponent);
    const annotationElement = document.createElement('div');
    annotationElement.className = 'option-annotation';
    annotationElement.id = 'option-annotation';
    document.getElementById('annotation-' + this.field.id).appendChild(annotationElement);
    const ref = factory.create(this._injector, [], annotationElement);
    ref.instance.field = optionField;
    ref.instance.position = { x: optionField.positionX, y: optionField.positionY };
    ref.instance.optionFieldsUpdated
      .subscribe((object: OptionField) => this.onFieldsUpdate(object, optionField.id));
    this._app.attachView(ref.hostView);
    this.getFieldData();
  }

  onFieldsUpdate(field: any, fieldID: number) {
    this._formFieldsFacade.updateOptionFields(field, fieldID).subscribe(e => console.log(e));
  }

  selectOptionFieldTypeChange(fieldValue, fieldID) {
    const field = new OptionField();
    field.type = fieldValue;
    this._formFieldsFacade.updateOptionFields(field, fieldID).subscribe(() => {
      this.getFieldData();
    });
  }

  selectedFormChange() {
    this.selectedFormID = parseInt(this.fieldsGroup.get('selectedFormID').value, 10);
    this._formFieldsFacade.getFormFieldsForImportFields(this.selectedFormID);
    this.formFieldsImport$.subscribe();
  }

  selectedFieldChange() {
    this.selectedFieldID = parseInt(this.fieldsGroup.get('selectedFieldID').value, 10);
    const selectedField = this.formFieldsImport$.value.find(f => f.id === this.selectedFieldID);
    const field = new FormFields();
    field.pulledDataType = 'formFieldID';
    field.pulledFieldID = selectedField.id;
    field.isDecimal = selectedField.isDecimal;
    this._formFieldsFacade.updateFormFields(field, this.field.id).subscribe();
  }

  optionFieldNameChange(event, fieldID: number) {
    const field = new OptionField();
    field.name = event;
    this._formFieldsFacade.updateOptionFields(field, fieldID).subscribe(() => {
      this.getFieldData();
      this._formFieldsFacade.resetFields(this.field.formID);
    });
  }

  optionFieldPositionChange(event, fieldID: number) {
    const field = new OptionField();
    field.position = event;
    this._formFieldsFacade.updateOptionFields(field, fieldID).subscribe(() => {
      this.getFieldData();
      this._formFieldsFacade.resetFields(this.field.formID);
    });
  }

  get showOptionFieldButton() {
    if (this.field?.type === 'multipleSelection' || this.field?.type === 'singleSelection' || this.field?.type === 'freeOptions') {
      return true;
    } else {
      return false;
    }
  }

  getFieldData() {
    this._formFieldsFacade.getSingleFormField(this.fieldID)
    .pipe(map(field => {
      const mutatedField = field;
      mutatedField.options = field.options.sort((option1, option2) => option2.id - option1.id);
      return mutatedField;
    }))
    .subscribe(field => {
      this.field = field;

      const calculatedFields = this.field?.pulledCalculatedFieldsIDs;
      this.conditionalLogic = this.field.conditionalLogic;

      let description2nl = this.field?.description;
      if (description2nl) {
        description2nl = description2nl.replace(/<br\s?\/?>/g, '\n');
      }

      this.fieldsGroup.setValue({
        name: this.field?.name || null,
        type: this.field?.type || null,
        description: description2nl || null,
        note: this.field?.note || null,
        number: this.field?.number || null,
        taxCode: this.field?.taxCode || null,
        position: this.field?.position,
        isRequired: this.field?.isRequired || false,
        selectedFormID: '' || null,
        selectedFieldID: this.field?.pulledFieldID || null,
        selectedFieldFromCompanyInfo: this.field?.pulledColumnName || '',
        selectedFieldFromFlowInfo: this.field?.pulledColumnName || '',
        calculatedFieldIDs: '',
        calculatedFieldFormula: '',
        calculatedFieldDefaultValue: '',
        calculatedFieldDefaultWhen: '',
        operationsSelect: null,
        operationsNumber: null,
        mustHaveValue: null,
        addFormsToFlow: null
      });

      if (calculatedFields) {
        this.fieldsGroup.patchValue({
          calculatedFieldIDs: calculatedFields.ids.toString(),
          calculatedFieldFormula: calculatedFields.formula,
          calculatedFieldDefaultValue: calculatedFields.defaultValue,
          calculatedFieldDefaultWhen: calculatedFields.useDefaultValueWhen
        });
      }

      this.getCompanyInfo();
      this.convertOperationToString(this.field.conditionalLogic);
      this.convertMustHaveValueToString(this.field.conditionalLogic);
      this.convertAddFormsToFlow(this.field.conditionalLogic);
    });
  }

  onCalculateFieldsChange() {
    const ids = this.fieldsGroup.get('calculatedFieldIDs').value;
    const formula = this.fieldsGroup.get('calculatedFieldFormula').value;
    const defaultValue = parseInt(this.fieldsGroup.get('calculatedFieldDefaultValue').value, 10);
    const useDefaultValueWhen = this.fieldsGroup.get('calculatedFieldDefaultWhen').value;

    const idsArray: number[] = ids.split(',').map((id: string) => parseInt(id, 10));

    const field = new FormFields();
    field.pulledDataType = 'calculated';
    field.pulledCalculatedFieldsIDs = {
      ids: idsArray,
      formula,
      defaultValue,
      useDefaultValueWhen
    };

    this._formFieldsFacade.updateFormFields(field, this.field.id).subscribe(e => console.log(e));
  }

  onDeleteFieldClicked() {
    if (confirm('האם אתה בטוח שברצונך למחוק את השדה?')) {
      this.fieldDeleted.emit({
        field: this.field
      });
      this.closeModal();
    }
  }

  onDeleteOptionFieldClicked(optionField: OptionField) {
    if (confirm('האם אתה בטוח שברצונך למחוק את האפשרות?')) {
      this._formFieldsFacade.deleteOptionFieldByID(optionField.id).subscribe(() => {
        this._formFieldsFacade.resetFields(this.field.formID);
        this.getFieldData();
      });
    }
  }

  getCompanyInfo() {
    this._formFieldsFacade.getPullDataParams();
  }

  didSelectEnableNegativeNumber(checkbox: any) {
    const isChecked = checkbox.checked;
    const field = new FormFields();
    field.enableNegativeNumber = isChecked;
    this._formFieldsFacade.updateFormFields(field, this.fieldID).subscribe();
  }

  selectedCompanyInfoChange() {
    if (this.fieldsGroup.get('selectedFieldFromCompanyInfo').value !== null) {
      const field = new FormFields();
      field.pulledDataType = 'companyInfo';
      field.pulledColumnName = this.fieldsGroup.get('selectedFieldFromCompanyInfo').value;
      this._formFieldsFacade.updateFormFields(field, this.fieldID).subscribe();
    }
  }

  selectedDeloitteInfoChange() {
    if (this.fieldsGroup.get('selectedFieldFromCompanyInfo').value !== null) {
      const field = new FormFields();
      field.pulledDataType = 'deloitteInfo';
      field.pulledColumnName = this.fieldsGroup.get('selectedFieldFromCompanyInfo').value;
      this._formFieldsFacade.updateFormFields(field, this.fieldID).subscribe();
    }
  }

  selectedFlowInfoChange() {
    if (this.fieldsGroup.get('selectedFieldFromFlowInfo').value !== null) {
      const field = new FormFields();
      field.pulledDataType = 'flowInfo';
      field.pulledColumnName = this.fieldsGroup.get('selectedFieldFromFlowInfo').value;
      this._formFieldsFacade.updateFormFields(field, this.fieldID).subscribe();
    }
  }

  convertCalculateArrayToString(field: any) {
    const [keyname] = Object.keys(field);
    const strngArr = field[keyname].join(',');
    const str = `${keyname} ${strngArr}`;
    return str;
  }

  convertOperations(operator: string, number: string) {
    const conditions = {
      conditions: {
        operations: [
          operator + ' ' + number
        ],
        mustHaveValue: [

        ],
        alertIfFilled: '',
        addFormsToFlow: [],
        fileUploadMandatory: false
      }
    };
    return conditions;
  }

  convertOperationToString(conditions: any) {
    if (conditions) {
      const operationArray = conditions.conditions.operations.join('');
      const operatinString = operationArray.substring(0, operationArray.lastIndexOf(' ') + 1).trim();
      const operationValue = operationArray.split(' ').pop().trim();
      this.fieldsGroup.controls.operationsSelect.setValue(operatinString);
      this.fieldsGroup.controls.operationsNumber.setValue(operationValue);
    } else {
      this.fieldsGroup.controls.operationsSelect.setValue('');
      this.fieldsGroup.controls.operationsNumber.setValue(null);
    }
  }

  convertMustHaveValueToString(conditions: any) {
    if (conditions) {
      const operationArray = conditions.conditions.mustHaveValue.join(',');
      this.fieldsGroup.controls.mustHaveValue.setValue(operationArray);
    } else {
      this.fieldsGroup.controls.mustHaveValue.setValue(null);
    }
  }

  convertAddFormsToFlow(conditions: any) {
    if (conditions) {
      const addForm = conditions.conditions.addFormsToFlow.join();
      this.fieldsGroup.controls.addFormsToFlow.setValue(addForm);
    } else {
      this.fieldsGroup.controls.addFormsToFlow.setValue(null);
    }
  }
}
