import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule as GlobalCoreModule } from '@libs/core/core.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GlobalCoreModule,
  ]
})
export class CoreModule { }
