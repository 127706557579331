import { Injectable } from '@angular/core';
import { ApiService } from '@digital/app/core/api/api.service';
import { BlogPost } from '@digital/app/blog/models/blog-post.model';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  constructor(private _api: ApiService) { }

  async getBlogPosts(page: number, completion: ((pages: number, posts: BlogPost[]) => void)) {
    try {
      const response = await this._api.getBlogPosts(page);
      completion(response.pages, response.posts);
    } catch (error) {
      throw error;
    }
  }

  async getSingleBlogPost(id: number, completion: ((post: BlogPost) => void)) {
    try {
      const response = await this._api.getSingleBlogPost(id);
      completion(response);
    } catch (error) {
      throw error;
    }
  }
}
