import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentsService } from '@digital/app/core/services/comments.service';
import { Comment, Stage } from '@digital/app/flow/models/comment.model';

@Component({
  selector: 'app-flow-add-comment',
  templateUrl: './flow-add-comment.component.html',
  styleUrls: ['./flow-add-comment.component.scss']
})
export class FlowAddCommentComponent implements OnInit {
  @Output() commentUpdated = new EventEmitter();
  @Input() currentInitialName: string;
  @Input() currentUserID: number;
  @Input() flowID: number;
  @Input() stage: Stage;
  @Input() parentID?: number;

  // Class members
  createCommentForm: FormGroup;

  constructor(private _formBuilder: FormBuilder, private _commentsService: CommentsService) { }

  ngOnInit() {
    this._setupFormBuilder();
  }

  // Setup
  private _setupFormBuilder() {
    this.createCommentForm = this._formBuilder.group({
      commentField: ['', [Validators.required]]
    });
  }


  // Actions
  submitComment() {
    const comment = this.createCommentForm.controls.commentField.value;
    this._commentsService.createNewComment(comment, this.currentUserID, this.flowID, this.stage.id, this.parentID || null, ((commentCreated: Comment) => this.onCommentCreated(commentCreated)));
  }

  // On Created Comment Success
  onCommentCreated(commentCreated: Comment) {
    const field = this.createCommentForm.get('commentField');
    field.patchValue('', { emitEvent: false });
    this.commentUpdated.emit(commentCreated);
  }
}
