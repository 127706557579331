import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FlowService } from '@digital/app/core/services/flow.service';
import { UserService } from '@digital/app/core/services/user.service';
import { Flow, RiskLevelType } from '@digital/app/_models/flow.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {
  constructor(private modalService: NgbModal, private _flowService: FlowService, private _userService: UserService) {}

  @Input() title = '';
  @Input() message?: string;
  @Input() flow: Flow;
  positiveTitle?: string;
  negativeTitle?: string;
  isLoading = false;

  //Risk Type
  RiskLevelType = RiskLevelType;
  selectedRiskLevel: RiskLevelType;
  flowRiskLevel = Object.keys(this.RiskLevelType);
  riskLevel = '';

  private _positiveAction?: () => void;
  private _negativeAction?: () => void;

  ngOnInit(): void {
    this.riskLevel = this.flow?.riskLevel;
  }

  positiveAction(title: string, action?: () => void) {
    this.positiveTitle = title;
    this._positiveAction = action;
  }

  negativeAction(title: string, action?: () => void) {
    this.negativeTitle = title;
    this._negativeAction = action;
  }

  positiveActionClicked() {
    if (this.riskLevel !== '') {
      this._updateRiskLevel().then(() => {
        this.isLoading = false;
        this._positiveAction();
        this.closeModal();
      });
    } else {
      this._positiveAction();
      this.closeModal();
    }
  }

  private async _updateRiskLevel() {
    if (!this.selectedRiskLevel) {
      return;
    }
    try {
      this.isLoading = true;
      this.flow.riskLevel = this.selectedRiskLevel;
      this._flowService.updateRiskLevel(this.flow, this.flow.riskLevel);
      const action = `עדכון רמת סיכון בשלב אישור שותף`;
      await this._userService.createNewUserEvent(this.flow, action);
    } catch (error) {
      throw error;
    }
  }

  negativeActionClicked() {
    this._negativeAction();
    this.closeModal();
  }

  closeModal() {
    this.modalService.dismissAll(null);
  }

  isNegativeAction(): boolean {
    return this.negativeTitle && this._negativeAction != null;
  }

  isPositiveAction(): boolean {
    return this.positiveTitle && this._positiveAction != null;
  }

  isMessage(): boolean {
    return this.message != null;
  }

  selectedValueRiskLevel(selectedValue: RiskLevelType) {
    this.selectedRiskLevel = selectedValue;
  }

  isContainsRiskLevel(): boolean {
    return this.riskLevel !== '' && this.riskLevel !== undefined;
  }

  translateRiskLevel(riskLevelName) {
    switch (riskLevelName) {
      case 'Undefined':
        return 'לא מוגדר';
      case 'Low':
        return 'נמוך';
      case 'Medium':
        return 'בינוני';
      case 'High':
        return 'גבוה';
      default:
        return '';
    }
  }
}
