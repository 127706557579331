import {
  Component,
  Input,
  OnInit,
  OnChanges,
  ApplicationRef,
  Injector,
  ComponentFactoryResolver,
  ViewChild,
  EventEmitter,
  Output
} from '@angular/core';
import { PdfViewerComponent, PDFDocumentProxy } from 'ng2-pdf-viewer';
import { FileService } from '@libs/core/services/file.service';
import { Flow } from '@digital/app/_models/flow.model';
import { Signature, SignatureType, SignatureEvent } from '@digital/app/flow/models/signature.model';
import { SignatureComponent } from '../signature/signature.component';
import { SignaturesService } from '@digital/app/flow/services/signatures.service';
import { UploadFileType } from '@libs/enums/upload-file-type.enum';
import { StageFile } from '@digital/app/_apis/files.api';
import { UploadFileStageType } from '@libs/enums/upload-file-stage-type.enum';
import { FlowStage } from '@digital/app/_enums/flow-stages.enum';
import { KitAPI } from '@digital/app/flow/apis/kit.api';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '@digital/app/shared/components/confirmation-modal/confirmation-modal.component';
import { UploaderComponent } from '@digital/app/shared/components/uploader/uploader.component';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { DomSanitizer } from '@angular/platform-browser';
import { FlowService } from '@digital/app/core/services/flow.service';
import { CustomDateParserFormatter } from '@libs/views/datepicker/datepicker-adapter';
import { take } from 'rxjs/operators';
import { FormsService } from '@digital/app/forms/forms.service';
import { FlowShellService } from '@digital/app/flow-shell/flow-shell.service';

@Component({
  selector: 'app-signature-verification-stage',
  templateUrl: './signature-verification-stage.component.html',
  styleUrls: ['./signature-verification-stage.component.scss']
})
export class SignatureVerificationStageComponent implements OnInit, OnChanges {
  @ViewChild(PdfViewerComponent) pdfViewer: PdfViewerComponent;
  @ViewChild(UploaderComponent) uploaderComponent: UploaderComponent;
  @ViewChild('signature') signaturePad: SignaturePadComponent;

  @Input() flow: Flow;
  @Output() containsUploadedFiles = new EventEmitter<boolean>();
  @Output() allSignaturesApproved = new EventEmitter<boolean>();
  @Output() enteredSignDate = new EventEmitter<boolean>();

  stageFile?: StageFile;
  clientSignaturePicFile: StageFile;
  companyInfo$ = this.formsService.companyInfo$;
  editingPermissions$ = this.flowShellService.editingPermissions$;

  signatures$ = this._signatureService.signatures$;
  signDate$ = this._signatureService.signDate$;
  currentPage = 1;
  totalPages = 0;
  lastSignatureId = 1;
  SignatureType = SignatureType;

  isPartnerViewMode = false;
  isClientViewMode = false;
  isImplementationViewMode = false;
  lastPartnerSignaturePage = 1;

  filePath: string;
  pdfSrc: any;
  selectedSignatureOption: string;
  isDownloading: boolean = false;
  isFileUploaded: boolean = false;

  isSignatureClientEntered: boolean = false;

  signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 0.8,
    maxWidth: 2,
    canvasWidth: 300,
    canvasHeight: 100,
    backgroundColor: 'rgba(0,0,0,0)',
    dotSize: 2,
    penColor: '#000000'
  };

  isEmptyCanvas: boolean = true;

  signDate: string = '';

  /* #region - Lifecycle */

  constructor(
    private _app: ApplicationRef,
    private _injector: Injector,
    private _factoryResolver: ComponentFactoryResolver,
    private _fileService: FileService,
    private formsService: FormsService,
    private _signatureService: SignaturesService,
    private kitAPI: KitAPI,
    private modal: NgbModal,
    private _sanitizer: DomSanitizer,
    private _flowService: FlowService,
    private flowShellService: FlowShellService
  ) {}

  ngOnInit() {
    this.containsUploadedFiles.subscribe((isFileUploaded: boolean) => {
      this.isFileUploaded = isFileUploaded;
      if (isFileUploaded) {
        this.getStageFile();
      } else if (!isFileUploaded && !this.isDigitalSignature) {
        this.pdfSrc = undefined;
      }
    });

    if (this.flow.stageFiles[0]?.stageType === UploadFileStageType.SIGNATURE_IMPLEMENTATION) {
      this.selectedSignatureOption = 'digitalSignature';
    } else if (this.flow.stageFiles[0]?.stageType === UploadFileStageType.ClientSignature) {
      this.selectedSignatureOption = 'uploadedSignature';
    }
  }

  ngOnChanges() {
    if (this.flow) {
      this.isPartnerViewMode = this.flow.stage.id === FlowStage.PARTNER_SIGNATURE;
      this.isClientViewMode = this.flow.stage.id === FlowStage.CLIENT_SIGNATURE;
      this.isImplementationViewMode = this.flow.stage.id === FlowStage.SIGNATURE_IMPLEMENTATION;
      this.getStageFile();
    }
  }

  ngAfterViewInit() {
    if (this.isClientViewMode) {
      this.signaturePad.clear();
    }
  }

  /* #endregion */

  get isDigitalSignature() {
    return this.selectedSignatureOption === 'digitalSignature';
  }

  get titleText() {
    let title = 'הטמעת חתימות';
    if (this.isPartnerViewMode) {
      title = 'חתימת שותף';
    } else if (this.isClientViewMode) {
      title = 'חתימת לקוח';
    }

    return title;
  }

  /* #region - Fetching and handling files */

  private getStageFile() {
    this._fileService
      .getFiles({
        flowID: this.flow.id,
        type: UploadFileType.STAGE
      })
      .subscribe((files) => {
        let signatureImplementationFiles = [];
        const clientSignaturePicFile = files.filter(
          (file) => file.stageType === UploadFileStageType.ClientSignaturePic
        );

        if (this.isDigitalSignature) {
          signatureImplementationFiles = files.filter(
            (file) => file.stageType === UploadFileStageType.SIGNATURE_IMPLEMENTATION
          );
        } else if (this.selectedSignatureOption === 'uploadedSignature') {
          signatureImplementationFiles = files.filter((file) => file.stageType === UploadFileStageType.ClientSignature);
        }

        if (signatureImplementationFiles[0]) {
          this.stageFile = signatureImplementationFiles[0];
          this.downloadFile(signatureImplementationFiles[0]);
          this._flowService.emitDataOnUpdateStageFiles(files);
          this.subscribeToSignDate();
        }
        if (clientSignaturePicFile[0]) {
          this.isSignatureClientEntered = true;
          this.clientSignaturePicFile = clientSignaturePicFile[0];
        }
      });
  }

  private downloadFile(file: StageFile) {
    this._fileService.download({ fileID: file.id }).subscribe((buffer) => {
      const blob = new Blob([buffer], { type: 'blob' });
      this.pdfSrc = URL.createObjectURL(blob);
      if (this.pdfSrc) {
        this._signatureService
          .getAll(this.flow.id, this.stageFile.id)
          .pipe(
            take(1) // Take only the first emitted value
          )
          .subscribe(() => {
            this.checkIfAllSignaturesIsApproved();
          });
      }
    });
  }

  subscribeToSignDate() {
    this.signDate$.subscribe((signDate) => {
      if (signDate) {
        const dateObj = new Date(signDate);
        this.signDate = dateObj
          .toLocaleDateString('he-IL', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          })
          .replace(/\./g, '/');
        this.enteredSignDate.emit(true);
      }
    });
  }

  /* #endregion */

  /* #region - PDFViewer actions */

  scrollToPage(page: number) {
    this.pdfViewer.pdfViewer.scrollPageIntoView({
      pageNumber: page
    });
  }

  showNextPartnerSignature() {
    const signatures = this.signatures$.getValue();
    const partnerSignatures = signatures
      .filter((signature) => signature.type === SignatureType.Partner)
      .sort((a, b) => a.page - b.page);

    for (const signature of partnerSignatures) {
      if (this.lastPartnerSignaturePage < signature.page) {
        this.scrollToPage(signature.page);
        this.lastPartnerSignaturePage = signature.page;
        break;
      }
    }
  }

  showPreviousPartnerSignature() {
    const signatures = this.signatures$.getValue();
    const partnerSignatures = signatures
      .filter((signature) => signature.type === SignatureType.Partner)
      .sort((a, b) => a.page + b.page);

    for (const signature of partnerSignatures) {
      if (this.lastPartnerSignaturePage > signature.page) {
        this.scrollToPage(signature.page);
        this.lastPartnerSignaturePage = signature.page;
        break;
      }
    }
  }

  showNextClientSignature() {
    const signatures = this.signatures$.getValue();
    const partnerSignatures = signatures
      .filter((signature) => signature.type === SignatureType.Client)
      .sort((a, b) => a.page - b.page);

    for (const signature of partnerSignatures) {
      if (this.lastPartnerSignaturePage < signature.page) {
        this.scrollToPage(signature.page);
        this.lastPartnerSignaturePage = signature.page;
        break;
      }
    }
  }

  showPreviousClientSignature() {
    const signatures = this.signatures$.getValue();
    const partnerSignatures = signatures
      .filter((signature) => signature.type === SignatureType.Client)
      .sort((a, b) => a.page + b.page);

    for (const signature of partnerSignatures) {
      if (this.lastPartnerSignaturePage > signature.page) {
        this.scrollToPage(signature.page);
        this.lastPartnerSignaturePage = signature.page;
        break;
      }
    }
  }

  /* #endregion */

  /* #region - PDFViewer delegates */

  onPageRendered(page: any) {
    // Create a signature container for the page
    const signaturesContainer = document.createElement('div');
    signaturesContainer.id = 'signatures-container-' + page.pageNumber;
    signaturesContainer.className = 'signatures-container';

    // Append the container to the page's annotation layer div
    page.source.div.appendChild(signaturesContainer);

    // Append existing signatures receieved from the server
    this.signatures$.subscribe((signatures) => {
      if (this.isClientViewMode) {
        signatures = signatures.filter((signature) => {
          return signature.type !== SignatureType.Partner && signature.type !== SignatureType.Identification;
        });
      }

      if (!this.signDate$.getValue() && !this.isImplementationViewMode) {
        signatures = signatures.filter((signature) => {
          return signature.type !== SignatureType.Date;
        });
      }

      signatures.forEach((signature) => {
        if (signature.page === page.pageNumber) {
          this.addSignatureFromServer(signature);
        }
      });
    });
  }

  onLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
  }

  /* #endregion */

  /* #region - Signatures handling */

  // Adds a signature received from the client/user
  addSignature(signatureType: SignatureType) {
    const container = document.querySelector('.ng2-pdf-viewer-container') as HTMLElement;
    const currentPage = document.querySelector(`.page[data-page-number='${this.currentPage}']`) as HTMLElement;
    if (container === null || currentPage === null) {
      return;
    }

    const topBoundry = container.scrollTop - currentPage.offsetTop;

    const position = {
      x: 100,
      y: topBoundry + 100
    };

    const size =
      signatureType === SignatureType.Identification
        ? { width: 150, height: 150 }
        : signatureType === SignatureType.Date
        ? { width: 90, height: 20 }
        : { width: 150, height: 30 };

    // Create a signature object
    const signature: Signature = {
      id: this.lastSignatureId,
      type: signatureType,
      imageUrl: `/assets/images/signatures/${signatureType}150.png`,
      flowID: this.flow.id,
      fileID: this.stageFile.id,
      page: this.currentPage,
      position,
      size: size,
      isApproved: false
    };
    // Send it to the server and append it to the page
    this._signatureService.create(signature).subscribe((createdSignature) => {
      signature.id = createdSignature.id;
      this._appendSignature(signature, true);
    });
  }

  // Adds a signature received from the server
  addSignatureFromServer(signature: Signature) {
    this._appendSignature(signature, false);
  }

  private _appendSignature(signature: Signature, isNew: boolean) {
    // Resolve the SignatureComponent as a factory (so we can use it as an actual object)
    const factory = this._factoryResolver.resolveComponentFactory(SignatureComponent);

    // Create a native html signature element that will contain our factory
    const signatureElement = document.createElement('div');
    signatureElement.className = `signature ${signature.type} ${!!this.isPartnerViewMode ?? 'editable'}`;
    document.getElementById('signatures-container-' + signature.page).appendChild(signatureElement);

    // Inject our native element to the factory to receive a ComponentRef
    const ref = factory.create(this._injector, [], signatureElement);
    //set the image url
    if (!signature.imageUrl) {
      if (this.isImplementationViewMode) {
        signature.imageUrl = `/assets/images/signatures/${signature.type}150.png`;
      } else if (signature.type === SignatureType.Partner) {
        signature.imageUrl = `/assets/images/signatures/partner-actual.png`;
      } else if (signature.type === SignatureType.Identification) {
        signature.imageUrl = `/assets/images/signatures/identification150.png`;
      } else if (signature.type === SignatureType.Client) {
        this._fileService.download({ fileID: this.clientSignaturePicFile.id }).subscribe((buffer) => {
          const blob = new Blob([buffer], { type: 'blob' });
          const imageUrl = URL.createObjectURL(blob);
          signature.imageUrl = this._sanitizer.bypassSecurityTrustUrl(imageUrl);
        });
      } else if (signature.type === SignatureType.Date) {
        ref.instance.date = this.signDate$.getValue();
      }
    }
    // After the injection, we can access the component's @Input(s) and variables so we can give it the Signature object we created
    ref.instance.signature = signature;
    ref.instance.isEditable = !this.isPartnerViewMode && !this.isClientViewMode;
    // Subscribe to the new component's changes
    ref.instance.signatureUpdated.subscribe((object: Signature) => this.onSignatureUpdated(object));

    ref.instance.signatureDeleted.subscribe((event: SignatureEvent) => this.onSignatureDeleted(event));

    // Attach the view to the app so it will get rendered to the DOM
    this._app.attachView(ref.hostView);
  }

  checkIfAllSignaturesIsApproved() {
    const signatureType: SignatureType = this.isPartnerViewMode ? SignatureType.Partner : SignatureType.Client;

    const signature = this.signatures$.getValue();

    if (!signature) return;

    const signaturesArray = signature.filter((signature) => {
      return signature.type === signatureType;
    });

    const allSignaturesApproved = signaturesArray.every((signature) => {
      return signature.isApproved === true;
    });

    this.allSignaturesApproved.emit(allSignaturesApproved);
  }

  firstSignDateSave(signature: Signature) {
    if (this.isClientViewMode && !this.signDate$.getValue() && signature.isApproved && this.isDigitalSignature) {
      const date = new Date().toISOString();
      this._signatureService.createSignDate(date, this.flow.id, this.stageFile.id).subscribe(
        (signDate) => {
          if (signDate) {
            this.signDate$.next(signDate);
            const signatures = this.signatures$.getValue().filter((signature) => {
              return signature.type === SignatureType.Date;
            });

            signatures.forEach((signature) => {
              this.addSignatureFromServer(signature);
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  /* #endregion */

  /* #region - Signatures delegates */

  onSignatureUpdated(signature: Signature) {
    this._signatureService.update(signature).subscribe(() => {
      this.checkIfAllSignaturesIsApproved();
      this.firstSignDateSave(signature);
    });
  }

  onSignatureDeleted({ component, signature }: SignatureEvent) {
    this._signatureService.delete(signature).subscribe(() => {
      // Find the signature index by id and delete the object
      // const foundIndex = this.signatures$.findIndex(object => object.id === signature.id);
      // this.signatures$.splice(foundIndex, 1);

      // Find the comopnent DOM element and remove it
      const factory = this._factoryResolver.resolveComponentFactory(SignatureComponent);
      const ref = factory.create(this._injector, [], component.nativeElement);
      this._app.detachView(ref.hostView);
      component.nativeElement.parentNode.removeChild(component.nativeElement);
    });
  }

  /* #endregion */

  generateKit() {
    this.isDownloading = true;
    this.kitAPI.generate(this.flow.id).subscribe(
      (buffer) => {
        this.isDownloading = false;
        const blob = new Blob([buffer], { type: 'blob' });
        const fileName = `ערכה_למס_${this.flow.company.nameHebrew}_${this.flow.year}.pdf`;
        const file = new File([blob], fileName);
        this.upload([file], UploadFileStageType.SIGNATURE_IMPLEMENTATION);
      },
      (error) => {
        this.isDownloading = false;
        console.log(error);
      }
    );
  }

  updateSignatureOption() {
    if (this.isDigitalSignature && !this.pdfSrc) {
      this.generateKit();
    }
  }

  clickSignatureOption(event) {
    const value = event.target.value;

    if (this.pdfSrc) {
      event.srcElement.blur();
      event.preventDefault();
      this.deleteFile(this.stageFile, value);
    }
  }

  deleteFile(file: StageFile, value) {
    const modalRef = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'confirmation-modal',
      centered: true
    });
    modalRef.componentInstance.title = 'שימו לב!';
    modalRef.componentInstance.content = 'הקובץ ימחק ולא יהיה ניתן לשחזור. האם למחוק?';
    modalRef.result.then((approve) => {
      if (approve) {
        this._fileService
          .delete({
            flowID: this.flow.id,
            originalName: file.fileName
          })
          .subscribe((success) => {
            if (success) {
              this.pdfSrc = undefined;
              this.isFileUploaded = false;
              this.selectedSignatureOption = value;
              if (this.isDigitalSignature) {
                this.totalPages = 0;
                this.generateKit();
              }
            }
          });
      }
    });
  }

  upload(files: File[], uploadFileStageType: UploadFileStageType) {
    this._fileService
      .upload({
        files: Array.from(files),
        flowID: this.flow.id,
        type: UploadFileType.STAGE,
        stageType: uploadFileStageType,
        stageID: this.flow?.stage.id
      })
      .subscribe(
        (success) => {
          if (success) {
            this.getStageFile();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /* #region - Signatures pad */

  drawComplete() {
    this.isEmptyCanvas = this.signaturePad.isEmpty();
  }

  clearSignaturePad() {
    this.signaturePad.clear();
    this.isEmptyCanvas = this.signaturePad.isEmpty();
  }

  ApplySignaturePad() {
    const dataUrl = this.signaturePad.toDataURL();
    const encodedImage = dataUrl.split(',')[1];
    const byteCharacters = atob(encodedImage);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const fileName = `חתימת_לקוח_${this.flow.company.nameHebrew}_${this.flow.year}.png`;
    const file = new File([byteArray], fileName, { type: 'image/png' });

    this.upload([file], UploadFileStageType.ClientSignaturePic);
  }

  /* #endregion */

  textForDescription() {
    let message = '';

    if (this.isDigitalSignature) {
      message = 'יש להטמיע חתימות לשם זיהוי, שותף, לקוח ותאריך עבור <b>כל הערכה.</b>';
    } else if (this.selectedSignatureOption === 'uploadedSignature') {
      message = `יש להעלות את הערכה החתומה ע"י הלקוח <b>במלואה</b><br/>
      יש לבחור את תאריך החתימה של הלקוח
      `;
    } else if (this.isClientViewMode) {
      message = `יש לעלות תמונה של החתימה שלך בהתאם להוראות.
      לאחר מכן יש לסמן אישור בתוך הצ'ק בוקס בכל מקום בו מופיע חותמת שלך על מנת לאשר את חתימתך.<br/>
      לא ניתן לעבור לשלב הבא ללא אישור כלל חתימות. יש לוודא כי החתימה ממוקמת כראוי.<br/>
      בסיום המעבר על כלל החתימות יש ללחוץ "העבר לשלב הבא"<br/>
      באפשרותך גם להחזיר את הערכה לצוות הביקורת על ידי לחיצה על "העבר לצוות הביקורת"
      `;
    } else if (this.isPartnerViewMode) {
      message = `באפשרותך לעבור בין העמודים בהם נדרשת חתימתך ע"י לחיצה על "לחתימה הבאה" / "לחתימה הקודמת" או לגלול מטה בטופס.<br/>
      יש לסמן אישור בתוך הצ'ק בוקס בכל מקום בו מופיע חותמת שלך על מנת לאשר את חתימתך. לא ניתן לעבור לשלב הבא ללא אישור כלל חתימות. יש לוודא כי החתימה ממוקמת כראוי.<br/>
      בסיום המעבר על כלל החתימות יש ללחוץ "אשר והמשך תהליך"<br/>
      באפשרותך גם להחזיר את הערכה לעריכה בשלב הקודם "הטעמת חתימות" באמצעות לחיצה על "דחייה וחזרה לשלב הקודם בתהליך" 
      `;
    }

    return message;
  }

  updateDateSign(date: NgbDate) {
    const dateParser = new CustomDateParserFormatter();
    const formattedDate = dateParser.format(date);
    const isoDate = new Date(Date.UTC(date.year, date.month - 1, date.day)).toISOString();
    const fieldElement = document.getElementById(`uploadedSignature-date`) as HTMLInputElement;
    fieldElement.value = formattedDate;
    this._signatureService.createSignDate(isoDate, this.flow.id, this.stageFile.id).subscribe(
      (signDate) => {
        this.signDate$.next(signDate);
        this.enteredSignDate.emit(true);
      },
      (error) => {
        console.log(error);
      }
    );
    //this.signDate = formattedDate;
  }

  didUpdateCompanyInfoField(value: any) {
    this.formsService.updateCompanyInfoField(value);
  }
}
