import { Deserializable } from '@digital/app/_models/deserializable.model';

export interface InternalUser {
  id: number;
  name: string;
  email: string;
  rankDesc: string;
}

export class InternalUsers implements Deserializable {
  internalUsers: InternalUser[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
