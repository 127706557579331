<div class="uploader" [class.small]="type === 'small'">
  <p class="instructions">{{ instructions }}</p>
  <div
    *ngIf="files.length < maxFiles || maxFiles === 0"
    class="uploadfilecontainer"
    appDragDrop
    (fileDropped)="upload($event)"
  >
    <input hidden type="file" #fileInput [multiple]="allowMultipleFilesUpload" (change)="upload($event.target.files)" />
    <div class="container">
      <div class="content">
        <div class="row mt-3">
          <div class="col">
            <h4>ניתן לגרור לכאן את הקבצים </h4>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-5">
            <hr />
          </div>
          <div class="col-2 text-center">
            <p>או</p>
          </div>
          <div class="col-5">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <button class="btn btn-primary-white" (click)="fileInput.click()">לבחור קובץ</button>
          </div>
          <div class="col text-center" *ngIf="type === 'small'">
            <button class="btn btn-borderless">ביטול</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="files">
    <ul class="file-row" *ngFor="let file of files">
      <li class="file-icon">
        <button class="btn" (click)="downloadFile(file)">
          <app-svg-icon name="pdf-file_special" class="file-icon"></app-svg-icon>
        </button>
      </li>
      <li class="file-name">
        <button class="btn" (click)="downloadFile(file)">{{ file.title }}</button>
      </li>
      <li class="file-action">
        <button class="delete-file-button" (click)="deleteFile(file)">
          <app-svg-icon *ngIf="!type" name="bin-white_special"></app-svg-icon>
          <app-svg-icon *ngIf="type === 'small'" name="bin-red_special"></app-svg-icon>
        </button>
      </li>
    </ul>
  </ng-container>
</div>
<div class="end-page-space"></div>
