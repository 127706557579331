import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  private keywordSubject: BehaviorSubject<string> = new BehaviorSubject("");
  keyword = this.keywordSubject.asObservable();

  constructor() {}

  searchKeywordChange(keyword: string) {
    this.keywordSubject.next(keyword);
  }
}
