import { ElementRef } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Position, Size } from '@libs/interfaces/dimensions.interface';

export enum SignatureType {
  Identification = 'identification',
  Partner = 'partner',
  Client = 'client',
  Date = 'date',
  Company = 'company'
}

export interface Signature {
  id?: number;
  type: SignatureType;
  imageUrl?: SafeUrl;
  flowID: number;
  fileID: number;
  page: number;
  position: Position;
  size: Size;
  isApproved: boolean;
}

export interface SignatureEvent {
  component: ElementRef;
  signature: Signature;
}
