import { Component, OnInit, Input } from '@angular/core';
import { FlowService } from '@digital/app/core/services/flow.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManagersPartners, User } from '@digital/app/_models/managers-partners.model';
import { UserService } from '@digital/app/core/services/user.service';
import { Flow, RiskLevelType } from '@digital/app/_models/flow.model';

enum ChangeFlowInfoModalType {
  manager = 1,
  partner = 2,
  riskLevel = 3
}

@Component({
  selector: 'app-change-flow-info-modal',
  templateUrl: './change-flow-info-modal.component.html',
  styleUrls: ['./change-flow-info-modal.component.scss']
})
class ChangeFlowInfoModalComponent implements OnInit {
  // Inputs
  @Input() type: ChangeFlowInfoModalType;
  @Input() companyID: number;
  @Input() flowID: number;
  @Input() currentUserID: number;
  @Input() currentRiskLevel: RiskLevelType;
  @Input() flow: Flow;

  title = '';
  massage = '';

  //Risk Type
  RiskLevelType = RiskLevelType;
  flowRiskLevel = Object.keys(this.RiskLevelType);

  // Class members
  private _users: User[];
  get users(): User[] {
    return this._users;
  }

  private _selectedUser: User;
  get selectedUser(): User {
    return this._selectedUser;
  }

  private _riskLevel: RiskLevelType;
  get selectedRiskLevel(): RiskLevelType {
    return this._riskLevel;
  }

  // Lifecycle
  constructor(
    private _flowService: FlowService,
    private _userService: UserService,
    private _activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.setTitleAndMassage();
  }

  // Actions
  didSelectUser(selectedValue: string) {
    const selectedUserID = parseInt(selectedValue, 10);
    this._selectedUser = this._users.filter((user: User) => user.id === selectedUserID)[0];
  }

  selectedValueRiskLevel(selectedValue: RiskLevelType) {
    this._riskLevel = selectedValue;
  }

  setTitleAndMassage() {
    switch (this.type) {
      case ChangeFlowInfoModalType.riskLevel:
        this.title = ' שינוי: רמת סיכון עבור התהליך';
        this.massage = 'אנא בחרו את רמת הסיכון החלופית';
        break;
      default:
        break;
    }
  }

  async submitChange() {
    if (!this._riskLevel && this.currentRiskLevel) {
      return;
    }
    try {
      let action;
      if (this.currentRiskLevel) {
        await this._flowService.updateRiskLevel(this.flow, this.selectedRiskLevel);
        action = `שינוי רמת סיכון`;
        this.flow.riskLevel = this.selectedRiskLevel;
      }

      await this._userService.createNewUserEvent(this.flow, action);
      this.closeModal();
      // emitter for update userEvents History without reload the page
      this._userService.emitDataForUpdateUserEvents('success');
      this.updateFlowData();
    } catch (error) {
      throw error;
    }
  }

  closeModal() {
    this._activeModal.close();
  }

  translateRiskLevel(riskLevel) {
    switch (riskLevel) {
      case RiskLevelType.Undefined:
        return 'לא מוגדר';
      case RiskLevelType.Low:
        return 'נמוך';
      case RiskLevelType.Medium:
        return 'בינוני';
      case RiskLevelType.High:
        return 'גבוה';
      default:
        return '';
    }
  }

  // Event emitter for update flow data (updateAt).
  updateFlowData() {
    const responseCode = 202;
    this._flowService.emitDataFromDynamicFooter(responseCode);
  }
}

export { ChangeFlowInfoModalType, ChangeFlowInfoModalComponent };
export default ChangeFlowInfoModalComponent;
