import { ID } from '@datorama/akita';

export enum UserRole {
  USER = 'user',
  MODERATOR = 'moderator',
  ADMIN = 'admin',
  FORMEDITOR = 'formEditor',
  EXTERNAL = 'external'
}

export interface User {
  id: ID;
  username: string;
  nameInitials: string;
  rank: string;
  role: UserRole;
  accessToken: string;
  refreshToken: string;
  isAuthenticated: boolean;
  division: string;
  isDisclaimed: boolean;
}
