import { Component, Input } from '@angular/core';
import { Scenario, Stage } from '@digital/app/flow/models';
import { FlowStage } from '@digital/app/_enums/flow-stages.enum';
import { Flow } from '@digital/app/_models/flow.model';

@Component({
  selector: 'app-stages-sidebar',
  templateUrl: './stages-sidebar.component.html',
  styleUrls: ['./stages-sidebar.component.scss']
})
export class StagesSidebarComponent {
  @Input() scenario: Scenario;
  @Input() flow: Flow;

  getAssignedUserNameForStage(stage: Stage): string {
    if (!this.flow) {
      return;
    }

    const managerName = this.flow.manager.fullName.hebrew;
    const partnerName = this.flow.partner.fullName.hebrew;

    switch (stage.id) {
      case FlowStage.MANAGER_APPROVAL:
        return managerName;
      case FlowStage.PARTNER_APPROVAL:
        return partnerName;
      case FlowStage.PARTNER_SIGNATURE:
        return partnerName;
      default:
        return;
    }
  }
}
