<div [formGroup]="form" class="row">
    <div *ngFor="let identifier of identifiers" class="col">
        <div class="row">
            <div class="col">
                <label [for]="idOf(identifier.controlName)" class="label">{{ identifier.placeholder }}</label>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <input [id]="idOf(identifier.controlName)"
                       type="text"
                       class="form-control"
                       [placeholder]="identifier.placeholder"
                       [formControlName]="identifier.controlName"
                       readOnly>
            </div>
        </div>
    </div>
    <div class="col">
        <div *ngIf="isInitialized">
            <div class="row">
                <div class="col">
                    <label [for]="idOf('operator')" class="label">אופרטור</label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <select [id]="idOf('operator')" class="form-control" formControlName="comparisonOperator">
                        <option value="" disabled hidden>בחר אופרטור</option>
                        <option *ngFor="let operator of comparisonOperators" [ngValue]="operator.value">
                            {{ operator.option }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div *ngIf="hasValueField">
            <div class="row">
                <div class="col">
                    <label [for]="idOf('value')" class="label">ערך</label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input [id]="idOf('value')"
                           [type]="valueType"
                           class="form-control"
                           formControlName="fieldValue"
                           placeholder="הקלד ערך">
                </div>
            </div>
        </div>
    </div>
</div>
