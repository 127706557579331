<section class="flow-section history">
  <div class="title-container">
    <div class="row">
      <div class="col-6 text-right">
      <h4 class="title">{{title}}</h4>
    </div>
    <div class="col-6 text-left">
      <p>להלן כל הסטורית האירועים שנעשו בערכה זו</p>
    </div>
    </div>
  </div>
  <div class="content-container">
    <table *ngIf="userEvents.length > 0" class="table">
      <thead>
        <tr>
          <th scope="col">
            מבצע
          </th>
          <th scope="col">
            פעולה
          </th>
          <th scope="col">
            מתי
          </th>
        </tr>
      </thead>
      <tbody *ngFor="let event of userEvents">
        <td>
          <ul class="user-with-image">
            <li class="info-item">
              <span class="profile-image"></span>
            </li>
            <li class="info-item">{{event.user.fullName.hebrew}}</li>
          </ul>
        </td>
        <td>{{event.actionTaken}}</td>
        <td>{{event.createdAt | date: 'dd/MM/yyyy בשעה hh:mm '}}</td>
      </tbody>
    </table>
  </div>
  <div class="table-pagination d-flex justify-content-between p-2">
    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
      (pageChange)="pageChange()">
      <ng-template ngbPaginationPrevious>
        <app-svg-icon name="angle-right_special"></app-svg-icon>
        לעמוד קודם
      </ng-template>
      <ng-template ngbPaginationNext>
        לעמוד הבא
        <app-svg-icon name="angle-left_special"></app-svg-icon>
      </ng-template>
    </ngb-pagination>
  </div>
</section>
