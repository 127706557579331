import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { CommentsService } from '@digital/app/core/services/comments.service';
import { UserService } from '@digital/app/core/services/user.service';
import { Comment } from '@digital/app/flow/models/comment.model';
import { AlertModalComponent } from '@digital/app/shared/components/alert-modal/alert-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-flow-view-comment',
  templateUrl: './flow-view-comment.component.html',
  styleUrls: ['./flow-view-comment.component.scss']
})
export class FlowViewCommentComponent implements OnInit {
  @Input() parentComment?: Comment;
  @Input() comment: Comment;
  @Input() flowID: number;
  @Input() currentInitialName: string;

  @Output() addCommentButtonPressed = new EventEmitter();

  get isAllowedToDeleteComment(): boolean {
    return this.userService.getCurrentUser().id === this.comment.user.id;
  }

  // Lifecycle
  constructor(
    private elementRef: ElementRef,
    private commentsService: CommentsService,
    private userService: UserService,
    private modal: NgbModal
  ) {}

  ngOnInit() {}

  // Networking & Emitters
  fetchCommentsReplies() {
    this.commentsService.getCommentsRepliesByID(this.flowID, this.comment.id, (commentsReplies: Comment[]) =>
      this.onGetCommentsReplies(commentsReplies)
    );
  }

  // Comments Replies
  onGetCommentsReplies(replies: Comment[]) {
    this.comment.replies = replies;
  }

  deleteComment(comment: Comment) {
    const confirmMessage = `שימו לב! תגובה זו וכל התגובות שלה ימחקו ולא יהיו ניתנות לשחזור`;
    const ref = this.modal.open(AlertModalComponent, { centered: true }).componentInstance;
    ref.title = 'מחיקת תגובה';
    ref.message = confirmMessage;
    ref.positiveAction('מחיקה', () => {
      this.commentsService.deleteComment(comment, this.flowID, (success) => {
        if (success) {
          if (comment.parentID === null) {
            var element = this.elementRef.nativeElement; // Assuming this is your DOM element
            // Check if the element has a parent node
            if (element.parentNode) {
              // Get the parent element
              var parentElement = element.parentNode;
              // Loop through the child nodes of the parent element
              for (var i = 0; i < parentElement.childNodes.length; i++) {
                var sibling = parentElement.childNodes[i];
                // Check if the sibling is an element node and not the current element
                if (sibling.nodeType === Node.ELEMENT_NODE && sibling !== element) {
                  // Delete or remove the sibling element
                  parentElement.removeChild(sibling);
                }
              }
            }
            this.elementRef.nativeElement.remove();
          }
          else {
            this.elementRef.nativeElement.remove();
          }
        }
      });
    });

    ref.negativeAction('ביטול', () => {});
  }
}
