export class AutoSearchTimer {
  private shortInterval = 750; // 0.75 sec
  private longInterval = 2000; // 2 sec
  private readonly callback: () => void;

  private shortTimer?: number;
  private longTimer?: number;


  constructor(callback: () => void) {
    this.callback = callback;
  }


  activate() {
    clearTimeout(this.shortTimer);
    this.shortTimer = window.setTimeout(() => this.fire(), this.shortInterval);

    if (this.longTimer == null) {
      this.longTimer = window.setTimeout(() => this.fire(), this.longInterval);
    }
  }

  cancel() {
    clearTimeout(this.shortTimer);
    clearTimeout(this.longTimer);
    this.shortTimer = null;
    this.longTimer = null;
  }

  private fire() {
    this.cancel();
    this.callback();
  }

}
