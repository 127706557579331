<div id="form-field-{{ field.id }}" class="form-field" [class.nth-child-background]="isSecondaryBackgroundColor" *ngIf="side ==='right'">
  <p class="form-field-title">
    <span class="field-identifier field-number" *ngIf="field.number">{{ field.number }}.</span>
    <span class="field-identifier field-taxCode" *ngIf="field.taxCode">[{{ field.taxCode }}]</span>
    {{ field.name }}
    <app-tooltip-indicator [tooltipTitle]="field.note"></app-tooltip-indicator>
  </p>
  <p class="form-field-description" [class.emphasize]="shouldEmphasizeDescription" [innerHTML]="field.description"></p>
  <!--  -->
  <div *ngFor="let option of field.options">
    <!-- Checkbox -->
    <div class="form-check type-checkbox" *ngIf="option.type === fieldOptionType.Checkbox">
      <div class="checkbox">
        <input
          class="form-check-input"
          type="checkbox"
          name="field-{{ field.id }}"
          id="option-{{ option.id }}"
          [value]="option.value"
          [checked]="getOptionValue(option)"
          (change)="updateFieldValue($event.target, option)"
          [required]="field.isRequired"
          [disabled]="isLockedForEditing"
        />
        <span class="checkmark"></span>
      </div>
      <label class="form-check-label" for="option-{{ option.id }}">{{ option.name }}</label>
    </div>

    <!-- Date -->
    <div class="form-check type-date" *ngIf="option.type === fieldOptionType.Date">
      <label class="form-check-label" for="option-{{ option.id }}">{{ option.name }}</label>
      <div class="datepicker">
        <input
          class="form-control"
          placeholder="בחירת תאריך"
          id="option-{{ option.id }}"
          name="option-{{ option.id }}"
          ngbDatepicker
          #datepicker="ngbDatepicker"
          firstDayOfWeek="0"
          [minDate]="{ year: 1947, month: 1, day: 1 }"
          [maxDate]="{ year: 2030, month: 12, day: 31 }"
          [value]="getOptionValue(option)"
          (dateSelect)="updateFieldValue($event, option)"
          [disabled]="isLockedForEditing"
          [required]="field.isRequired"
        />
        <button class="calendar-btn" (click)="datepicker.toggle()" type="button"></button>
      </div>
    </div>

    <!-- Text -->
    <div
      class="form-check type-text"
      *ngIf="option.type === fieldOptionType.Text || option.type === fieldOptionType.Number"
    >
      <label class="form-check-label" for="option-{{ option.id }}">{{ option.name }}</label>
      <input
        type="text"
        id="option-{{ option.id }}"
        name="option-{{ option.id }}"
        [value]="getOptionValue(option)"
        (change)="updateFieldValue($event.target, option)"
        [disabled]="isLockedForEditing"
        placeholder="הקלד/י ערך"
      />
    </div>
  </div>
  <!--  -->
</div>

<ng-container *ngIf="side ==='left'" class="absolute">
  <div *ngFor="let option of field.options">
    <!-- Checkbox -->
    <div *ngIf="option.type === fieldOptionType.Checkbox">
      <div >
        <input
          type="checkbox"
          class="form-check-input-pdf"
          name="pdf-field-{{ field.id }}" id="pdf-option-{{ option.id }}"
          [value]="option.value"
          [checked]="getOptionValue(option)"
          (change)="updateFieldValue($event.target, option)"
          [required]="field.isRequired"
          [disabled]="isLockedForEditing"
          [style.width.px]="option.width" [style.height.px]="option.height"
          [ngStyle]="{ transform: 'translate(' + option.positionX + 'px, ' + option.positionY + 'px)' }"
        />
        <span class="checkmark"></span>
      </div>
    </div>
    <!-- Date -->
    <div *ngIf="option.type === fieldOptionType.Date">
      <div class="datepicker-pdf" >
        <input
          class="form-date"
          placeholder="בחירת תאריך"
          id="pdf-option-{{ option.id }}"
          name="pdf-option-{{ option.id }}"
          ngbDatepicker
          #datepicker="ngbDatepicker"
          firstDayOfWeek="0"
          [minDate]="{ year: 1947, month: 1, day: 1 }"
          [maxDate]="{ year: 2030, month: 12, day: 31 }"
          [value]="getOptionValue(option)"
          (dateSelect)="updateFieldValue($event, option)"
          [disabled]="isLockedForEditing"
          [required]="field.isRequired"
          [style.width.px]="option.width"
          [style.height.px]="option.height"
          [ngStyle]="{ transform: 'translate(' + option.positionX + 'px, ' + option.positionY + 'px)' }"
        />
        <button class="calendar-btn" (click)="datepicker.toggle()" type="button"
          [style.width.px]="option.width"
          [style.height.px]="option.height"
          [disabled]="isLockedForEditing"
          [ngStyle]="{ transform: 'translate(' + option.positionX + 'px, ' + option.positionY + 'px)' }"
        ></button>
      </div>
    </div>

    <!-- Text -->
    <div
      *ngIf="option.type === fieldOptionType.Text || option.type === fieldOptionType.Number"
    >
      <input
        type="text"
        class="form-text-input-pdf"
        id="option-{{ option.id }}"
        name="option-{{ option.id }}"
        [value]="getOptionValue(option)"
        (change)="updateFieldValue($event.target, option)"
        [disabled]="isLockedForEditing"
        [style.width.px]="option.width"
        [style.height.px]="option.height"
        [ngStyle]="{ transform: 'translate(' + option.positionX + 'px, ' + option.positionY + 'px)' }"
      />
    </div>
  </div>
</ng-container>
