<div class="table-container">
  <div class="title-container">
    <h4 class="title">שאילתות</h4>
  </div>

  <ng-container *ngIf="state.isLoading; then loading; else loaded"></ng-container>
</div>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

<ng-template #loaded>
  <kendo-grid class="k-rtl"
              [style.height.%]="100"
              [sortable]="true"
              filterable="menu"
              [kendoGridBinding]="state.queries">
    
    <kendo-grid-column *ngFor="let column of columns"
                       class="text-center Center"
                       [field]="column.field"
                       [title]="column.title"
                       [width]="column.width"
                       [headerStyle]="{'font-weight': 'bold', 'padding-right':'12px'}">
      <ng-template kendoGridFilterMenuTemplate
                   let-column="column"
                   let-filterService="filterService"
                   [ngSwitch]="column.filter">
        <kendo-grid-numeric-filter-menu *ngSwitchCase="'numeric'"
                                        [column]="column"
                                        [filterService]="filterService"
                                        [decimals]="0"
                                        format="#"
                                        [extra]="false">
        </kendo-grid-numeric-filter-menu>
        <kendo-grid-string-filter-menu *ngSwitchCase="'text'"
                                       [column]="column"
                                       [filterService]="filterService"
                                       [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
      <ng-template *ngIf="column.field === 'name'" kendoGridCellTemplate let-query>
        <a class="text-primary pointer" [routerLink]="queryRouteById(query.id)">
          {{ query.name }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-messages filterStartsWithOperator="מתחיל ב..."
                         filterContainsOperator="מכיל..."
                         filterNotContainsOperator="לא מכיל..."
                         filterEndsWithOperator="נגמר עם..."
                         filterLtOperator="קטן מ..."
                         filterLteOperator="קטן מ או שווה ל..."
                         filterEqOperator="שווה ל..."
                         filterNotEqOperator="לא שווה ל..."
                         filterGteOperator="גדול מ או שווה ל..."
                         filterGtOperator="גדול מ..."
                         filterIsEmptyOperator="ריק"
                         filterIsNotEmptyOperator="לא ריק"
                         filterIsNotNullOperator="מכיל נתון כלשהו"
                         filterIsNullOperator="ללא נתון"
                         filterFilterButton="סנן"
                         filterClearButton="נקה בחירה"
                         noRecords="לא קיימות שאילתות">
    </kendo-grid-messages>
  </kendo-grid>
</ng-template>
