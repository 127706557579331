import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NetworkService } from '@libs/core/network/network.service';
import { GlobalAPI } from '@digital/app/_constants';

import { SectionItem, UpdateSectionItemOrderDTO, UpdateSectionItemIncludedDTO, UpdateSectionFileIncludedDTO } from '../models/section.model';

@Injectable({ providedIn: 'root' })
export class KitAPI {
  constructor(private _network: NetworkService) {}

  getFormsList(flowID: number): Observable<SectionItem[]> {
    const endpoint = `${GlobalAPI.endpoints.flows.base}/${flowID}/${GlobalAPI.endpoints.flows.formPreviewList}`;
    return this._network.get(GlobalAPI.baseURL, endpoint).pipe(map((response) => response.data.list));
  }

  updateFormsOrder(flowID: number, dtos: UpdateSectionItemOrderDTO[]): Observable<boolean> {
    const endpoint = `${GlobalAPI.endpoints.flows.base}/${flowID}/${GlobalAPI.endpoints.flows.formPreviewList}`;
    return this._network.patch(GlobalAPI.baseURL, endpoint, dtos).pipe(map((response) => response.code === 202));
  }

  generate(flowID: number): Observable<ArrayBuffer> {
    const endpoint = `${GlobalAPI.endpoints.forms.base}/${flowID}/${GlobalAPI.endpoints.forms.generateKit}`;
    return this._network.getArrayBuffer(GlobalAPI.baseURL, endpoint);
  }

  generateSigned(flowID: number): Observable<ArrayBuffer> {
    const endpoint = `${GlobalAPI.endpoints.forms.base}/${flowID}/${GlobalAPI.endpoints.forms.generateSignedKit}`;
    return this._network.getArrayBuffer(GlobalAPI.baseURL, endpoint);
  }

  updateFormIncludedInExport(flowID: number, dto: UpdateSectionItemIncludedDTO) {
    const endpoint = `${GlobalAPI.endpoints.forms.base}/${flowID}/${GlobalAPI.endpoints.forms.allForms}`;
    return this._network.patch(GlobalAPI.baseURL, endpoint, dto).pipe(map((response) => response.data));
  }

  updateFileIncludedInExport(flowID: number, file: UpdateSectionFileIncludedDTO) {
    const endpoint = `${GlobalAPI.endpoints.files.base}/${flowID}/${GlobalAPI.endpoints.files.update}`;
    return this._network.patch(GlobalAPI.baseURL, endpoint, file).pipe(map((response) => response.data));
  }
}
