import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { FlowService } from '@digital/app/core/services/flow.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Flow } from '@digital/app/_models/flow.model';
import { UserService } from '@digital/app/core/services/user.service';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { UserRole } from '@libs/authentication/models/user.model';

@Component({
  selector: 'app-delete-flow-modal',
  templateUrl: './delete-flow-modal.component.html',
  styleUrls: ['./delete-flow-modal.component.scss']
})
export class DeleteFlowModalComponent implements OnInit {
  @Input() flow: Flow;
  deleteFlowForm: FormGroup;
  isLoading = false;

  constructor(
    private _authenticationService: AuthenticationService,
    private _flowService: FlowService,
    private _userService: UserService,
    private _activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this._setupFormBuilder();
  }

  // Setup
  private _setupFormBuilder() {
    this.deleteFlowForm = this._formBuilder.group({
      reasonField: ['', [Validators.required, Validators.minLength(3), this._noWhitespaceValidator]]
    });
  }

  // Form validators
  private _noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  // Actions
  async submitDeleteRequest() {
    this.isLoading = true;

    const user = this._authenticationService.getCurrentUser();

    if (user.role === UserRole.ADMIN || user.role === UserRole.FORMEDITOR) {
      const deleteFlow = await this._flowService.deleteFlow(this.flow.id, 'approve');
      if (deleteFlow.code === 202) {
        document.location.href = '/';
      }
      return;
    }

    const action = 'בקשה למחיקת תהליך';
    const deleteReason = this.deleteFlowForm.controls.reasonField.value;

    await this._flowService.deleteFlowRequest(this.flow.id, true, deleteReason);
    await this._userService.createNewUserEvent(this.flow, action);

    this._userService.emitDataForUpdateUserEvents('success');

    this.closeModal(true);
  }

  closeModal(approved: boolean) {
    this._activeModal.close(approved);
  }
}
