<div
  id="form-field-{{ field.id }}"
  class="form-field"
  [class.nth-child-background]="isSecondaryBackgroundColor"
  *ngIf="side === 'right'"
>
  <label class="form-check-label form-field-title" for="field-{{ field.id }}">
    <span class="field-identifier field-number" *ngIf="field.number">{{ field.number }}.</span>
    <span class="field-identifier field-taxCode" *ngIf="field.taxCode">[{{ field.taxCode }}]</span>
    {{ field.name }}
    <app-tooltip-indicator [tooltipTitle]="field.note"></app-tooltip-indicator>
  </label>

  <div class="datepicker">
    <input
      class="form-control"
      [placeholder]="isLeadButtonVariant ? 'לחץ כאן על מנת לייצר קריטריון סינון חדש' : 'בחירת תאריך'"
      name="field-{{ field.id }}"
      id="field-{{ field.id }}"
      ngbDatepicker
      #datepicker="ngbDatepicker"
      firstDayOfWeek="0"
      [minDate]="{ year: 1947, month: 1, day: 1 }"
      [maxDate]="{ year: 2030, month: 12, day: 31 }"
      [value]="field.value || field.defaultValue"
      (dateSelect)="updateFieldValue($event)"
      [disabled]="isLockedForEditing"
      [required]="field.isRequired"
    />
    <button
      class="calendar-btn"
      (click)="isLeadButtonVariant ? null : datepicker.toggle()"
      type="button"
      [disabled]="isLockedForEditing"
    ></button>
  </div>
  <p class="form-field-description" [class.emphasize]="shouldEmphasizeDescription" [innerHTML]="field.description"></p>
</div>
<div class="datepicker-pdf" *ngIf="side === 'left'">
  <input
    class="form-date"
    placeholder="בחירת תאריך"
    name="pdf-field-{{ field.id }}"
    id="pdf-{{ field.id }}"
    ngbDatepicker
    #datepicker="ngbDatepicker"
    firstDayOfWeek="0"
    [ngClass]="{ 'small-datepicker-pdf': field.width < 80 }"
    [minDate]="{ year: 1947, month: 1, day: 1 }"
    [maxDate]="{ year: 2030, month: 12, day: 31 }"
    [value]="field.value || field.defaultValue"
    (dateSelect)="updateFieldValue($event)"
    [disabled]="isLockedForEditing"
    [required]="field.isRequired"
    [style.width.px]="field.width"
    [style.height.px]="field.height"
    [ngStyle]="{ transform: 'translate(' + field.positionX + 'px, ' + field.positionY + 'px)' }"
  />
  <button
    class="calendar-btn"
    (click)="isLeadButtonVariant ? null : datepicker.toggle()"
    type="button"
    [style.width.px]="field.width"
    [style.height.px]="field.height"
    [disabled]="isLockedForEditing"
    [ngStyle]="{ transform: 'translate(' + field.positionX + 'px, ' + field.positionY + 'px)' }"
  ></button>
</div>
