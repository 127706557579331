<ng-container *ngIf="flow$ | async">
  <main>
    <div class="main-flow-container" key="flow$.id">
      <!-- Sidebar begins -->
      <app-stages-sidebar [scenario]="scenario" [flow]="flow$ | async"></app-stages-sidebar>
      <!-- Sidebar ends -->

      <!-- Main content begins -->
      <div class="main-content">
        <!-- Message bar component accepts array of buttons, title, and icon name  -->
        <app-message-bar
          *ngIf="showMessageBar"
          [flow]="flow$ | async"
          [title]="messageBarTitle"
          [titleIcon]="messageBarIcon"
          [buttons]="messageBarButtons"
          [showTakeOverButton]="messageBarShowTakeOverButton"
          (takeOverFlowEmitted)="takeOverFlow()"
        ></app-message-bar>

        <div class="loading-state" *ngIf="isLoading$ | async">
          <app-loading></app-loading>
        </div>

        <ng-container>
          <!-- Begin flow header -->
          <app-flow-header
            [flow]="flow$ | async"
            [editingPermissions]="editingPermissions$ | async"
            (backButtonClicked)="goBackToMainPage()"
            (generateKitButtonClicked)="openOrganizeKitModal()"
          >
          </app-flow-header>
          <!-- End flow header -->

          <div class="container-fluid">
            <!-- Begin flow tabs -->
            <app-flow-tabs>
              <!-- Main tab -->
              <app-flow-tab pageTitle="ראשי" iconName="list_special">
                <app-flow-edit-mode
                  [editingPermissions]="editingPermissions$ | async"
                  [isLoading]="isLoading$ | async"
                  [scenario]="scenario"
                  [stage]="(flow$ | async).stage"
                  [flowID]="(flow$ | async).id"
                  [flow]="flow$ | async"
                ></app-flow-edit-mode>
              </app-flow-tab>

              <!-- Events log tab -->
              <app-flow-tab pageTitle="הסטורית אירועים" iconName="history_special">
                <app-flow-history-tab title="הסטורית אירועים" [flowID]="(flow$ | async).id"></app-flow-history-tab>
              </app-flow-tab>

              <!-- Inbox tab -->
              <app-flow-tab pageTitle="תיעוד שנים קודמות" iconName="inbox_special">
                <app-file-managment-tab title="תיעוד שנים קודמות" [flow]="flow$ | async"></app-file-managment-tab>
              </app-flow-tab>

              <!-- Permissions tab-->
              <app-flow-tab pageTitle="הרשאות" iconName="team_special">
                <app-flow-permissions-tab
                  title1="הרשאות משתמשים פנימיים"
                  title2="הרשאות משתמשים חיצוניים"
                  [flow]="flow$ | async"
                ></app-flow-permissions-tab>
              </app-flow-tab>
            </app-flow-tabs>

            <!-- End flow tabs -->
          </div>
        </ng-container>

        <div class="bottom-scroll-margin"></div>
      </div>
      <!-- Main content ends -->
    </div>
  </main>
</ng-container>
<div class="loading-state" *ngIf="isLoading$ | async">
  <app-loading></app-loading>
</div>
