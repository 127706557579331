import { ID } from '@datorama/akita';

export class Consultation {
  id?: number;
  flowID: number;
  companyID: number;
  departmentID: number;
  creatorUserID: ID;
  consultationUserID: number;
  stageID: number;
  body: string;
  createdAt?: Date;
  updatedAt?: Date;
}
