import { Injectable } from '@angular/core';
import { Store, StoreConfig, ID } from '@datorama/akita';
import { UserRole } from '../models/user.model';

export interface AuthenticationState {
  id: ID | null;
  username: string | null;
  nameInitials: string | null;
  rank: string | null;
  role: UserRole | null;
  accessToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean | false;
  division: string | null;
  isDisclaimed: boolean | false;
}

export function createInitialState(): AuthenticationState {
  return {
    id: null,
    username: null,
    nameInitials: null,
    rank: null,
    role: null,
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    division: null,
    isDisclaimed: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'authentication', resettable: true })
export class AuthenticationStore extends Store<AuthenticationState> {
  constructor() {
    super(createInitialState());
  }
}
