<div id="page-create-flow">
  <div class="form-container" *ngIf="!isLoading">
    <div class="container">
      <!-- Title -->
      <form [formGroup]="createFlowForm" (submit)="submitFlow()">
        <div class="title-container">
          <div class="row">
            <div class="col-6">
              <h3 class="title">יצירת תהליך חדש</h3>
              <div class="title-tag">{{ flowName }}</div>
            </div>
            <div class="col-6 button-col">
              <div class="buttons-container">
                <button type="submit" class="btn btn-success" [disabled]="!newFlow.companyID">
                  סיום
                  <app-svg-icon name="checkmark_special"></app-svg-icon>
                </button>
                <button type="button" class="btn btn-outline-dark" (click)="cancelButtonPressed()">
                  חזרה לדף הראשי
                  <app-svg-icon name="angle-left_special"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Form fields -->
        <div class="fields-container">
          <app-flow-tabs [sticky]="true">
            <app-flow-tab
              pageTitle="פרטי התהליך"
              iconName="list_special"
              [isInvalidInside]="createFlowForm.get('details').invalid && isSubmitClick"
            >
              <app-process-details-tab
                (isTaxYearSet)="isTaxYearSet($event)"
                [formGroup]="createFlowForm"
                [newFlow]="newFlow"
                [isSubmitClick]="isSubmitClick"
                [changeFormFieldState]="changeFormFieldState"
              ></app-process-details-tab>
            </app-flow-tab>
            <!-- form main close-->
            <app-flow-tab
              pageTitle="הרשאות"
              iconName="team_special"
              [isDisabled]="isPermissionDisable"
              [isInvalidInside]="createFlowForm.get('permissions').invalid && isSubmitClick"
            >
              <app-process-permissions-tab
                [subjectPermissionDisable]="subjectPermissionDisable"
                [formGroup]="createFlowForm"
                [newFlow]="newFlow"
                [isSubmitClick]="isSubmitClick"
              ></app-process-permissions-tab>
            </app-flow-tab>
          </app-flow-tabs>
        </div>
      </form>
    </div>
  </div>
  <div class="empty-view-container" *ngIf="isLoading">
    <h2>המערכת מייצרת את התהליך</h2>
    <h4>אנא המתן...</h4>
    <app-loading></app-loading>
  </div>
</div>
