import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeadQueryCriterion } from '@digital/app/_models/lead-query-criterion';

@Injectable({
  providedIn: 'root'
})
export class LeadCriterionFormMappingService {
  private readonly typesWithValue: string[] = ['text', 'number', 'date'];

  constructor(private fb: FormBuilder) { }

  toForm(criterion?: LeadQueryCriterion): FormGroup {
    const form = this.fb.group({
      year: this.fb.control(criterion?.year, Validators.required),
      formId: this.fb.control(criterion?.formId, Validators.required),
      formName: this.fb.control(criterion?.formName, Validators.required),
      fieldId: this.fb.control(criterion?.fieldId, Validators.required),
      fieldName: this.fb.control(criterion?.fieldName, Validators.required),
      valueType: this.fb.control(criterion?.valueType, Validators.required)
    });

    const isInitialized = criterion?.fieldId;

    if (isInitialized) {
      form.addControl('comparisonOperator', this.fb.control(criterion?.comparisonOperator || '', Validators.required));
      
      const hasValue = this.valueTypeHasValue(criterion?.valueType);

      if (hasValue) {
        form.addControl('fieldValue', this.fb.control(criterion?.fieldValue, Validators.required));
      }
    }

    return form;
  }

  fromFormValue(formValue: any): LeadQueryCriterion {
    return formValue;
  }

  valueTypeHasValue(valueType: string): boolean {
    return this.typesWithValue.includes(valueType);
  }
}
