import { Component, OnInit, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormsService } from '@digital/app/forms/forms.service';
import { CreateCustomFormFieldDTO } from '@digital/app/_dtos/create-custom-field.dto';
import { EnforceNumberType, ReconcileNumberType } from '@digital/app/_models/form-field.model';
import { clone } from 'lodash';
import { FieldBaseComponent } from '../field-base/field-base.component';

enum FieldCustomPlaceholderMode {
  Initial,
  Create
}
@Component({
  selector: 'app-field-custom-placeholder',
  template: `
    <div class="form-field-container">
      <ng-container *ngIf="mode === FieldCustomPlaceholderMode.Initial">
        <div class="form-field-placeholder">
          <button class="btn btn-primary" (click)="startCreateMode()">
            <app-svg-icon name="plus_special"></app-svg-icon>
            הוספת שדה מותאם אישית
          </button>
        </div>
      </ng-container>
      <!--  -->
      <ng-container *ngIf="mode === FieldCustomPlaceholderMode.Create">
        <div class="form-field-create">
          <label class="form-check-label form-field-title" for="field-{{ field.id }}"> שם השדה </label>
          <div class="input-container">
            <input
              class="form-control form-text-input"
              type="text"
              name="field-{{ field.id }}"
              placeholder="הקלד/י ערך"
              [(ngModel)]="fieldName"
              (keyup.enter)="submit()"
            />
          </div>
          <app-field-custom-options
            *ngIf="displayCustomOptions"
            title="סוג השדה"
            [options]="[
              { name: 'הוסף', value: ReconcileNumberType.Add, isSelected: false },
              { name: 'הפחת', value: ReconcileNumberType.Subtract, isSelected: false }
            ]"
            (didChooseOption)="setFieldReconcileType($event.value)"
          ></app-field-custom-options>
          <p class="form-field-error" *ngIf="this.error">{{ error.message }}</p>
          <div class="buttons-container">
            <button class="btn btn-outline-primary" (click)="cancelCreateMode()">ביטול</button>
            <button class="btn btn-primary" (click)="submit()">שמירה</button>
          </div>
        </div>
        <div *ngIf="isLoading" class="loading">
          <app-loading></app-loading>
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./field-custom-placeholder.component.scss']
})
export class FieldCustomPlaceholderComponent extends FieldBaseComponent implements OnInit {
  @Input() placeholderData: CreateCustomFormFieldDTO;

  @Output() createCustomField = new EventEmitter<CreateCustomFormFieldDTO>();

  isLoading = false;
  mode = FieldCustomPlaceholderMode.Initial;

  fieldName: string;
  fieldReconcileType: ReconcileNumberType;

  FieldCustomPlaceholderMode = FieldCustomPlaceholderMode;
  ReconcileNumberType = ReconcileNumberType;

  get displayCustomOptions(): boolean {
    return this.placeholderData.reconcileType === ReconcileNumberType.Both;
  }

  constructor(public _elementRef: ElementRef, public formsService: FormsService) {
    super(_elementRef, formsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  startCreateMode() {
    this.mode = FieldCustomPlaceholderMode.Create;
    this.fieldReconcileType = this.field.reconcileType;
  }

  cancelCreateMode() {
    this.mode = FieldCustomPlaceholderMode.Initial;

    this.fieldName = undefined;
    this.fieldReconcileType = undefined;

    this.error = undefined;
  }

  setFieldReconcileType(type: ReconcileNumberType) {
    this.fieldReconcileType = type;
  }

  submit() {
    if (!this.fieldName || this.fieldName.length === 0) {
      this.error = new Error('יש להקליד את שם השדה');
      return;
    } else {
      this.error = undefined;
    }

    if (this.fieldReconcileType === ReconcileNumberType.Both) {
      this.error = new Error('יש לבחור את סוג השדה');
      return;
    }

    this.error = undefined;
    this.isLoading = true;

    const dto: CreateCustomFormFieldDTO = clone(this.placeholderData);
    dto.name = this.fieldName;
    dto.position = this.placeholderData.position - 1;
    dto.reconcileType = this.fieldReconcileType;
    dto.enforceNumberType = EnforceNumberType.Positive;
    
    this.formsService.createCustomFormField(dto).subscribe(
      (field) => {
        this.isLoading = false;
        console.log(field)
        if (field) {
          this.cancelCreateMode();
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
}
