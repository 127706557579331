<div
  class="signature-block"
  cdkDrag
  vsDragScroll
  [vsDragScrollContainer]="pdfContainer"
  [cdkDragData]="signature"
  cdkDragBoundary=".signatures-container"
  (cdkDragEnded)="onDragEnded($event)"
  [cdkDragFreeDragPosition]="signature.position"
  ngClass="{{ isEditable || isDateAfterImplementation() ? '' : 'click-pointer' }}"
>
  <img
    #resizeBox
    *ngIf="!isDateAfterImplementation()"
    [src]="signature.imageUrl"
    alt="{{ signature.type }} signature"
    class="signature-img"
    [ngStyle]="{ width: signature.size.width + 'px', height: signature.size.height + 'px' }"
  />
  <div
    class="date-div"
    *ngIf="isDateAfterImplementation()"
    [ngStyle]="{ width: signature.size.width + 'px', height: signature.size.height + 'px' }"
  >
    <p>{{ date | date : 'dd.MM.yyyy' }}</p>
  </div>
  <span
    *ngIf="isEditable"
    class="signature-button delete"
    ngClass="{{ isDate() ? 'delete-date' : '' }}"
    (click)="deleteSignatureClicked()"
    >X</span
  >
  <span *ngIf="!isDate()" class="signature-button big" (click)="changeSignatureSize(true)">+</span>
  <span *ngIf="!isDate()" class="signature-button small" (click)="changeSignatureSize(false)">-</span>
  <input
    type="checkbox"
    *ngIf="!isEditable && !isDate() && !isIdentification"
    class="signature-button checkbox"
    [checked]="this.signature.isApproved"
    (change)="didChangeCheckStatus()"
  />
</div>
