<form>
  <!-- <textarea type="text" id="newNote"  name="title" [(ngModel)]="newNote" placeholder="הוספת תגובה"></textarea> -->

  <div class="relative-block-container">
    <div>
      <textarea
        #textareaRef
        cols="40"
        rows="6"
        [formControl]="textCtrl"
        placeholder="הוספת הערה ושימוש ב-@ על מנת לתייג משהו"
        wrap="hard"
      ></textarea>

      <ngx-mentions
        [textInputElement]="textareaRef"
        [menuTemplate]="menuTemplate"
        [triggerCharacter]="'@'"
        [selectedChoices]="selectedChoices"
        [getChoiceLabel]="getChoiceLabel"
        [searchRegexp]="searchRegexp"
        [removeWholeTagOnBackspace]="true"
        (search)="loadChoices($event)"
        (selectedChoicesChange)="onSelectedChoicesChange($event)"
      ></ngx-mentions>

      <ng-template #menuTemplate let-selectChoice="selectChoice">
        <ngx-text-input-autocomplete-menu
          [choices]="tmpMentionUsers"
          [getDisplayLabel]="getDisplayLabel"
          (selectChoice)="selectChoice($event)"
        >
        </ngx-text-input-autocomplete-menu>
      </ng-template>
    </div>
  </div>
  <button type="button" (click)="addNote()">שמור</button>
</form>
