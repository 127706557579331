export class Utils {
  public static isNullOrEmpty(value: any): boolean {
    return value === undefined || value === null || value === '';
  }

  public static MONTHS = [
    'ינואר',
    'פברואר',
    'מרץ',
    'אפריל',
    'מאי',
    'יוני',
    'יולי',
    'אוגוסט',
    'ספטמבר',
    'אוקטובר',
    'נובמבר',
    'דצמבר'
  ];

  public static parseHebrewDate(date: Date) {
    return date.getDate() + ' ' + Utils.MONTHS[date.getMonth()] + ', ' + date.getFullYear();
  }

  public static getIndexByParameter(arr, parameter, value) {
    return arr.map((val) => val[parameter]).indexOf(value);
  }

  public static parseDateForMySQL(date: any) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const newDate = `${year}-${month}-${day}`;
    return newDate;
  }

  static parseHebrewMonth(date: any) {
    return date.getFullYear() + ' ' + Utils.MONTHS[date.getMonth()];
  }

  static filterStringArrayByKeyword(data: string[], keyword: string) {
    const filterValue = keyword.toLowerCase();
    return data.filter((value) => value.toLowerCase().includes(filterValue));
  }

  static openUrlInNewTab(url: string) {
    const tab = window.open(url, '_blank');
    tab.focus();
  }
}
