import * as internal from 'events';
import { IFTTTAction, IFTTTElse, IFTTTThenThat } from '../_interfaces/ifttt-action.inteface';
import { FormFieldOption } from './form-field-option.model';

export enum FormFieldType {
  Title = 'title',
  Subtitle = 'subtitle',
  Text = 'text',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date',
  Calculated = 'calculated',
  SingleSelection = 'singleSelection',
  MultipleSelection = 'multipleSelection',
  PullData = 'pullData',
  FreeOptions = 'freeOptions',
  CustomFieldPlaceholder = 'customFieldPlaceholder'
}

export enum PulledDataType {
  None = 'none',
  CompanyInfo = 'companyInfo',
  FormFieldID = 'formFieldID',
  Calculated = 'calculated'
}

export enum EnforceNumberType {
  None = 'none',
  Both = 'both',
  Positive = 'positive',
  Negative = 'negative'
}

export enum ReconcileNumberType {
  None = 'none',
  Profit = 'profit',
  Loss = 'loss',
  Both = 'both',
  Add = 'add',
  Subtract = 'subtract',
  Other = 'others',
  OtherAdd = 'othersAdd',
  OtherSubtract = 'othersSubtract'
}

export enum ReconcileColumn {
  Right = 'right',
  Left = 'left'
}

export interface FormField {
  id?: number;
  version?: number;
  sectionID?: number; // Applies for Custom Field Placeholder
  formID?: number;
  formPage?: number;
  position?: number;
  name?: string;
  number?: string;
  taxCode?: string;
  type: FormFieldType;
  description?: string;
  note?: string;
  isRequired?: boolean;
  isDecimal?: boolean;
  enforceNumberType: EnforceNumberType;
  enableNegativeNumber?: boolean;
  options?: FormFieldOption[];
  fields?: FormField[]; // Nested fields if applicable
  isVisible?: boolean;
  conditionalLogic?: ConditionalLogic;

  // Values
  defaultValue?: number; // The default value if not filled
  value?: any; // The current value received from the server
  newValue?: any; // The new value if changed
  previousValue?: any; // Save the previous value in order to support revert

  // Pulling values
  pulledDataType?: PulledDataType;

  // Reconcile props
  reconcileType?: ReconcileNumberType;
  reconcileParentSection?: number;
  reconcileColumnPosition?: ReconcileColumn;

  // Position of the field in the PDF form
  positionX?: number;
  positionY?: number;
  width?: number;
  height?: number;

  // Custom field props
  isCustom: boolean;
  createFieldOfType?: FormFieldType.Number | FormFieldType.Text;
}

export interface FormFieldUpdate {
  fieldsToUpdate?: FormFieldToUpdate[];
  actions?: IFTTTAction[];
}

// Used for fields which are of 'calculated' types
export interface FormFieldToUpdate {
  fieldID: number;
  updatedValue: number;
}

export interface ConditionalLogic {
  else: IFTTTElse;
  ifThat: string;
  thenThat: IFTTTThenThat;
}
