<div class="modal-header">
  <div class="title-container">
    <h5 class="title">{{ title }}</h5>
  </div>
</div>

<div class="modal-body">
  <p class="subtitle">{{ subtitle }}</p>
  <p [innerHTML]="content"></p>
</div>

<div class="modal-footer">
  <div class="buttons-container" [class.update-file]="actionType === 'updateFile' || actionType === 'deleteFile'">
    <button
      class="btn btn-danger delete"
      *ngIf="
        actionType !== 'updateFile' &&
        actionType !== 'deleteFlow' &&
        actionType !== 'updateStageFile' &&
        actionType !== 'digitalForms'
      "
      (click)="approveDelete()"
    >
      מחיקה
    </button>
    <button class="btn btn-danger delete" *ngIf="actionType === 'updateFile'" (click)="approveReplaceFile()">
      אישור
    </button>
    <button class="btn btn-danger delete" *ngIf="actionType === 'deleteFlow'" (click)="deleteWithoutPermission()">
      אישור מחיקה
    </button>
    <button class="btn btn-danger delete" *ngIf="actionType === 'updateStageFile'" (click)="approveReplaceStageFile()">
      אישור מחיקה
    </button>
    <button class="btn btn-danger delete" *ngIf="actionType === 'digitalForms'" (click)="approveDeleteDigitalForms()">
      אישור מחיקה
    </button>
    <!--  -->
    <button *ngIf="actionType !== 'deleteFlow'" class="btn btn-outline-primary cancel" (click)="cancelDelete()">
      ביטול
    </button>
    <button
      *ngIf="actionType === 'deleteFlow'"
      class="btn btn-outline-primary cancel"
      (click)="cancelDeleteFlowRequest()"
    >
      לא מאושר למחיקה
    </button>
  </div>
</div>
