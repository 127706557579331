import {
  Component,
  OnInit,
  OnDestroy,
  ApplicationRef,
  Injector,
  ComponentFactoryResolver,
  ComponentRef,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

// Dependencies
import { Utils } from '@digital/app/shared/utils';
import { PdfViewerComponent, PDFDocumentProxy } from 'ng2-pdf-viewer';

// Models

import { Form, FormState, FormType } from '@digital/app/_models/form.model';

// Services
import { LeadFormService } from '../lead-form.service';

// Components
import { FlowShellService } from '@digital/app/flow-shell/flow-shell.service';
import { CreateCustomFormFieldDTO } from '@digital/app/_dtos/create-custom-field.dto';
import * as _ from 'lodash';
import { FieldAnnotationComponent } from '@digital/app/forms/components/field-annotation/field-annotation.component';
import { FieldsGridComponent } from '@digital/app/forms/components/fields-grid/fields-grid.component';
import { FieldsGridComponentOld } from '@digital/app/forms/components/fields-grid-old/fields-grid-old.component';
import { FormField } from '@digital/app/_models/form-field.model';

@Component({
  selector: 'app-lead-query-form-content',
  templateUrl: './lead-query-form-content.component.html',
  styleUrls: ['./lead-query-form-content.component.scss']
})
export class MainFormContentComponent implements OnInit, OnDestroy {
  @ViewChild(PdfViewerComponent) pdfViewer: PdfViewerComponent;

  isLoadingFormData$ = this.LeadFormService.isLoading$;

  currentForm$ = this.LeadFormService.currentForm$;
  currentFormPdfURL$ = this.LeadFormService.currentFormPdfURL$;
  currentFormFields$ = this.LeadFormService.currentFormFields$;

  currentFormSubscription: Subscription;
  currentFormFieldsSubscription: Subscription;

  formType = FormType;
  // fieldType = FormFieldType;
  formState = FormState;
  filteredFields$ = new BehaviorSubject(Array<FormField>());

  isSearching = false;
  currentKeyword: string;

  totalPages = 0;
  currentPage = 1;
  showRightSide = true;

  editingPermissions$ = this.LeadFormService.editingPermissions$;
  shouldLockFieldsForEditing = false; // Decides whether to lock all of the current form fields for editing based on its state.

  annotationRefs: ComponentRef<FieldAnnotationComponent>[] = [];
  fieldGridsRefs: ComponentRef<FieldsGridComponent>[] = [];
  fieldGridsOldRefs: ComponentRef<FieldsGridComponentOld>[] = [];

  get isPdfFormAvailable(): boolean {
    if (
      this.currentForm$.value.formTaxID == 3 ||
      this.currentForm$.value.formTaxID == 4 ||
      this.currentForm$.value.formTaxID == 5
    ) {
      return false;
    }

    const pdfURL = this.currentFormPdfURL$.value;
    return !Utils.isNullOrEmpty(pdfURL);
  }

  // Lifecycle
  constructor(
    private flowShellService: FlowShellService,
    private LeadFormService: LeadFormService,
    private app: ApplicationRef,
    private injector: Injector,
    private factoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const isLockedForEditing = this.editingPermissions$.value.isLockedForEditing;
    if (isLockedForEditing) {
      this.shouldLockFieldsForEditing = isLockedForEditing;
    }

    this.currentFormSubscription = this.currentForm$.subscribe((form) => {
      if (form.id) {
        this.shouldLockFieldsForEditing = form.state === FormState.Published;
        // this.LeadFormService.getFormData(form);
        // this.LeadFormService.getAllFormsForSpecifiedYear(2021);
        this.showOrHideRightSidePanel(form);
      }
    });

    this.currentFormFieldsSubscription = this.currentFormFields$.subscribe((fields) => {
      console.log({ fields });
      this.filteredFields$.next(fields);

      if (this.isSearching) {
        this.searchFieldsKeywordTyped(this.currentKeyword);
      }
    });

    this.currentFormSubscription.add(this.currentFormFieldsSubscription);
  }

  ngOnDestroy() {
    this.currentFormSubscription.unsubscribe();
  }

  // Methods
  changeCurrentFormState() {
    const currentState = this.currentForm$.value.state;
    const newState = currentState === FormState.Draft ? FormState.Published : FormState.Draft;
    this.LeadFormService.updateFormState(newState);
  }

  collapseRightClicked() {
    this.showRightSide = !this.showRightSide;
  }

  showOrHideRightSidePanel(form: Form) {
    if (form.sectionID === 7 || form.formTaxID == 2) {
      this.showRightSide = true;
    } else {
      this.showRightSide = true;
    }
  }

  // Search filter
  searchFieldsKeywordTyped(keyword: string) {
    const formFields = this.currentFormFields$.value;

    if (Utils.isNullOrEmpty(keyword)) {
      this.isSearching = false;
      this.currentKeyword = undefined;
      this.filteredFields$.next(formFields);
      return;
    }

    this.isSearching = true;
    this.currentKeyword = keyword;

    let filteredFields: FormField[];

    if (this.LeadFormService.isNestedDataStructure) {
      filteredFields = this.applySearchOnNestedFieldsStructure(formFields, keyword);
    } else {
      filteredFields = this.applySearchOnFlatFieldsStructure(formFields, keyword);
    }

    this.filteredFields$.next(filteredFields);
  }

  private applySearchOnFlatFieldsStructure(fields: FormField[], keyword: string): FormField[] {
    return fields.filter((field) => this.formFieldNameMatchesKeyword(field, keyword));
  }

  private applySearchOnNestedFieldsStructure(fields: FormField[], keyword: string): FormField[] {
    // TODO: This method returns subfields without their parents - figure it out.

    let results: FormField[] = [];

    for (const field of fields) {
      if (this.formFieldNameMatchesKeyword(field, keyword)) {
        results.push(field);
      }

      if (field.fields && field.fields.length > 0) {
        const subFields = this.applySearchOnNestedFieldsStructure(field.fields, keyword);
        const mappedResults = subFields.map((subField) => {
          if (this.formFieldNameMatchesKeyword(subField, keyword)) {
            return subField;
          }
        });
        results = results.concat(mappedResults);
      }
    }

    return results;
  }

  private formFieldNameMatchesKeyword(field: FormField, keyword: string): boolean {
    if (!field.name) {
      return false;
    }
    return field.name.toLowerCase().indexOf(keyword.toLowerCase().trim()) > -1;
  }

  // PDF methods
  onLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
    this.annotationRefs.forEach((ref) => {
      this.app.detachView(ref.hostView);
    });
    this.annotationRefs = [];
  }

  onPageRendered(page: any) {
    // Create an annotation container for the page
    const annotationsContainer = document.createElement('div');
    annotationsContainer.id = 'annotation-container-' + page.pageNumber;
    annotationsContainer.className = 'annotation-container';

    // Append the container to the page's annotation layer div
    page.source.div.appendChild(annotationsContainer);

    // Create an annotation container for the page
    const fieldsGrid = document.createElement('div');
    fieldsGrid.id = 'fields-grid-container-' + page.pageNumber;
    fieldsGrid.className = 'fields-grid-container';

    // Append the container to the page's annotation layer div
    page.source.div.appendChild(fieldsGrid);

    this.appendAnnotationToPage(page.pageNumber);
    this.appendFieldsGridToPage(page.pageNumber);
  }

  scrollToPage(page: number) {
    this.pdfViewer.pdfViewer.scrollPageIntoView({
      pageNumber: page
    });
  }

  appendAnnotationToPage(page: number) {
    // Resolve the FieldAnnotationComponent as a factory (so we can use it as an actual object)
    const factory = this.factoryResolver.resolveComponentFactory(FieldAnnotationComponent);

    // Create a native html annotation element that will contain our factory
    const annotationElement = document.createElement('div');
    annotationElement.className = 'field-annotation';
    document.getElementById('annotation-container-' + page).appendChild(annotationElement);

    // Inject our native element to the factory to receive a ComponentRef
    const ref = factory.create(this.injector, [], annotationElement);
    this.annotationRefs.push(ref);

    // Attach the element to the app's host view
    this.app.attachView(ref.hostView);
  }

  appendFieldsGridToPage(page: number) {
    if (this.currentForm$.value.formTaxID != 2) {
      this.newFieldsGrid(page);
    } else {
      this.oldFieldsGrid(page);
    }
  }

  private newFieldsGrid(page: number) {
    // Resolve the FieldAnnotationComponent as a factory (so we can use it as an actual object)
    const factory = this.factoryResolver.resolveComponentFactory(FieldsGridComponent);

    // Create a native html annotation element that will contain our factory
    const gridElement = document.createElement('div');
    gridElement.className = 'fields-grid';
    document.getElementById('fields-grid-container-' + page).appendChild(gridElement);

    // Inject our native element to the factory to receive a ComponentRef
    const ref = factory.create(this.injector, [], gridElement);
    this.fieldGridsRefs.push(ref);

    ref.instance.formPage = page;
    ref.instance.isLockedForEditing = this.editingPermissions$.value.isLockedForEditing;
    ref.instance.shouldLockFieldsForEditing = this.shouldLockFieldsForEditing;
    ref.instance.didUpdateFormFieldValue.subscribe((event) => {
      // this.didUpdateFormFieldValue(event);
    });
    ref.instance.onFieldClicked = (field) => {
      const fieldElement = document.getElementById(`form-field-${field.id}`);
      fieldElement.classList.add('scrolledIntoView');
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

      setTimeout(() => {
        fieldElement.classList.remove('scrolledIntoView');
      }, 2000);
    };

    // Attach the element to the app's host view
    this.app.attachView(ref.hostView);
  }

  private oldFieldsGrid(page: number) {
    // Resolve the FieldAnnotationComponent as a factory (so we can use it as an actual object)
    const factory = this.factoryResolver.resolveComponentFactory(FieldsGridComponentOld);

    // Create a native html annotation element that will contain our factory
    const gridElement = document.createElement('div');
    gridElement.className = 'fields-grid';
    document.getElementById('fields-grid-container-' + page).appendChild(gridElement);

    // Inject our native element to the factory to receive a ComponentRef
    const ref = factory.create(this.injector, [], gridElement);
    this.fieldGridsOldRefs.push(ref);

    ref.instance.formPage = page;
    ref.instance.onFieldClicked = (field) => {
      const fieldElement = document.getElementById(`form-field-${field.id}`);
      fieldElement.classList.add('scrolledIntoView');
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

      setTimeout(() => {
        fieldElement.classList.remove('scrolledIntoView');
      }, 2000);
    };

    // Attach the element to the app's host view
    this.app.attachView(ref.hostView);
  }

  // // Delegate methods
  // didUpdateFormFieldValue(field: FormField) {
  //   this.LeadFormService.insertFormFieldValue(field);
  // }

  mouseDidEnterFormField(field: FormField) {
    if (!this.isPdfFormAvailable) {
      return;
    }

    if (!field.positionX || !field.positionY) {
      return;
    }

    const pageElement = document.getElementById('annotation-container-' + field.formPage);
    const annotation = this.annotationRefs[field.formPage - 1];
    if (annotation) {
      annotation.location.nativeElement.style.width = field.width;
      annotation.location.nativeElement.style.height = field.height;
      annotation.instance.containerSize = {
        width: pageElement.clientWidth,
        height: pageElement.clientHeight
      };
      annotation.instance.dimensions = {
        position: {
          x: field.positionX,
          y: field.positionY
        },
        size: {
          width: field.width,
          height: field.height
        }
      };
      annotation.instance.show();
    }

    if (this.currentPage !== field.formPage) {
      this.scrollToPage(field.formPage);
    }
  }

  mouseDidLeaveFormField(field: FormField) {
    if (!this.isPdfFormAvailable) {
      return;
    }

    if (!field.formPage) {
      return;
    }

    const annotation = this.annotationRefs[field.formPage - 1];
    annotation.instance.hide();
  }

  createCustomField(dto: CreateCustomFormFieldDTO) {}

  deleteCustomFormField(field: FormField) {
    // this.LeadFormService.deleteCustomFormField(field);
    console.log({ field });
  }

  didUpdateFormFieldValue(field: FormField) {
    this.LeadFormService.selectField(field);
  }
}
