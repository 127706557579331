import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { BrowserModule } from '@angular/platform-browser';
import { EditFormComponent } from './pages/edit-form/edit-form.component';
import { RouterModule } from '@angular/router';
import { CreateNewFormComponent } from './pages/create-new-form/create-new-form.component';
import { DuplicateFormComponent } from './pages/duplicate-form/duplicate-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AnnotationComponent } from './components/annotation/annotation.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragScrollModule } from 'cdk-drag-scroll';
import { FormFieldsModalComponent } from './components/form-fields-modal/form-fields-modal.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewsModule as GlobalViewsModule } from '@libs/views/views.module';
import { SharedModule } from '@digital/app/shared/shared.module';

@NgModule({
  declarations: [
    HomeComponent, 
    EditFormComponent, 
    CreateNewFormComponent, 
    DuplicateFormComponent, 
    AnnotationComponent, 
    FormFieldsModalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    DragDropModule,
    DragScrollModule,
    NgbModule,
    GlobalViewsModule,
    SharedModule,


  ],
  providers: [
    NgbActiveModal,
  ]
})
export class FormModule { }
