import { Component, OnInit, ComponentFactoryResolver, Injector, ApplicationRef, ViewChild } from '@angular/core';
import { PdfViewerComponent, PDFDocumentProxy } from 'ng2-pdf-viewer';
import { FormFieldsFacade } from '../../form-fields.facade';
import { ActivatedRoute } from '@angular/router';
import { FormFields, OptionField, FieldEvent } from '../../models/form-fields.model';
import { AnnotationComponent } from '../../components/annotation/annotation.component';
import { Form, FormType } from '../../models/form.model';
import { FormsFacade } from '../../form.facade';
import { AdminPanelRoutes } from '@admin/app/shared/constants';

import { cloneDeep } from 'lodash';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldsModalComponent } from '../../components/form-fields-modal/form-fields-modal.component';


@Component({
  selector: 'app-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss']
})
export class EditFormComponent implements OnInit {
  @ViewChild(PdfViewerComponent) pdfViewer: PdfViewerComponent;
  formFields$ = this._formFieldsFacade.formFields$;
  form$ = this._formFacade.form$;

  formType = FormType;
  formID: number;
  fieldID: number;

  pdfSrc: any;
  currentPage = 1;
  totalPages: number;

  lastUsedFieldPosition: number;

  constructor(
    private _formFieldsFacade: FormFieldsFacade,
    private _formFacade: FormsFacade,
    private _app: ApplicationRef,
    private route: ActivatedRoute,
    private _factoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _activeModal: NgbActiveModal,
    private _modalService: NgbModal
  ) {
    this.route.params.subscribe((res) => (this.formID = res.id));
  }

  get homeRoute () {
    return '/' + AdminPanelRoutes.home; 
  }

  ngOnInit(): void {
    this._formFacade.getSingleForm(this.formID);
    this._formFieldsFacade.getFormFields(this.formID);
    this.form$.subscribe((form) => {
      this.pdfSrc = form.filePath;
    });
  }

  /* #region - PDFViewer delegates */

  onPageRendered(page: any) {
    // Create a signature container for the page
    const annotationContainer = document.createElement('div');
    annotationContainer.id = 'annotation-container-' + page.pageNumber;
    annotationContainer.className = 'annotation-container';

    // Append the container to the page's annotation layer div
    page.source.div.appendChild(annotationContainer);

    // Append existing annotation receieved from the server
    this.formFields$.subscribe((formFields) => {
      formFields.forEach((fields) => {
        if (fields.formPage === page.pageNumber) {
          this.addAnnotationFromServer(fields);
        }
      });
    });
  }

  scrollToPage(page: number) {
    this.pdfViewer.pdfViewer.scrollPageIntoView({
      pageNumber: page
    });
  }

  // Adds annotation received from the server
  addAnnotationFromServer(fields: FormFields) {
    this._appendField(fields, false);
  }

  closeModal() {
    this._activeModal.close();
  }

  onDeleteForm(){
    if (confirm('האם אתה בטוח שברצונך למחוק את הטופס עם כל השדות שלו? (יש לוודא שהקובץ לא בשימוש בשום מקום)')) {
      this._formFacade.deleteForm(this.formID);
      this.closeModal();
    }
  }

  // Adds a annotation received from the client/user
  addAnnotationFields() {
    const container = document.querySelector('.ng2-pdf-viewer-container') as HTMLElement;
    const currentPage = document.querySelector(`.page[data-page-number='${this.currentPage}']`) as HTMLElement;
    if (container === null || currentPage === null) {
      return;
    }

    const topBoundry = container.scrollTop - currentPage.offsetTop;

    const position = {
      x: 200,
      y: topBoundry + 200
    };

    // Create a annotation object
    let fields: FormFields = {
      positionX: position.x,
      positionY: position.y,
      version: this.form$.value.formYear,
      formPage: this.currentPage,
      width: 200,
      height: 50
    };

    this._formFieldsFacade.createFormFields(fields, this.formID).subscribe((field) => {
      fields = field;
      this._appendField(fields, true);
    });
  }

  private _appendField(fields: FormFields, isNew: boolean) {
    const factory = this._factoryResolver.resolveComponentFactory(AnnotationComponent);
    const annotationElement = document.createElement('div');
    annotationElement.className = 'annotation-' + fields.id;
    annotationElement.id = 'annotation-' + fields.id;
    if (document.getElementById('annotation-container-' + fields.formPage) !== null) {
      document.getElementById('annotation-container-' + fields.formPage).appendChild(annotationElement);
      // Inject our native element to the factory to receive a ComponentRef
      const ref = factory.create(this._injector, [], annotationElement);

      ref.instance.field = fields;

      ref.instance.position = { x: fields.positionX, y: fields.positionY };

      ref.instance.modalOpen.subscribe((event: FieldEvent) => this.onModalOpen(event));

      ref.instance.fieldsUpdated.subscribe((object: FormFields) => this.onFieldsUpdate(object, fields.id, 'field'));

      ref.instance.fieldDeleted.subscribe((event: FieldEvent) => this.onFieldDeleted(event));

      // Controls popover
      ref.instance.didClickDeleteButton.subscribe((event: FieldEvent) => this.onFieldDeleted(event));

      ref.instance.didClickDuplicateButton.subscribe((event: FieldEvent) => this.onFieldDuplicate(event));

      this._app.attachView(ref.hostView);
      if (fields.options) {
        this._appendOptionField(fields.options, fields.id);
      }
    }
  }

  onModalOpen({ component, field }: FieldEvent){
    const modalRef = this._modalService.open(FormFieldsModalComponent, { windowClass: 'form-fields-modal', centered: true, size: 'xl', scrollable: true });
      modalRef.componentInstance.fieldID = field.id;
      modalRef.componentInstance.fieldDeleted.subscribe(() => {
        this.onFieldDeleted({
          component: component,
          field: field
        });
      });
  }

  onFieldDuplicate({ component, field }: FieldEvent) {
    let newField = cloneDeep(field);
    newField = this.deleteEmptyKeysFromObject(newField);
    newField.positionY = field.positionY + field.height;
    delete newField.id;

    this._formFieldsFacade.createFormFields(newField, this.formID).subscribe((createdField) => {
      newField.id = createdField.id;

      newField.options.forEach((option) => {
        delete option.id;
        option.positionY = newField.positionY;
        option = this.deleteEmptyKeysFromObject(option);
        this._formFieldsFacade.createOptionFields(option, newField.id).subscribe((createdOption) => {
          option.id = createdOption.id;
        });
      });

      this._appendField(newField, true);
      this._appendOptionField(newField.options, newField.id);
    });
  }

  onFieldDeleted({ component, field }: FieldEvent) {
    this._formFieldsFacade.deleteFieldByID(field.id).subscribe(() => {
      // Find the signature index by id and delete the object
      // const foundIndex = this.signatures$.findIndex(object => object.id === signature.id);
      // this.signatures$.splice(foundIndex, 1);

      // Find the comopnent DOM element and remove it
      const factory = this._factoryResolver.resolveComponentFactory(AnnotationComponent);
      const ref = factory.create(this._injector, [], component.nativeElement);
      this._app.detachView(ref.hostView);
      component.nativeElement.parentNode.removeChild(component.nativeElement);
    });
  }

  deleteEmptyKeysFromObject(object) {
    Object.keys(object).forEach((key) => {
      if (object[key] === null || object[key] === undefined) {
        delete object[key];
      }
    });
    return object;
  }

  onLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
  }

  onFieldsUpdate(field: any, fieldID: number, type: string) {
    if (type === 'field') {
      console.log('Normal Field');
      this._formFieldsFacade.updateFormFields(field, fieldID).subscribe((e) => console.log(e));
    } else {
      console.log('Option Field');
      this._formFieldsFacade.updateOptionFields(field, fieldID).subscribe((e) => console.log(e));
    }
  }

  private _appendOptionField(options: OptionField[], parentID: number) {
    options.forEach((optionField) => {
      const factory = this._factoryResolver.resolveComponentFactory(AnnotationComponent);

      const annotationElement = document.createElement('div');
      annotationElement.className = 'option-annotation';
      annotationElement.id = `option-annotation-${optionField.id}`;

      document.getElementById('annotation-' + parentID).appendChild(annotationElement);

      const ref = factory.create(this._injector, [], annotationElement);
      ref.instance.field = optionField;
      //this act on the blue square sign on edit positions page
      //if the x >400 move it -3 
      //ref.instance.position = { x: optionField.positionX > 400 ? optionField.positionX - 3 : optionField.positionX , y: optionField.positionY };
      ref.instance.position = { x: optionField.positionX , y: optionField.positionY };
      ref.instance.optionFieldsUpdated.subscribe((object: OptionField) =>
        this.onFieldsUpdate(object, optionField.id, 'option')
      );

      this._app.attachView(ref.hostView);
    });
  }

  onStateButtonClicked(event: string) {
    const updatedForm = new Form();
    if (event === 'Draft') {
      updatedForm.state = 'Published';
      this._formFacade.updateForm(updatedForm, this.formID);
    } else {
      if (confirm('האם אתה בטוח בפעולה הזאת?')) {
        updatedForm.state = 'Draft';
        this._formFacade.updateForm(updatedForm, this.formID);
      }
    }
  }
}
