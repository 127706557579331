<div class="main-form-view-search">
  <div class="container-fluid">
    <div class="row no-gutters">
      <div class="col-12">
        <div class="input-container">
          <app-svg-icon name="search_special" class="search-icon"></app-svg-icon>
          <input type="text" placeholder="חיפוש" (keyup)="searchFieldsKeywordTyped($event.target.value)" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main-form-content">
  <ng-container *ngIf="isLoadingFormData$ | async">
    <div class="loading-form-content">
      <app-loading></app-loading>
    </div>
  </ng-container>
  <ng-container *ngIf="!(isLoadingFormData$ | async)">
    <div class="save-form-bar">
      <div class="title-container">
        <h4 class="title">
          {{ (currentForm$ | async).formTaxID }} -
          <span *ngIf="(currentForm$ | async).instance > 0">({{ (currentForm$ | async).instance }}) </span>
          {{ (currentForm$ | async).name }}
        </h4>
        <p class="subtitle" [innerHTML]="(currentForm$ | async).subtitle"></p>
      </div>
      <div class="button-container">
        <button
          *ngIf="!(editingPermissions$ | async).isLockedForEditing"
          class="save-form-button btn"
          [class.btn-success]="(currentForm$ | async).state === formState.Draft"
          [class.btn-outline-primary]="(currentForm$ | async).state === formState.Published"
          (click)="changeCurrentFormState()"
        >
          <app-svg-icon
            [name]="(currentForm$ | async).state === formState.Draft ? 'checkmark_special' : 'edit_special'"
          ></app-svg-icon>
          {{ (currentForm$ | async).state === formState.Draft ? 'סיימתי למלא את טופס זה' : 'פתיחת הטופס לעריכה מחדש' }}
        </button>
      </div>
    </div>
    <!--  -->
    <div class="form-content">
      <div class="row no-gutters">
        <div class="col" [hidden]="!showRightSide">
          <div class="container-right">
            <div class="input-group row">
              <div class="col">
                <!-- Start fields tree -->
                <lead-app-fields-tree
                  [fields]="filteredFields$ | async"
                  [shouldLockFieldsForEditing]="shouldLockFieldsForEditing"
                  [editingPermissions]="editingPermissions$ | async"
                  (didUpdateFormFieldValue)="didUpdateFormFieldValue($event)"
                  (mouseDidEnterFormField)="mouseDidEnterFormField($event)"
                  (mouseDidLeaveFormField)="mouseDidLeaveFormField($event)"
                  (createCustomField)="createCustomField($event)"
                  (deleteFieldButtonClicked)="deleteCustomFormField($event)"
                ></lead-app-fields-tree>
                <!-- End fields tree -->
              </div>
            </div>
          </div>
        </div>
        <button
          class="collapse-bar-button"
          (click)="collapseRightClicked()"
          ngbTooltip="{{ showRightSide ? 'הסתר צד ימין' : 'הצג צד ימין' }}"
        >
          <app-svg-icon
            name="{{ showRightSide ? 'angle-right_special' : 'angle-left_special' }}"
            class="arrow-circle-icon"
          ></app-svg-icon>
        </button>
        <div
          [class.col-12]="!showRightSide"
          [class.col-6]="showRightSide"
          [class.close-for-edit]="false"
          *ngIf="isPdfFormAvailable"
        >
          <div class="container-left">
            <div>
              <div class="pdf-container">
                <div class="page-tracker">עמוד {{ currentPage }} מתוך {{ totalPages }}</div>
                <pdf-viewer
                  [src]="currentFormPdfURL$ | async"
                  [(page)]="currentPage"
                  (page-rendered)="onPageRendered($event)"
                  (after-load-complete)="onLoadComplete($event)"
                  [original-size]="true"
                  [show-all]="true"
                  [render-text]="false"
                  [autoresize]="true"
                  [zoom-scale]="'page-width'"
                  [show-borders]="false"
                >
                </pdf-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
