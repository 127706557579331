<div class="post">
    <div class="title">
        <div class="row">
            <div class="col-12">
                <p class="date">{{ post.createdAt | parseDatePipe }}</p>
                <h4 class="subject">{{ post.title }}</h4>
            </div>
            <div class="col-12">
                <p><span class="author">{{ post.user.fullName.hebrew }}</span></p>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="row">
            <div class="col">
                <p>{{ post.body }}</p>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="row">
            <div class="col button-container">
                <a [routerLink]="[singlePostRoute, post.id]" class="btn btn-outline-primary">להמשך קריאה</a>
            </div>
        </div>
    </div>
</div>