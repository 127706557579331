export class PullDataParams {
  flowInfo: PullDataParam[];
  companyInfo: PullDataParam[];
  deloitteInfo: PullDataParam[];
}

export class PullDataParam {
  name: string;
  value: string;
}
