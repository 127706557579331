export enum FormFieldOptionType {
    Text = 'text',
    Number = 'number',
    Checkbox = 'checkbox',
    Radio = 'radio',
    Date = 'date'
}

export interface FormFieldOption {
    id: number;
    name: string;
    type: FormFieldOptionType; // Not sent from the server
    position: number;
    positionX: number;
    positionY: number;
    width: number;
    value: any; // Not sent from the server
}
