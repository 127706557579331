<h2 *ngIf="notes.length === 0" class="no-notes">לא קיימות הערות עבור מסמך זה</h2>
<div *ngIf="notes.length > 0">
  <div *ngFor="let note of notes">
    <button class="accordion" [id]="'note-button-' + note.id" (click)="toggleAccordion(note.id)">{{ 'מספר הערה: ' + note.id }}</button>
    <div class="panel" [ngClass]="{ 'active': selectedNoteId === note.id }">
      <select [(ngModel)]="note.status" (change)="onStatusChange(note.id, $event.target.value)" *ngIf="selectedNoteId === note.id">
        <option value="opened" [selected]="note.status === 'opened'">נפתח</option>
        <option value="active" [selected]="note.status === 'active'">בתהליך</option>
        <option value="closed" [selected]="note.status === 'closed'">נסגר</option>
        <option *ngIf="isNotExternalUserOrCreator(note.id)" value="delete" [selected]="note.status === 'delete'">למחוק</option>
      </select> 
      <div *ngIf="selectedNoteId === note.id">
        <ul>
          <li *ngFor="let c of note.comments">
            <div class="bold-text">{{ getUserNameById(c.createdBy) }}, {{ localDateTime(c.createdAt) }}</div>
            <div class="content" [innerHTML]="formatText(c.content)"></div>
          </li>
        </ul>
        <button type="button" (click)="onAddNote(note.id)" *ngIf="!showAddNoteComponent && selectedNoteId === note.id" class="add-note">הוסף תגובה</button>
      </div>
      <app-add-note
        *ngIf="showAddNoteComponent && selectedNoteId === note.id"
        [tempNote]="note"
        [mentionUsers]="mentionUsers"
        (noteAdded)="showAddNoteComponent = false;"
      ></app-add-note>
    </div>
  </div>
</div>
