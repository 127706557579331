<div class="content-container">
  <h1 class="title">401</h1>
  <h2 class="subtitle">גישה לעמוד זה אינה מאושרת</h2>
  <div class="container text-center action">
    <h4 class="subtitle-body">יש לפעול ע״פ השלבים הבאים:</h4>
    <p>1. יש לוודא חיבור לVPN</p>
    <p>2. לוודא כי הנכם מתחברים מדפדפן CHROME / EDGE</p>
    <p>3. לוודא כי הוזן שם משתמש וסיסמה עדכניים</p>
    <p>4. בכתובת האתר יש למחוק את המילה "unauthorized" ולנסות שנית</p>
    <p class="longrow">
      5. מחיקת CACHE: לחיצה על ctrl+shift+J -> לחיצה על כפתור זה << בחלק העליון של החלון שנפתח -> Application -> בצד
      שמאל ללחוץ על Storage -> לגלול קצת למטה וללחוץ על clear site data
    </p>
  </div>
  <p class="action-body">אם זו טעות, אנא פתח/י קריאה.</p>
  <p class="action">
    <button class="btn btn-primary" (click)="openTicketServiceNow()">פתיחת קריאה</button>
    <button class="btn btn-secondary" (click)="navigateToLoginPage()">נסה/י שוב</button>
  </p>
</div>
