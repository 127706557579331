import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BnNgIdleService } from 'bn-ng-idle';

// Services
import { AuthenticationService } from './authentication.service';

// Interceptors
import { AuthenticationHeaderInterceptor } from './interceptors/header.interceptor';
import { AuthenticationErrorInterceptor } from './interceptors/error.interceptor';

// Comopnents
import { SessionExpiredModalComponent } from './components/session-expired-modal/session-expired-modal.component';


@NgModule({
  declarations: [
    // Comopnents
    SessionExpiredModalComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    // Comopnents
    SessionExpiredModalComponent
  ],
  providers: [
    // Services
    AuthenticationService,
    BnNgIdleService,

    // Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationErrorInterceptor, multi: true }
  ]
})
export class AuthenticationModule { }
