import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@digital/app/_constants';
import { environment } from '@digital/environments/environment';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { GlobalRoutes } from '@libs/constants';
import { IconsService } from '@libs/core/services/icons.service';
import { FlowAPI } from './_apis/flow.api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // Getters
  get languageDirection() {
    return Constants.languageDirection;
  }

  // Lifecycle
  //! dont delete iconService error will show
  constructor(private authService: AuthenticationService, private iconsService: IconsService, private router: Router) {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate([GlobalRoutes.login]);
    }
    // this.authService.initWithEnvironment(environment).authenticate(() => {
    //   // When session has expired, let's remove the user as editor from all open flows
    //   this.flowAPI.removeCurrentEditorFromAll();
    // });
    //this._router.navigate([GlobalRoutes.home]); //for entering url with values, need to check when login to sso.
  }
}
