import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-flow-tab',
  templateUrl: './flow-tab.component.html',
  styleUrls: ['./flow-tab.component.scss']
})
export class FlowTabComponent implements OnInit {
  @Input() pageTitle: string;
  @Input() iconName: string;
  @Input() navigateToRouterLink?: [];
  @Input() isActive = false;
  @Input() isDisabled = false;
  @Input() isInvalidInside = false;

  constructor() {}

  ngOnInit() {}
}
