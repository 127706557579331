import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-end-flow-stage',
  templateUrl: './end-flow-stage.component.html',
  styleUrls: ['./end-flow-stage.component.scss']
})
export class EndFlowStageComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
