<section class="flow-section flow-permissions" name="deloitte-team">
  <div class="title-container">
    <div class="row">
      <div class="col-6 text-right">
        <h4 class="title">
          {{ title1 }}
        </h4>
      </div>
      <div class="col-6 text-left">
        <span class="tooltip-wrapper" placement="top" *ngIf="!isCurrentUserExternal()">
          <button (click)="addNewEditor()" class="btn btn-borderless">
            <app-svg-icon name="plus_special"></app-svg-icon>
            הוספת עורך
          </button>
        </span>
      </div>
    </div>
  </div>
  <div class="content-container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">שם מלא</th>
          <th scope="col">כתובת מייל</th>
          <th scope="col">סוג הרשאה</th>
          <th scope="col">סטאטוס</th>
          <th scope="col">פעולות</th>
        </tr>
      </thead>
      <tbody *ngFor="let user of deloitteTeam; index as i">
        <td class="fullname">
          <div *ngIf="isManagerPartner(user)" class="fullname">
            <div>
              <ng-template #customTemplate let-r="result" class="check-test">
                <img [src]="'assets/images/user.png'" class="img-template" />
                <ngb-highlight [result]="r.name" class="highlight-title"></ngb-highlight>
              </ng-template>
              <input
                type="text"
                [ngClass]="{ 'user-auto-complete': isPartner(user) ? !isEditPartnerPushed : !isEditManagerPushed }"
                [ngbTypeahead]="isPartner(user) ? deloittePartnersAutoComplete : deloitteManagersAutoComplete"
                #instance="ngbTypeahead"
                [inputFormatter]="formatter"
                [resultTemplate]="customTemplate"
                placement="bottom-right"
                [disabled]="isPartner(user) ? !isEditPartnerPushed : !isEditManagerPushed"
                [(ngModel)]="isPartner(user) ? selectedPartner : selectedManager"
                (selectItem)="onSelectPartnerOrManager($event.item, isPartner(user), i)"
              />
            </div>
            <div>
              <button
                class="edit-user-button"
                *ngIf="!isEditable(user) && !isCurrentUserExternal()"
                [ngbTooltip]="'אשר'"
                (click)="confirmChange(user)"
              >
                <app-svg-icon name="checkmark-green_special"></app-svg-icon>
              </button>
              <button
                class="close-edit-button"
                *ngIf="!isEditable(user) && !isCurrentUserExternal() && user.permissionType === PermissionType.Partner && user.permissionType === PermissionType.Manager"
                [ngbTooltip]="'בטל'"
                (click)="abortEdit(user, i)"
              >
                <app-svg-icon name="close-red_special"></app-svg-icon>
              </button>
              <button
                class="edit-user-button"
                *ngIf="isEditable(user) && !isCurrentUserExternal()"
                [ngbTooltip]="'עריכה'"
                (click)="editUserClicked(user)"
              >
                <app-svg-icon name="edit-profile-blue_special"></app-svg-icon>
              </button>
            </div>
          </div>
          <ng-container *ngIf="!isManagerPartner(user)">
            {{ user.data.fullName.hebrew }}
          </ng-container>
        </td>
        <td>{{ user.data.email }}</td>
        <td>{{ user.permissionType }}</td>
        <td class="status-icon">
          <app-svg-icon
            name="{{ isUserActive(user.data) ? 'user-active_special' : 'user-not-active_special' }}"
            [ngbTooltip]="isUserActive(user.data) ? 'פעיל' : 'לא פעיל'"
          ></app-svg-icon>
        </td>
        <td class="delete-user">
          <button
            class="delete-user-button"
            *ngIf="isUserEditor(user) && !isCurrentUserExternal()"
            [ngbTooltip]="'מחיקה'"
            (click)="deleteInternalUserClicked(user.data, i)"
          >
            <app-svg-icon name="bin-red_special"></app-svg-icon>
          </button>
        </td>
      </tbody>
      <!-- New users -->
      <tbody *ngIf="addNewInternalUser">
        <td class="fullname">
          <div>
            <ng-template #customTemplate let-r="result" class="check-test">
              <img [src]="'assets/images/user.png'" class="img-template" />
              <ngb-highlight [result]="r.name" class="highlight-title"></ngb-highlight>
            </ng-template>
            <input
              id="newInternalUserInput"
              type="text"
              [ngClass]="{ 'user-auto-complete': isInternalUserSelected }"
              [ngbTypeahead]="deloitteUsersAutoComplete"
              #instance="ngbTypeahead"
              [inputFormatter]="formatter"
              [resultTemplate]="customTemplate"
              placement="bottom-right"
              [disabled]="isInternalUserSelected"
              (selectItem)="onSelectNewInternalUser($event.item)"
              placeholder="בחר משתמש"
            />
          </div>
        </td>
        <td>{{ selectedNewInternalUser?.email }}</td>
        <td>עורך</td>
        <td class="status-icon">
          <app-svg-icon *ngIf="isInternalUserSelected" name="user-active_special" [ngbTooltip]="'פעיל'"></app-svg-icon>
        </td>
        <td class="delete-user">
          <button class="delete-user-button" [ngbTooltip]="'מחיקה'" (click)="resetNewInternalUser()">
            <app-svg-icon name="bin-red_special"></app-svg-icon>
          </button>
        </td>
      </tbody>
      <!-- End of new users -->
    </table>
  </div>
</section>
<section class="flow-section flow-permissions" name="client-team">
  <div class="title-container">
    <div class="row">
      <div class="col-6 text-right">
        <h4 class="title">
          {{ title2 }}
        </h4>
      </div>
      <div class="col-6 text-left">
        <span class="tooltip-wrapper" placement="top" *ngIf="!isCurrentUserExternal()">
          <button (click)="addNewExternalUserToTable()" class="btn btn-borderless">
            <app-svg-icon name="plus_special"></app-svg-icon>
            הוספת משתמש חיצוני
          </button>
        </span>
      </div>
      <div class="col text-left"></div>
      <div *ngIf="isLoading" class="col-12">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="content-container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">שם מלא</th>
          <th scope="col">כתובת מייל</th>
          <th scope="col">טלפון</th>
          <th scope="col">סוג הרשאה</th>
          <th scope="col">סטאטוס</th>
          <th scope="col">פעולות</th>
        </tr>
      </thead>
      <tbody *ngFor="let user of externalTeam; index as i">
        <td>{{ user.firstName + ' ' + user.lastName }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.mobilePhone ? user.mobilePhone : 'לא קיים מספר טלפון' }}</td>
        <td>{{ getExternalPermissionTypeText(user.role) }}</td>
        <td class="status-icon">
          <app-svg-icon
            name="{{ isExternalUserActive(user) ? 'user-active_special' : 'user-pending_special' }}"
            [ngbTooltip]="isExternalUserActive(user) ? 'רשום' : 'ממתין לרישום'"
          ></app-svg-icon>
        </td>
        <td class="delete-user">
          <div class="delete-user-button fake-div mr-3" *ngIf="addNewExternalUser"></div>
          <button
            class="delete-user-button"
            *ngIf="!isCurrentUserExternal()"
            [ngbTooltip]="'מחיקת הרשאות למשתמש'"
            (click)="deleteExternalUserClicked(user, i)"
          >
            <app-svg-icon name="bin-red_special"></app-svg-icon>
          </button>
          <button
            class="delete-user-button"
            *ngIf="isExternalUserPending(user.status) && !isCurrentUserExternal()"
            [ngbTooltip]="'שלח מייל התחברות'"
            (click)="externalUserPendingClick(user)"
          >
            <app-svg-icon name="mail_special"></app-svg-icon>
          </button>
        </td>
      </tbody>
      <!-- New external users -->
      <tbody *ngIf="addNewExternalUser">
        <td class="fullname">
          <input
            type="input"
            class="form-control not-full-col ml-3"
            *ngIf="newExternalUserEmail !== ''"
            [(ngModel)]="newExternalUserFirstName"
            name="externalUserFirstName"
            [placeholder]="'הכנס שם פרטי'"
            [disabled]="disableExternalUserName"
          />
          <input
            type="input"
            class="form-control not-full-col"
            *ngIf="newExternalUserEmail !== ''"
            [(ngModel)]="newExternalUserLastName"
            name="externalUserLastName"
            [placeholder]="'הכנס שם משפחה'"
            [disabled]="disableExternalUserName"
          />
        </td>
        <td>
          <input
            id="newExternalUserInput"
            type="email"
            class="form-control not-full-col"
            name="externalUserEmail"
            (blur)="onExternalUserEmailBlur()"
            [placeholder]="'הכנס כתובת מייל'"
            [(ngModel)]="newExternalUserEmail"
          />
        </td>
        <td>יעודכן לאחר רישום</td>
        <td>
          <select
            class="custom-select not-full-col"
            name="externalUserType"
            *ngIf="newExternalUserEmail !== ''"
            [(ngModel)]="newExternalUserType"
          >
            <option selected value="" disabled>בחירת הרשאה</option>
            <option *ngFor="let option of externalUserOptions" [value]="ExternalUserType[option]">
              {{ getHebrewUserType(option) }}
            </option>
          </select>
        </td>
        <td class="status-icon">
          <app-svg-icon
            name="user-pending_special"
            [ngbTooltip]="'ממתין לרישום'"
            *ngIf="newExternalUserEmail !== ''"
          ></app-svg-icon>
        </td>
        <td class="delete-user">
          <button class="delete-user-button mr-3" [ngbTooltip]="'הוסף'" (click)="submitNewExternalUser()">
            <app-svg-icon name="checkmark-green_special"></app-svg-icon>
          </button>
          <button class="delete-user-button" [ngbTooltip]="'מחיקה'" (click)="resetNewExternalUser()">
            <app-svg-icon name="bin-red_special"></app-svg-icon>
          </button>
        </td>
      </tbody>
      <!-- End of new external users -->
    </table>
    <ng-content select="[content]"></ng-content>
  </div>
</section>
