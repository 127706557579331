import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Company } from '@digital/app/_models/company.model';
import { FlowService } from '@digital/app/core/services/flow.service';
import { ManagersPartners } from '@digital/app/_models/managers-partners.model';
import { User } from '@digital/app/_models/managers-partners.model';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, FormControl, ControlContainer, FormArray } from '@angular/forms';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { InternalUsers, InternalUser } from '@digital/app/_models/internal-users.model';
import { CreateFlow } from '../../models/create-flow.model';
import { ExternalUser, ExternalUserType } from '@digital/app/_models/client-users';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-process-permissions-tab',
  templateUrl: './process-permissions-tab.component.html',
  styleUrls: ['./process-permissions-tab.component.scss']
})
export class ProcessPermissionsTabComponent implements OnInit {
  @Input() subjectPermissionDisable: Subject<boolean>;
  @Input() newFlow: CreateFlow;
  @Input() isSubmitClick: boolean;

  // Viewchilds
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  private _currentUser = this._authenticationService.getCurrentUser();

  // Form group
  createFlowForm: FormGroup;

  // Class members
  flowManagers: User[];
  flowPartners: User[];
  flowDeloitteUsers: InternalUser[];

  externalUserSizeArray: Array<number>;
  externalUserCheckBox: boolean = false;
  isEnteredClientEmail: boolean[] = new Array<boolean>();

  //client type options
  externalUserOptions = Object.keys(ExternalUserType);
  ExternalUserType = ExternalUserType;

  // Lifecycle
  constructor(
    private _authenticationService: AuthenticationService,
    private _flowService: FlowService,
    private _controlContainer: ControlContainer,
    private toastr: ToastrService
  ) {
    this.toastr.toastrConfig.preventDuplicates = true;
  }

  ngOnInit() {
    this._setupFormBuilder();
    this._subscribeToFormChanges();
    this.isEnteredClientEmail[0] = false;

    this.subjectPermissionDisable.subscribe((res) => {
      this.setupInitialData();
    });
  }

  // Setup
  private _setupFormBuilder() {
    this.createFlowForm = <FormGroup>this._controlContainer.control;
  }

  public setupInitialData() {
    this._getManagersAndPartners();
    this._getAllDeloitteUsers();
  }

  private _subscribeToFormChanges() {
    this.externalUsers.valueChanges.subscribe((externalUsers) => {
      for (let i = 0; i < externalUsers.length; i++) {
        const externalUserEmailControl = this.externalUsers.at(i).get('externalUserEmail');
        if (externalUserEmailControl.valid && externalUserEmailControl.value !== '') {
          this.isEnteredClientEmail[i] = true;
        } else {
          this.isEnteredClientEmail[i] = false;
        }
      }
    });
  }
  // Networking
  private _getManagersAndPartners() {
    this._flowService.getManagersAndPartners(this.newFlow.companyID, this.newFlow.year, (managersAndPartners) => {
      this._onGetManagersAndPartners(managersAndPartners);
    });
  }

  private _getIfExternalUserExistsByEmail(email: string, index: number) {
    this._flowService.getExternalUserByEmail(email, (user) => {
      if (user) {
        this._onGetExternalUserExists(user, index);
      } else {
        this.externalUserNotExists(email, index);
      }
    });
  }

  private _getAllDeloitteUsers() {
    this._flowService.getAllUsersForBanch(this.newFlow.companyID, this.newFlow.year, (allUsers) => {
      this._onGetAllDeloitteUsers(allUsers);
    });
  }

  private _onGetAllDeloitteUsers(users: InternalUsers) {
    this.flowDeloitteUsers = users.internalUsers.map((users) => users).sort((a, b) => a.name.localeCompare(b.name));
  }

  private _onGetManagersAndPartners(users: ManagersPartners) {
    this.flowManagers = users.managers.map((managers) => managers).sort((a, b) => a.name.localeCompare(b.name));
    this.flowPartners = users.partners.map((partners) => partners).sort((a, b) => a.name.localeCompare(b.name));
  }

  private _onGetExternalUserExists(user: ExternalUser, index: number) {
    this.externalUsers.at(index).get('externalUserFirstName').setValue(user.firstName);
    this.externalUsers.at(index).get('externalUserFirstName').disable();
    this.externalUsers.at(index).get('externalUserLastName').setValue(user.lastName);
    this.externalUsers.at(index).get('externalUserLastName').disable();
    this.externalUsers.at(index).get('externalUserType').setValidators([Validators.required]);
    this.externalUsers.at(index).get('externalUserType').updateValueAndValidity();
  }

  // Actions

  externalUserNotExists(email: string, index: number) {
    this.externalUsers.at(index).get('externalUserFirstName').enable();
    this.externalUsers.at(index).get('externalUserFirstName').setValue('');
    this.externalUsers.at(index).get('externalUserFirstName').setValidators([Validators.required]);
    this.externalUsers.at(index).get('externalUserFirstName').updateValueAndValidity();
    this.externalUsers.at(index).get('externalUserLastName').enable();
    this.externalUsers.at(index).get('externalUserLastName').setValue('');
    this.externalUsers.at(index).get('externalUserLastName').setValidators([Validators.required]);
    this.externalUsers.at(index).get('externalUserLastName').updateValueAndValidity();
    this.externalUsers.at(index).get('externalUserType').setValidators([Validators.required]);
    this.externalUsers.at(index).get('externalUserType').updateValueAndValidity();
  }

  onExternalUserEmailBlur(index: number) {
    if (
      this.externalUsers.at(index).get('externalUserEmail').value !== '' &&
      this.externalUsers.at(index).get('externalUserEmail').valid
    ) {
      this._getIfExternalUserExistsByEmail(this.externalUsers.at(index).get('externalUserEmail').value, index);
    } else {
      this.externalUsers.at(index).get('externalUserFirstName').clearValidators();
      this.externalUsers.at(index).get('externalUserLastName').clearValidators();
      this.externalUsers.at(index).get('externalUserType').clearValidators();
    }
  }

  onInternalUserEmailBlur(index: number) {
    if (this.internalUsers.at(index).value.length > 0) {
      this.internalUsers.at(index).setValue('');
      this.toastr.error('המשתמש לא קיים, יש לבחור משתמש מהרשימה');
    }
  }

  selectedValuePartner(selectedValue: User) {
    this.newFlow.partnerID = selectedValue.id;
  }

  selectedValueManager(selectedValue: User) {
    this.newFlow.managerID = selectedValue.id;
  }

  selectedValueDeloitteUser(selectedValue: InternalUser, index: number) {
    if (!this.newFlow.internalUsers) {
      this.newFlow.internalUsers = [];
    }

    this.newFlow.internalUsers[index] = selectedValue;
  }

  deloitteUsersAutoComplete = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$).pipe(
      map((term) =>
        (term === ''
          ? this.flowDeloitteUsers
              .filter((user: InternalUser) => !this.newFlow.internalUsers?.find((du) => du.id === user.id))
              .map((user: any) => user)
          : this.flowDeloitteUsers
              .filter(
                (user: InternalUser) =>
                  (user.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                    user.email.toLowerCase().indexOf(term.toLowerCase()) > -1) &&
                  !this.newFlow.internalUsers?.find((du) => du.id === user.id)
              )
              .map((user: any) => user)
        ).slice(0, 4)
      )
    );
  };

  deloittePartnersAutoComplete = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$).pipe(
      map((term) =>
        (term === ''
          ? this.flowPartners.map((user: any) => user)
          : this.flowPartners
              .filter(
                (user: User) =>
                  user.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                  user.email.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .map((user: any) => user)
        ).slice(0, 7)
      )
    );
  };

  deloitteManagersAutoComplete = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$).pipe(
      map((term) =>
        (term === ''
          ? this.flowManagers.map((user: any) => user)
          : this.flowManagers
              .filter(
                (user: User) =>
                  user.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                  user.email.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .map((user: any) => user)
        ).slice(0, 7)
      )
    );
  };

  get permissions() {
    return this.createFlowForm.get('permissions') as FormGroup;
  }

  getValidationAfterSubmit(groupName, controlName) {
    let invalid = false;
    if (this.isSubmitClick) {
      if (groupName !== '') {
        invalid = this.permissions.get(groupName).get(controlName).invalid;
      } else {
        invalid = this.permissions.get(controlName).invalid;
      }
    }

    return invalid;
  }

  getValidationAfterSubmitForArray(controlName, index) {
    let invalid = false;

    if (this.isSubmitClick) {
      invalid = this.externalUsers.at(index).get(controlName).invalid;
    }

    return invalid;
  }

  get internalUsers() {
    return this.permissions.get('internalUsers') as FormArray;
  }

  get externalUsers() {
    return this.permissions.get('externalUsers') as FormArray;
  }

  getExternalUsersByIndex(index: number, controlName: string) {
    return this.externalUsers.at(index).get(controlName);
  }

  deleteDeloitteUser(index: number) {
    this.internalUsers.removeAt(index);
    this.newFlow.internalUsers.splice(index, 1);
  }

  addDeloitteUser() {
    this.internalUsers.push(new FormControl(''));
  }

  deleteExternalUser(index: number) {
    this.externalUsers.removeAt(index);
  }

  addExternalUser() {
    this.externalUsers.push(
      new FormGroup({
        externalUserType: new FormControl(''),
        externalUserEmail: new FormControl(
          '',
          Validators.compose([
            Validators.email,
            Validators.pattern(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ])
        ),
        externalUserFirstName: new FormControl(''),
        externalUserLastName: new FormControl('')
      })
    );
  }

  didChangeExternalUserCheckBox() {
    this.externalUserCheckBox = !this.externalUserCheckBox;

    if (this.externalUserCheckBox) {
      this.externalUserSizeArray = [1];
    } else {
      this.externalUserSizeArray = undefined;
    }
  }

  formatter = (user: { name: string }) => user.name;

  deleteExternalUserItemClicked(item: number) {
    this.externalUserSizeArray.splice(this.externalUserSizeArray.indexOf(item), 1);
  }

  getHebrewUserType(externalUserOption) {
    switch (externalUserOption) {
      case 'Editor':
        return 'עורך';
      case 'Signer':
        return 'חותם';
      case 'All':
        return 'עורך וחותם';
      default:
        return '';
    }
  }
}
