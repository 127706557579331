<div class="header-container modal-header">
  <h4>שכפול שאילתה</h4>
  <button class="modal-close-button" (click)="closeModal(true)">x</button>
</div>

<div class="body-container modal-body">
  <p>הכנסת שם השאילתה החדשה</p>
  <form [formGroup]="copyFlowForm" (submit)="submitOnCopy()">
    <input
      #queryNameField
      type="text"
      class="form-control"
      name="queryNameField"
      formControlName="queryNameField"
      value="{{ queryNameValue }}"
    />
    <div class="footer-container">
      <button class="btn btn-danger">שכפול</button>
    </div>
    <ng-container *ngIf="isLoading">
      <app-loading></app-loading>
    </ng-container>
  </form>
</div>
