export enum IFTTTActionButtonType {
    ADD_FORM = 'addForm',
    CANCEL = 'cancel'
}
export interface IFTTTActionButton {
    title: string;
    type: IFTTTActionButtonType;
    formID?: number;
}

export enum IFTTTActionType {
    POPUP = 'popup',
    SHOWFIELDS = 'showFields',
    HIDEFIELDS = 'hideFields'
}

export interface IFTTTAction {
    type: IFTTTActionType;
    message: string;
    buttons: IFTTTActionButton[];
    fields?: string;
}

export interface IFTTTElse {
    message?:string;
    actionDenied?:boolean;
    code?: number;
    actions: IFTTTAction;
}

export interface IFTTTThenThat {
    code:number;
    actions: IFTTTAction;
}
