import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Network
import { HttpParams } from '@angular/common/http';

// Models
import { Flows } from '@digital/app/_models/flows.model';
import { Flow } from '@digital/app/_models/flow.model';
import { FlowType } from '@digital/app/_enums/flow-type.enum';

// Constants
import { GlobalAPI } from '@digital/app/_constants';
import { HomePageConstants } from '@digital/app/home/constants';

// Directives
import { SortEvent } from '@libs/directives/sortable-header.directive';
import { API } from './api';

@Injectable({ providedIn: 'root' })
export class FlowsAPI extends API {
  getAllFlows(
    page: number = 1,
    limit: number = HomePageConstants.flowsPerPage,
    type?: FlowType,
    sort?: SortEvent
  ): Observable<Flows> {
    let params = new HttpParams().set('page', `${page}`).set('limit', `${limit}`);

    if (type) {
      params = params.append('type', `${type}`);
    }

    if (sort) {
      params = params.append('orderBy', sort.column);
      params = params.append('orderDirection', sort.direction);
    }

    return this._network
      .get(GlobalAPI.baseURL, GlobalAPI.endpoints.flows.base, params)
      .pipe(map((response) => new Flows().deserialize(response.data)));
  }

  searchFlows(keyword: string, page: number = 1, limit: number = HomePageConstants.flowsPerPage): Observable<Flows> {
    const params = new HttpParams().set('keyword', `${keyword}`).set('page', `${page}`).set('limit', `${limit}`);

    return this._network
      .get(GlobalAPI.baseURL, GlobalAPI.endpoints.flows.search, params)
      .pipe(map((response) => new Flows().deserialize(response.data)));
  }

  getFlowByID(flowID: number): Observable<Flow> {
    return this._network
      .get(GlobalAPI.baseURL, `${GlobalAPI.endpoints.flows.base}/${flowID}`)
      .pipe(map((response) => new Flow().deserialize(response.data.flow)));
  }
}
