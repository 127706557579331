import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewsModule as GlobalViewsModule } from '@libs/views/views.module';
import { SharedModule } from '@digital/app/shared/shared.module';

// Dependencies
import { PdfViewerModule } from 'ng2-pdf-viewer';

// Modules
import { DigitalFormsRoutingModule } from './forms-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlowModule } from '@digital/app/flow/flow.module';

// Pages
import { EditFormsPageComponent } from './pages/edit-forms/edit-forms.component';

// Components
import { MainFormViewComponent } from './components/main-form-view/main-form-view.component';
import { MainFormContentComponent } from './components/main-form-content/main-form-content.component';

// Fields Components
import { FieldBaseComponent } from './components/fields/field-base/field-base.component';
import { FieldTextComponent } from './components/fields/field-text/field-text.component';
import { FieldNumberComponent } from './components/fields/field-number/field-number.component';
import { FieldSingleSelectionComponent } from './components/fields/field-single-selection/field-single-selection.component';
import { FieldMultipleSelectionComponent } from './components/fields/field-multiple-selection/field-multiple-selection.component';
import { FieldFreeOptionsComponent } from './components/fields/field-free-options/field-free-options.component';
import { FieldTitleComponent } from './components/fields/field-title/field-title.component';
import { FieldSubtitleComponent } from './components/fields/field-subtitle/field-subtitle.component';
import { FieldAnnotationComponent } from './components/field-annotation/field-annotation.component';
import { FieldDateComponent } from './components/fields/field-date/field-date.component';
import { FieldBooleanComponent } from './components/fields/field-boolean/field-boolean.component';
import { FieldsGridComponent } from './components/fields-grid/fields-grid.component';
import { FieldsGridComponentOld } from './components/fields-grid-old/fields-grid-old.component';
import { FieldsTreeComponent } from './components/fields-tree/fields-tree.component';
import { FieldCustomPlaceholderComponent } from './components/fields/field-custom-placeholder/field-custom-placeholder.component';
import { FieldInputTagComponent } from './components/fields/field-input-tag/field-input-tag.component';
import { FieldCustomControlsComponent } from './components/fields/field-custom-controls/field-custom-controls.component';
import { FormsService } from './forms.service';
import { FieldCustomOptionsComponent } from './components/fields/field-custom-options/field-custom-options.component';
import { NotesListComponent } from './components/notes-list/notes-list.component';
import { AddNoteComponent } from './components/add-note/add-note.component';
import { RightClickMenuComponent } from './components/right-click-menu/right-click-menu.component';
import { NgxMentionsModule } from 'ngx-mentions';

@NgModule({
  declarations: [
    // Form Components
    EditFormsPageComponent,
    MainFormViewComponent,
    MainFormContentComponent,

    // Fields Components
    FieldBaseComponent,
    FieldTextComponent,
    FieldNumberComponent,
    FieldSingleSelectionComponent,
    FieldMultipleSelectionComponent,
    FieldFreeOptionsComponent,
    FieldTitleComponent,
    FieldSubtitleComponent,
    FieldAnnotationComponent,
    FieldDateComponent,
    FieldBooleanComponent,
    FieldsGridComponent,
    FieldsGridComponentOld,
    FieldsTreeComponent,
    FieldCustomPlaceholderComponent,
    FieldInputTagComponent,
    FieldCustomControlsComponent,
    FieldCustomOptionsComponent,
    NotesListComponent,
    AddNoteComponent,
    RightClickMenuComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    DigitalFormsRoutingModule,
    GlobalViewsModule,
    SharedModule,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    FlowModule,
    NgxMentionsModule
  ],
  exports: [
    // Form Components
    EditFormsPageComponent,
    MainFormViewComponent,
    MainFormContentComponent,

    // Fields Components
    FieldBaseComponent,
    FieldTextComponent,
    FieldNumberComponent,
    FieldSingleSelectionComponent,
    FieldMultipleSelectionComponent,
    FieldFreeOptionsComponent,
    FieldTitleComponent,
    FieldSubtitleComponent,
    FieldAnnotationComponent,
    FieldDateComponent,
    FieldBooleanComponent,
    FieldsGridComponent,
    FieldsGridComponentOld,
    FieldsTreeComponent,
    FieldCustomPlaceholderComponent,
    FieldInputTagComponent,
    FieldCustomControlsComponent,
    FieldCustomOptionsComponent
  ]
})
export class DigitalFormsModule {}
