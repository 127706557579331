import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip-indicator',
  templateUrl: './tooltip-indicator.component.html',
  styleUrls: ['./tooltip-indicator.component.scss']
})
export class TooltipIndicatorComponent implements OnInit {
  @Input() tooltipTitle: string;

  constructor() { }

  ngOnInit(): void {
  }

}
