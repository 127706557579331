import { Component, OnInit } from '@angular/core';
import { ErrorPageBaseComponent } from '../error-page-base/error-page-base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-page-not-found',
  templateUrl: './error-page-not-found.component.html',
  styleUrls: ['../error-page-base/error-page-base.component.scss', './error-page-not-found.component.scss']
})
export class ErrorPageNotFoundComponent extends ErrorPageBaseComponent implements OnInit {
  constructor(protected router: Router) {
    super(router);
  }
  ngOnInit(): void { }
}
