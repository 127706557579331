<div class="container-fluid">
  <div class="row mt-2">
    <div class="col-12">
      <p id="title">{{ title }}</p>
    </div>
  </div>

  <div *ngIf="isMessage()" class="row">
    <div class="col-12">
      <p id="message" [innerHTML]="message"></p>
    </div>
  </div>
  <div *ngIf="isContainsRiskLevel()" class="row d-flex justify-content-center align-items-center mb-4">
    <label style="padding-left: 1rem;"><b>בחר רמת סיכון:</b></label>
    <select class="custom-select rounded-0 col-4" (change)="selectedValueRiskLevel($event.target.value)"
      [ngModel]="riskLevel"
    >
      <option *ngFor="let name of flowRiskLevel" [value]="RiskLevelType[name]"> {{translateRiskLevel(name)}} </option>
    </select>
  </div>
  <div class="loading-state" *ngIf="isLoading">
    <app-loading></app-loading>
  </div>
  <div class="row d-flex justify-content-center align-items-center mb-4">
    <div *ngIf="isPositiveAction()" class="col-4">
      <button class="btn btn-primary w-100" (click)="positiveActionClicked()">{{ positiveTitle }}</button>
    </div>

    <div *ngIf="isNegativeAction()" class="col-4">
      <button class="btn btn-outline-primary w-100" (click)="negativeActionClicked()">{{ negativeTitle }}</button>
    </div>
  </div>
</div>
