import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lead-delete-query-modal',
  templateUrl: './lead-delete-query-modal.component.html',
  styleUrls: ['./lead-delete-query-modal.component.scss']
})
export class LeadDeleteQueryModalComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this.activeModal.dismiss();
  }

  onDelete(): void {
    this.activeModal.close();
  }
}
