import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { LeadQueryResultsState } from '@digital/app/_models/lead-query-result-state';
import { Observable, combineLatest, concat, merge, of } from 'rxjs';
import { filter, map, pluck, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { LeadCriteriaFormMappingService } from '../lead-query-criteria/lead-criteria-form-mapping.service';
import { LeadService } from '@digital/app/core/services/lead.service';
import { LeadQuery } from '@digital/app/_models/lead-query';
import { LeadQueryCriterion } from '@digital/app/_models/lead-query-criterion';

@Injectable({
  providedIn: 'root'
})
export class LeadQueryResultsService {
  constructor(private leadService: LeadService,
              private criteriaMappingService: LeadCriteriaFormMappingService) { }

  getResultsState(form$: Observable<FormArray>, query$?: Observable<LeadQuery>): Observable<LeadQueryResultsState> {
    const initialResultsState$ = this.getInitialResultsState(query$);
    const changedResultsState$ = this.getChangedResultsState(form$);

    return merge(
      initialResultsState$.pipe(takeUntil(changedResultsState$)),
      changedResultsState$
    ).pipe(shareReplay(1));
  }

  private getInitialResultsState(query$: Observable<LeadQuery>): Observable<LeadQueryResultsState> {
    return query$ ? this.getSavedResultsState(query$) : of({ companies: [] });
  }

  private getSavedResultsState(query$: Observable<LeadQuery>): Observable<LeadQueryResultsState> {
    return query$.pipe(
      pluck('queryCriterias'),
      switchMap(criteria => this.leadService.getCriteriaResults(criteria)),
      map(companies => ({ companies })),
      startWith({ isLoading: true })
    );
  }

  private getChangedResultsState(form$: Observable<FormArray>): Observable<LeadQueryResultsState> {
    return form$.pipe(
      switchMap(form => combineLatest([form.valueChanges, of(form)])),
      filter(([, form]) => form.valid),
      map(([formValue]) => this.criteriaMappingService.fromFormValue(formValue)),
      switchMap(criteria => this.getCriteriaResultsState(criteria))
    );
  }

  private getCriteriaResultsState(criteria: LeadQueryCriterion[]) :Observable<LeadQueryResultsState> {
    const criteriaResultsState$ = this.leadService
      .getCriteriaResults(criteria)
      .pipe(map(companies => ({ companies })));
    
    return concat(of({ isLoading: true }), criteriaResultsState$);
  }
}
