<footer>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <p>All right reserved to Deloitte ® {{ currentYear }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="app-version">
          D.Tax <span class="version-code">v{{ appVersion }}</span>
        </p>
      </div>
    </div>
  </div>
</footer>
