<div class="stages-sidebar">
  <h4 class="title">שלבי התהליך</h4>
  <div class="stages">
    <ng-container *ngFor="let stage of scenario?.stages">
      <app-stage
        [stage]="stage"
        [assignedUserName]="getAssignedUserNameForStage(stage)"
        [isLast]="stage.state.position == scenario.stages.length"
      >
      </app-stage>
    </ng-container>
  </div>
</div>
