import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Dimensions, Size } from '@libs/interfaces/dimensions.interface';

@Component({
  selector: 'app-field-annotation',
  templateUrl: './field-annotation.component.html',
  styleUrls: ['./field-annotation.component.scss']
})
export class FieldAnnotationComponent implements OnInit {
  @Input() dimensions: Dimensions;
  @Input() containerSize: Size;

  constructor(private _elementRef: ElementRef) { }
  ngOnInit() { }

  show() {
    if (!this.dimensions && !this.containerSize) {
      return;
    }

    const element =  this._elementRef.nativeElement;
    element.style.opacity = 1;

    const scaledDimensions = this.dimensions;
    const annotationElement = element.querySelector('.field-annotation');
    annotationElement.style.transform = `translate3d(${scaledDimensions.position.x}px, ${scaledDimensions.position.y}px, 0px)`;
    annotationElement.style.width = `${scaledDimensions.size.width}px`;
    annotationElement.style.height = `${scaledDimensions.size.height}px`;
    annotationElement.classList.add('breathing');
  }

  hide() {
    const element =  this._elementRef.nativeElement;
    element.style.opacity = 0;

    const annotationElement = element.querySelector('.field-annotation');
    annotationElement.classList.remove('breathing');
  }

  private _calculateDimensionsByScale(containerSize: Size, dimensions: Dimensions): Dimensions  {
    const scaledDimensions = {
      position: {
        x: dimensions.position.x / containerSize.width,
        y: containerSize.height / dimensions.position.y
      },
      size: dimensions.size
    };
    return scaledDimensions;
  }
}
