import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DigitalitRoutes } from '@digital/app/_constants';
import { FlowsFacade } from '@digital/app/home/flows.facade';
import { Utils } from '@digital/app/shared/utils';
import { FlowType } from '@digital/app/_enums/flow-type.enum';
import { SortEvent } from '@libs/directives/sortable-header.directive';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { UserRole } from '@libs/authentication/models/user.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  flows$ = this._flowsFacade.flows$;
  isLoading$ = this._flowsFacade.isLoading$;

  isFiltering = false;
  flowType: FlowType;

  isSearching = false;
  searchValue: string;

  isSorting = false;
  sort: SortEvent;

  private currentUser = this._authenticationService.getCurrentUser();

  // Lifecycle
  constructor(
    private _flowsFacade: FlowsFacade,
    private _authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  // Getters
  get createFlowRoute(): string {
    return '../' + DigitalitRoutes.flow.create;
  }

  get isExternalUser() {
    return this.currentUser?.role === UserRole.EXTERNAL;
  }

  /* #region Actions */
  resetDataSource({ refetchData = true } = {}) {
    this.isFiltering = false;
    this.flowType = undefined;

    this.isSearching = false;
    this.searchValue = undefined;

    this.isSorting = false;
    this.sort = undefined;

    if (refetchData) {
      this._flowsFacade.getAllFlows(this._flowsFacade.currentPage);
    }
  }
  /* #endregion */

  /* #region Emitter Listeners */
  onFilterButtonClicked(button: any) {
    if (Utils.isNullOrEmpty(button)) {
      this.resetDataSource();
      return;
    }

    // If it's currently searching and not filtering, let's reset the data source.
    if (this.isSearching && !this.isFiltering) {
      this.resetDataSource({ refetchData: false });
    }

    this.isFiltering = true;
    this.flowType = button.value;
    this._flowsFacade.getAllFlows(this._flowsFacade.currentPage, button.value);
  }

  onSearchValueChanged(value: string) {
    if (Utils.isNullOrEmpty(value)) {
      this.resetDataSource();
      return;
    }

    // If it's currently filtering and not searching, let's reset the data source.
    if (this.isFiltering && !this.isSearching) {
      this.resetDataSource({ refetchData: false });
    }

    this.searchValue = value;
    this.isSearching = true;
    this._flowsFacade.currentPage = 1;
    this._flowsFacade.searchAllFlows(this.searchValue, this._flowsFacade.currentPage);
  }

  onPageChanged(page: number) {
    this._flowsFacade.currentPage = page;

    if (this.isFiltering) {
      this._flowsFacade.getAllFlows(page, this.flowType);
      return;
    }

    if (this.isSearching) {
      this._flowsFacade.searchAllFlows(this.searchValue, page);
      return;
    }

    if (this.isSorting) {
      this._flowsFacade.getAllFlows(page, this.flowType, this.sort);
      return;
    }

    this._flowsFacade.getAllFlows(page, this.flowType);
  }

  onSortButtonClicked(sort: SortEvent) {
    if (Utils.isNullOrEmpty(sort)) {
      this.resetDataSource();
      return;
    }

    this.isSorting = true;
    this.sort = sort;
    this._flowsFacade.getAllFlows(this._flowsFacade.currentPage, this.flowType, sort);
  }
  /* #endregion */
}
