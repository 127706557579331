import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlowShellRoutingModule } from './flow-shell-routing.module';
import { FlowShellComponent } from './flow-shell.component';
import { FlowShellService } from './flow-shell.service';

@NgModule({
  declarations: [FlowShellComponent],
  imports: [CommonModule, FlowShellRoutingModule],
  exports: [FlowShellComponent],
  providers: [FlowShellService]
})
export class FlowShellModule {}
