import { Component, OnInit } from '@angular/core';
import { BlogPost} from '../../models/blog-post.model';
import { BlogService } from '@digital/app/core/services/blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  posts: BlogPost[];

  // Lifecycle
  constructor(private _blogService: BlogService) { }

  ngOnInit() {
    this.getPosts();
  }

  // Networking
  private getPosts() {
    this._blogService.getBlogPosts(1, ((pages, posts) => this.onGetBlogPosts(pages, posts)));
  }

  private onGetBlogPosts(pages: number, posts: BlogPost[]) {
    this.posts = posts;
  }
}
