<div class="change-flow-info-modal">
  <div class="modal-header">
    <h4 class="title">{{ title }}</h4>
    <button class="modal-close-button" (click)="closeModal()">x</button>
  </div>
  <div class="modal-body">
    <div class="body-container">
      <p>{{massage}}</p>
      <select class="custom-select rounded-0" (change)="didSelectUser($event.target.value)" *ngIf="type !== 3" required>
        <option value="{{ user.id }}" *ngFor="let user of users" [selected]="user.id === currentUserID">{{ user.name }}</option>
      </select>
      <select class="custom-select rounded-0" (change)="selectedValueRiskLevel($event.target.value)" *ngIf="type === 3" 
        [ngModel]="currentRiskLevel"
      >
        <option *ngFor="let name of flowRiskLevel" [value]="RiskLevelType[name]"> 
          {{translateRiskLevel(RiskLevelType[name])}} 
        </option>
      </select>
    </div>
    <div class="modal-footer">
      <div class="buttons-container">
        <button class="btn btn-primary" (click)="submitChange()" [disabled]="(!selectedUser && currentUserID)||(!selectedRiskLevel && currentRiskLevel)">עדכון</button>
        <button class="btn btn-outline-primary" (click)="closeModal()">ביטול</button>
      </div>
    </div>
  </div>
</div>
