import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadHomeComponent } from './lead-home/lead-home.component';
import { LeadRoutingModule } from './lead-routing.module';
import { ViewsModule } from '@libs/views/views.module';
import { SharedModule } from '../shared/shared.module';
import { LeadQueriesTableComponent } from './lead-queries-table/lead-queries-table.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { LeadQueryComponent } from './lead-query/lead-query.component';
import { LeadQueryResultsTableComponent } from './lead-query-results-table/lead-query-results-table.component';
import { LeadQueryTopBarComponent } from './lead-query-top-bar/lead-query-top-bar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LeadDeleteQueryModalComponent } from './lead-delete-query-modal/lead-delete-query-modal.component';
import { LeadShareQueryModalComponent } from './lead-share-query-modal/lead-share-query-modal.component';
import { LeadCopyQueryModalComponent } from './lead-copy-query-modal/lead-copy-query-modal.component';
import { LeadQueryPropertiesComponent } from './lead-query-properties/lead-query-properties.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeadQueryPropertyComponent } from './lead-query-property/lead-query-property.component';
import { LeadQueriesResultsPage } from './lead-queries-results-page/lead-queries-results-page.component';
import { LeadQueryCriterionComponent } from './lead-query-criterion/lead-query-criterion.component';
import { LeadQueryCriterionGroupComponent } from './lead-query-criterion-group/lead-query-criterion-group.component';
import { LeadLogicalOperatorButtonComponent } from './lead-logical-operator-button/lead-logical-operator-button.component';
import { LeadQueryCriteriaComponent } from './lead-query-criteria/lead-query-criteria.component';
import { DigitalFormsRoutingModule } from '../forms/forms-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FlowModule } from '../flow/flow.module';
import { DigitalFormsModule } from '../forms/forms.module';
import { LeadQueryPdfFormWrapperComponent } from './lead-query-pdf-form-wrapper/lead-query-pdf-form-wrapper.component';
import { MainFormContentComponent } from './lead-query-form-content/lead-query-form-content.component';
import { LeadFieldsTreeComponent } from './lead-fields-tree/lead-fields-tree.component';
import { LeadMainFormViewComponent } from './lead-main-form-view/main-form-view.component';
import { LeadInvalidQueryModalComponent } from './lead-invalid-query-modal/lead-invalid-query-modal.component';

@NgModule({
  declarations: [
    LeadHomeComponent,
    LeadQueriesTableComponent,
    LeadQueryComponent,
    LeadQueryResultsTableComponent,
    LeadQueryTopBarComponent,
    LeadDeleteQueryModalComponent,
    LeadShareQueryModalComponent,
    LeadCopyQueryModalComponent,
    LeadQueryPropertiesComponent,
    LeadQueryPropertyComponent,
    LeadQueriesResultsPage,
    LeadQueryCriterionComponent,
    LeadQueryCriterionGroupComponent,
    LeadLogicalOperatorButtonComponent,
    LeadQueryCriteriaComponent,
    LeadQueryPdfFormWrapperComponent,
    MainFormContentComponent,
    LeadFieldsTreeComponent,
    LeadMainFormViewComponent,
    LeadInvalidQueryModalComponent
  ],
  imports: [
    CommonModule,
    LeadRoutingModule,
    ViewsModule,
    SharedModule,
    GridModule,
    NgbModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    DigitalFormsRoutingModule,
    SharedModule,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    FlowModule,
    DigitalFormsModule
  ]
})
export class LeadModule {}
