import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowService } from '@digital/app/core/services/flow.service';
import { GlobalRoutes } from '@libs/constants';

export enum DeleteFlowActions {
  Decline = 'decline',
  Approve = 'approve'
}

@Component({
  selector: 'app-delete-flow',
  templateUrl: './delete-flow.component.html',
  styleUrls: ['./delete-flow.component.scss']
})
export class DeleteFlowComponent implements OnInit {
  // Lifecycle
  constructor(private _route: ActivatedRoute, private _router: Router, private _flowService: FlowService) {}

  ngOnInit() {
    const action = this._route.snapshot.queryParams.action as DeleteFlowActions;
    this._handleFlowDeletionRequest(action, this._route.snapshot.params.id);
  }

  // Methods
  private _handleFlowDeletionRequest(action: DeleteFlowActions, flowID: number) {
    if (!action) {
      this._router.navigate([GlobalRoutes.dtax]);
      return;
    }
    this._flowService.deleteFlow(flowID, action);
    this._router.navigate([GlobalRoutes.dtax]);
  }
}
