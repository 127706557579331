import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NetworkService } from '@libs/core/network/network.service';
import { GlobalAPI } from '@digital/app/_constants';

import { Signature } from '../models/signature.model';

@Injectable({ providedIn: 'root' })
export class SignaturesAPI {
  // Lifecycle
  constructor(private _network: NetworkService) {}

  // API Methods
  getAll(flowID: number, fileID: number): Observable<{ signatures: Signature[]; signDate: Date }> {
    const endpoint = `${GlobalAPI.endpoints.signatures.base}/${flowID}/${fileID}`;

    return this._network.get(GlobalAPI.baseURL, endpoint).pipe(
      map((response) => ({
        signatures: response.data.signatures,
        signDate: response.data.signDate[0]?.date
      }))
    );
  }

  create(signature: Signature): Observable<Signature> {
    return this._network
      .post(GlobalAPI.baseURL, GlobalAPI.endpoints.signatures.base, signature)
      .pipe(map((response) => response.data.signature));
  }

  update(signature: Signature): Observable<any> {
    return this._network
      .patch(GlobalAPI.baseURL, GlobalAPI.endpoints.signatures.base, signature)
      .pipe(map((response) => response.data));
  }

  delete(signatureID: number): Observable<any> {
    const endpoint = `${GlobalAPI.endpoints.signatures.base}/${signatureID}`;

    return this._network.delete(GlobalAPI.baseURL, endpoint).pipe(map((response) => response.data));
  }

  createSignDate(date: string, flowID: number, fileID: number): Observable<Date> {
    const endpoint = `${GlobalAPI.endpoints.signatures.base}/${flowID}/${fileID}`;
    return this._network.post(GlobalAPI.baseURL, endpoint, { date }).pipe(map((response) => response.data.date));
  }

  //   getSignDate(flowID: number, fileID: number): Observable<any> {
  //     const endpoint = `${GlobalAPI.endpoints.signatures.base}/${flowID}/${fileID}`;

  //     return this._network.get(GlobalAPI.baseURL, endpoint).pipe(map((response) => response.data.signDate));
  //   }
}
