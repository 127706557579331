import { Deserializable } from '@digital/app/_models/deserializable.model';

export interface User {
  id: number;
  name: string;
  email: string;
}

export class ManagersPartners implements Deserializable {
  partners: User[];
  managers: User[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
