<div [formGroup]="form" class="row">
  <app-lead-query-property class="col-3" id="queryName" label="שם השאילתה">
    <input type="text" id="queryName" class="form-control" formControlName="queryName" [placeholder]="placeholder" />
  </app-lead-query-property>
  <app-lead-query-property class="col-3" id="desc" label="תיאור">
    <input type="text" id="desc" class="form-control" formControlName="desc" [placeholder]="placeholder" />
  </app-lead-query-property>
  <app-lead-query-property class="col-3" id="year" label="שנה">
    <div ngbDropdown autoClose="outside">
      <button class="btn btn-outline-primary" id="dropdownMenuButton" ngbDropdownToggle>
        {{ selectedYear }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
        <div *ngFor="let year of years">
          <input type="radio"
                 class="year-checkbox"
                 [id]="year"
                 name="year"
                 [value]="year"
                 [formControl]="yearControl" />
          <label [for]="year">{{ year }}</label>
        </div>
      </div>
    </div>
  </app-lead-query-property>
</div>
