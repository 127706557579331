import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lead-invalid-query-modal',
  templateUrl: './lead-invalid-query-modal.component.html',
  styleUrls: ['./lead-invalid-query-modal.component.scss']
})
export class LeadInvalidQueryModalComponent implements OnInit {
  @Input() form: FormGroup;
  
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.form.markAllAsTouched();
  }
}
