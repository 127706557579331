import { Injectable } from '@angular/core';
import { LeadAPI } from '@digital/app/_apis/lead.api';
import { LeadQuery } from '@digital/app/_models/lead-query';
import { LeadQueryCriterion } from '@digital/app/_models/lead-query-criterion';
import { LeadQueryResult } from '@digital/app/_models/lead-query-result';
import { LeadQueryRow } from '@digital/app/_models/lead-query-row';
import APIResponse from '@libs/core/network/model/api-response.model';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LeadService {
  constructor(private api: LeadAPI) {}

  getQueries(): Observable<LeadQueryRow[]> {
    return this.api.getQueries();
  }

  getQuery(id: number): Observable<LeadQuery> {
    return this.api.getQuery(id);
  }

  addQuery(query: LeadQuery): Observable<number> {
    return this.api.addQuery(query);
  }

  updateQuery(id: number, query: LeadQuery): Observable<APIResponse> {
    return this.api.updateQuery(id, query);
  }

  deleteQuery(id: number): Observable<APIResponse> {
    return this.api.deleteQuery(id);
  }

  getQueryResults(id: number): Observable<APIResponse> {
    return this.api.getQueryResults(id);
  }

  getCriteriaResults(criteria: LeadQueryCriterion[]): Observable<LeadQueryResult[]> {
    return this.api.getCriteriaResults(criteria);
  }

  getYears(): Observable<number[]> {
    return this.api
      .getYears()
      .pipe(
        shareReplay(1)
      );
  }

  shareQuery(queryId: number, userId: number): Observable<APIResponse> {
    return this.api.shareQuery(queryId, userId);
  }

  copyQuery(copiedQueryId: number, newQueryName: string): Observable<APIResponse> {
    return this.api.copyQuery(copiedQueryId, newQueryName);
  }

  getUsersOfTaxDivision(): Observable<APIResponse> {
    return this.api.getUsersOfTaxDivision();
  }
}
