import { Component, OnInit, ElementRef } from '@angular/core';
import { FieldBaseComponent } from '../field-base/field-base.component';
import { FormFieldOption } from '@digital/app/_models/form-field-option.model';
import { FormsService } from '@digital/app/forms/forms.service';

@Component({
  selector: 'app-field-multiple-selection',
  templateUrl: './field-multiple-selection.component.html',
  styleUrls: ['../field-base/field-base.component.scss', './field-multiple-selection.component.scss']
})
export class FieldMultipleSelectionComponent extends FieldBaseComponent implements OnInit {
  /* #region Lifecycle */

  constructor(public _elementRef: ElementRef, public formsService: FormsService) {
    super(_elementRef, formsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /* #endregion */

  /* #region Methods */

  updateFieldValue($target: any) {
    const selectedValue = parseInt($target.value, 10);
    const isChecked = $target.checked;
    const finalValues = this.field.value || [];

    if (isChecked) {
      finalValues.push(selectedValue);
    } else {
      const index = finalValues.indexOf(selectedValue);
      finalValues.splice(index, 1);
    }

    this.field.newValue = finalValues;
    this.didUpdateFormFieldValue.emit(this.field);

    // After the new value has been emitted, set the new value as the current value so the next field update would send a PATCH instead of POST
    this.field.value = finalValues;
  }

  isChecked(option: FormFieldOption): boolean {
    if (!this.field.value) {
      return false;
    }

    const value: any[] = this.field.value;
    return value.includes(option.id);
  }

  /* #endregion */
}
