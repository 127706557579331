import { Deserializable } from '@digital/app/_models/deserializable.model';
import Stage from './stage.model';

export default class Scenario implements Deserializable {
    id: number;
    name: string;
    stages: Stage[];

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
