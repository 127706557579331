<app-flow-add-comment
  (commentUpdated)="onCommentCreated($event)"
  [currentInitialName]="currentInitialName"
  [currentUserID]="currentUserID"
  [flowID]="flowID"
  [stage]="stage"
></app-flow-add-comment>
<ng-container *ngFor="let comment of comments">
  <div style="margin-top: 2rem">
    <app-flow-view-comment
      [currentInitialName]="currentInitialName"
      [comment]="comment"
      [flowID]="flowID"
      (addCommentButtonPressed)="toggleOpenCommentButton(comment.id)"
    ></app-flow-view-comment>
    <div class="container-fluid" *ngIf="comment.replyCount === 0">
      <app-flow-add-comment
        (commentUpdated)="onCommentCreated($event)"
        *ngIf="this.commentsIds.includes(comment.id) && !comment.replies"
        [currentInitialName]="currentInitialName"
        [currentUserID]="currentUserID"
        [flowID]="flowID"
        [stage]="stage"
        [parentID]="comment.id"
      ></app-flow-add-comment>
    </div>
    <ng-container *ngFor="let reply of comment.replies">
      <app-flow-view-comment [parentComment]="comment" [comment]="reply" [flowID]="flowID"></app-flow-view-comment>
      <app-flow-add-comment
        (commentUpdated)="onCommentCreated($event)"
        *ngIf="reply === comment.replies[comment.replies.length - 1]"
        [currentInitialName]="currentInitialName"
        [currentUserID]="currentUserID"
        [parentID]="comment.id"
        [flowID]="flowID"
        [stage]="stage"
      ></app-flow-add-comment>
      <div class="container-fluid comment">
        <button
          class="comment-button"
          *ngIf="reply === comment.replies[comment.replies.length - 1]"
          (click)="closeReplyComment(comment.id)"
        >
          <app-svg-icon name="angle-up-bold_special"></app-svg-icon>
          סגור
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>
