import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-bridge-login',
  templateUrl: './bridge-login.component.html',
  styleUrls: []
})
export class BridgeLoginComponent implements OnInit, OnDestroy {
  signInMsal: boolean = false;
  /* #region Lifecycle */

  constructor(
    private _msalBroadcastService: MsalBroadcastService,
    private _authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    if (this._authenticationService.isLoggedIn()) {
      this._authenticationService.routeUserByDivision();
    }
  }

  ngOnDestroy() {}

  msalBroadcast() {
    this._msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        this._authenticationService.setIsDeloitteUser(true);
      });
  }
}
