import { Deserializable } from '@digital/app/_models/deserializable.model';

export class BlogPosts implements Deserializable {
    pages: number;
    posts: BlogPost[];

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

export class BlogPost implements Deserializable {
    id: number;
    title: string;
    user: {
        id: number;
        fullName: {
            english: string;
            hebrew: string;
        };
        nameInitials: string,
        rank: {
            english: string;
            hebrew: string;
        };
    };
    name: string;
    body: string;
    createdAt: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
