<!-- Loading view-->
<div class="loading-state" *ngIf="isLoading">
  <app-loading></app-loading>
</div>
<!-- Empty view (when no companies are available for the user) -->
<div class="empty-view-container" *ngIf="companies && companies.length === 0">
  <div class="row">
    <div class="col-12">
      <h5 class="title">לא ניתן ליצור תהליך חדש</h5>
      <p class="content">
        לצערינו לא קיימות חברות המשוייכות לחשבון שלך.
        <br />
        אם מדובר בטעות, יש ליצור קשר עם מנהל/ת המערכת.
      </p>
      <p class="action">
        <button class="btn btn-primary" (click)="openTicketServiceNow()">פתיחת קריאה</button>
        <button class="btn btn-secondary" (click)="navigateToHomePage()">חזרה לעמוד הבית</button>
      </p>
    </div>
  </div>
</div>
<!-- Form view-->
<div class="fields-container" *ngIf="!isLoading && companies.length !== 0" [formGroup]="createFlowForm">
  <div class="row">
    <div class="col-12" formGroupName="details">
      <div formGroupName="company">
        <div class="input-group row">
          <div class="col-4">
            <label for="company-id">
              ח.פ. החברה
              <app-svg-icon
                name="help-indicator-glyph_special"
                ngbTooltip="ח.פ החברה יוזן אוטומטית במידה וחברה נבחרה בשורה הקודמת"
              ></app-svg-icon>
            </label>
          </div>
          <div class="col-5">
            <input
              type="text"
              (selectItem)="selectedCompanyID($event.item)"
              class="form-control"
              [ngbTypeahead]="companyIDAutoComplete"
              #instance="ngbTypeahead"
              formControlName="id"
              required
            />
          </div>
          <div class="col-3">
            <button
              type="button"
              class="btn btn-secondary"
              ngbTooltip="במקרה והחברה לא נמצאת יש לפתוח קריאה בלחיצה כאן"
              (click)="openTicketServiceNow()"
            >
              חברה לא נמצאת?
            </button>
          </div>
        </div>
        <!-- -->
        <div class="input-group row">
          <div class="col-4">
            <label for="company-name">
              שם החברה
              <app-svg-icon
                name="help-indicator-glyph_special"
                ngbTooltip="שם החברה יוזן אוטומטית במידה וח.פ. החברה יוזן בשורה הבאה"
              ></app-svg-icon>
            </label>
          </div>
          <div class="col-5">
            <input
              type="text"
              (selectItem)="selectedCompanyName($event.item)"
              class="form-control"
              [ngbTypeahead]="companyNameAutoComplete"
              #instance="ngbTypeahead"
              formControlName="name"
              required
            />
          </div>
        </div>
      </div>
      <!-- company close -->
      <div class="input-group row">
        <div class="col-4">
          <label for="tax-year">
            שנת המס
            <app-svg-icon name="help-indicator-glyph_special" ngbTooltip="שנת המס הרלוונטית עבור דוח זה">
            </app-svg-icon>
          </label>
        </div>
        <div class="col-5">
          <select
            class="custom-select rounded-0"
            formControlName="taxYear"
            (change)="selectedValueTaxYear($event.target.value)"
            [ngClass]="{ inv: getValidationAfterSubmit('taxYear') }"
            required
          >
            <option selected value="" disabled>בחירת שנת מס</option>
            <option value="{{ year }}" *ngFor="let year of taxYears">{{ year }}</option>
          </select>
        </div>
      </div>
      <!-- Tax Year close-->
      <div class="input-group row">
        <div class="col-4">
          <label for="deadline">תאריך צפי סיום עבודה על הדוח</label>
        </div>
        <div class="col-5">
          <div
            class="datepicker"
            [class.invalid]="dataPickerValidation"
            (click)="dataPickerClicked()"
            [ngClass]="{ inv: getValidationAfterSubmit('deadline') }"
          >
            <input
              ngbDatepicker
              class="form-control"
              placeholder="בחירת תאריך"
              name="dp"
              #d="ngbDatepicker"
              firstDayOfWeek="0"
              formControlName="deadline"
              required
              [minDate]="minDate"
            />
            <button class="calendar-btn" (click)="d.toggle()" type="button"></button>
          </div>
        </div>
      </div>
      <!--data picker close-->
      <div class="input-group row">
        <div class="col-4">
          <label for="riskLevel">
            רמת סיכון
            <app-svg-icon
              name="help-indicator-glyph_special"
              ngbTooltip="דוח מס ברמת סיכון גבוהה יותר יקבל עדיפות בבדיקת בקרת איכות. 
                        רמת סיכון - רמת הסיכון לטעות מהותית בדוח נקבעת על פי שיקול דעתו של השותף (יש לקחת בחשבון מקרים חריגים כגון: הלקוח נקט בפוזיציות מס משמעותיות, רולינג או שינוי מבנה מורכב שבוצע והוא בעל השלכה על דוח המס, וכו') "
            ></app-svg-icon>
          </label>
        </div>
        <div class="col-5">
          <select
            class="custom-select rounded-0"
            formControlName="riskLevel"
            (change)="selectedValueRiskLevel($event.target.value)"
            [value]="RiskLevelType.Undefined"
          >
            <option selected value="" disabled>בחירת רמת סיכון</option>
            <option *ngFor="let name of flowRiskLevel" [value]="RiskLevelType[name]">{{ riskLevel(name) }}</option>
          </select>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</div>
