<section class="new-comment" [class.comments]="!parentID" [class.reply]="parentID">
<div class="container-fluid">
  <form class="flow-comments-box" [formGroup]="createCommentForm" (submit)="submitComment()">
    <div class="row">
      <h4 class="comment-title" *ngIf="!parentID">תגובות</h4>
    </div>
    <div class="row">
      <span class="profile-image"><label>{{currentInitialName}}</label></span>
      <div class="col">
        <textarea class="add-new-comment" placeholder="מה ברצונך להגיב?" formControlName="commentField" required></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-left">
        <button type="submit" class="btn btn-outline-primary" [disabled]="!createCommentForm.valid" >הוספת תגובה</button>
      </div>
    </div>
  </form>
</div>
</section>
