import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormField, FormFieldType } from '../../../_models/form-field.model';
import { Dimensions } from '@libs/interfaces/dimensions.interface';
import { FormsService } from '../../forms.service';
import { CreateCustomFormFieldDTO } from '@digital/app/_dtos/create-custom-field.dto';

interface GridField {
  id: number;
  dimensions: Dimensions;
}

@Component({
  selector: 'app-fields-grid',
  templateUrl: './fields-grid.component.html',
  styleUrls: ['./fields-grid.component.scss']
})
export class FieldsGridComponent implements OnInit {
  @Input() formPage: number;
  @Input() isLockedForEditing: boolean;
  @Input() shouldLockFieldsForEditing: boolean;
  @Input() nestedFields: FormField[];

  @Output() clickedField = new EventEmitter<FormField>();

  @Output() didUpdateFormFieldValue = new EventEmitter<FormField>();
  @Output() mouseDidEnterFormField = new EventEmitter<FormField>();
  @Output() mouseDidLeaveFormField = new EventEmitter<FormField>();
  @Output() createCustomField = new EventEmitter<CreateCustomFormFieldDTO>();
  @Output() editFieldButtonClicked = new EventEmitter<FormField>();
  @Output() deleteFieldButtonClicked = new EventEmitter<FormField>();

  currentFormFields$ = this.formsService.currentFormFields$;
  fields: FormField[] = [];
  fieldType = FormFieldType;
  formattedValue: string;

  constructor(private formsService: FormsService) {}

  ngOnInit() {
    this.currentFormFields$.subscribe((fields) => {
      const flattenedFields = this.flattenFieldsArray(fields);
      const filteredFieldsByPage = flattenedFields.filter((field) => field.formPage === this.formPage);
      this.fields = [...this.fields, ...filteredFieldsByPage];
    });
  }

  private flattenFieldsArray(fields: FormField[]): FormField[] {
    let flatFields: FormField[] = [];

    for (const field of fields) {
      flatFields = [...flatFields, field];
      if (field.fields && field.fields.length > 0) {
        flatFields = [...flatFields, ...this.flattenFieldsArray(field.fields)];
      }
    }

    return flatFields;
  }

  onFieldClicked(field: FormField) {
    this.clickedField.emit(field);
  }
}
