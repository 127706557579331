import { Component, OnInit, ElementRef } from '@angular/core';
import { FormsService } from '@digital/app/forms/forms.service';
import { FieldBaseComponent } from '../field-base/field-base.component';

@Component({
  selector: 'app-field-single-selection',
  templateUrl: './field-single-selection.component.html',
  styleUrls: ['../field-base/field-base.component.scss', './field-single-selection.component.scss']
})
export class FieldSingleSelectionComponent extends FieldBaseComponent implements OnInit {
  /* #region Lifecycle */

  constructor(public _elementRef: ElementRef, public formsService: FormsService) {
    super(_elementRef, formsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /* #endregion */

  /* #region Methods */

  updateFieldValue($target: any) {
    this.field.previousValue = this.field.value;
    const value = $target.value;
    this.field.newValue = value;
    this.didUpdateFormFieldValue.emit(this.field);
    this.field.value = value; 
  }

  clearIfSelected($target: any) {
    if(this.field.value == $target.value){
      $target.checked = !$target.checked
      this.field.newValue = '';
      this.didUpdateFormFieldValue.emit(this.field);
      this.field.value = '';
    }
  }

  /* #endregion */
}
