<div id="form-field-{{ field.id }}" class="form-field" [class.nth-child-background]="isSecondaryBackgroundColor" *ngIf="side === 'right'">
    <div class="form-field-title">
        <div class="form-check">
            <div class="checkbox">
                <input
                    class="form-check-input"
                    type="checkbox"
                    name="field-{{ field.id }}" id="option-{{ field.id }}"
                    [value]="field.id"
                    [checked]="isChecked"
                    [required]="field.isRequired"
                    (change)="updateFieldValue($event.target)"
                    [disabled]="isLockedForEditing">
                <span class="checkmark"></span>
            </div>
            <span class="field-identifier field-number" *ngIf="field.number">{{ field.number }}.</span>
            <span class="field-identifier field-taxCode" *ngIf="field.taxCode">[{{ field.taxCode }}]</span>
            <label class="form-check-label" for="option-{{ field.id }}">{{ field.name }}</label>
            <app-tooltip-indicator [tooltipTitle]="field.note"></app-tooltip-indicator>
        </div>
    </div>
    <p class="form-field-description" [class.emphasize]="shouldEmphasizeDescription" [innerHTML]="field.description"></p>
</div>
<ng-container *ngIf="side === 'left'">
    <input
        class="form-check-input-pdf"
        type="checkbox"
        name="field-{{ field.id }}" id="option-{{ field.id }}"
        [value]="field.id"
        [checked]="isChecked"
        [required]="field.isRequired"
        [style.width.px]="field.width"
        [style.height.px]="field.height"
        [ngStyle]="{ transform: 'translate(' + field.positionX + 'px, ' + field.positionY + 'px)' }"
        (change)="updateFieldValue($event.target)"
        [disabled]="isLockedForEditing"
    />
</ng-container>
