<div class="modal-header">
  <div class="title-container">
    <h4>[{{ this.field?.id }}] {{ this.field?.name || 'ללא שם' }}</h4>
  </div>
</div>
<div class="modal-body">
  <div class="form-container">
    <form dir="rtl" [formGroup]="fieldsGroup">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="name">שם השדה</label>
            <input
              type="text"
              class="form-control"
              formControlName="name"
              required
              (change)="onSaveFormButtonClicked('change')"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="description">תיאור השדה (יופיע מתחת לשם השדה)</label>
            <!-- <input type="text" class="form-control" formControlName="description" required (change)='onSaveFormButtonClicked("change")'> -->
            <textarea
              class="form-control"
              formControlName="description"
              required
              (change)="onSaveFormButtonClicked('change')"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="note">הערה (טולטיפ)</label>
            <input
              type="text"
              class="form-control"
              formControlName="note"
              (change)="onSaveFormButtonClicked('change')"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label for="position">מיקום השדה</label>
            <input
              type="number"
              class="form-control"
              name="position"
              formControlName="position"
              required
              (change)="onSaveFormButtonClicked('change', $event)"
            />
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label for="number">מספר הסעיף/שדה בטופס</label>
            <input
              type="text"
              class="form-control"
              formControlName="number"
              required
              (change)="onSaveFormButtonClicked('change')"
            />
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label for="number">קוד השדה במס הכנסה</label>
            <input
              type="text"
              class="form-control"
              formControlName="taxCode"
              required
              (change)="onSaveFormButtonClicked('change')"
            />
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label for="isRequired">האם זה שדה חובה?</label>
            <select
              class="form-control"
              id="isRequired"
              formControlName="isRequired"
              required
              (change)="onSaveFormButtonClicked('change')"
            >
              <option selected value="" disabled>בחר</option>
              <option value="true">כן</option>
              <option value="false">לא</option>
            </select>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="type">סוג השדה</label>
            <select
              class="form-control"
              id="type"
              formControlName="type"
              required
              (change)="onFieldTypeChange($event.target.value)"
            >
              <option selected value="" disabled>בחר</option>
              <option value="title">כותרת ראשית</option>
              <option value="subtitle">כותרת משנית</option>
              <option value="text">טקסט</option>
              <option value="number">מספר</option>
              <option value="boolean">בוליאן</option>
              <option value="date">תאריך</option>
              <option value="calculated">חישוב</option>
              <option value="singleSelection">בחירה יחידה</option>
              <option value="multipleSelection">בחירה מרובה</option>
              <option value="pullData">משיכה משדה</option>
              <option value="freeOptions">בחירה חופשית</option>
            </select>
          </div>

          <div class="calculate-fields" *ngIf="this.field?.type === 'calculated'">
            <h5>חישוב ערך משדות אחרים</h5>
            <div class="row">
              <div class="col">
                <label for="calculation-field-ids">מזהי השדות לחישוב</label>
                <input
                  type="text"
                  name="calculation-field-ids"
                  class="form-control"
                  formControlName="calculatedFieldIDs"
                  placeholder="172, 329, 12, 3"
                  dir="rtl"
                  (change)="onCalculateFieldsChange()"
                />
                <p>דוגמא: 172, 329, 12, 3</p>
                <hr />
                <label for="calculation-formula">נוסחה</label>
                <input
                  type="text"
                  name="calculation-formula"
                  class="form-control"
                  formControlName="calculatedFieldFormula"
                  placeholder="(d + d - d) * d)"
                  dir="rtl"
                  (change)="onCalculateFieldsChange()"
                />
                <p>דוגמה: ((d + d - d) * d)</p>
                <p>ה-d יתחלפו במזהי השדות לפי הסדר</p>
                <hr />
                <label for="calculation-default-value">ערך ברירת מחדל</label>
                <input
                  type="text"
                  name="calculation-default-value"
                  class="form-control"
                  formControlName="calculatedFieldDefaultValue"
                  placeholder="0"
                  dir="rtl"
                  (change)="onCalculateFieldsChange()"
                />
                <p>ערך ברירת המחדל עבור שדה זה</p>
                <hr />
                <label for="calculation-default-when">השתמש בערך ברירת המחדל כאשר</label>
                <input
                  type="text"
                  name="calculation-default-when"
                  class="form-control"
                  formControlName="calculatedFieldDefaultWhen"
                  placeholder="POSITIVE"
                  dir="rtl"
                  (change)="onCalculateFieldsChange()"
                />
                <p>תנאי לשימוש בערך ברירת המחדל</p>
              </div>
            </div>
          </div>

          <div class="option-container" *ngIf="showOptionFieldButton">
            <button class="add-option-field-button" (click)="addOptionField()">+הוספת אפשרות חדשה (סימון פנימי)</button>
            <div class="option-field" *ngFor="let optionField of sortedOptions">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="option-name">{{ optionField?.name || 'ללא שם' }} (מזהה: {{ optionField?.id }})</label>
                    <input
                      type="text"
                      [value]="optionField?.name || ''"
                      class="form-control"
                      required
                      (change)="optionFieldNameChange($event.target.value, optionField.id)"
                    />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="type">סוג האפשרות</label>
                    <select
                      class="form-control"
                      id="option-type"
                      (change)="selectOptionFieldTypeChange($event.target.value, optionField.id)"
                    >
                      <option
                        *ngIf="this.field.type === 'freeOptions'"
                        value="text"
                        [selected]="optionField?.type === 'text'"
                      >
                        טקסט
                      </option>
                      <option
                        *ngIf="this.field.type === 'freeOptions'"
                        value="number"
                        [selected]="optionField?.type === 'number'"
                      >
                        מספר
                      </option>
                      <option
                        *ngIf="this.field.type === 'freeOptions'"
                        value="date"
                        [selected]="optionField?.type === 'date'"
                      >
                        תאריך
                      </option>
                      <option
                        *ngIf="this.field.type === 'multipleSelection' || this.field.type === 'freeOptions'"
                        value="checkbox"
                        [selected]="optionField?.type === 'checkbox'"
                      >
                        צ׳ק בוקס
                      </option>
                      <option
                        *ngIf="this.field.type === 'singleSelection' || this.field.type === 'freeOptions'"
                        value="radio"
                        [selected]="optionField?.type === 'radio'"
                      >
                        רדיו
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label for="option-name">מיקום</label>
                    <input
                      type="text"
                      [value]="optionField?.position"
                      required
                      (change)="optionFieldPositionChange($event.target.value, optionField.id)"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <button class="btn btn-danger delete-option" (click)="onDeleteOptionFieldClicked(optionField)">
                    מחיקת האפשרות
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="number" *ngIf="this.field?.type === 'number'">
            <input
              type="checkbox"
              name="enableNegativeNumber"
              (change)="didSelectEnableNegativeNumber($event.target)"
              [checked]="this.field?.enableNegativeNumber"
            />
            <label for="enableNegativeNumber">אפשר מספר שלילי</label>
          </div>

          <div class="pull-data" *ngIf="this.field?.type === 'pullData'">
            <div class="import-from-other-form">
              <h5>משיכה משדה בטופס אחר</h5>
              <div class="row">
                <div class="col-6">
                  <div class="form-group" *ngIf="forms$ | async as forms">
                    <label for="type">בחירת טופס</label>
                    <select
                      class="form-control"
                      required
                      formControlName="selectedFormID"
                      (change)="selectedFormChange()"
                    >
                      <option [value]="" [selected]="this.field?.formID === ''"></option>
                      <ng-container *ngFor="let form of forms">
                        <option *ngIf="form.id !== this.field?.formID" [value]="form.id">
                          [{{ form.formYear }}] [{{ form.formTaxID }}] {{ form.name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group" *ngIf="formFieldsImport$ | async as fields">
                    <label for="type">בחירת שדה</label>
                    <select
                      class="form-control"
                      formControlName="selectedFieldID"
                      required
                      (change)="selectedFieldChange()"
                    >
                      <option [value]="" [selected]="this.field?.id === ''"></option>
                      <ng-container *ngFor="let selectedField of fields">
                        <option [value]="selectedField.id" [selected]="this.field?.id === selectedField.pulledFieldID">
                          {{ selectedField?.name || 'שדה ללא שם' }}
                        </option>
                      </ng-container>
                    </select>
                    <p>(אפשרות הבחירה תיפתח לאחר בחירת טופס)</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="import-company-info">
              <h5>משיכה משדה מידע על החברה</h5>
              <div class="row">
                <div class="col-12">
                  <select
                    class="form-control"
                    formControlName="selectedFieldFromCompanyInfo"
                    *ngIf="(pullDataParams$ | async).companyInfo as companyInfo"
                    (change)="selectedCompanyInfoChange()"
                  >
                    <option [value]="" [selected]="this.field?.pulledColumnName === ''"></option>
                    <ng-container *ngFor="let info of companyInfo">
                      <option [value]="info.value" [selected]="this.field?.pulledColumnName === info.value">
                        {{ info.name }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>

            <div class="import-deloitte-info">
              <h5>משיכה משדה פרטי המייצג</h5>
              <div class="row">
                <div class="col-12">
                  <select
                    class="form-control"
                    formControlName="selectedFieldFromCompanyInfo"
                    *ngIf="(pullDataParams$ | async).deloitteInfo as deloitteInfo"
                    (change)="selectedDeloitteInfoChange()"
                  >
                    <option [value]="" [selected]="this.field?.pulledColumnName === ''"></option>
                    <ng-container *ngFor="let info of deloitteInfo">
                      <option [value]="info.value" [selected]="this.field?.pulledColumnName === info.value">
                        {{ info.name }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>

            <div class="import-flow-info">
              <h5>משיכה משדה של התהליך</h5>
              <div class="row">
                <div class="col-12">
                  <select
                    class="form-control"
                    formControlName="selectedFieldFromFlowInfo"
                    *ngIf="(pullDataParams$ | async).flowInfo as flowInfo"
                    (change)="selectedFlowInfoChange()"
                  >
                    <option [value]="" [selected]="this.field?.pulledColumnName === ''"></option>
                    <ng-container *ngFor="let info of flowInfo">
                      <option [value]="info.value" [selected]="this.field?.pulledColumnName === info.value">
                        {{ info.name }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6" style="border-right: 2px solid lightgray">
          <div class="operations">
            <div class="row">
              <div class="col-6">
                <h5>תנאים וחוקיות</h5>
              </div>
              <div class="col-6 text-left">
                <button class="add-operations">+הוספת תנאי</button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>תנאי 1</p>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <select class="form-control" formControlName="operationsSelect" required>
                    <option selected value="" disabled>בחר</option>
                    <option *ngFor="let operator of operation" [value]="operator.name">{{ operator.value }}</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input type="number" class="form-control" formControlName="operationsNumber" placeholder="מספר" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <p>תנאי 2</p>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <select class="form-control" required>
                    <option selected value="" disabled>לחייב מילוי שדה</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input
                    dir="rtl"
                    type="text"
                    class="form-control"
                    formControlName="mustHaveValue"
                    placeholder="הזן שדות"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <p>תנאי 3</p>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <select class="form-control" required>
                    <option selected value="" disabled>לחייב הוספת טופס לעריכה</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" *ngIf="this.forms$ | async as forms">
                  <select class="form-control" required formControlName="addFormsToFlow">
                    <option selected value="" disabled>בחירת טופס</option>
                    <option *ngFor="let singleForm of forms" [value]="singleForm.id">{{ singleForm.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer">
  <div class="row buttons-container">
    <div class="col-4">
      <button type="submit" class="btn btn-success submit" (click)="onSaveFormButtonClicked('new')">שמירה</button>
    </div>
    <div class="col-4">
      <button type="button" class="btn btn-danger cancel-submit" (click)="closeModal()">ביטול</button>
    </div>
    <div class="col-4">
      <button type="button" class="btn btn-danger delete-field" (click)="onDeleteFieldClicked()">מחיקת שדה</button>
    </div>
  </div>
</div>
