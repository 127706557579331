import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowShellService } from '@digital/app/flow-shell/flow-shell.service';
import { OrganizeKitFilesModalComponent } from '@digital/app/flow/pages/flow/components/_tax/organize-kit-files-modal/organize-kit-files-modal.component';
import { DigitalitRoutes } from '@digital/app/_constants';
import { IFlowEditingPermissions } from '@digital/app/_interfaces/flow-editing-permissions.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormsService } from '../../forms.service';

@Component({
  selector: 'app-digital-form',
  templateUrl: './edit-forms.component.html',
  styleUrls: ['./edit-forms.component.scss']
})
export class EditFormsPageComponent implements OnInit, OnDestroy {
  flowID: number;
  formID?: number; // If given, the page will load and display this form initially
  sectionID?: number; // If given, the page will show only section 7(Tax) else it will be normal
  instance?: number; // If given, the page will load and display this form initially

  private subscriptions = new Subscription();

  isLoading$ = new BehaviorSubject<boolean>(false);

  flow$ = this.formsService.flow$;
  companyInfo$ = this.formsService.companyInfo$;

  forms$ = this.formsService.forms$;
  curretForm$ = this.formsService.currentForm$;

  editingPermissions$ = this.flowShellService.editingPermissions$;

  showMessageBar = false;
  messageBarTitle: string;
  messageBarIcon: string;
  messageBarShowTakeOverButton = false;

  // Lifecycle
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private flowShellService: FlowShellService,
    private formsService: FormsService,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {
    // Fetch the flow id and form id from the url params
    this.flowID = this.route.snapshot.parent.params.id || 1;
    this.formID = parseInt(this.route.snapshot.queryParams.formID, 10);
    this.sectionID = parseInt(this.route.snapshot.queryParams.sectionID, 10);
    this.instance = parseInt(this.route.snapshot.queryParams.instance, 10);

    // Respond to isLoading$ when necessary
    const loadingSubscription = this.formsService.isLoading$.subscribe((isLoading) => this.showLoader(isLoading));
    this.subscriptions.add(loadingSubscription);

    // Get screen required data
    if (this.sectionID) {
      this.formsService.initialSectionID = this.sectionID;
    }
    if (this.formID) {
      this.formsService.initialFormID = this.formID;
    }
    if (this.instance != null) {
      this.formsService.initialInstance = this.instance;
    }
    this.formsService.getFlowCompanyInfoAndForms(this.flowID);

    this.setupPermissionsSubscription();
  }

  ngAfterViewInit(): void {
    document.getElementById('my-body').classList.add('scroll-hidden');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.formsService.resetCurrentForm();
    console.log('edit-forms.component.ts DESTROYED');
    document.getElementById('my-body').classList.remove('scroll-hidden');
  }

  private setupPermissionsSubscription() {
    const permissionsSubscription = this.editingPermissions$.subscribe(
      (permissions) => this.handlePermissionsChange(permissions),
      (error) => console.log(error)
    );
    this.subscriptions.add(permissionsSubscription);
  }

  private handlePermissionsChange(permissions: IFlowEditingPermissions) {
    const flow = this.flow$.value;

    if (!flow) {
      return;
    }

    if (permissions.isLockedForEditing) {
      let message: string;

      if (flow.currentEditor?.id) {
        message = `מצב צפיה בלבד. כעת בעריכה אצל ${flow.currentEditor.fullName.hebrew}`;
      } else {
        message = 'מצב צפיה בלבד';
      }

      this.displayMessageBarWithMessage(message, permissions.canLockForEditing, true);
    } else {
      this.hideMessageBar();
    }
  }

  private showLoader(isActive: boolean) {
    this.isLoading$.next(isActive);
  }

  // Child components delegate methods
  didUpdateCompanyInfoField(value: any) {
    this.formsService.updateCompanyInfoField(value);
  }

  goBackToFlowPage() {
    this.router.navigate([DigitalitRoutes.flow.main, this.flowID]);
  }

  openOrganizeKitModal() {
    const modelRef = this.modal.open(OrganizeKitFilesModalComponent, {
      windowClass: 'organize-kit-files-modal',
      centered: true,
      size: 'xl'
    });
    modelRef.componentInstance.flow = this.flow$.value;
  }

  private displayMessageBarWithMessage(message: string, showTakeOverButton = false, isViewOnly = false) {
    this.showMessageBar = true;
    this.messageBarTitle = message;
    this.messageBarShowTakeOverButton = showTakeOverButton;
    this.messageBarIcon = isViewOnly ? 'read-only_special' : undefined;
  }

  private hideMessageBar() {
    this.showMessageBar = false;
  }

  takeOverFlow() {
    this.flowShellService.setAsCurrentEditorIfNeeded(true);
    this.hideMessageBar();
  }
}
