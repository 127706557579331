<div class="preview-form-modal">
  <div class="container-fluid">
    <button class="modal-close-button" (click)="closeModal()">x</button>
    <div class="modal-header">
      <div class="title-container">
        <div class="row">
          <div class="col text-right vertical-middle">
            <h4 class="title">{{ name }}</h4>
          </div>
          <div class="col text-left">
            <button class="btn btn-borderless download" (click)="downloadFile()">
              <app-svg-icon name="download_special"></app-svg-icon>
              הורדה
            </button>
            <!-- <button class="btn btn-borderless printer" [disabled]="true" (click)="printFile()">
              <app-svg-icon name="print_special"></app-svg-icon>
              הדפסה
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div class="pdf-container">
        <pdf-viewer
          id="pdf-content"
          [src]="file"
          [original-size]="false"
          [fit-to-page]="false"
          [render-text]="true"
        ></pdf-viewer>
      </div>
    </div>
    <div class="modal-footer"></div>
  </div>
</div>
