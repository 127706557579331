import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormsApi } from '../core/api/forms.api';
import { FormFields, OptionField } from './models/form-fields.model';
import { PullDataParams, PullDataParam } from './models/pull-data-params.model';

@Injectable({ providedIn: 'root' })
export class FormFieldsFacade {
  formFields$: BehaviorSubject<FormFields[]> = new BehaviorSubject([new FormFields()]);
  formFieldsImport$: BehaviorSubject<FormFields[]> = new BehaviorSubject([new FormFields()]);
  optionFormFields$: BehaviorSubject<OptionField[]> = new BehaviorSubject([new OptionField()]);
  createdOptionField$: BehaviorSubject<OptionField> = new BehaviorSubject(new OptionField());
  pullDataParams$: BehaviorSubject<PullDataParams> = new BehaviorSubject(new PullDataParams());

  lastUsedFieldPosition$: BehaviorSubject<number> = new BehaviorSubject(0);

  // Lifecycle
  constructor(private _formsApi: FormsApi) {
  }

  getFormFields(formID: number) {
    this._formsApi.getFormFields(formID)
      .subscribe(fields => {
        this.formFields$.next(fields);
      });
  }

  getFormFieldsForImportFields(formID: number) {
    this._formsApi.getFormFields(formID)
      .subscribe(fields => {
        this.formFieldsImport$.next(fields);
      });
  }

  createFormFields(fields: FormFields, formID: number) {
    return this._formsApi.createFormFields(fields, formID);
  }

  createOptionFields(optionField: OptionField, parentFieldID: number) {
    return this._formsApi.createOptionFields(optionField, parentFieldID);
  }

  updateOptionFields(optionField: OptionField, optionFieldID: number) {
    return this._formsApi.updateOptionFields(optionField, optionFieldID);
  }

  updateFormFields(fields: FormFields, fieldID: number) {
    return this._formsApi.updateFormFields(fields, fieldID);
  }

  getSingleFormField(fieldID: number) {
    return this._formsApi.getSingleFormField(fieldID);
  }

  deleteFieldByID(fieldID: number) {
    return this._formsApi.deleteFieldByID(fieldID);
  }

  deleteOptionFieldByID(fieldID: number) {
    return this._formsApi.deleteOptionFieldByID(fieldID);
  }

  getPullDataParams() {
    return this._formsApi.getPullDataParams()
      .subscribe(params => {
        this.pullDataParams$.next(params);
      });
  }

  // Use for update the annotation when option field removed
  resetFields(formID: number) {
    const myNode = document.getElementById('annotation-container-1');
    while (myNode.firstChild) {
      myNode.removeChild(myNode.lastChild);
    }
    this.getFormFields(formID);
  }
}
