import { Component, OnInit } from '@angular/core';
import { LeadQueryResultsState } from '@digital/app/_models/lead-query-result-state';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LeadService } from '@digital/app/core/services/lead.service';

@Component({
  selector: 'lead-queries-results-page',
  templateUrl: './lead-queries-results-page.component.html',
  styleUrls: ['./lead-queries-results-page.component.scss']
})
export class LeadQueriesResultsPage implements OnInit {
  state: Observable<LeadQueryResultsState>;

  constructor(private leadService: LeadService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const paramId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.state = this.leadService.getQueryResults(paramId).pipe(
      map((response) => ({ companies: response.data.finalResults })),
      startWith({ isLoading: true })
    );
  }
}
