import { Deserializable } from "./deserializable.model";

export class User implements Deserializable {
  id: number;
  fullName: {
    english: string;
    hebrew: string;
  };
  nameInitials?: string;
  email: string;
  rank: {
    hebrew: string;
    english: string;
  };
  companies: number[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  getNameInitials(): string {
    return this.fullName.english
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  }
}
