<ng-container *ngFor="let field of fields; let i = index">
  <app-field-text
    *ngIf="
      field.type === fieldType.Text ||
      field.type === fieldType.Number ||
      field.type === fieldType.Calculated ||
      field.type === fieldType.PullData
    "
    [field]="field"
    [fieldIndex]="i"
    [side]="'left'"
    (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
    (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
    (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
    (editFieldButtonClicked)="editFieldButtonClicked.emit($event)"
    (deleteFieldButtonClicked)="deleteFieldButtonClicked.emit($event)"
    [isLockedForEditing]="isLockedForEditing || shouldLockFieldsForEditing"
    (click)="onFieldClicked(field)"
  >
  </app-field-text>

  <app-field-boolean
    [field]="field"
    [fieldIndex]="i"
    [side]="'left'"
    *ngIf="field.type === fieldType.Boolean"
    (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
    (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
    (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
    [isLockedForEditing]="isLockedForEditing || shouldLockFieldsForEditing"
    (click)="onFieldClicked(field)"
  >
  </app-field-boolean>

  <app-field-date
    [field]="field"
    [fieldIndex]="i"
    [side]="'left'"
    *ngIf="field.type === fieldType.Date"
    (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
    (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
    (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
    [isLockedForEditing]="isLockedForEditing || shouldLockFieldsForEditing"
    (click)="onFieldClicked(field)"
  >
  </app-field-date>

  <app-field-single-selection
    [field]="field"
    [fieldIndex]="i"
    [side]="'left'"
    *ngIf="field.type === fieldType.SingleSelection"
    (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
    (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
    (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
    [isLockedForEditing]="isLockedForEditing || shouldLockFieldsForEditing"
    (click)="onFieldClicked(field)"
  >
  </app-field-single-selection>

  <app-field-multiple-selection
    [field]="field"
    [fieldIndex]="i"
    [side]="'left'"
    *ngIf="field.type === fieldType.MultipleSelection"
    (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
    (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
    (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
    [isLockedForEditing]="isLockedForEditing || shouldLockFieldsForEditing"
    (click)="onFieldClicked(field)"
  >
  </app-field-multiple-selection>

  <app-field-free-options
    [field]="field"
    [fieldIndex]="i"
    [side]="'left'"
    *ngIf="field.type === fieldType.FreeOptions"
    (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
    (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
    (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
    [isLockedForEditing]="isLockedForEditing || shouldLockFieldsForEditing"
    (click)="onFieldClicked(field)"
  >
  </app-field-free-options>

  <!-- Nested fields if applicable -->
  <ng-container *ngIf="field.fields && field.fields.length > 0">
    <app-fields-grid
      [nestedFields]="field.fields"
      [shouldLockFieldsForEditing]="shouldLockFieldsForEditing"
      [isLockedForEditing]="isLockedForEditing || shouldLockFieldsForEditing"
      (didUpdateFormFieldValue)="didUpdateFormFieldValue.emit($event)"
      (mouseDidEnterFormField)="mouseDidEnterFormField.emit($event)"
      (mouseDidLeaveFormField)="mouseDidLeaveFormField.emit($event)"
      (createCustomField)="createCustomField.emit($event)"
      (click)="onFieldClicked(field)"
    ></app-fields-grid>
  </ng-container>
  <!-- End nested fields -->
</ng-container>
