import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-open-notes-modal',
  templateUrl: './alert-open-notes-modal.component.html',
  styleUrls: ['./alert-open-notes-modal.component.scss']
})
export class AlertOpenNotesModalComponent implements OnInit {
  @Input() notesNotClosed;
  @Input() nextStage;
  @Input() confirmMessage;
  baseUrl: string;

  constructor(private _activeModal: NgbActiveModal) { 
    this.baseUrl = window.location.origin;
  }

  ngOnInit(): void {
  }


  close(approved) {
    this._activeModal.close(approved);
  }
}
