<div class="consultation-modal">
  <div class="modal-header">
    <h4 class="title">העבר להתייעצות</h4>
    <button class="modal-close-button" (click)="closeModal()">x</button>
  </div>
  <form [formGroup]="consultationGroup" (submit)="createConsultation()">
    <div class="modal-body">
      <p class="consult-with">בחר אדם ספציפי איתו תרצה התייעצות</p>
      <p class="employee-name">שם העובד</p>
      <div class="row">
        <div class="col-6">
          <ng-template #customTemplate let-r="result" class="custom-template">
            <img [src]="'assets/images/user.png'" class="custom-template">
            <ngb-highlight [result]="r"></ngb-highlight>
            <div class="details">
              <label id="city">ישראל - תל אביב, חדשנות</label>
              <label id="title">יועץ</label>
            </div>
          </ng-template>

          <input type="text" class="form-control user-auto-complete" placeholder="התחל להקליד..."
            [ngbTypeahead]="consultationUsersAutoComplete" #instance="ngbTypeahead"
            (selectItem)="selectedUserID($event.item)" formControlName="user" [resultTemplate]="customTemplate"
            placement="bottom-right" required>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="consultation-content">תוכן ההתייעצות</p>
        </div>
      </div>
      <div class="row" *ngIf="!isLoading">
        <div class="col">
          <textarea class="form-control consultation-body" placeholder="על מה תרצה להתייעץ"
            formControlName="consultationContent" required></textarea>
        </div>
      </div>

      <div class="row" *ngIf="isLoading">
        <div class="col">
          <app-loading></app-loading>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <div class="buttons-container">
        <div class="row">
          <div class="col">
            <button class="btn btn-primary" [disabled]="!consultationGroup.valid">שלח</button>
          </div>
          <div class="col">
            <button class="btn btn-outline-primary" (click)="closeModal()">ביטול</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
