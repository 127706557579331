import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalAPI } from '@digital/app/_constants';
import { Categories } from '../flow/models/forms-categories';
import { API } from './api';
import { UpdateSectionFormInstancesDTO } from '../flow/models/section.model';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FlowFormsAPI extends API {
  getDigitalForms(flowID: number): Observable<Categories[]> {
    return this._network.get(GlobalAPI.baseURL, `${GlobalAPI.endpoints.forms.base}/${flowID}/${GlobalAPI.endpoints.forms.allForms}/${GlobalAPI.endpoints.forms.sortedByCategories}`)
      .pipe(map(response => (response.data.categories)));
  }

  addOrDeleteDigitalForms(flowID: number, formIDsToAdd: Array<any>, formIDsToRemove: Array<any>, instancesToRemove: Array<any>, instancesToAdd: Array<any>) {
    return this._network.post(GlobalAPI.baseURL, 
      `${GlobalAPI.endpoints.forms.base}/${flowID}/${GlobalAPI.endpoints.forms.allForms}/${GlobalAPI.endpoints.forms.sortedByCategories}`, 
      { formIDsToAdd, formIDsToRemove, instancesToRemove, instancesToAdd}
    );
  }

  updateSectionFormInstances(flowID: number, dto: UpdateSectionFormInstancesDTO) {
    const endpoint = `${GlobalAPI.endpoints.forms.base}/${flowID}/${GlobalAPI.endpoints.forms.allForms}`;

    return this._network.patch(GlobalAPI.baseURL, endpoint, dto).pipe(map((response) => response.data));
  }

}
