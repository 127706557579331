import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FieldBaseComponent } from '../field-base/field-base.component';
import { EnforceNumberType, FormFieldType, ReconcileNumberType } from '@digital/app/_models/form-field.model';
import { Utils } from '@digital/app/shared/utils';
import { FormsService } from '@digital/app/forms/forms.service';

@Component({
  selector: 'app-field-text',
  templateUrl: './field-text.component.html',
  styleUrls: ['../field-base/field-base.component.scss', './field-text.component.scss']
})
export class FieldTextComponent extends FieldBaseComponent implements OnInit {
  formattedValue: string;
  @ViewChild('valueinput') valueInput: HTMLInputElement;

  fieldInputTagType = ReconcileNumberType.None;

  get placeholderValue(): string {
    if (this.isLeadButtonVariant && !this.isPulledDataField) {
      return 'לחץ כאן על מנת לייצר קריטריון סינון חדש';
    }

    if (this.isLockedForEditing) {
      return '';
    }

    return this.field.type === FormFieldType.Text ? 'הקלד/י ערך' : 'הקלד/י ערך מספרי';
  }

  get shouldDisplayReconcileInputTag(): boolean {
    return this.field.reconcileType !== this.ReconcileNumberType.None && this.field.type === this.FormFieldType.Number;
  }

  /* #region Lifecycle */

  constructor(public _elementRef: ElementRef, public formsService: FormsService) {
    super(_elementRef, formsService);
  }

  ngOnInit() {
    super.ngOnInit();
    this._setup();
  }

  private _setup() {
    const value = this.field.value || this.field.defaultValue || '';
    const type = this.field.type;
    const isDecimal = this.field.isDecimal;

    if (this.field.reconcileType) {
      this.fieldInputTagType = this.field.reconcileType;
    }

    if (
      type === FormFieldType.Number ||
      type === FormFieldType.Calculated ||
      (type === FormFieldType.PullData && isDecimal)
    ) {
      this.formattedValue = this._getFormattedValue();
      this._updateInputFieldTagTypeIfNeeded(value, this.field.reconcileType);
    } else {
      this.formattedValue = value;
    }
  }

  /* #endregion */

  /* #region Methods */

  updateFieldValue($target: any) {
    const tempValue = $target.value;
    const pdfElement = document.getElementById(`pdf-${this.field.id}`) as HTMLInputElement;
    if (pdfElement) {
      pdfElement.value = tempValue;
    }

    const fieldElement = document.getElementById(`field-${this.field.id}`) as HTMLInputElement;
    fieldElement.value = tempValue;

    const valueIsNaN = isNaN(tempValue);
    const fieldTypeIsNumber = this.field.type === FormFieldType.Number;
    if (fieldTypeIsNumber && valueIsNaN) {
      if (fieldElement && fieldElement?.value) {
        fieldElement.value = '';
      }
      if (pdfElement && pdfElement?.value) {
        pdfElement.value = '';
      }
    } else if (fieldTypeIsNumber) {
      $target.classList.add('form-text-input-pdf-number');
    }
    if (tempValue === null || tempValue === undefined || tempValue == '') {
      this._handleEmptyValue();
      return;
    }
    if (this.field.isDecimal || this.field.type === 'number' || this.field.type === 'calculated') {
      this._handleValueOfTypeNumber($target, tempValue);
      return;
    }

    this._handleValueOfTypeText($target);
  }

  /* #endregion */

  /* #region Handling values */

  private _handleEmptyValue() {
    this.field.newValue = null;
    this.field.value = null;
    this.formattedValue = null;
    this.didUpdateFormFieldValue.emit(this.field);
    this._updateInputFieldTagTypeIfNeeded(0, this.field.reconcileType);
  }

  private _handleValueOfTypeText($target: any) {
    this.field.newValue = $target.value;
    this.didUpdateFormFieldValue.emit(this.field);
  }

  private _handleValueOfTypeNumber($target: any, tempValue) {
    let decimalValue = parseFloat(tempValue.replace(/,/g, ''));
    // If value is not decimal, display error
    if (isNaN(decimalValue)) {
      this.error = new Error('נא להכניס ערך מספרי בלבד');
      $target.classList.remove('ng-valid');
      $target.classList.add('ng-invalid');
      return;
    }

    if (this.field.enforceNumberType === EnforceNumberType.Positive && decimalValue < 0) {
      this.error = new Error('לא ניתן להכניס מספר שלילי בשדה זה');
      $target.classList.remove('ng-valid');
      $target.classList.add('ng-invalid');
      return;
    }

    if (this.field.enforceNumberType === EnforceNumberType.Negative && decimalValue > -1) {
      this.error = new Error('לא ניתן להכניס מספר חיובי בשדה זה');
      $target.classList.remove('ng-valid');
      $target.classList.add('ng-invalid');
      return;
    }

    // If this is a subtract (reconcile only) value, emit it as a negative decimal value
    if (
      this.field.reconcileType === ReconcileNumberType.Subtract ||
      this.field.reconcileType === ReconcileNumberType.OtherSubtract ||
      this.field.reconcileType === ReconcileNumberType.Loss
    ) {
      decimalValue = decimalValue * -1;
    }

    $target.classList.remove('ng-invalid');
    this.error = undefined;

    this.field.newValue = decimalValue;
    this.didUpdateFormFieldValue.emit(this.field);
    this.field.value = decimalValue;

    this.formattedValue = this._getFormattedValue();

    this._updateInputFieldTagTypeIfNeeded(decimalValue, this.field.reconcileType);

    // if (decimalValue > 999 || decimalValue < -999) {
    //   this.formattedValue = this._getFormattedValue();
    // } else {
    //   this.formattedValue = this.field.value.toString();
    // }
  }

  private _updateInputFieldTagTypeIfNeeded(value: number, reconcileType: ReconcileNumberType) {
    if (reconcileType !== ReconcileNumberType.Both) {
      return;
    }

    if (Utils.isNullOrEmpty(value)) {
      this.fieldInputTagType = reconcileType;
      return;
    }

    if (value > 0) {
      this.fieldInputTagType = ReconcileNumberType.Add;
    } else if (value < 0) {
      this.fieldInputTagType = ReconcileNumberType.Subtract;
    } else {
      this.fieldInputTagType = ReconcileNumberType.Both;
    }
  }

  /* #endregion */

  /* #region Helpers */

  private _getFormattedValue(): string {
    let value = this.field.value;
    let type = this.field.type;
    if(type == FormFieldType.Calculated){
      value = this.customRound(value)
    }
    // If this is a Subtract type reconcile field, display it as a positive value in the UI (requested by Deloitte's team)
    if (
      value &&
      this.field.isDecimal &&
      (this.field.reconcileType === ReconcileNumberType.Subtract ||
        this.field.reconcileType === ReconcileNumberType.OtherSubtract ||
        this.field.reconcileType === ReconcileNumberType.Loss)
    ) {
      value = Math.abs(value);
    }

    if (this.field.type === FormFieldType.PullData) {
      console.log(this.field);
    }
    return DecimalPipe.prototype.transform(value, undefined, 'he-IL');
  }

  customRound(number: number): number {
    const decimalPart = number - Math.floor(number); // Get the decimal part of the number
    if (decimalPart < 0.5) {
      return Math.floor(number);
    } else {
      return Math.ceil(number);
    }
  }

  /* #endregion */
}
