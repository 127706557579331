import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@libs/authentication/authentication.service';

@Injectable()
export class AuthenticationHeaderInterceptor implements HttpInterceptor {
  AUTHORIZATION_HEADR = 'X-Authorization';

  constructor(private _authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {
      withCredentials: true, // Appends the NTLM credentials
      setHeaders: {        
        // 'X-Frame-Options': 'DENY'
      }
    };

    const user = this._authenticationService.getCurrentUser();
    if (user && user.accessToken) {
      headers.setHeaders[this.AUTHORIZATION_HEADR] = user.accessToken;
    }

    request = request.clone(headers);

    return next.handle(request);
  }
}
