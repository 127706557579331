import { Component, OnInit, QueryList, ContentChildren, AfterContentInit, Input } from '@angular/core';
import { FlowTabComponent } from '../flow-tab/flow-tab.component';

@Component({
  selector: 'app-flow-tabs',
  templateUrl: './flow-tabs.component.html',
  styleUrls: ['./flow-tabs.component.scss']
})
export class FlowTabsComponent implements AfterContentInit {
  @ContentChildren(FlowTabComponent) tabs: QueryList<FlowTabComponent>;
  @Input() sticky = false;

  constructor() {}

  ngAfterContentInit() {
    this.initializeTabs();
  }

  initializeTabs() {
    const activeTabs = this.tabs.filter((tab: FlowTabComponent) => tab.isActive);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(selectedTab: FlowTabComponent) {
    this.tabs.toArray().forEach((tab) => (tab.isActive = false));
    selectedTab.isActive = true;
  }
}
