import { Component, OnInit } from '@angular/core';
import { DuplicateForm } from '../../models/form.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreateFormFacade } from '../../create-form.facade'; 
import { AdminPanelRoutes } from '@admin/app/shared/constants';


@Component({
  selector: 'app-duplicate-form',
  templateUrl: './duplicate-form.component.html',
  styleUrls: ['./duplicate-form.component.scss']
})
export class DuplicateFormComponent implements OnInit {
  duplicateForm: FormGroup;
  fileToUpload: File = null;
  isLoading = false;

  constructor(private _createFormFacade: CreateFormFacade, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this._setupFormBuilder();
  }

  // Setup
  private _setupFormBuilder() {
    this.duplicateForm = this._formBuilder.group({
      formTaxID: ['', Validators.required],
      newFormYear: ['', Validators.required],
      year: ['', Validators.required],
      file: [null, Validators.required],
    });
  }

  onSubmitduplicateFormButtonClicked() {
    if (!this.duplicateForm.valid) { return; }
    this.isLoading = true;
    const duplicateForm = new DuplicateForm();
    duplicateForm.formTaxID = this.duplicateForm.get('formTaxID').value;
    duplicateForm.year = this.duplicateForm.get('year').value;
    duplicateForm.newFormYear = this.duplicateForm.get('newFormYear').value;
    duplicateForm.filePath = this.fileToUpload;

    this._createFormFacade.duplicateForm(duplicateForm)
    .then((result)=>{
      this.isLoading = false;
    })
    .catch((error)=>{
      throw error;
    });
  }

  handleFileUploadChange(files: FileList) {
    this.fileToUpload = files.item(0);
  }


  get homeRoute () {
    return '/' + AdminPanelRoutes.home; 
  }
  
}
