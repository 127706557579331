import { Injectable } from '@angular/core';
import { ApiService } from '@libs/core/api/api.service';
import { UserEvent } from '@libs/core/network/model/user-event.model';
import UserEvents from '@libs/core/network/model/users-events.model';


@Injectable({
  providedIn: 'root'
})
export class UserEventsService {

  constructor(private api: ApiService) { }

  async createLogEvent(userID: number, actionTaken: string, flowID?: number, companyID?: number) {
    try {
      const response = await this.api.createLogEvent(userID, actionTaken, flowID, companyID);
      return response;
    } catch (error) {

    }
  }

  async getAllFlowLogs(flowID: number, page: number, completion: ((events: UserEvents) => void)) {
    try {
      const response = await this.api.getAllFlowLogs(flowID, page);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async getAllUserLogs(userID: number, page: number, completion: ((events: UserEvents) => void)) {
    try {
      const response = await this.api.getAllUserLogs(userID, page);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

}

