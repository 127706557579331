<div class="header-container modal-header">
  <h4>שיתוף שאילתה</h4>
  <button class="modal-close-button" (click)="closeModal(true)">x</button>
</div>
<div class="body-container modal-body">
  <p>הכנסת שם המשתמש לשיתוף השאילתה</p>
  <form [formGroup]="shareFlowForm" (submit)="submitOnShare()">
    <ng-template #customTemplate let-r="result" class="check-test">
      <img [src]="'assets/images/user.png'" class="img-template" />
      <ngb-highlight [result]="r.fullName.hebrew" class="highlight-title"></ngb-highlight>
    </ng-template>
    <input
      formControlName="shareWithField"
      [formControl]="shareWithField"
      name="shareWithField"
      #instance="ngbTypeahead"
      type="text"
      placeholder="חיפוש משתמש"
      class="form-control"
      [ngbTypeahead]="deloitteUsersAutoComplete"
      [inputFormatter]="formatter"
      (selectItem)="onSelectUser($event.item)"
      [resultTemplate]="customTemplate"
      placement="bottom-right"
      value="{{ selectedUser?.fullName.hebrew }}"
    />

    <div class="footer-container">
      <button class="btn btn-danger" type="submit">שיתוף</button>
    </div>
  </form>
</div>
