import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Modules
import { BlogRoutingModule } from './blog-routing.module';
import { ViewsModule as GlobalViewsModule } from '@libs/views/views.module';
import { SharedModule } from '@digital/app/shared/shared.module';

// Components
import { components } from './index';


@NgModule({
  declarations: [
    components
  ],
  imports: [
    CommonModule,
    BlogRoutingModule,
    GlobalViewsModule,
    SharedModule,
    NgbModule,
  ]
})
export class BlogModule { }
