import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FlowShellService } from './flow-shell.service';

@Component({
  selector: 'app-flow-shell',
  template: `<router-outlet></router-outlet>`
})
export class FlowShellComponent implements OnInit, OnDestroy {
  flow$ = this.shellService.flow$;
  editingPermissions$ = this.shellService.editingPermissions$;

  rtInterval?: ReturnType<typeof setInterval>;
  readonly rtIntervalTime = 5 * 1500;

  constructor(private route: ActivatedRoute, private shellService: FlowShellService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params) {
        const paramIdentifier = 'id';
        const flowID = +params[paramIdentifier];
        this.shellService.getFlowByID(flowID);
      }
    });

    this.setupRealTimeDataInterval();
    this.setupLogs();
  }

  ngOnDestroy(): void {
    this.clearRealTimeDataInterval();
    this.shellService.resetFlowData();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event: Event) {
    this.clearRealTimeDataInterval();
    this.shellService.removeAsCurrentEditorIfNeeded();
  }

  private setupRealTimeDataInterval() {
    this.rtInterval = setInterval(() => {
      if (this.flow$.value) {
        this.shellService.getFlowRealTimeData(this.flow$.value.id);
      }
    }, this.rtIntervalTime);
  }

  private clearRealTimeDataInterval() {
    if (this.rtInterval) {
      clearInterval(this.rtInterval);
      this.rtInterval = undefined;
    }
  }

  private setupLogs() {
    this.editingPermissions$.subscribe((permissions) => console.log(permissions));
    this.flow$.subscribe((flow) => {
      if (flow) {
        if (flow.currentEditor?.id) {
          console.log('Current Editor', flow.currentEditor?.fullName.hebrew);
        } else {
          console.log('Current Editor', 'None!');
        }
      }
    });
  }
}
