import { Deserializable } from '@digital/app/_models/deserializable.model';

export class CompanyInfo implements Deserializable {
  companyID?: number;
  year?: number;
  phone?: number;
  fax?: number;
  email?: string;
  address?: string;
  mailingAddress?: string;
  accountancyAddress?: string;
  assessmentOfficeAddress?: string;
  taxCaseNumber?: number;
  vatCaseNumber?: number;
  bankAccountNumber?: number;
  bankBranchNumber?: number;
  bankNumber?: number;
  companyType?: string;
  publicOrPrivate?: string;
  occupation?: any;
  firmName?: string;
  firmExpertise?: string;
  firmCompanyID?: string;
  firmAddress?: string;
  firmPhone?: string;
  nameSigningCustomer?: string;
  roleSigningCustomer?: string;
  createdAt?: Date;
  updatedAt?: Date;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
