export enum FormType {
  Form = 'form',
  Reconcile = 'reconciliation',
  ContentLetter = 'contentLetter'
}

export const FormTypeLabel: Record<FormType, string> = {
  [FormType.Form]: "טופס דיגטלי",
  [FormType.Reconcile]: "דוח התאמה",
  [FormType.ContentLetter]: "מכתב נלווה",
};

export class DuplicateForm{
  formTaxID: number;
  year: number;
  newFormYear: number;
  filePath?: File;
}

export class Form {
  id: number;
  type: FormType;
  formTaxID: number;
  name: string;
  formYear: number;
  sectionID: number;
  state: string;
  filePath?: File;
  createdAt?: Date;
  updatedAt?: Date;
}

export class FilePath {
  filePath: string;
}
