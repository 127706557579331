<div
  class="container-fluid"
  *ngIf="!isConsultation?.consult.isActive && !flow?.isAwaitingDeletion && showFooter() && !this.currentUserEditor"
>
  <div class="dynamic-footer">
    <div class="row d-flex h-100">
      <div class="col align-self-center">
        <!-- <button
          class="btn btn-outline-primary consultation-btn"
          *ngIf="
            flow?.stage.id === FlowStage.WORK_ON_TAX_FLOW ||
            flow?.stage.id === FlowStage.MANAGER_APPROVAL ||
            flow?.stage.id === FlowStage.PARTNER_APPROVAL
          "
          (click)="openConsultationModal()"
        >
          <app-svg-icon name="edit-profile-blue_special" class="edit-profile-icon"></app-svg-icon>
          {{ workOnTaxStage.Consultation }}
        </button> -->

        <button
          class="btn btn-outline-primary"
          (click)="setNewStage('next', null, true)"
        >
          <app-svg-icon name="arrow-left_special"></app-svg-icon>
          {{ managerApproveStage.BackToQuality }}
        </button>

        <button
          class="btn btn-outline-primary"
          *ngIf="flow?.stage.id === FlowStage.QUALITY_CONTROL"
          (click)="setNewStageWithWarning('previous', FlowStage.MANAGER_APPROVAL, FlowStage.QUALITY_CONTROL)"
        >
          <app-svg-icon name="arrow-left_special"></app-svg-icon>
          {{ managerApproveStage.BackToManager }}
        </button>
      </div>

      <div class="col text-left align-self-center">
        <button
          class="btn btn-primary"
          *ngIf="flow?.stage.id === FlowStage.WORK_ON_TAX_FLOW"
          (click)="setNewStage('next')"
        >
          {{ isExternalUser ? workOnTaxStage.ManagerApproveForExternalUser : workOnTaxStage.MangerApprove }}
        </button>

        <button
          class="btn btn-outline-primary reject"
          *ngIf="
            flow?.stage.id === FlowStage.MANAGER_APPROVAL ||
            flow?.stage.id === FlowStage.PARTNER_APPROVAL ||
            flow?.stage.id === FlowStage.IRS_CONFIRMATION ||
            flow?.stage.id === FlowStage.QUALITY_CONTROL
          "
          (click)="setNewStage('previous')"
        >
          <app-svg-icon name="close-blue_special"></app-svg-icon>
          {{ managerApproveStage.Reject }}
        </button>

        <button
          class="btn btn-primary checkmark"
          *ngIf="flow?.stage.id === FlowStage.PARTNER_APPROVAL"
          (click)="setNewStageWithWarning('next', FlowStage.QUALITY_CONTROL, FlowStage.PARTNER_APPROVAL)"
        >
          <app-svg-icon name="checkmark_special"></app-svg-icon>
          {{ managerApproveStage.Approve }}
        </button>

        <button
          class="btn btn-primary checkmark"
          *ngIf="flow?.stage.id === FlowStage.MANAGER_APPROVAL || flow?.stage.id === FlowStage.QUALITY_CONTROL"
          (click)="setNewStage('next')"
        >
          <app-svg-icon name="checkmark_special"></app-svg-icon>
          {{ managerApproveStage.Approve }}
        </button>

        <button
          class="btn btn-outline-primary reject"
          *ngIf="flow?.stage.id === FlowStage.SIGNATURE_IMPLEMENTATION"
          (click)="setNewStageWithWarning('previous', FlowStage.WORK_ON_TAX_FLOW, FlowStage.SIGNATURE_IMPLEMENTATION)"
        >
          <app-svg-icon name="close-blue_special"></app-svg-icon>
          {{ signatureImplementation.Reject }}
        </button>

        <button
          class="btn btn-primary checkmark"
          *ngIf="flow?.stage.id === FlowStage.SIGNATURE_IMPLEMENTATION"
          (click)="setNewStageWithWarning('next', FlowStage.CLIENT_SIGNATURE, FlowStage.SIGNATURE_IMPLEMENTATION)"
        >
          <app-svg-icon name="checkmark_special"></app-svg-icon>
          {{ signatureVerification.Approve }}
        </button>

        <button
          class="btn btn-outline-primary reject"
          *ngIf="flow?.stage.id === FlowStage.CLIENT_SIGNATURE"
          (click)="setNewStageWithWarning('previous', FlowStage.WORK_ON_TAX_FLOW, FlowStage.CLIENT_SIGNATURE)"
        >
          <app-svg-icon name="close-blue_special"></app-svg-icon>
          {{ isExternalUser ? clientOrPartnerSignature.RejectForExternalUser : signatureImplementation.Reject }}
        </button>

        <button
          class="btn btn-primary checkmark"
          *ngIf="flow?.stage.id === FlowStage.CLIENT_SIGNATURE"
          (click)="setNewStageWithVerifications('next', FlowStage.PARTNER_SIGNATURE, FlowStage.CLIENT_SIGNATURE)"
        >
          <app-svg-icon name="checkmark_special"></app-svg-icon>
          {{ clientOrPartnerSignature.Approve }}
        </button>

        <button
          class="btn btn-outline-primary reject"
          *ngIf="flow?.stage.id === FlowStage.PARTNER_SIGNATURE"
          (click)="setNewStageWithWarning('previous', FlowStage.WORK_ON_TAX_FLOW, FlowStage.PARTNER_SIGNATURE)"
        >
          <app-svg-icon name="close-blue_special"></app-svg-icon>
          {{ signatureImplementation.Reject }}
        </button>

        <button
          class="btn btn-primary checkmark"
          *ngIf="flow?.stage.id === FlowStage.PARTNER_SIGNATURE"
          (click)="setNewStageWithVerifications('next', FlowStage.IRS_CONFIRMATION, FlowStage.PARTNER_SIGNATURE)"
        >
          <app-svg-icon name="checkmark_special" class="checkmark-icon"></app-svg-icon>
          {{ clientOrPartnerSignature.Approve }}
        </button>

        <button
          class="btn btn-primary checkmark"
          *ngIf="flow?.stage.id === FlowStage.IRS_CONFIRMATION"
          (click)="setNewStage('next')"
        >
          <app-svg-icon name="checkmark_special"></app-svg-icon>
          {{ clientOrPartnerSignature.Approve }}
        </button>

        <button
          class="btn btn-primary checkmark"
          *ngIf="flow?.stage.id === FlowStage.WORK_ON_FINANCIAL_FLOW || flow?.stage.id === FlowStage.EQCR_IF_NEEDED"
          (click)="setNewStage('next')"
        >
          <app-svg-icon name="checkmark_special" class="checkmark-icon"></app-svg-icon>
          {{ workOnTaxStage.Approve }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="end-page-space"></div>
