import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from '@digital/app/home/pages/home/home.component';
import { GlobalRoutes } from '@libs/constants';
import { BrowserGuard } from '@libs/guards/browser.guard';

// Routes
const homeRoutes: Routes = [
  {
    path: GlobalRoutes.dtax,
    component: HomeComponent,
    canActivate: [MsalGuard, BrowserGuard],
    data: { returnUrl: GlobalRoutes.dtax }
  }
];

@NgModule({
  imports: [RouterModule.forChild(homeRoutes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {}
