<div class="header-container modal-header">
    <h4>מחיקת שאילתה</h4>
    <button class="modal-close-button" (click)="onClose()">x</button>
</div>
<div class="body-container modal-body">
    <p>פעולה זו תמחק את השאילתה לצמיתות</p>
</div>
<div class="footer-container">
    <button class="btn btn-danger" (click)="onDelete()">
        מחק
    </button>
</div>
