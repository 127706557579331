import { Deserializable } from '@libs/core/network/model/deserializable.model';

export interface User {
  id: number;
  deloitteID: number;
}

export interface FullName {
  english: string;
  hebrew: string;
}

export class CreateUserEvent {
  flowID: number;
  companyID: number;
  actionTaken: string;
}

export class UserEvent implements Deserializable {
  id: number;
  actionTaken: string;
  createdAt: Date;
  user: User;
  fullName: FullName;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
