import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxMentionsModule, ChoiceWithIndices } from 'ngx-mentions';
import { Note } from '../../models/note';
import { FormsService } from '../../forms.service';
import { UserMention } from '../../models/user-mention';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NoteComment } from '../../models/note-comment';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { forEach } from 'lodash';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent implements OnInit {
  @Output() noteAdded: EventEmitter<any> = new EventEmitter<any>();
  @Input() tempNote: Note = null;
  @Input() mentionUsers: UserMention[] = null;
  tmpMentionUsers: UserMention[] = null;
  mentions: ChoiceWithIndices[] = [];
  selectedChoices: ChoiceWithIndices[] = [];
  searchRegexp = new RegExp('^([-&.\\w]+ *){0,3}$');
  textCtrl: FormControl = new FormControl('');
  newNote: string = ''; //Note = null;
  parentCommentStatusBasedStyles = {
    bgColor: '#d4ebff',
    color: '#3380c4',
    bdColor: '#87ceeb'
  };
  notes: Note[];

  constructor(private formsService: FormsService, private sanitizer: DomSanitizer, private authService: AuthenticationService) {}

  ngOnInit(): void {}

  addNote() {
    if (this.textCtrl.value.trim() !== '') {
      this.tempNote.mentionUsersList = this.tempNote.mentionUsersList || '';
      const sendEmailTo = this.mentions.map((obj) => obj.choice.email);
      sendEmailTo.forEach( email => {
        if (!this.tempNote.mentionUsersList.includes(email))
        this.tempNote.mentionUsersList += email + ';'
      })
      if (this.tempNote.id) {
        const eventCommentData: NoteComment = {
          noteId: this.tempNote.id,
          content: this.textCtrl.value,
          mentionUsersList: this.tempNote.mentionUsersList
        }
        this.formsService.addCommentToNote(eventCommentData, sendEmailTo);
        this.noteAdded.emit(eventCommentData);
      } else {
        const eventNoteData: Note = {
          x: this.tempNote.x,
          y: this.tempNote.y,
          page: this.tempNote.page,
          content: this.textCtrl.value,
          mentionUsersList: this.tempNote.mentionUsersList
        };
        this.formsService.addNote(eventNoteData, sendEmailTo);
        this.noteAdded.emit(eventNoteData);
      }
      this.newNote = '';
    }
  }

  getChoiceLabel = (user: UserMention): string => {
    return `@${user.name}`;
  };

  async loadChoices(searchTerm: string): Promise<UserMention[]> {
    const users = this.mentionUsers;

    this.tmpMentionUsers = users.filter((user) => {
      const alreadyExists = this.mentions.some((m) => m.choice.name === user.name);
      return user.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 && !alreadyExists  && user.id !== this.authService.getCurrentUser().id;
    });
    return this.tmpMentionUsers;
  }

  onSelectedChoicesChange(choices: ChoiceWithIndices[]): void {
    this.mentions = choices;
    this.getFormattedHighlightText(this.textCtrl.value, this.mentions, this.parentCommentStatusBasedStyles);
  }

  private getFormattedHighlightText(
    content: string,
    ranges: any[],
    parentCommentStatusBasedStyles: {
      bgColor: string;
      color: string;
      bdColor: string;
    }
  ) {
    let highlightedContent = content;
    let replaceContentIndex = 0;
    ranges.forEach((range) => {
      const start = range.indices.start;
      const end = range.indices.end;
      const highlightedText = content.substring(start, end);
      const highlighted = `<span style="background-color: ${parentCommentStatusBasedStyles.bgColor};color: ${parentCommentStatusBasedStyles.color};white-space: nowrap;border: 1px solid ${parentCommentStatusBasedStyles.bdColor};padding: 0 3px;border-radius: 3px;">${highlightedText}</span>`;
      const newReplace = highlightedContent.substring(replaceContentIndex).replace(highlightedText, highlighted);
      highlightedContent =
        replaceContentIndex === 0 ? newReplace : highlightedContent.substring(0, replaceContentIndex) + newReplace;
      replaceContentIndex = highlightedContent.lastIndexOf('</span>') + 7;
    });

    highlightedContent = highlightedContent.replace(/\n/g, '<br>');
  }

  getDisplayLabel = (user: UserMention): string => {
    return user.name;
  };
}
