import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsModule as GlobalViewsModule } from '@libs/views/views.module';
import { SharedModule } from '@digital/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as allComponents from './index';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ChangeFlowInfoModalComponent } from '@digital/app/flow/pages/flow/components/_general/change-flow-info-modal/change-flow-info-modal.component';
import { DeleteFlowModalComponent } from '@digital/app/flow/pages/flow/components/_general/delete-flow-modal/delete-flow-modal.component';
import { KitModalComponent } from '@digital/app/flow/pages/flow/components/_tax/kit-modal/kit-modal.component';
import { FileManagmentTabComponent } from './pages/flow/components/_general/file-managment-tab/file-managment-tab.component';
import { FlowHistoryTabComponent } from './pages/flow/components/_general/flow-history-tab/flow-history-tab.component';
import { FlowEditModeComponent } from './pages/flow/components/_general/flow-edit-mode/flow-edit-mode.component';
import { FlowSectionActionComponent } from './pages/flow/components/_general/flow-section-action/flow-section-action.component';
import { FlowAddCommentComponent } from './pages/flow/components/_general/flow-comment/components/flow-add-comment/flow-add-comment.component';
import { FlowViewCommentComponent } from './pages/flow/components/_general/flow-comment/components/flow-view-comment/flow-view-comment.component';
import { DynamicFooterComponent } from './pages/flow/components/_general/dynamic-footer/dynamic-footer.component';
import { FlowCommentComponent } from './pages/flow/components/_general/flow-comment/flow-comment.component';
import { SignatureVerificationStageComponent } from './pages/flow/components/_general/signature-verification-stage/signature-verification-stage.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PartnerSignatureStageComponent } from './pages/flow/components/_general/partner-signature-stage/partner-signature-stage.component';
import { ManagerApproveStageComponent } from './pages/flow/components/_general/manager-approve-stage/manager-approve-stage.component';
import { PreviewFormModalComponent } from './pages/flow/components/_general/preview-form-modal/preview-form-modal.component';
import { EndFlowStageComponent } from './pages/flow/components/_general/end-flow-stage/end-flow-stage.component';
import { RouterModule } from '@angular/router';
import { ConsultationModalComponent } from './pages/flow/components/_tax/consultation-modal/consultation-modal.component';
import { OrganizeKitFilesModalComponent } from './pages/flow/components/_tax/organize-kit-files-modal/organize-kit-files-modal.component';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragScrollModule } from 'cdk-drag-scroll';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { ConsultationStateComponent } from './pages/flow/components/_tax/consultation-state/consultation-state.component';
import { PartnerApproveStageComponent } from './pages/flow/components/_general/partner-approve-stage/partner-approve-stage.component';
import { FinancialConsultationStageComponent } from './pages/flow/components/_financial/financial-consultation-stage/financial-consultation-stage.component';
import { FlowPermissionsTabComponent } from './pages/flow/components/_general/flow-permissions-tab/flow-permissions-tab.component';

@NgModule({
  declarations: [
    allComponents.components,
    FileManagmentTabComponent,
    FlowHistoryTabComponent,
    FlowEditModeComponent,
    FlowSectionActionComponent,
    FlowAddCommentComponent,
    FlowViewCommentComponent,
    DynamicFooterComponent,
    FlowCommentComponent,
    SignatureVerificationStageComponent,
    PartnerSignatureStageComponent,
    ManagerApproveStageComponent,
    PreviewFormModalComponent,
    EndFlowStageComponent,
    ConsultationModalComponent,
    OrganizeKitFilesModalComponent,
    ConsultationStateComponent,
    PartnerApproveStageComponent,
    FinancialConsultationStageComponent,
    FlowPermissionsTabComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    GlobalViewsModule,
    SharedModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    DragulaModule,
    DragDropModule,
    DragScrollModule,
    AngularSignaturePadModule
  ],
  exports: [allComponents.components],
  entryComponents: [
    ChangeFlowInfoModalComponent,
    DeleteFlowModalComponent,
    KitModalComponent,
    PreviewFormModalComponent,
    ConsultationModalComponent
  ],
  providers: [DragulaService]
})
export class FlowModule {}
