import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-lead-logical-operator-button',
  templateUrl: './lead-logical-operator-button.component.html',
  styleUrls: ['./lead-logical-operator-button.component.scss']
})
export class LeadLogicalOperatorButtonComponent implements OnInit {
  operators: string[] = ['AND', 'OR'];
  @Input() form: FormControl;
  @Input() id: string;

  constructor() { }

  ngOnInit(): void { }

  isSelectedOperator(operator: string): boolean {
    return operator === this.form.value;
  }

  get operatorId(): string {
    return `logical-operator-${this.id}`;
  }

}
