export enum FlowStage {
  // Tax flow
  WORK_ON_TAX_FLOW = 1,
  MANAGER_APPROVAL = 2,
  PARTNER_APPROVAL = 3,
  QUALITY_CONTROL = 4,
  SIGNATURE_IMPLEMENTATION = 5,
  CLIENT_SIGNATURE = 6,
  PARTNER_SIGNATURE = 7,
  IRS_CONFIRMATION = 8,

  // Financial flow
  WORK_ON_FINANCIAL_FLOW = 10,
  EQCR_IF_NEEDED = 11,
  PARTNER_APPROVAL_FOR_CONSULTATION = 12,
  EQCR_APPROVAL = 13,
  PRO_CONSULTATION = 14,

  // General
  FINISHED = 9
}
