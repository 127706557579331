<div class="modal-header">
  <h4 class="title">שימו לב!!</h4>
  <button class="modal-close-button" (click)="close(false)">
    <span>x</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="confirmMessage">{{ confirmMessage }}</p>
  <p *ngIf="nextStage !== 'הטמעת חתימות'">
    ישנם {{ notesNotClosed.numberOfRows }} הערות פתוחות בתהליך, לא יהיה ניתן להעביר את הדוח לשלב הטמעת חתימות ללא סגירה
    של כל ההערות הפתוחות בטפסי הערכה.
  </p>
  <p *ngIf="nextStage === 'הטמעת חתימות'">
    ישנם {{ notesNotClosed.numberOfRows }} הערות פתוחות בתהליך, לא ניתן להעביר את הדוח לשלב הטמעת חתימות ללא סגירה של כל
    ההערות הפתוחות בטפסי הערכה.
  </p>
  <p *ngIf="nextStage === 'הטמעת חתימות'">אם מדובר בהערות שנפתחו על ידי צוות בקרת איכות יש לסגור את הערות בשלב זה.</p>
  <p *ngIf="nextStage !== 'הטמעת חתימות'">האם אתה בטוח שברצונך להעביר את התהליך לשלב {{ nextStage }}?</p>
  <div class="url-list" *ngFor="let note of notesNotClosed.rows">
    <a
      href="{{ baseUrl }}/#/flow/{{ note.flowId }}/forms?formID={{ note.formId }}&sectionID={{
        note.Form.sectionID
      }}&instance=0"
      target="_blank"
      >{{ note.Form.name }}</a
    >
  </div>
</div>
<div class="modal-footer">
  <button *ngIf="nextStage !== 'הטמעת חתימות'" type="button" class="btn btn-primary" (click)="close(true)">
    התעלם והמשך
  </button>
  <button type="button" class="btn btn-primary" (click)="close(false)">סגירה</button>
</div>
