<div class="top-border"></div>
<div class="post container-fluid" *ngIf="post">
  <div class="page-header">
    <div class="row">
      <div class="col">
        <p class="date">{{ post.createdAt | parseDatePipe }}</p>
        <h4 class="title">{{ post.title }}</h4>
        <p class="author">{{ post.user.fullName.hebrew }}</p>
      </div>
    </div>
  </div>
  <div class="content">
    <p class="body">{{ post.body }}</p>
  </div>
  <div class="row">
    <div class="col">
      <div class="back-button-container">
        <a [routerLink]="blogRoute" class="btn btn-outline-primary">חזרה לתקשורים מקצועיים</a>
      </div>
    </div>
  </div>
</div>