<section class="flow-section file-managment">
  <div class="title-container">
    <div class="row">
      <div class="col text-right">
        <h4 class="title">
          <button class="back-button" *ngIf="isOpenFiles" (click)="backToYears()">
            <app-svg-icon name="arrow-right_special"></app-svg-icon>
          </button>
          {{ title }}
        </h4>
      </div>
      <div class="col">
        <div *ngIf="isLoading">
          <app-loading></app-loading>
        </div>
      </div>
      <div class="col text-left" *ngIf="flow?.id === filesFlowID">
        <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" />
        <button class="btn btn-borderless upload-file" *ngIf="isOpenFiles" (click)="fileInput.click()">
          <app-svg-icon name="plus_special"></app-svg-icon>
          העלה קובץ
        </button>
      </div>
    </div>
  </div>
  <div class="content-container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ fileTitle }}
          </th>
          <th scope="col">עודכן לאחרונה</th>
        </tr>
      </thead>
      <ng-container *ngFor="let year of years">
        <tbody *ngIf="year.year !== flow.year">
          <td class="years">
            <button (click)="onYearClicked(year)">
              <app-svg-icon name="link_special"></app-svg-icon>
              <div id="space-between-icon"></div>
              מעבר לתיק משנת - {{ year.year }}
            </button>
          </td>
          <td>{{ year.updatedAt | parseDatePipe: 'time' }}</td>
        </tbody>
      </ng-container>

      <!-- <ng-container *ngIf="documentation">
        <tbody *ngFor="let document of documentation">
          <td>
            <button class="documentation" (click)="downloadFile(document)">
              <app-svg-icon
                name="file-empty_special"
                class="file-empty_special"
                ngbTooltip="להורדת המסמך"
              ></app-svg-icon>
              {{ document.name.split('.').slice(0, -1).join('.') }}
            </button>
          </td>
          <td>{{ document.updatedAt | parseDatePipe: 'time' }} ע"י {{ document.username }}</td>
        </tbody>
      </ng-container> -->
    </table>
    <ng-content select="[content]"></ng-content>
  </div>
</section>
