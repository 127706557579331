<div
  id="form-field-{{ field.id }}"
  class="form-field"
  [class.nth-child-background]="isSecondaryBackgroundColor"
  [class.custom-field-background]="field.isCustom"
  *ngIf="side === 'right'"
>
  <ng-container *ngIf="fieldMode === FormFieldMode.Initial">
    <label class="form-check-label form-field-title" for="field-{{ field.id }}">
      <span class="field-identifier field-number" *ngIf="field.number">{{ field.number }}.</span>
      <span class="field-identifier field-taxCode" *ngIf="field.taxCode">[{{ field.taxCode }}]</span>
      <span [innerHTML]="field.name"></span>
      <app-tooltip-indicator [tooltipTitle]="field.note"></app-tooltip-indicator>
    </label>
    <div class="input-container">
      <ng-container *ngIf="shouldDisplayReconcileInputTag">
        <app-field-input-tag [type]="fieldInputTagType"></app-field-input-tag>
      </ng-container>
      <input
        #valueinput
        class="form-control form-text-input"
        type="text"
        name="field-{{ field.id }}"
        id="field-{{ field.id }}"
        [value]="formattedValue"
        (change)="updateFieldValue($event.target)"
        [tabindex]="field.position"
        [disabled]="isLockedForEditing"
        [placeholder]="placeholderValue"
        [ngClass]="{ 'input-lead__button': isLeadButtonVariant && isLockedForEditing && isPulledDataField }"
      />
    </div>
    <ng-container *ngIf="field.description && field.description.length > 0">
      <p
        class="form-field-description"
        [class.emphasize]="shouldEmphasizeDescription"
        [innerHTML]="
          isLeadButtonVariant && isLockedForEditing
            ? 'שדה נמשך, על מנת לסנן לפי שדה זה נא להשתמש במקור'
            : field.description
        "
      ></p>
    </ng-container>
    <p class="form-field-error" *ngIf="this.error">{{ error.message }}</p>
    <ng-container *ngIf="shouldDisplayCustomFieldControls">
      <app-field-custom-controls
        [field]="field"
        (editFieldButtonClicked)="startEditMode()"
        (deleteFieldButtonClicked)="deleteFieldButtonClicked.emit($event)"
      ></app-field-custom-controls>
    </ng-container>
  </ng-container>
  <!--  -->
  <ng-container *ngIf="fieldMode === FormFieldMode.Edit && field.isCustom">
    <label class="form-check-label form-field-title" for="field-{{ field.id }}"> שם השדה </label>
    <div class="input-container">
      <input
        class="form-control form-text-input"
        type="text"
        name="field-{{ field.id }}"
        placeholder="הקלד/י ערך"
        [value]="newFieldName"
        [(ngModel)]="newFieldName"
        (keyup.enter)="saveEditMode()"
      />
    </div>
    <p class="form-field-error" *ngIf="this.error">{{ error.message }}</p>
    <div class="buttons-container">
      <button class="btn btn-outline-primary" (click)="cancelEditMode()">ביטול</button>
      <button class="btn btn-primary" (click)="saveEditMode()">שמירה</button>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="side === 'left'">
  <input
    #valueinput
    class="form-text-input-pdf"
    type="text"
    name="field-{{ field.id }}"
    id="pdf-{{ field.id }}"
    [value]="formattedValue"
    [style.width.px]="field.width"
    [style.height.px]="field.height"
    [ngStyle]="{ transform: 'translate(' + field.positionX + 'px, ' + field.positionY + 'px)' }"
    (change)="updateFieldValue($event.target)"
    [tabindex]="field.position"
    [disabled]="isLockedForEditing"
    data-toggle="tooltip"
    title="{{ field.description }}"
  />
</ng-container>
