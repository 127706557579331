import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

// Dependencies
import { environment } from '@digital/environments/environment';
import { GlobalRoutes } from '@libs/constants';

@Component({
  selector: 'app-error-page-base',
  template: ``,
  styleUrls: ['./error-page-base.component.scss']
})
export class ErrorPageBaseComponent implements OnInit {
  constructor(protected router: Router) {}
  ngOnInit(): void {}

  navigateToLoginPage() {
    this.router.navigate([GlobalRoutes.dtax]);
  }

  navigateToHomePage() {
    this.router.navigate(['/']);
  }

  openTicketServiceNow() {
    window.open(
      'https://deloitteus.service-now.com/israelsp?id=sc_cat_item_israel&sys_id=88a876afdb58555441aa8fd33996194e&business_service_sys_id=619e2e964f0aa788f7a9cf5d0210c73d'
    );
  }
}
