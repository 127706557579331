import { Injectable } from '@angular/core';
import { NetworkService } from '@libs/core/network/network.service';
import { environment } from '@digital/environments/environment';
import { Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import UserEvents from '@libs/core/network/model/users-events.model';
import { StageFiles } from '@digital/app/flow/models/stage-files.model';
import { FixedFile, SectionFiles } from '@digital/app/flow/models/sections-files.model';
import Documentation from '@digital/app/flow/models/documentation';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { Files } from '@digital/app/flow/models/sections-files.model';
import { UploadFileType } from '@libs/enums/upload-file-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl: string;
  private USERS_API = 'users';
  private FLOWS_API = 'flows';
  private LOG_EVENTS = 'log_events';
  private LOG_EVENT = 'log_event';
  private AUTH = 'auth';
  private FILES = 'files';
  private UPLOAD_FILE_TO_STAGE = 'upload_file_to_stage';
  private UPLOAD_FILE_TO_SECTION = 'upload_file_to_section';
  private UPLOAD_DOCUMENT = 'upload_document';
  private STAGE_FILES = 'stage_files';
  private DELETE_FILE_FROM_STAGE = 'delete_file_from_stage';
  private DOWNLOAD_FILE_FROM_STAGE = 'download_file_from_stage';
  private DOWNLOAD_FIXED_FILES = 'download_fixed_files';
  private SECTIONS_FILES = 'sections_files';
  private FIXED_FILES = 'fixed_files';
  private DOWNLOAD_FILE = 'download_file';
  private DELETE_FILE_FROM_SECTION = 'delete_file_from_section';
  private COMBINE_PDF_FILES = 'combine_pdf_files';
  private DOCUMENTATION_FILES = 'documentation_files';

  constructor(private network: NetworkService, private httpClient: HttpClient, private _authenticationService: AuthenticationService) {
    this.baseUrl = environment.apiUrl;
  }

  // not used
  authUser(): Observable<any> {
    return this.network.post(this.baseUrl, `${this.USERS_API}/${this.AUTH}`);
  }

  uploadFilesToStage(companyID: string, flowID: string, stageID: string, uploadedFile: any) {
    return new Observable((observer: Observer<any>) => {
      if (this._authenticationService.getCurrentUser().accessToken) {
        const token = this._authenticationService.getCurrentUser().accessToken;
        const xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.open('POST', `${this.baseUrl}${this.FILES}/${this.UPLOAD_FILE_TO_STAGE}`, true);
        xhr.setRequestHeader('X-Authorization', token);
        const formData = new FormData();
        formData.append('companyID', companyID);
        formData.append('flowID', flowID);
        formData.append('stageID', stageID);
        formData.append('file', uploadedFile);
        xhr.send(formData);
        xhr.onload = () => {
          if (xhr.readyState === xhr.DONE) {
            if (xhr.status === 200) {
              observer.next(JSON.parse(xhr.response));
            } else {
              observer.error(xhr.response);
            }
          }
        };
      }
    });
  }

  uploadFilesToSection(companyID: string, flowID: string, uploadedFile: any, sectionID: string) {
    return new Observable((observer: Observer<any>) => {
      if (this._authenticationService.getCurrentUser().accessToken) {
        const token = this._authenticationService.getCurrentUser().accessToken;
        const xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.open('POST', `${this.baseUrl}${this.FILES}/${this.UPLOAD_FILE_TO_SECTION}`, true);
        xhr.setRequestHeader('X-Authorization', token);
        const formData = new FormData();
        formData.append('companyID', companyID);
        formData.append('flowID', flowID);
        formData.append('sectionID', sectionID);
        for (const file of uploadedFile) {
          formData.append('file', file);
        }
        xhr.send(formData);
        xhr.onload = () => {
          if (xhr.readyState === xhr.DONE) {
            if (xhr.status === 200) {
              observer.next(JSON.parse(xhr.response));
            } else {
              observer.error(xhr.response);
            }
          }
        };
      }
    });
  }
  async getSectionsFiles(flowID: number): Promise<SectionFiles[]> {
    const response = await this.network.get(this.baseUrl, `${this.FILES}/${flowID}/${this.SECTIONS_FILES}`).toPromise();
    const files = response.data;
    return files;
  }

  async getFixedFiles(sectionID: number): Promise<FixedFile[]> {
    const response = await this.network.get(this.baseUrl, `${this.FILES}/${sectionID}/${this.FIXED_FILES}`).toPromise();
    const files = response.data;
    return files;
  }

  async updateFlowFilesOrder(files: Files[], flowID: number): Promise<any> {
    return await this.network.patch(this.baseUrl, `${this.FILES}/${flowID}/${this.SECTIONS_FILES}`, { files }).toPromise();
  }

  async deleteFileFromSection(filePath: string, companyID: number, flowID: number, fileID: number) {
    const response = await this.network.post(this.baseUrl, `${this.FILES}/${this.DELETE_FILE_FROM_SECTION}`, {filePath, flowID, companyID, fileID}).toPromise();
    return response;
  }

  async getStagesFiles(flowID: number, stageID: number): Promise<StageFiles> {
    const response = await this.network.get(this.baseUrl, `${this.FILES}/${flowID}/${stageID}/${this.STAGE_FILES}`).toPromise();
    const files = response.data;
    return files;
  }

  async deleteFileFromStage(filePath: string, flowID: number, stageID: number, fileID: number) {
    const response = await this.network.post(this.baseUrl, `${this.FILES}/${this.DELETE_FILE_FROM_STAGE}`, {filePath, flowID, stageID, fileID}).toPromise();
    return response;
  }

  createLogEvent(userID: number, actionTaken: string, flowID?: number, companyID?: number) {
    return this.network.post(this.baseUrl, `${this.USERS_API}/${userID}/${this.LOG_EVENT}`, { flowID, companyID, actionTaken }).toPromise();
  }

  async getAllFlowLogs(flowID: number, page: number): Promise<UserEvents> {
    const response = await this.network.get(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.LOG_EVENTS}?page=${page}&limit=10`).toPromise();
    const flowEvents = new UserEvents().deserialize(response.data);
    return flowEvents;
  }

  async getAllUserLogs(userID: number, page: number): Promise<UserEvents> {
    const response = await this.network.get(this.baseUrl, `${this.USERS_API}/${userID}/${this.LOG_EVENT}?page=${page}&limit=10`).toPromise();
    const userEvents = new UserEvents().deserialize(response.data);
    return userEvents;
  }

  async downloadFileFromStage(filePath: string, companyID: number, flowID: number) {
    const response = await this.httpClient.post(`${this.baseUrl}${this.FILES}/${this.DOWNLOAD_FILE_FROM_STAGE}`, { filePath, companyID, flowID }, { responseType: 'arraybuffer' }).toPromise();
    return response;
  }

  async downloadFixedFile(fileName: string) {
    const response = await this.httpClient.post(`${this.baseUrl}${this.FILES}/${this.DOWNLOAD_FIXED_FILES}`, { fileName }, { responseType: 'arraybuffer' }).toPromise();
    return response;
  }

  async downloadFile(url: string) {
    const response = await this.httpClient.get(url, { responseType: 'arraybuffer' }).toPromise();
    return response;
  }

  async download(fileID: number) {
    const endpoint = `${this.baseUrl}/files/download/${fileID}`;
    const response = await this.httpClient.get(endpoint, { responseType: 'arraybuffer' }).toPromise();
    return response;
  }

  async downloadFinalKitFile(signatures: any) {
    const response = await this.httpClient.post('https://heroapps.co.il/scripts/deloitte/implement-signatures/', { signatures }, { responseType: 'arraybuffer' }).toPromise();
    return response;
  }

  async combinePdfFiles(flowID: number) {
  const response = await this.httpClient.post(`${this.baseUrl}${this.FILES}/${this.COMBINE_PDF_FILES}`, {flowID}, {responseType: 'arraybuffer'}).toPromise();
  return response;
  }

  async getDocumentationFiles(flowID: number): Promise<Documentation[]> {
    const response = await this.network.get(this.baseUrl, `${this.FILES}/${flowID}/${this.DOCUMENTATION_FILES}`).toPromise();
    const files = response.data;
    return files;
  }

  uploadDocument(flowID: string, companyID: string, uploadedFile: any) {
    return new Observable((observer: Observer<any>) => {
      if (this._authenticationService.getCurrentUser().accessToken) {
        const token = this._authenticationService.getCurrentUser().accessToken;
        const xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.open('POST', `${this.baseUrl}${this.FILES}/${this.UPLOAD_DOCUMENT}`, true);
        xhr.setRequestHeader('X-Authorization', token);
        const formData = new FormData();
        formData.append('companyID', companyID);
        formData.append('flowID', flowID);
        formData.append('file', uploadedFile);
        xhr.send(formData);
        xhr.onload = () => {
          if (xhr.readyState === xhr.DONE) {
            if (xhr.status === 200) {
              observer.next(JSON.parse(xhr.response));
            } else {
              observer.error(xhr.response);
            }
          }
        };
      }
    });
  }
}
