<div class="main-table">
  <div class="container-fluid">
    <div class="create-form-button-continaer">
      <button type="button" class="btn btn-primary" [routerLink]="['create-form']">
        <app-svg-icon name="plus_special"></app-svg-icon>
        יצירת טופס חדש
      </button>
    </div>
    <div class="duplicate-form-button-continaer">
      <button type="button" class="btn btn-primary" [routerLink]="['duplicate-form']">
        <app-svg-icon name="edit_special"></app-svg-icon>
        שכפול טופס לשנה חדשה
      </button>
    </div>
    <div class="table-container" *ngIf="forms$ | async as forms">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">מספר הטופס</th>
            <th scope="col">שם הטופס</th>
            <th scope="col">שנה</th>
            <th scope="col">קטגוריה</th>
            <th scope="col">סטטוס</th>
            <th scope="col">נוצר בתאריך</th>
            <th scope="col">עודכן בתאריך</th>
          </tr>
        </thead>
        <tbody *ngFor="let form of forms">
          <tr>
            <td>{{form.formTaxID}}</td>
            <a [routerLink]="['edit-form/', form.id]">
              {{ form.name }}</a>
            <td>{{form.formYear}}</td>
            <td>{{form.sectionID}}</td>
            <td>{{form.state}}</td>
            <td>{{form.createdAt | date: 'dd/MM/yyyy בשעה hh:mm '}}</td>
            <td>{{form.updatedAt | date: 'dd/MM/yyyy בשעה hh:mm '}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
