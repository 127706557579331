import { Deserializable } from '@digital/app/_models/deserializable.model';
import { Flow } from '@digital/app/_models/flow.model';

export class Flows implements Deserializable {
  total: number;
  pages: number;
  limit: number;
  flows: Flow[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
