<div class="delete-flow-modal">
  <div class="modal-header">
    <h4 class="title">בקשה למחיקת התהליך</h4>
    <button class="modal-close-button" (click)="closeModal(false)">x</button>
  </div>
  <div class="modal-body">
    <p class="regular-paragraph">
      בחרתם למחוק את תהליך דוח המס עבור {{ flow.company.nameHebrew }} לשנת {{ flow.year }}.
    </p>
    <p class="red-paragraph"><strong>שימו לב:</strong> הבקשה תשלח למנהל/ת התיק האחראי/ת על התהליך לאישור.</p>
  </div>
  <div class="modal-footer">
    <form [formGroup]="deleteFlowForm" (submit)="submitDeleteRequest()">
      <input
        #reasonField
        formControlName="reasonField"
        type="text"
        class="form-control"
        placeholder="סיבת המחיקה"
        required
      />
      <ng-container *ngIf="!isLoading">
        <button type="submit" class="btn btn-danger" [disabled]="!deleteFlowForm.valid">
          שליחת הבקשה למחיקת התהליך
        </button>
      </ng-container>
      <ng-container *ngIf="isLoading">
        <app-loading></app-loading>
      </ng-container>
    </form>
  </div>
</div>
