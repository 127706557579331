import { Component, OnInit, Input } from '@angular/core';
import { FlowService } from '@digital/app/core/services/flow.service';
import { Flow } from '@digital/app/_models/flow.model';
import { EmailerFlow } from '@digital/app/flow/models/emailer-flow.model';
import { UserService } from '@digital/app/core/services/user.service';
import { ConsultationModalComponent } from '../../_tax/consultation-modal/consultation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manager-approve-stage',
  templateUrl: './manager-approve-stage.component.html',
  styleUrls: ['./manager-approve-stage.component.scss']
})
export class ManagerApproveStageComponent implements OnInit {
  @Input() flow: Flow;
  constructor(private _flowService: FlowService, private _userService: UserService, private modalService: NgbModal) {}

  ngOnInit() {}

  public setNewStage(type: string) {
    const emailerFlow = new EmailerFlow();
    emailerFlow.companyName = this.flow.company.nameHebrew;
    emailerFlow.flowType = this.flow.type.name;
    emailerFlow.managerMail = this.flow.manager.email;
    emailerFlow.partnerMail = this.flow.partner.email;
    emailerFlow.creatorMail = this.flow.creator.email;
    // emailerFlow.userName = this._userService.getCurrentUser().fullName.hebrew;

    switch (type) {
      case 'next':
        this._flowService.setNewStage(this.flow, 'next', emailerFlow);
        break;
      case 'previous':
        this._flowService.setNewStage(this.flow, 'previous', emailerFlow);
        break;
      default:
    }
  }

  openConsultationModal() {
    const modalRef = this.modalService.open(ConsultationModalComponent, {
      windowClass: 'consultation-modal',
      centered: true
    });
    modalRef.componentInstance.flow = this.flow;
  }
}
