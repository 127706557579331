import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '@digital/app/core/services/user.service';
import { FlowService } from '@digital/app/core/services/flow.service';
import { Consultation } from '@digital/app/flow/models/consultation.model';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Flow } from '@digital/app/_models/flow.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-consultation-modal',
  templateUrl: './consultation-modal.component.html',
  styleUrls: ['./consultation-modal.component.scss']
})
export class ConsultationModalComponent implements OnInit {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() flow: Flow;
  users: any;
  selectedUser: number;
  consultationGroup: FormGroup;
  isLoading: boolean;

  constructor(
    private _userService: UserService,
    private _flowService: FlowService,
    private _formBuilder: FormBuilder,
    private _activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this._setupFormBuilder();
    this.getManagersAndPartners();
  }

  // Setup
  private _setupFormBuilder() {
    this.consultationGroup = this._formBuilder.group({
      user: ['', Validators.required],
      consultationContent: ['', Validators.required]
    });
  }

  // Submit consultation
  createConsultation() {
    this.isLoading$.next(true);
    this.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    if (!this.consultationGroup.valid) {
      return;
    }
    const consultation = new Consultation();
    consultation.flowID = this.flow.id;
    consultation.companyID = this.flow.company.id;
    consultation.departmentID = null;
    consultation.creatorUserID = this._userService.getCurrentUser().id;
    consultation.consultationUserID = this.selectedUser;
    consultation.stageID = this.flow.stage.id;
    consultation.body = this.consultationGroup.controls.consultationContent.value;
    this._flowService.createNewConsultation(consultation).then((response) => {
      if (response.code === 200) {
        this.isLoading$.next(false);
        this.isLoading$.subscribe((isLoading) => {
          this.isLoading = isLoading;
        });
        this._flowService.emitDataFromConsultationModel(response.code);
        this._activeModal.close();
      }
    });
  }

  private getManagersAndPartners() {
    this.isLoading$.next(true);
    this.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this._flowService.getManagersAndPartners(23953144, 2020, (users: any) => this.onGetUsers(users));
  }

  onGetUsers(users: any) {
    this.isLoading$.next(false);
    this.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.users = users.managers.map((partners: any) => partners);
  }

  consultationUsersAutoComplete = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$).pipe(
      map((term) =>
        (term === ''
          ? this.users.map((user: any) => user.name)
          : this.users
              .map((user: any) => user.name)
              .filter((v: string) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
        ).slice(0, 10)
      )
    );
  };

  selectedUserID(userName: string) {
    const selected = this.users.filter((user: any) => user.name === userName);
    this.selectedUser = selected[0].id;
  }

  closeModal() {
    this._activeModal.close();
  }
}
