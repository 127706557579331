// Global routes
export class GlobalRoutes {
  public static wildcard = '**';
  public static root = '/';
  public static dtax = 'dtax';
  public static login = '';
  public static errors = {
    base: 'error',
    notFound: 'not-found',
    forbidden: 'forbidden',
    unauthorized: 'unauthorized',
    unauthorizedToFlow: 'unauthorized-to-flow',
    serverError: 'server-error',
    browser: 'unsupported-browser'
  };
  public static qtax = {
    base: 'qtax',
    query: 'query'
  };
}
