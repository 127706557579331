import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {
  @Input() name: string;

  constructor() { }

  ngOnInit() { }

  setIconName(name: string) {
    this.name = name;
  }
}
