import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { LeadQueryCriterion } from '@digital/app/_models/lead-query-criterion';
import { LeadCriterionGroupFormMappingService } from '@digital/app/lead/lead-query-criterion-group/lead-criterion-group-form-mapping.service';

@Injectable({
  providedIn: 'root'
})
export class LeadCriteriaFormMappingService {

  constructor(private fb: FormBuilder,
              private criterionGroupMappingService: LeadCriterionGroupFormMappingService) { }

  toForm(criteria?: LeadQueryCriterion[]): FormArray {
    if (!Array.isArray(criteria)) {
      criteria = [];
    }

    const criterionGroupElements = criteria.map(this.toLogicalCriterionGroupForm.bind(this));
    
    return this.fb.array(criterionGroupElements);
  }

  fromFormValue(formValue: any): LeadQueryCriterion[] {
    return formValue.map(this.fromLogicalCriterionGroupFormValue.bind(this));
  }

  toLogicalCriterionGroupForm(criterionGroup?: LeadQueryCriterion): FormGroup {
    const criterionGroupForm = this.criterionGroupMappingService.toForm(criterionGroup?.children);
    const logicalOperator = [criterionGroup?.logicalOperator || 'AND'];
    
    return this.fb.group({ criterion: criterionGroupForm, logicalOperator });
  }

  fromLogicalCriterionGroupFormValue(formValue: any): LeadQueryCriterion {
    const criterionGroups = this.criterionGroupMappingService.fromFormValue(formValue.criterion);
    const logicalOperator = formValue.logicalOperator;

    return { children: criterionGroups, logicalOperator };
  }
}
