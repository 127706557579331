<div class="main-form-view-container">
  <div class="row no-gutters">
    <div class="col-2" *ngIf="showTabs">
      <div class="tabs-container" *ngIf="forms$ | async">
        <div class="tabs-search">
          <div class="input-container">
            <app-svg-icon name="search_special" class="search-icon"></app-svg-icon>
            <input type="text" placeholder="חיפוש" (keyup)="searchFormsKeywordTyped($event.target.value)" />
          </div>
        </div>
        <!--  -->
        <ul class="tabs" *ngIf="filteredForms.length > 0">
          <li
            *ngFor="let form of filteredForms"
            class="tab text-content"
            [class.selected]="
              (currentForm$ | async).id === form.id && (currentForm$ | async).instance === form.instanceID
            "
            (click)="formTabClicked(form)"
          >
            <app-svg-icon
              name="file-empty_special"
              class="file-empty-icon"
              [class.draft]="form.state === FormState.Draft"
              [class.published]="form.state === FormState.Published"
            ></app-svg-icon>
            <span class="text-truncate">
              <span *ngIf="form.formTaxID"> {{ form.formTaxID }} - </span>
              <span *ngIf="form.instanceID > 0"> ({{ form.instanceID }}) </span>
              {{ form.name }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div [class.col-10]="showTabs" [class.col-12]="!showTabs">
      <!--  -->
      <button class="collapse-bar-button" (click)="collapseBarClicked()">
        <app-svg-icon
          name="{{ showTabs ? 'angle-right_special' : 'angle-left_special' }}"
          class="arrow-circle-icon"
        ></app-svg-icon>
      </button>
      <!--  -->
      <app-lead-query-form-content *ngIf="currentForm$ | async"></app-lead-query-form-content>
      <!--  -->
    </div>
  </div>
</div>
