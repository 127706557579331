import { Component, OnInit, ElementRef } from '@angular/core';
import { FieldBaseComponent } from '../field-base/field-base.component';
import { NgbDatepickerI18n, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { I18n, CustomDatepickerI18n } from '@libs/views/datepicker-i18n/datepicker-i18n';
import { CustomDateParserFormatter } from '@libs/views/datepicker/datepicker-adapter';
import { FormsService } from '@digital/app/forms/forms.service';

@Component({
  selector: 'app-field-date',
  templateUrl: './field-date.component.html',
  styleUrls: ['../field-base/field-base.component.scss', './field-date.component.scss'],
  providers: [
    I18n,
    {
      provide: NgbDatepickerI18n,
      useClass: CustomDatepickerI18n
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatter
    }
  ]
})
export class FieldDateComponent extends FieldBaseComponent implements OnInit {
  /* #region Lifecycle */

  constructor(public _elementRef: ElementRef, public formsService: FormsService) {
    super(_elementRef, formsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /* #endregion */

  /* #region Methods */

  updateFieldValue(date: NgbDate, callback?: (date) => void) {
    const dateParser = new CustomDateParserFormatter();
    const formattedDate = dateParser.format(date);
    this.field.newValue = formattedDate;
    const pdfElement = document.getElementById(`pdf-${this.field.id}`) as HTMLInputElement;
    if (pdfElement && pdfElement?.value) {
      pdfElement.value = this.field.newValue;
    }
    const fieldElement = document.getElementById(`field-${this.field.id}`) as HTMLInputElement;
    if (fieldElement && fieldElement?.value) {
      fieldElement.value = this.field.newValue;
    }
    this.didUpdateFormFieldValue.emit(this.field);

    callback && callback(date);
  }

  /* #endregion */
}
