import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalAPI } from '@digital/app/_constants';
import { CompanyInfo } from '@digital/app/_models/company-info.model';
import { API } from './api';

@Injectable({ providedIn: 'root' })
export class CompaniesAPI extends API {
  getCompanyInfo(companyID: number, year: number): Observable<CompanyInfo> {
    return this._network.get(GlobalAPI.baseURL, `${GlobalAPI.endpoints.companies.base}/${companyID}/${GlobalAPI.endpoints.companies.info}/${year}`)
      .pipe(map(response => new CompanyInfo().deserialize(response.data.info)));
  }

  updateCompanyInfo(companyID: number, year: number, fieldToUpdate: any): Observable<boolean> {
    return this._network.patch(GlobalAPI.baseURL, `${GlobalAPI.endpoints.companies.base}/${companyID}/${GlobalAPI.endpoints.companies.info}/${year}`, fieldToUpdate)
      .pipe(map(response => response.code === 202));
  }
}
