import { Component, OnInit, Input } from '@angular/core';

import { GridData } from '@digital/app/_models/grid-data';

import columnsJson from './columns.json';
import { LeadQueriesState } from '@digital/app/_models/lead-queries-state';
import { GlobalRoutes } from '@libs/constants';

@Component({
  selector: 'app-lead-queries-table',
  templateUrl: './lead-queries-table.component.html',
  styleUrls: ['./lead-queries-table.component.scss']
})
export class LeadQueriesTableComponent implements OnInit {
  columns: GridData[] = columnsJson;
  @Input() state: LeadQueriesState;
  queryRoute: string = GlobalRoutes.qtax.query;

  constructor() {}

  ngOnInit() {}

  queryRouteById(queryId: string): string[] {
    return [this.queryRoute, queryId];
  }
}
