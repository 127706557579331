import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-lead-query-top-bar',
  templateUrl: './lead-query-top-bar.component.html',
  styleUrls: ['./lead-query-top-bar.component.scss']
})
export class LeadQueryTopBarComponent implements OnInit {
  @Input() isNew: boolean;
  @Input() isDisabled: boolean;
  @Output() clickBack = new EventEmitter();
  @Output() clickSave = new EventEmitter();
  @Output() clickClone = new EventEmitter();
  @Output() clickShare = new EventEmitter();
  @Output() clickDelete = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClickBack(): void {
    this.clickBack.emit();
  }

  onClickSave(): void {
    this.clickSave.emit();
  }

  onClickDelete(): void {
    this.clickDelete.emit();
  }

  onClickClone(): void {
    this.clickClone.emit();
  }

  onClickShare(): void {
    this.clickShare.emit();
  }
}
