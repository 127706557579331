import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from '@libs/views/error-dialog/error-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';

interface ErrorInfo {
  title: string;
  body: string;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {
  public isDialogOpen = false;

  constructor(private _modalService: NgbModal) {}

  openDialogForhHttpError(error: HttpErrorResponse) {
    console.log(error);
    if (this.isDialogOpen) {
      return false;
    }

    let errorInfo: ErrorInfo;

    switch (error.status) {
      case 500:
        errorInfo = this.handleServerError(error);
        break;
      case 400:
        errorInfo = this.handleBadRequestError(error);
        break;
      case 409:
        errorInfo = this.handleDuplicateError(error);
        break;
      case 415:
        errorInfo = this.handleUnsupportedFileError(error);
        break;
      case 401:
        errorInfo = this.handelTokenRefreshError(error);
        break;
      default:
        errorInfo = this.handleDefaultError(error);
        break;
    }

    if (!errorInfo) {
      return;
    }

    this.isDialogOpen = true;

    const dialogRef = this._modalService.open(ErrorDialogComponent, { windowClass: 'error-dialog', centered: true });
    dialogRef.componentInstance.title = errorInfo.title;
    dialogRef.componentInstance.body = errorInfo.body;
    dialogRef.result.then((_) => (this.isDialogOpen = false));
  }

  private handelTokenRefreshError(error: HttpErrorResponse): ErrorInfo {
    return {
      title: `זמן ההתחברות שלך למערכת`,
      body: 'יש להתחבר מחדש למערכת (כל העבודות שלך נשמרו, אחת 30 ימים יש להתחבר מחדש)'
    };
  }

  private handleDefaultError(error: HttpErrorResponse): ErrorInfo {
    return {
      title: `${error.status} - אירעה שגיאה`,
      body: error.error.localizedMessage || 'אנא נסו מאוחר יותר.'
    };
  }

  private handleServerError(error: HttpErrorResponse): ErrorInfo {
    return {
      title: 'אופס! אירעה שגיאה',
      body: error.error.localizedMessage || 'אנחנו עובדים על זה. אנא נסו מאוחר יותר'
    };
  }

  private handleBadRequestError(error: HttpErrorResponse): ErrorInfo {
    if (error.error.message.iftttFailed) {
      const messages: string[] = error.error.message.iftttFailed;
      let body = '<ol>';

      for (const message of messages) {
        if (!body) {
          body = `${message}`;
          continue;
        }
        body += `<li>${message}</li>`;
      }

      body += '</ol>';

      return {
        title: 'אירעה שגיאה',
        body
      };
    }

    return {
      title: 'שימו לב!',
      body: error.error.localizedMessage || 'אנא נסו מאוחר יותר.'
    };
  }

  private handleDuplicateError(error: HttpErrorResponse): ErrorInfo {
    return {
      title: 'רשומה קיימת',
      body: error.error.localizedMessage || 'אנא נסו מאוחר יותר.'
    };
  }

  private handleUnsupportedFileError(error: HttpErrorResponse): ErrorInfo {
    return {
      title: 'סוג קובץ זה אינו נתמך',
      body: 'ניתן להכניס רק את סוגי הקבצים הבאים: pdf, xlsx, xls, docx, doc'
    };
  }
}
