import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from './api';
import { GlobalAPI } from '@digital/app/_constants';
import { Flow, FlowRealTimeData } from '@digital/app/_models/flow.model';

@Injectable({ providedIn: 'root' })
export class FlowAPI extends API {
  getFlowByID(flowID: number): Observable<Flow> {
    return this._network
      .get(GlobalAPI.baseURL, `${GlobalAPI.endpoints.flows.base}/${flowID}`)
      .pipe(map((response) => new Flow().deserialize(response.data.flow)));
  }

  getBaseFlowByID(flowID: number): Observable<Flow> {
    return this._network
      .get(GlobalAPI.baseURL, `${GlobalAPI.endpoints.flows.base}/${flowID}/base_flow`)
      .pipe(map((response) => new Flow().deserialize(response.data.flow)));
  }

  getFlowRealTimeData(flowID: number): Observable<FlowRealTimeData> {
    return this._network
      .get(GlobalAPI.baseURL, `${GlobalAPI.endpoints.flows.base}/${flowID}/rt_attr`)
      .pipe(map((response) => response.data));
  }

  setCurrentEditor(flowID: number): Observable<boolean> {
    const endpoint = `${GlobalAPI.endpoints.flows.base}/${flowID}/${GlobalAPI.endpoints.flows.setCurrentEditor}`;
    return this._network.patch(GlobalAPI.baseURL, endpoint).pipe(map((response) => response.code === 202));
  }

  setCurrentEditorSync(flowID: number): Promise<Response> {
    const endpoint = `${GlobalAPI.baseURL}${GlobalAPI.endpoints.flows.base}/${flowID}/${GlobalAPI.endpoints.flows.setCurrentEditor}`;
    const accessToken = this._authenticationService.getCurrentUser().accessToken;
    return fetch(endpoint, {
      keepalive: true,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-Authorization': accessToken
      }
    });
  }

  removeCurrentEditor(flowID: number): Observable<boolean> {
    const endpoint = `${GlobalAPI.endpoints.flows.base}/${flowID}/${GlobalAPI.endpoints.flows.removeCurrentEditor}`;
    return this._network.patch(GlobalAPI.baseURL, endpoint).pipe(map((response) => response.code === 202));
  }

  removeCurrentEditorSync(flowID: number): Promise<Response> {
    const endpoint = `${GlobalAPI.baseURL}${GlobalAPI.endpoints.flows.base}/${flowID}/${GlobalAPI.endpoints.flows.removeCurrentEditor}`;
    const accessToken = this._authenticationService.getCurrentUser().accessToken;
    return fetch(endpoint, {
      keepalive: true,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-Authorization': accessToken
      }
    });
  }

  removeCurrentEditorFromAll(): Observable<boolean> {
    return this._network
      .patch(GlobalAPI.baseURL, GlobalAPI.endpoints.flows.removeCurrentEditorFromAll)
      .pipe(map((response) => response.code === 202));
  }
}
