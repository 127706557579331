import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpEventType
} from '@angular/common/http';
import { observable, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { ErrorDialogService } from '@libs/core/services/errorDialog.service';
import { AuthenticationService } from '../authentication.service';
import { FlowAPI } from '@digital/app/_apis/flow.api';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationErrorInterceptor implements HttpInterceptor {
  private accessToken$ = this._authenticationService.accessToken$;
  private isRefreshing = false;

  constructor(
    private _router: Router,
    private _errorDialogService: ErrorDialogService,
    private _authenticationService: AuthenticationService,
    private _flowAPI: FlowAPI
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const isLoggedIn = this._authenticationService.isLoggedIn();

        // If logged in and still getting a 401, append the access token to the request and re-send it.
        if (error.status === 401 && isLoggedIn) {
          if (error.error.message.includes('permission to access this flow')) {
            this._router.navigate(['unauthorized-to-flow']);
          } else if (error.error.message.includes('Refresh token expired')) {
            this._flowAPI.removeCurrentEditorFromAll();
            this._authenticationService.logoutRefreshTokenExpired();
          } else if (error.error.message.includes('jwt expired')) {
            return this.handelRefreshToken().pipe(
              switchMap((user) => next.handle(this.addAccessToken(request, user.accessToken)))
            );
          } else if (error.error.message.includes('Invalid or missing refresh token')) {
            this._flowAPI.removeCurrentEditorFromAll();
            this._authenticationService.logoutRefreshTokenExpired();
          }
          return this.accessToken$.pipe(
            switchMap((token) => {
              return next.handle(this.addAccessToken(request, token));
            })
          );
        }

        // If error occured and the user is logged in, let's display an error dialog.
        if (error && isLoggedIn && error.status !== 403 && error.status !== 401) {
          this._errorDialogService.openDialogForhHttpError(error);
          return throwError(error.error.message || error.statusText);
        }

        // If not logged in, let's redirect the user to the correct error page.
        if (!isLoggedIn) {
          switch (error.status) {
            case 401:
              this._router.navigate(['']);
              break;
            case 403:
              this._router.navigate(['forbidden']);
              break;
            default:
              this._router.navigate(['forbidden']);
              break;
          }
          return throwError(error.error.message || error.statusText);
        }
      })
    );
  }

  handelRefreshToken(): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this._authenticationService.refreshToken().pipe(
        tap((data) => {
          this.isRefreshing = false;
        }),
        catchError((error) => {
          this.isRefreshing = false;
          return throwError(error);
        })
      );
    }
  }

  // Appends the currently stored access token to the re-request.
  addAccessToken(request: HttpRequest<any>, token?: string): HttpRequest<any> {
    if (!token) {
      return request;
    }

    return request.clone({ headers: request.headers.set('X-Authorization', token) });
  }
}
