import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeadQuery } from '@digital/app/_models/lead-query';

@Injectable({
  providedIn: 'root'
})
export class LeadPropertiesFormMappingService {

  constructor(private fb: FormBuilder) { }

  toForm(years: number[], query?: LeadQuery): FormGroup {
    const queryYears = query?.years;
    const queryYear = queryYears?.length > 0 ? queryYears[0] : null;
    const latestYear = years?.length > 0 ? years[years.length - 1] : null;
    const year = queryYear || latestYear;

    return this.fb.group({
      queryName: [query?.queryName, Validators.required],
      desc: [query?.desc],
      year: [year, Validators.required]
    });
  }

  fromFormValue(formValue: any): any {
    return { queryName: formValue.queryName, desc: formValue.desc, years: [formValue.year] };
  }
}
