<!-- Empty view -->
<div class="empty-view-container" *ngIf="!hasFormsAvailable && !isLoading">
  <div class="row">
    <div class="col-12">
      <h5 class="title">לא קיימים טפסים דיגיטלים</h5>
      <p class="content">לא קיימים טפסים דיגיטלים עבור שנת המס של תהליך זה.</p>
      <p class="action">
        <button class="btn btn-primary" (click)="closeModal()">סגירה</button>
      </p>
    </div>
  </div>
</div>

<!-- Non-empty view -->
<div *ngIf="hasFormsAvailable && !isLoading">
  <div class="modal-header">
    <div class="title-container">
      <h5 class="title">הוספה והסרת טפסים דיגיטלים</h5>
      <button class="modal-close-button" (click)="closeModal()">x</button>
    </div>
    <div *ngIf="!performingChanges">
      <p>נא לבחור את המסמכים הרלוונטים עבור ערכה זו</p>
      <div class="buttons-container">
        <span
          class="tooltip-wrapper"
          placement="bottom"
          ngbTooltip="מאפשר לסמן את כל המסמכים אשר סומנו בשנה הקודמת עבור החברה הנוכחית"
        >
          <button class="btn btn-outline-primary" (click)="checkPastDocuments()" [disabled]="categories.length === 0">
            סימון כל המסמכים משנה קודמת
          </button>
        </span>
        <button class="btn btn-outline-primary" (click)="disableAllCheckedForms()" [disabled]="categories.length === 0">
          הסרת כל הסימונים
        </button>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="modal-body">
    <!-- Performing changes view -->
    <div *ngIf="performingChanges" class="performing-changes-container">
      <div *ngIf="addedForms.length > 0" class="adding-forms">
        <h5 class="title">מוסיף טפסים:</h5>
        <ol>
          <li *ngFor="let form of addedForms">{{ form.name }}</li>
        </ol>
      </div>
      <!--  -->
      <div *ngIf="removedForms.length > 0" class="removing-forms">
        <h5 class="title">מסיר טפסים:</h5>
        <ol>
          <li *ngFor="let form of removedForms">{{ form.name }}</li>
        </ol>
      </div>
      <p *ngIf="finishedPerformingChanges" style="text-align: center; color: #86bc25; font-weight: bold">
        הערכה עודכנה בהצלחה!
      </p>
      <div class="expander"></div>
    </div>

    <!-- Forms list view -->
    <div *ngIf="!performingChanges">
      <div *ngFor="let category of this.categories">
        <div *ngIf="category.forms.length > 0">
          <p class="table-title">{{ category.name }}</p>
          <table class="table">
            <thead>
              <tr>
                <th class="tableTh" scope="col"></th>
                <th class="tableTh" scope="col">מספר</th>
                <th class="tableTh" scope="col">תיאור המסמך</th>
                <th class="tableTh" scope="col">מופעים</th>
              </tr>
            </thead>
            <tbody *ngFor="let form of category.forms">
              <tr *ngIf="form">
                <th scope="row">
                  <div class="checkbox">
                    <input
                      type="checkbox"
                      [value]="form.id"
                      [checked]="form.isSelected"
                      (change)="didChangeFormCheckState($event.target, form)"
                    />
                    <span class="checkmark"></span>
                  </div>
                </th>
                <td>{{ form.taxID }}</td>
                <td>{{ form.name }}</td>
                <td class="col-sm-2">
                  <input
                    type="number"
                    [value]="form.instanceCount"
                    (change)="didChangeFormInstances($event.target, form)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="expander"></div>
  </div>
  <!--  -->
  <div class="modal-footer">
    <app-loading *ngIf="performingChanges && !finishedPerformingChanges"></app-loading>
    <div *ngIf="!performingChanges" class="buttons-container">
      <button
        class="btn btn-primary"
        (click)="saveButtonClicked()"
        [disabled]="addedForms.length === 0 && removedForms.length === 0 && addFormInstancesDTO.length === 0 && removeInstanceFormDTO.length === 0"
      >
        שמירה
      </button>
      <button class="btn btn-outline-primary" (click)="closeModal()">ביטול</button>
    </div>
    <div *ngIf="performingChanges && finishedPerformingChanges" class="buttons-container">
      <button class="btn btn-outline-primary" (click)="closeModal()">סיום</button>
    </div>
  </div>
</div>
