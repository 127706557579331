import { Component, Input, OnInit } from '@angular/core';
import { IFTTTAction, IFTTTActionButton, IFTTTActionButtonType } from '@digital/app/_interfaces/ifttt-action.inteface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-action-required-modal',
  templateUrl: './action-required-modal.component.html',
  styleUrls: ['./action-required-modal.component.scss']
})
export class ActionRequiredModalComponent implements OnInit {
  @Input() action: IFTTTAction;

  buttonType = IFTTTActionButtonType;

  constructor(private _activeModal: NgbActiveModal) { }
  ngOnInit(): void {}

  buttonClicked(button: IFTTTActionButton) {
    this._activeModal.close(button);
  }
}
