import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeleteFlowModalComponent } from '@digital/app/flow/pages/flow/components/_general/delete-flow-modal/delete-flow-modal.component';
import { ConfirmationModalComponent } from '@digital/app/shared/components/confirmation-modal/confirmation-modal.component';
import { IFlowEditingPermissions } from '@digital/app/_interfaces/flow-editing-permissions.interface';
import { Flow, RiskLevelType } from '@digital/app/_models/flow.model';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { UserRole } from '@libs/authentication/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ChangeFlowInfoModalType,
  ChangeFlowInfoModalComponent
} from '../change-flow-info-modal/change-flow-info-modal.component';

@Component({
  selector: 'app-flow-header',
  templateUrl: './flow-header.component.html',
  styleUrls: ['./flow-header.component.scss']
})
export class FlowHeaderComponent implements OnInit {
  @Input() flow: Flow;
  @Input() editingPermissions: IFlowEditingPermissions;

  @Output() backButtonClicked = new EventEmitter();
  @Output() generateKitButtonClicked = new EventEmitter();

  readonly currentUser = this.authService.getCurrentUser();

  UserRole = UserRole;

  // Lifecycke
  constructor(private modal: NgbModal, private authService: AuthenticationService) {}
  ngOnInit() {}

  // Getters
  get flowUserType() {
    return {
      manager: ChangeFlowInfoModalType.manager,
      partner: ChangeFlowInfoModalType.partner,
      riskLevel: ChangeFlowInfoModalType.riskLevel
    };
  }

  get deadlinePassed(): boolean {
    return new Date() >= new Date(this.flow.deadline);
  }

  get isSettingsMenuDisabled(): boolean {
    if (this.currentUser.role === UserRole.ADMIN || this.currentUser.role === UserRole.FORMEDITOR) {
      return false;
    }
    return this.editingPermissions.isLockedForEditing || this.flow.isAwaitingDeletion;
  }

  get isExternalUser(): boolean {
    return this.currentUser.role === UserRole.EXTERNAL;
  }

  // Actions
  openChangeFlowInfoModal(modalType: ChangeFlowInfoModalType) {
    const modalRef = this.modal.open(ChangeFlowInfoModalComponent, {
      windowClass: 'change-flow-info-modal',
      centered: true
    });
    modalRef.componentInstance.type = modalType;
    modalRef.componentInstance.companyID = this.flow.company.id;
    modalRef.componentInstance.flowID = this.flow.id;
    modalRef.componentInstance.flow = this.flow;

    switch (modalType) {
      case ChangeFlowInfoModalType.manager:
        modalRef.componentInstance.currentUserID = this.flow.manager.id;
        break;
      case ChangeFlowInfoModalType.partner:
        modalRef.componentInstance.currentUserID = this.flow.partner.id;
        break;
      case ChangeFlowInfoModalType.riskLevel:
        modalRef.componentInstance.currentRiskLevel = this.flow.riskLevel;
      default:
        break;
    }
  }

  deleteFlowRequest() {
    if (this.currentUser.id === this.flow.manager.id || this.currentUser.id === this.flow.manager.id) {
      const modalRef = this.modal.open(ConfirmationModalComponent, {
        windowClass: 'confirmation-modal',
        scrollable: true,
        centered: true
      });
      modalRef.componentInstance.flow = this.flow;
      modalRef.componentInstance.actionType = 'deleteFlow';
      modalRef.componentInstance.title = 'מחיקת תהליך';
      modalRef.componentInstance.content = 'אזהרה! פעולה זו לא ניתנת לשחזור';
    } else {
      const modalRef = this.modal.open(DeleteFlowModalComponent, {
        windowClass: 'delete-flow-modal',
        centered: true
      });
      modalRef.componentInstance.flow = this.flow;
      modalRef.result.then((approved) => {
        if (approved) {
          this.flow.isAwaitingDeletion = true;
        }
      });
    }
  }

  openTicketServiceNow() {
    window.open(
      'https://deloitteus.service-now.com/israelsp?id=sc_cat_item_israel&sys_id=88a876afdb58555441aa8fd33996194e&business_service_sys_id=619e2e964f0aa788f7a9cf5d0210c73d'
    );
  }

  translateRiskLevel(riskLevel) {
    switch (riskLevel) {
      case RiskLevelType.Undefined:
        return 'לא מוגדר';
      case RiskLevelType.Low:
        return 'נמוך';
      case RiskLevelType.Medium:
        return 'בינוני';
      case RiskLevelType.High:
        return 'גבוה';
      default:
        return '';
    }
  }
}
