import { environment } from '@digital/environments/environment';

// Global app settings
export class Constants {
  public static languageDirection = 'rtl';
}

// App routes
export class DigitalitRoutes {
  public static flow = {
    main: 'flow',
    events: 'events',
    files: 'files',
    create: 'create',
    delete: 'delete',
    digitalForms: 'forms'
  };
  public static blog = {
    main: 'blog',
    single: 'post'
  };
}

// Global API settings
export const GlobalAPI = {
  baseURL: environment.apiUrl,
  endpoints: {
    auth: {
      base: 'auth',
      refreshToken: 'auth/refresh',
      external: 'auth/external'
    },
    users: {
      base: 'users'
    },
    flows: {
      base: 'flows',
      search: 'flows/search',
      uploadFiles: 'upload_files',
      formPreviewList: 'form_preview_list',
      setCurrentEditor: 'set_current_editor',
      removeCurrentEditor: 'remove_current_editor',
      removeCurrentEditorFromAll: 'flows/remove_user_all_current_editor'
    },
    lead: {
      queries: 'leadquery',
      query: 'leadquery/query'
    },
    signatures: {
      base: 'signatures'
    },
    companies: {
      base: 'companies',
      info: 'info'
    },
    forms: {
      base: 'flowForms',
      allForms: 'forms',
      fields: 'fields',
      formField: 'formField',
      sortedByCategories: 'sortedByCategories',
      generateKit: 'generateKit',
      generateSignedKit: 'generateSignedKit',
      createCustomFormField: 'createCustomField',
      customField: 'customField',
      notes: 'notes',
      commentToNote: 'commentToNote',
      statusOnNote: 'statusOnNote',
      getNotClosedNotesByFlowId: 'getNotClosedNotesByFlowId'
    },
    files: {
      base: 'files',
      download: 'files/download',
      update: 'sections_files'
    }
  }
};
