import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { CreateFlowRoutingModule } from './create-flow-routing.module';
import { ViewsModule as GlobalViewsModule } from '@libs/views/views.module';
import { SharedModule } from '@digital/app/shared/shared.module';

// Components
import { components } from './index';
import { ProcessDetailsComponent } from './components/process-details-tab/process-details-tab.component';
import { ProcessPermissionsTabComponent } from './components/process-permissions-tab/process-permissions-tab.component';

@NgModule({
  declarations: [components, ProcessDetailsComponent, ProcessPermissionsTabComponent],
  imports: [
    CommonModule,
    CreateFlowRoutingModule,
    GlobalViewsModule,
    SharedModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CreateFlowModule {}
