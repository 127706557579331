import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FlowService } from '@digital/app/core/services/flow.service';
import { FlowShellService } from '@digital/app/flow-shell/flow-shell.service';
import { Scenario } from '@digital/app/flow/models';
import { KitModalComponent } from '@digital/app/flow/pages/flow/components/_tax/kit-modal/kit-modal.component';
import { ConfirmationModalComponent } from '@digital/app/shared/components/confirmation-modal/confirmation-modal.component';
import { IMessageBarDownloadButton } from '@digital/app/shared/components/message-bar/message-bar.component';
import { DigitalitRoutes } from '@digital/app/_constants';
import { IFlowEditingPermissions } from '@digital/app/_interfaces/flow-editing-permissions.interface';
import { IFlowStageFile } from '@digital/app/_interfaces/flow-stage-file.interface';
import { Flow } from '@digital/app/_models/flow.model';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { GlobalRoutes } from '@libs/constants';
import { UploadFileStageType } from '@libs/enums/upload-file-stage-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OrganizeKitFilesModalComponent } from './components/_tax/organize-kit-files-modal/organize-kit-files-modal.component';
import { FlowStage } from '@digital/app/_enums/flow-stages.enum';
import { UploadFileType } from '@libs/enums/upload-file-type.enum';
import { DeleteFlowActions } from '../delete-flow/delete-flow.component';
import { UserRole } from '@libs/authentication/models/user.model';
import { ExternalUserType } from '@digital/app/_models/client-users';
import { ErrorDialogComponent } from '@libs/views/error-dialog/error-dialog.component';

@Component({
  selector: 'app-flow',
  templateUrl: './flow.component.html',
  styleUrls: ['./flow.component.scss']
})
export class FlowComponent implements OnInit, OnDestroy {
  readonly currentUser = this.authService.getCurrentUser();

  private subscriptions = new Subscription();

  isLoading$ = new BehaviorSubject(false);
  flow$ = this.flowShellService.flow$;
  flowState$ = this.flowShellService.flowState$;
  editingPermissions$ = this.flowShellService.editingPermissions$;

  scenario?: Scenario;

  showMessageBar = false;
  messageBarTitle: string;
  messageBarIcon: string;
  messageBarShowTakeOverButton = false;
  messageBarButtons: IMessageBarDownloadButton[] = [];

  isAwaitingDeletionModalOpen = false;
  isAwaitingDeletionAlreadyPrompted = false;

  /* #region Lifecycle */

  constructor(
    private router: Router,
    private title: Title,
    private flowShellService: FlowShellService,
    private flowService: FlowService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.toastr.toastrConfig.preventDuplicates = true;
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
    this.setupFlowSubscription();
    this.setupPermissionsSubscription();
    this.updateFlowAfterStageChange();
    this.updateFlowAfterOrganizeFileChange();
    this.updateStageFile();
  }

  ngAfterViewInit(): void {
    document.getElementById('my-body').classList.add('scroll-hidden');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    document.getElementById('my-body').classList.remove('scroll-hidden');
  }

  /* #endregion */

  /* #region Flow state */

  private setupFlowSubscription() {
    this.isLoading$.next(true);
    const flowSubscription = this.flow$.subscribe(
      (flow) => {
        if (flow) {
          this.setupFlow(flow);
          this.isLoading$.next(false);
        }
      },
      (error) => {
        console.log(error);
        this.router.navigate([GlobalRoutes.dtax]);
      }
    );
    this.subscriptions.add(flowSubscription);
  }

  private setupPermissionsSubscription() {
    const permissionsSubscription = this.editingPermissions$.subscribe(
      (permissions) => this.handlePermissionsChange(permissions),
      (error) => console.log(error)
    );
    this.subscriptions.add(permissionsSubscription);
  }

  private handlePermissionsChange(permissions: IFlowEditingPermissions) {
    const flow = this.flow$.value;

    if (!flow) {
      return;
    }

    let showTakeOverButton: boolean;
    if (flow.currentEditor) {
      showTakeOverButton = permissions.canLockForEditing && flow.currentEditor.id !== this.currentUser.id;
    } else {
      showTakeOverButton = false;
    }

    if (permissions.isLockedForEditing) {
      let message: string;

      if (flow.currentEditor?.id) {
        message = `מצב צפיה בלבד. כעת בעריכה אצל ${flow.currentEditor.fullName.hebrew}`;
      } else {
        message = 'מצב צפיה בלבד';
      }

      this.displayMessageBarWithMessage(message, showTakeOverButton, true);
    } else {
      if (this.messageBarButtons.length > 0) {
        this.displayMessageBarWithMessage('', showTakeOverButton, false);
      } else {
        this.hideMessageBar();
      }
    }
  }

  private setMessageBarButtonsAccordingToStageFiles(files: IFlowStageFile[], stage: FlowStage) {
    const buttons = files
      .filter((file) => {
        return (
          file.stageType !== UploadFileStageType.ClientSignaturePic &&
          file.stageType !== UploadFileStageType.SIGNATURE_IMPLEMENTATION
        );
      })
      .map((file) => {
        const button: IMessageBarDownloadButton = {
          title: this.getStageDownloadFileButtonName(file),
          file
        };
        return button;
      });

    if (buttons.length === 0 || stage === FlowStage.SIGNATURE_IMPLEMENTATION) {
      return;
    }

    this.messageBarButtons = buttons;
    this.showMessageBar = true;
  }

  private setMessageBarGenerateSignedKitAccordingToStage(stage: FlowStage) {
    switch (stage) {
      case FlowStage.IRS_CONFIRMATION:
      case FlowStage.FINISHED:
        const button: IMessageBarDownloadButton = {
          title: 'הורדת ערכה חתומה ע"י שותף',
          file: {
            id: 0,
            title: '',
            type: UploadFileType.STAGE,
            stageType: UploadFileStageType.FinalKit,
            createdAt: new Date(),
            updatedAt: new Date()
          },
          action: () => this.flowShellService.downloadSignedKit()
        };
        const arrayContainsObject =
          this.messageBarButtons.filter((carBrand) => carBrand.title === button.title).length > 0;
        if (!arrayContainsObject) {
          this.messageBarButtons = [...this.messageBarButtons, button];
        }
        break;
      default:
        break;
    }
  }

  private getStageDownloadFileButtonName(file: IFlowStageFile): string {
    switch (file.stageType) {
      case UploadFileStageType.ClientSignature:
        return 'הורדת ערכה חתומה ע"י הלקוח';
      case UploadFileStageType.IrsConfirmation:
        return 'אישור "התקבל" ממס הכנסה';
      default:
        return '';
    }
  }

  /* #endregion */

  /* #region Actions */
  openModalForExternalUser(isNewStage: boolean) {
    const modalRef = this.modalService.open(ErrorDialogComponent, {
      windowClass: 'confirmation-modal',
      centered: true
    });
    if (isNewStage) {
      modalRef.componentInstance.title = 'מאושר';
      modalRef.componentInstance.body = 'התהליך עבר לשלב הבא בתהליך. תודה';
    } else {
      modalRef.componentInstance.title = 'שגיאה';
      modalRef.componentInstance.body = 'אין לך הרשאות להיכנס לשלב זה לתהליך. אם זו טעות יש לפנות למנהל המערכת.';
    }
  }

  goBackToMainPage() {
    const currentRoute = this.router.url;
    const isDigitalFormsRoute = currentRoute.includes(DigitalitRoutes.flow.digitalForms);
    if (isDigitalFormsRoute) {
      this.router.navigate([DigitalitRoutes.flow.main, this.flow$.value.id]);
    }
    this.router.navigate([GlobalRoutes.dtax]);
    this.flowShellService.removeAsCurrentEditorIfNeeded();
  }

  openKitModal() {
    if (!this.flow$.value) {
      return;
    }
    this.modalService.open(KitModalComponent, {
      windowClass: 'kit-modal',
      centered: true,
      scrollable: true
    });
  }

  openOrganizeKitModal() {
    const modelRef = this.modalService.open(OrganizeKitFilesModalComponent, {
      windowClass: 'organize-kit-files-modal',
      centered: true,
      size: 'xl'
    });
    modelRef.componentInstance.flow = this.flow$.value;
  }

  openBlogsPage() {
    this.router.navigate([DigitalitRoutes.blog.main]);
  }

  takeOverFlow() {
    this.flowShellService.setAsCurrentEditorIfNeeded(true);
    this.hideMessageBar();
  }

  /* #endregion */

  handleIsAwaitingForDeletion(flow: Flow) {
    if (this.isAwaitingDeletionModalOpen) {
      return;
    }

    if (!flow.isAwaitingDeletion) {
      return;
    }

    if (
      this.currentUser.id === flow.manager.id ||
      this.currentUser.id === flow.partner.id ||
      this.currentUser.role === UserRole.ADMIN ||
      this.currentUser.role === UserRole.FORMEDITOR
    ) {
      this.isAwaitingDeletionModalOpen = true;

      const modalRef = this.modalService.open(ConfirmationModalComponent, {
        windowClass: 'confirmation-modal',
        scrollable: true,
        centered: true
      });
      modalRef.componentInstance.flow = flow;
      modalRef.componentInstance.actionType = 'deleteFlow';
      modalRef.componentInstance.title = 'מחיקת תהליך';
      modalRef.componentInstance.content =
        'תהליך זה ממתין לאישור מחיקה על ידך.<br /><br />אזהרה! פעולה זו אינה ניתנת לשחזור';

      modalRef.result.then(async (approved) => {
        if (approved) {
          await this.flowService.deleteFlow(flow.id, DeleteFlowActions.Approve);
        } else {
          await this.flowService.deleteFlow(flow.id, DeleteFlowActions.Decline);
        }

        this.isAwaitingDeletionModalOpen = false;
        this.isAwaitingDeletionAlreadyPrompted = true;

        flow.isAwaitingDeletion = false;
      });
    }
  }

  successAlertPopupAfterStageChange() {
    if (this.flow$.value.stage.id === FlowStage.FINISHED) {
      return this.toastr.success('סיום תהליך');
    }
    return this.toastr.success(
      `התהליך הועבר לשלב ${this.flow$.value.stage.name} לטיפול ${this.flow$.value.assignedUser.fullName.hebrew}`
    );
  }

  /* #region Message bar */

  private displayMessageBarWithMessage(message: string, showTakeOverButton = false, isViewOnly = false) {
    this.showMessageBar = true;
    this.messageBarTitle = message;
    this.messageBarShowTakeOverButton = showTakeOverButton;
    this.messageBarIcon = isViewOnly ? 'read-only_special' : undefined;
  }

  private hideMessageBar() {
    if (this.messageBarButtons.length === 0) {
      this.showMessageBar = false;
    }
  }

  /* #endregion */

  /* #region Networking requests */

  private getScenario(flow: Flow) {
    this.flowService.getScenario(flow.id, (scenario: Scenario) => {
      const stages = scenario.stages.sort((a, b) => a.state.position - b.state.position);
      scenario.stages = stages;
      this.scenario = scenario;
      this.isLoading$.next(false);
    });

    this.flowService.updateStages().subscribe((response: number) => {
      if (response === 200) {
        this.getScenario(flow);
      }
    });
  }

  updateFlowAfterStageChange() {
    this.flowService.updateFlowAfterStageChange().subscribe((response: number) => {
      if (response === 202) {
        this.flowService.isChangingStage = true;
        this.flowService
          .getFlowByID(this.flow$.value.id, (flow: Flow) => this.setupFlow(flow))
          .catch((_) => this.router.navigate([GlobalRoutes.dtax]));
      }
    });
  }

  updateFlowAfterOrganizeFileChange() {
    this.flowService.updateFlowWhenChangeOnFooter().subscribe((response: number) => {
      if (response === 202) {
        this.flowService
          .getFlowByID(this.flow$.value.id, (flow: Flow) => this.setupFlow(flow))
          .catch((_) => this.router.navigate([GlobalRoutes.dtax]));
      }
    });
  }

  updateStageFile() {
    this.flowService.updateStageFile().subscribe((files) => {
      console.log('enterd');
      this.flow$.value.stageFiles = files;
    });
  }

  /* #endregion */

  /* #region Networking handlers */

  private setupFlow(flow: Flow) {
    this.title.setTitle(flow.company.nameHebrew + ' - ' + flow.type.name + ' ' + flow.year);

    this.getScenario(flow);
    this.setMessageBarButtonsAccordingToStageFiles(flow.stageFiles, flow.stage.id as FlowStage);
    this.setMessageBarGenerateSignedKitAccordingToStage(flow.stage.id as FlowStage);

    if (flow.isAwaitingDeletion && !this.isAwaitingDeletionAlreadyPrompted) {
      this.handleIsAwaitingForDeletion(flow);
    }

    if (!this.flowShellService.isExternalUserAndPermitted(flow)) {
      if (this.flowService.isChangingStage) {
        this.openModalForExternalUser(true);
      } else {
        this.openModalForExternalUser(false);
      }
    }
    if (this.flowService.isChangingStage) {
      // this.successAlertPopupAfterStageChange();
      this.flowService.isChangingStage = false;
    }

    this.flowShellService.setAsCurrentEditorIfNeeded();

    this.flow$.value.partner = flow.partner;
    this.flow$.value.manager = flow.manager;
  }

  /* #endregion */
}
