<section class="financial-consultation-stage">
    <div class="row">
        <div class="col text-right">
            <div class="title-container">
                <h4>התייעצות מקצועית</h4>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="content">
                <p>
                    תיאור/הסבר
                </p>
                <ul class="consultation-data">
                    <li class="item">
                        <span class="item-title">האם מדובר בתיק PCAOB?</span>
                        כן
                    </li>
                    <li class="item">
                        <span class="item-title">האם מדובר ב-PIE?</span>
                        כן
                    </li>
                    <li class="item">
                        <span class="item-title">מגזר</span>
                        נדל״ן
                    </li>
                    <li class="item">
                        <span class="item-title">סוג ההתייעצות</span>
                        קביעת מהותיות
                    </li>
                    <li class="item">
                        <span class="item-title">סיבת ההתייעצות</span>
                        <br>
                        אני לא מבין מה קורה בשורה 3 שבעמוד 12
                    </li>
                </ul>
                <div class="buttons-container">
                    <button class="btn btn-primary-white download">
                        <app-svg-icon name="download_special" class="download-icon"></app-svg-icon>
                        הורדת קובץ ההתייעצות
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
