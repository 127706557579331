import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { NetworkService } from '@libs/core/network/network.service';

@Injectable({ providedIn: 'root' })
export class API {
    protected _network: NetworkService;
    protected _authenticationService: AuthenticationService;

    constructor(injector: Injector) {
        this._network = injector.get(NetworkService);
        this._authenticationService = injector.get(AuthenticationService);
    }

    protected isLoggedIn(): boolean {
        return this._authenticationService.isLoggedIn() &&
                this._authenticationService.isAuthenticated();
    }
}
