import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { Subscription, BehaviorSubject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Flow } from '@digital/app/_models/flow.model';

import { KitAPI } from '@digital/app/flow/apis/kit.api';
import {
  SectionItem,
  SectionItemKind,
  SectionItemType,
  UpdateFile,
  UpdateSectionFileIncludedDTO,
  UpdateSectionItemIncludedDTO,
  UpdateSectionItemOrderDTO
} from '@digital/app/flow/models/section.model';
import { Files } from '@digital/app/flow/models/sections-files.model';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-organize-kit-files-modal',
  templateUrl: './organize-kit-files-modal.component.html',
  styleUrls: ['./organize-kit-files-modal.component.scss']
})
export class OrganizeKitFilesModalComponent implements OnInit, OnDestroy {
  @Input() flow: Flow;
  @ViewChild('autoscroll') autoscroll: ElementRef;

  isLoading$ = new BehaviorSubject(false);

  items?: {
    draggable?: SectionItem[];
    nonDraggable?: SectionItem[];
    initialOrder?: SectionItem[];
    lastOrder?: SectionItem[];
  };

  private subscribers = new Subscription();

  dropGroupName = 'LIST_ITEMS';
  isDownloading = false;
  disableRestoreButton = true;

  readonly SectionItemKind = SectionItemKind;
  readonly SectionItemType = SectionItemType;

  constructor(private dragulaService: DragulaService, private activeModal: NgbActiveModal, private kitAPI: KitAPI) {
    this.dragulaService.createGroup(this.dropGroupName, {
      invalid: (el, handle) => {
        return el.classList.contains('not-draggable');
      }
    });

    this.subscribers.add(
      this.dragulaService.drop(this.dropGroupName).subscribe(() => {
        this.saveOrder();
      })
    );
  }

  ngOnInit() {
    this.getListItems();
  }

  ngOnDestroy() {
    this.items = {};
    this.subscribers.unsubscribe();
    this.dragulaService.destroy(this.dropGroupName);
  }

  private getListItems() {
    this.isLoading$.next(true);
    this.kitAPI.getFormsList(this.flow.id).subscribe((items) => {
      this.isLoading$.next(false);
      console.log(items);
      if (items.length === 0) {
        return;
      }

      this.items = {};

      items = items.filter((item) => item.sectionID !== 6);

      const draggable = items.filter((item) => !item.disablePositionChange);
      const nonDraggable = items.filter((item) => item.disablePositionChange);

      this.items = {
        draggable,
        nonDraggable,
        initialOrder: draggable,
        lastOrder: undefined
      };
    });
  }

  closeModal() {
    this.activeModal.close(false);
  }

  updateIncludedInExport(item: SectionItem) {
    const dto: UpdateSectionItemIncludedDTO = {
      formID: item.id,
      type: item.type,
      includedInExport: item.includedInExport,
      instanceID: item.instanceID
    };

    const file: UpdateFile = {
      id: item.id,
      name: item.name,
      state: item.state,
      filePath: item.url,
      createdAt: item.createdAt,
      sectionID: item.sectionID,
      position: item.position,
      includedInExport: item.includedInExport,
      userName: {
        id: this.flow.currentEditor.id,
        fullName: {
          english: this.flow.currentEditor.fullName.english,
          hebrew: this.flow.currentEditor.fullName.hebrew
        },
        rank: this.flow.currentEditor.rank.english
      }
    };

    const filesObj: UpdateSectionFileIncludedDTO = {
      file: [file]
    };

    if (item.type === SectionItemType.File) {
      this.kitAPI.updateFileIncludedInExport(this.flow.id, filesObj).subscribe(
        (success) => {
          console.log(success);
        },
        (error) => {
          item.includedInExport = !item.includedInExport;
        }
      );
    } else {
      this.kitAPI.updateFormIncludedInExport(this.flow.id, dto).subscribe(
        (success) => {
          console.log(success);
        },
        (error) => {
          item.includedInExport = !item.includedInExport;
        }
      );
    }
  }

  saveOrder(isRestore: boolean = false) {
    const newItemsOrder: UpdateSectionItemOrderDTO[] = this.items.draggable?.map((item, index) => {
      const startPositionFrom = this.items.nonDraggable.length;
      const position = index + 1 + startPositionFrom;
      return {
        id: item.id,
        position,
        type: item.type,
        instance: item.instanceID
      };
    });

    this.kitAPI.updateFormsOrder(this.flow.id, newItemsOrder).subscribe(
      (success) => {
        if (success) {
          this.items.lastOrder = this.items.draggable;
          if (!isRestore) {
            this.disableRestoreButton = false;
          }
        }
      },
      (error) => {
        console.log(error);
        if (this.items.lastOrder) {
          this.items.draggable = this.items.lastOrder;
        } else {
          if (this.items.initialOrder) {
            this.items.draggable = this.items.initialOrder;
          }
        }
      }
    );
  }

  restoreInitialOrder() {
    if (!this.items.initialOrder) {
      return;
    }
    this.items.draggable = this.items.initialOrder;
    this.disableRestoreButton = true;
    this.saveOrder(true);
  }

  generateKit() {
    this.isDownloading = true;
    this.kitAPI.generate(this.flow.id).subscribe(
      (buffer) => {
        this.isDownloading = false;
        const file = new Blob([buffer], { type: 'blob' });
        const fileName = `ערכה_למס_${this.flow.company.nameHebrew}_${this.flow.year}.pdf`;
        saveAs(file, fileName);
      },
      (error) => {
        this.isDownloading = false;
        console.log(error);
      }
    );
  }
}
