import { Component, OnInit } from '@angular/core';
import { Form, FormType, FormTypeLabel} from '../../models/form.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreateFormFacade } from '../../create-form.facade';
import { AdminPanelRoutes } from '@admin/app/shared/constants';


@Component({
  selector: 'app-create-new-form',
  templateUrl: './create-new-form.component.html',
  styleUrls: ['./create-new-form.component.scss']
})
export class CreateNewFormComponent implements OnInit {
  createNewForm: FormGroup;
  createdForm$ = this._createFormFacade.createdFields$;
  section$ = this._createFormFacade.sections$;
  fileToUpload: File = null;
  formType = Object.values(FormType);
  FormTypeLabel = FormTypeLabel;
  isLoading = false;


  constructor(private _createFormFacade: CreateFormFacade, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this._setupFormBuilder();
    this.getSection();
  }

  // Setup
  private _setupFormBuilder() {
    this.createNewForm = this._formBuilder.group({
      formTaxID: ['', Validators.required],
      formYear: ['', Validators.required],
      name: ['', Validators.required],
      sectionID: ['', Validators.required],
      file: [null, Validators.required],
      type: ['', Validators.required]
    });
  }

  onSubmitFormButtonClicked() {
    if (!this.createNewForm.valid) { return; }
    const form = new Form();
    this.isLoading = true;
    
    form.formTaxID = this.createNewForm.get('formTaxID').value;
    form.formYear = this.createNewForm.get('formYear').value;
    form.name = this.createNewForm.get('name').value;
    form.sectionID = this.createNewForm.get('sectionID').value;
    form.filePath = this.fileToUpload;
    form.type = this.createNewForm.get('type').value;

    this._createFormFacade.createNewForm(form).then((result)=>{
      this.isLoading = false;
    }) 
    .catch((error)=> {
      throw error;
    });
  }

  handleFileUploadChange(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  getSection() {
    this._createFormFacade.getSections();
  }

  get homeRoute () {
    return '/' + AdminPanelRoutes.home; 
  }
  
}
