<div class="modal-header">
    <h4 class="title">{{ title }}</h4>
    <button class="modal-close-button" (click)="close()">
        <span>x</span>
    </button>
</div>
<div class="modal-body">
    <p [innerHTML]="body"></p>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="close()">סגירה</button>
</div>
