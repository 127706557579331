import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { DigitalitRoutes } from '@digital/app/_constants';
import { AuthenticationGuard } from '@libs/authentication/guards/authentication.guard';
import { BrowserGuard } from '@libs/guards/browser.guard';
import { FlowModule } from '../flow/flow.module';
import { DeleteFlowComponent } from '../flow/pages/delete-flow/delete-flow.component';
import { FlowComponent } from '../flow/pages/flow/flow.component';
import { DigitalFormsModule } from '../forms/forms.module';
import { EditFormsPageComponent } from '../forms/pages/edit-forms/edit-forms.component';
import { FlowShellComponent } from './flow-shell.component';

const routes: Routes = [
  {
    path: `${DigitalitRoutes.flow.main}/:id`,
    component: FlowShellComponent,
    canActivate: [BrowserGuard, MsalGuard],
    children: [
      {
        path: '',
        component: FlowComponent,
        canActivate: [MsalGuard]
      },
      {
        path: DigitalitRoutes.flow.digitalForms,
        component: EditFormsPageComponent,
        canActivate: [MsalGuard]
      },
      {
        path: DigitalitRoutes.flow.delete,
        component: DeleteFlowComponent,
        canActivate: [MsalGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), FlowModule, DigitalFormsModule],
  exports: [RouterModule]
})
export class FlowShellRoutingModule {}
