<div class="create-form-container">
  <form [formGroup]="companyInfoForm">
    <div class="form-body-title">
      <h5>פרטים כללים של העסק (פרטים אלו יכנסו אוטומטית לתוך הטפסים)</h5>
    </div>
    <div class="row">
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="taxCaseNumber"> מספר תיק ניכויים </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              name="taxCaseNumber"
              formControlName="taxCaseNumber"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('taxCaseNumber')"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="vatCaseNumber"> מספר תיק במע״מ </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              name="vatCaseNumber"
              formControlName="vatCaseNumber"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('vatCaseNumber')"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="address"> כתובת העסק </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              name="address"
              formControlName="address"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('address')"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="mailingAddress"> כתובת למשלוח דואר (כולל מיקוד) </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              name="mailingAddress"
              formControlName="mailingAddress"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('mailingAddress')"
            />
          </div>
        </div>
      </div>
      <!--  -->
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="fax"> מספר פקס </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="phone"
              class="form-control"
              name="fax"
              formControlName="fax"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('fax')"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="phone"> מספר טלפון </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="phone"
              name="phone"
              formControlName="phone"
              class="form-control"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('phone')"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="accountancyAddress"> כתובת בה מוחזקת מערכת החשבונות </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              name="accountancyAddress"
              class="form-control"
              formControlName="accountancyAddress"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('accountancyAddress')"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="assessmentOfficeAddress"> משרד השומה בו מתנהל התיק </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              name="assessmentOfficeAddress"
              formControlName="assessmentOfficeAddress"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('assessmentOfficeAddress')"
            />
          </div>
        </div>
      </div>
      <!--  -->
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="email"> אימייל </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="email"
              class="form-control"
              name="email"
              formControlName="email"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('email')"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="occupation"> תיאור העיסוק של החברה </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              name="occupation"
              formControlName="occupation"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('occupation')"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="bankAccountNumber"> מספר חשבון בנק </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control input"
              name="bankAccountNumber"
              formControlName="bankAccountNumber"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('bankAccountNumber')"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="bankBranchNumber"> מספר סניף הבנק </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="number"
              class="form-control"
              name="bankBranchNumber"
              formControlName="bankBranchNumber"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('bankBranchNumber')"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="bankNumber"> מספר הבנק (סמל) </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="number"
              formControlName="bankNumber"
              name="bankNumber"
              class="form-control"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('bankNumber')"
            />
          </div>
        </div>
      </div>
      <div class="col-3 input-field">
        <label for="nameSigningCustomer"> שם לקוח חותם </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              name="nameSigningCustomer"
              class="form-control"
              formControlName="nameSigningCustomer"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('nameSigningCustomer')"
            />
          </div>
        </div>
      </div>
      <div class="col-3 input-field">
        <label for="roleSigningCustomer"> תפקיד לקוח חותם </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              name="roleSigningCustomer"
              class="form-control"
              formControlName="roleSigningCustomer"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('roleSigningCustomer')"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="companyType"> סוג החברה </label>
        <div class="input-group row">
          <div class="col-12">
            <select
              type="number"
              formControlName="companyType"
              name="companyType"
              class="form-control"
              (ngModelChange)="updateCompanyInfo('companyType')"
            >
              <option selected disabled>בחירת סוג החברה</option>
              <option value="2 - מפעל מאושר/מוטב/מועדף/טכנולוגי">מפעל מאושר/מוטב/מועדף/טכנולוגי</option>
              <option value="3 - מוסד כספי">מוסד כספי</option>
              <option value="4 - אחר">אחר</option>
              <option value="5 - מפעל מאושר/מוטב בשנת הטבה אחרונה">מפעל מאושר/מוטב בשנת הטבה אחרונה</option>
              <option value="6 - מפעל מאושר/מוטב בשנה שלפני שנת הטבה האחרונה">
                מפעל מאושר/מוטב בשנה שלפני שנת הטבה האחרונה
              </option>
              <option value="7 - חברת בית">חברת בית</option>
              <!-- <ng-container *ngFor="let type of companyTypes">
                <option [value]="getCompanyTypeValue(type)">{{ type.name }}</option>
              </ng-container> -->
            </select>
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="publicOrPrivate"> ציבורית / פרטית </label>
        <div class="input-group row">
          <div class="col-12">
            <select
              type="string"
              formControlName="publicOrPrivate"
              name="publicOrPrivate"
              class="form-control"
              (ngModelChange)="updateCompanyInfo('publicOrPrivate')"
            >
              <option selected disabled>בחירה ציבורית / פרטית</option>
              <option value="public">ציבורית</option>
              <option value="private">פרטית</option>
            </select>
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="companyClassification"> סיווג החברה </label>
        <div class="input-group row">
          <div class="col-12">
            <select
              type="string"
              formControlName="companyClassification"
              name="companyClassification"
              class="form-control"
              (ngModelChange)="updateCompanyInfo('companyClassification')"
            >
              <option selected disabled>בחירת סיווג החברה</option>
              <option value="חברה קבלנית">חברה קבלנית</option>
              <option value="חברת שירותים">חברת שירותים</option>
              <option value="חברת הייטק">חברת הייטק</option>
              <option value="חברת החזקה">חברת החזקה</option>
              <option value="חברה תעשיתית">חברה תעשיתית</option>
              <option value="חברה קמעונאית">חברה קמעונאית</option>
              <option value="חברה שהיא מוסד כספי">חברה שהיא מוסד כספי</option>
              <option value="חברה שהיא קרן הון סיכון">חברה שהיא קרן הון סיכון</option>
              <option value="חברה שהיא בית מלון">חברה שהיא בית מלון</option>
            </select>
          </div>
        </div>
      </div>
      <div *ngIf="showAll" class="col-3 input-field">
        <label for="secondaryClassification"> סיווג משני </label>
        <div class="input-group row">
          <div class="col-12">
            <select
              type="string"
              formControlName="secondaryClassification"
              name="secondaryClassification"
              class="form-control"
              (ngModelChange)="updateCompanyInfo('secondaryClassification')"
            >
              <option selected disabled>בחירת סיווג משני</option>
              <option value="חברת מעטים">חברת מעטים</option>
              <option value="חברה משפחתית">חברה משפחתית</option>
              <option value="חברת בית">חברת בית</option>
              <option value="שותפות">שותפות</option>
              <option value="חברה המעניקה שירותי מופ">חברה המעניקה שירותי מו"פ</option>
              <option value="חברה מוטבת">חברה מוטבת</option>
              <option value="חברה מועדפת">חברה מועדפת</option>
              <option value="חברה במסלול טכנולוגי מועדף">חברה במסלול טכנולוגי מועדף</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showAll" class="form-body-title">
      <h5>פרטי המייצג (פרטים אלו יכנסו אוטומטית לתוך הטפסים)</h5>
    </div>
    <div *ngIf="showAll" class="row">
      <div class="col-3 input-field">
        <label for="taxCaseNumber"> שם המשרד המסייע </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              name="firmName"
              formControlName="firmName"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('firmName')"
            />
          </div>
        </div>
      </div>
      <div class="col-3 input-field">
        <label for="firmExpertise"> מקצוע </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              name="firmExpertise"
              formControlName="firmExpertise"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('firmExpertise')"
            />
          </div>
        </div>
      </div>
      <div class="col-3 input-field">
        <label for="firmCompanyID"> מספר עוסק מורשה </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              name="firmCompanyID"
              formControlName="firmCompanyID"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('firmCompanyID')"
            />
          </div>
        </div>
      </div>
      <div class="col-3 input-field">
        <label for="firmAddress"> כתובת (כולל מיקוד) </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              name="firmAddress"
              formControlName="firmAddress"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('firmAddress')"
            />
          </div>
        </div>
      </div>
      <div class="col-3 input-field">
        <label for="firmPhone"> מספר טלפון </label>
        <div class="input-group row">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              name="firmPhone"
              formControlName="firmPhone"
              [placeholder]="fieldPlaceholder"
              (blur)="updateCompanyInfo('firmPhone')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="space"></div>
  </form>
</div>
