<section class="comment" [class.comments]="comment.type === 'comments'" [class.replies]="comment.type === 'replies'">
  <div class="container-fluid">
    <div class="flow-comment">
      <div class="row mt-2">
        <ul class="comment-info">
          <li class="info-item profile">
            <span class="profile-image">
              <label>{{ comment.user.nameInitials }}</label>
            </span>
          </li>
          <li class="info-item name">{{ comment.user.fullName.hebrew }}</li>
          <li class="info-item department">{{ comment.user.rank.english }}</li>
          <li class="info-item stage">
            <span class="title-tag">{{ comment.stage.name }}</span>
          </li>
          <li class="info-item date">{{ comment.createdAt | parseDatePipe: 'time' }}</li>
        </ul>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="comment-content">
            <p>
              {{ comment.message }}
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="comment-controls-container">
            <ng-container *ngIf="comment.type === 'comments' && comment.replyCount > 0 && !comment.replies">
              <button class="btn btn-outline-primary comment-replies" (click)="fetchCommentsReplies()">
                צפיה ב-{{ comment.replyCount }} תגובות
              </button>
            </ng-container>
            <ng-container *ngIf="comment.replyCount === 0 && comment.parentID === null">
              <button class="btn btn-primary" (click)="addCommentButtonPressed.emit()">הגב/י לתגובה זו</button>
            </ng-container>
            <ng-container *ngIf="isAllowedToDeleteComment">
              <button class="btn btn-danger" (click)="deleteComment(comment)">מחיקת תגובה</button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
