import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FlowService } from '@digital/app/core/services/flow.service';
import { Flow } from '@digital/app/_models/flow.model';
import { FileService } from '@libs/core/services/file.service';
import Documentation from '@digital/app/flow/models/documentation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewFormModalComponent } from '../preview-form-modal/preview-form-modal.component';
import { BehaviorSubject } from 'rxjs';
import { DigitalitRoutes } from '@digital/app/_constants';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-file-managment-tab',
  templateUrl: './file-managment-tab.component.html',
  styleUrls: ['./file-managment-tab.component.scss']
})
export class FileManagmentTabComponent implements OnChanges, OnInit {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() title: string;
  @Input() flow: Flow;
  years: any;
  pdfFile: string;
  isOpenFiles = false;
  fileTitle = 'שנה';
  filesFlowID: number;
  documentation: Documentation[];
  flowsYearsAndFile = [];
  isLoading = false;
  constructor(private _flowsAPI: FlowService, private _fileAPI: FileService, private modalService: NgbModal, private _router: Router,) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.flow) {
      this._fetchYears();
    }
  }

  onGetYears(years: any) {
    this.years = years.sort((a: { year: number }, b: { year: number }) => a.year - b.year);
  }

  onGetDocumentation(files: Documentation[], flow?: string) {
    if (flow) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < files.length; i++) {
        // tslint:disable-next-line: prefer-for-of
        for (let y = 0; y < this.years.length; y++) {
          if (files[i].flowID === this.years[y].id) {
            this.years[y].updatedAt = files[files.length - 1].updatedAt;
          }
        }
      }
    } else {
      this.documentation = files;
    }
  }

  onYearClicked(flow: any) {
    console.log(flow);
    this._router.navigate([DigitalitRoutes.flow.main, flow.id]);
  }

  get flowRoute() {
    return DigitalitRoutes.flow.main;
  }

  backToYears() {
    this.documentation = null;
    this.isOpenFiles = false;
    this.title = 'תיעוד מסמכים וניירות עבודה';
    this.fileTitle = 'שנה';
  }

  _fetchDocumentation(flowID: number, flow?: string): void {
    this._fileAPI.getDocumentationFiles(flowID, (files: Documentation[]) => this.onGetDocumentation(files, flow));
  }

  _fetchYears() {
    this._flowsAPI.getFlowsYearsByID(this.flow.company.id, (years) => this.onGetYears(years));
  }

  downloadFile(document: Documentation) {
    this.isLoading$.next(true);
    this.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    if (document.filePath.indexOf('.pdf') !== -1) {
      this._fileAPI.downloadFile(document.filePath).then((response) => {
        this.isLoading$.next(false);
        this.isLoading$.subscribe((isLoading) => {
          this.isLoading = isLoading;
        });
        const file = new Blob([response], { type: 'blob' });
        this.pdfFile = URL.createObjectURL(file);
        const modalRef = this.modalService.open(PreviewFormModalComponent, {
          windowClass: 'preview-form-modal',
          scrollable: true,
          centered: true,
          size: 'xl'
        });
        modalRef.componentInstance.pdfFile = this.pdfFile;
        modalRef.componentInstance.pdfName = document.name;
      });
    } else {
      this._fileAPI.downloadFile(document.filePath).then((response) => {
        this.isLoading$.next(false);
        this.isLoading$.subscribe((isLoading) => {
          this.isLoading = isLoading;
        });
        const file = new Blob([response], { type: 'blob' });
        saveAs(file, document.name);
      });
    }
  }

  uploadFile(event: any) {
    this.isLoading$.next(true);
    this.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    const file = event[0];
    this._fileAPI
      .uploadDocument(this.filesFlowID.toString(), this.flow.company.id.toString(), file)
      .subscribe((uploadedFile) => {
        if (uploadedFile) {
          this.isLoading$.next(false);
          this.isLoading$.subscribe((isLoading) => {
            this.isLoading = isLoading;
          });
          this._fetchDocumentation(this.filesFlowID);
          this._fetchYears();
        }
      });
  }
}
