import { InjectionToken } from '@angular/core';

export const APP_ENV = new InjectionToken('Application config');

export enum EnvironmentType {
  Production,
  Staging,
  Development,
  QA
}

export interface msalDetails {
  clientId: string,
  authority: string,
  redirectUri: string
}

export interface Environment {
  production: boolean;
  type: EnvironmentType;
  appVersion: string;
  userSessionDuration: number;
  shouldDetectUserSessionActivity: boolean;
  apiHost?: string;
  apiUrl: string;
  msalDetails: msalDetails;
}
