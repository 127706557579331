import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { LeadQueryCriterion } from '@digital/app/_models/lead-query-criterion';
import { LeadCriterionFormMappingService } from '@digital/app/lead/lead-query-criterion/lead-criterion-form-mapping.service';

@Injectable({
  providedIn: 'root'
})
export class LeadCriterionGroupFormMappingService {

  constructor(private fb: FormBuilder,
              private criterionMappingService: LeadCriterionFormMappingService) { }

  toForm(criterionGroup?: LeadQueryCriterion[]): FormArray {
    if (!Array.isArray(criterionGroup)) {
      criterionGroup = [];
    }

    const criterionElements = criterionGroup.map(this.toLogicalCriterionForm.bind(this));
    
    return this.fb.array(criterionElements, this.nonEmptyGroupValidator);
  }

  fromFormValue(formValue: any): LeadQueryCriterion[] {
    return formValue.map(this.fromLogicalCriterionFormValue.bind(this));
  }

  toLogicalCriterionForm(criterion?: LeadQueryCriterion): FormGroup {
    const criterionForm = this.criterionMappingService.toForm(criterion);
    const logicalOperator = [criterion?.logicalOperator || 'AND'];

    return this.fb.group({ criterion: criterionForm, logicalOperator })
  }

  fromLogicalCriterionFormValue(formValue: any): LeadQueryCriterion {
    const criterion = this.criterionMappingService.fromFormValue(formValue.criterion);
    const logicalOperator = formValue.logicalOperator;

    return { ... criterion, logicalOperator };
  }

  private nonEmptyGroupValidator(form: FormArray): ValidationErrors | null {
    return form.length > 0 ? null : { 'emptyCriterionGroup': true };
  };
}
