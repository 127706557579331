import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// APIs
import { CompaniesAPI } from '@digital/app/_apis/companies.api';
import { FormsAPI } from '@digital/app/_apis/forms.api';
import { FlowsAPI } from '@digital/app/_apis/flows.api';

// Models
import { Form, FormState, FormType } from '../_models/form.model';
import { FormField, FormFieldToUpdate, FormFieldType } from '../_models/form-field.model';
import { CreateCustomFormFieldDTO } from '../_dtos/create-custom-field.dto';
import { IFTTTAction, IFTTTActionButton } from '../_interfaces/ifttt-action.inteface';
import { FlowShellService } from '../flow-shell/flow-shell.service';
import { CompanyInfo } from '../_models/company-info.model';
import { IFlowEditingPermissions } from '../_interfaces/flow-editing-permissions.interface';
import { LeadSelectedFormField } from './services/lead-selected-form-field.service';
import { LeadFormField } from '../_models/lead-form-field';

@Injectable({
  providedIn: 'root'
})
export class LeadFormService {
  isLoading$ = new BehaviorSubject(false);

  forms$ = new BehaviorSubject([new Form()]); // All forms for a specified year
  currentForm$ = new BehaviorSubject<Form>(null); // Currently selected form
  currentFormPdfURL$ = new BehaviorSubject<string>(null); // Currently selected form's pdf url
  currentFormFields$ = new BehaviorSubject<FormField[]>([]); // Currently selected form's fields

  constructor(
    private flowShellService: FlowShellService,
    private formsAPI: FormsAPI,
    private companiesAPI: CompaniesAPI,
    private flowsAPI: FlowsAPI,
    public leadSelectedFormField: LeadSelectedFormField
  ) {}

  isLoadingFormData$ = new BehaviorSubject(false);
  isLockedForEditingGlobally$ = new BehaviorSubject(false);

  editingPermissions$ = new BehaviorSubject<IFlowEditingPermissions>({
    isLockedForEditing: true,
    canLockForEditing: false,
    canTakeOverEditing: false
  });

  flow$ = this.flowShellService.flow$;
  companyInfo$ = new BehaviorSubject(new CompanyInfo());

  isNestedDataStructure = false;

  initialFormID?: number; // If given, this form ID will be loaded initially
  initialSectionID?: number; // If given, this section ID will be loaded initially
  initialInstance?: number; // If given, this instance will be loaded initially

  formFieldInsertToRetry?: FormField;

  setDefaultInitialComponentState(year = 2021) {
    this.isLoading$.next(true);
    this.getAllFormsForSpecifiedYear(year);
  }

  getAllFormsForSpecifiedYear(year: number) {
    this.resetComponentState();
    this.formsAPI.getAllFormsForSpecifiedYear(year).subscribe((forms) => {
      const updatedEditingStateForms = forms.map((form) => ({ ...form, state: 'Draft' }));
      const isFormListNotEmpty = !!forms.length;
      if (isFormListNotEmpty) {
        const initialForm = forms[0];
        console.log({ updatedEditingStateForms, forms, initialForm });
        // @ts-ignore
        this.forms$.next(updatedEditingStateForms);
        this.currentForm$.next(initialForm);
        this.currentFormPdfURL$.next(initialForm?.filePath);
        this.isLoading$.next(false);
        this.editingPermissions$.next({
          isLockedForEditing: false,
          canLockForEditing: false,
          canTakeOverEditing: false
        });

        this.getFieldsForForm(initialForm.id);
      }
    });
  }

  getFieldsForForm(formId: number) {
    this.formsAPI.getAllFieldsForFormByFormId(formId).subscribe((formFields) => {
      console.log({ formFields });
      this.currentFormFields$.next(formFields);
    });
  }

  resetComponentState() {
    this.forms$.next([new Form()]);
    this.currentForm$.next(null);
    this.currentFormPdfURL$.next(null);
    this.currentFormFields$.next([]);
  }

  deleteCustomFormField(field: FormField) {
    // const flowId = this.flow$.value.id;
    // const formId = this.currentForm$.value.id;
    // this.isLoading$.next(true);

    // this.formsAPI.deleteCustomFormField(flowId, formId, field.id).subscribe((response) => {
    //   this.formFieldInsertToRetry = undefined;
    //   this.isLoading$.next(false);

    //   if (response.fieldsToUpdate) {
    //     const currentFormFields = this.currentFormFields$.value;
    //     this.updateRequiredFormFields2(currentFormFields, response.fieldsToUpdate);
    //   }

    //   if (response.actions) {
    //     response.actions.forEach((action) => {
    //       this.handleFormFieldAction(action, field);
    //     });
    //   }

    //   this.removeCustomFormFieldFromDataStructure(field);
    // });
    console.log({ field });
  }

  updateCustomFormField(field: FormField) {
    // const flowId = this.flow$.value.id;
    // const formId = this.currentForm$.value.id;
    // return this.formsAPI.updateCustomFormField(flowId, formId, field.id, { name: field.name });
    console.log({ field });
  }

  private findFieldObjectByID(data: FormField[], id: number): FormField | undefined {
    let result: FormField;
    function iter(a: FormField) {
      if (a.id === id) {
        result = a;
        return true;
      }
      return Array.isArray(a.fields) && a.fields.some(iter);
    }
    data.some(iter);
    return result;
  }

  private handleActionRequiredButtonClicked(button: IFTTTActionButton, field: FormField) {
    // switch (button.type) {
    //   case IFTTTActionButtonType.ADD_FORM:
    //     this.addFormsFromFieldAction([button.formID], field);
    //     break;
    //   default:
    //     this.revertFormFieldValue(field);
    //     break;
    // }
    console.log({ button, field });
  }

  private addFormsFromFieldAction(formIDs: number[], field: FormField) {
    // this.flowFormsAPI.addOrDeleteDigitalForms(this.flow$.value.id, formIDs, [], [], []).subscribe(
    //   (_) => {
    //     this._getFlowForms(this.flow$.value.id);
    //     if (this.formFieldInsertToRetry) {
    //       this.insertFormFieldValue(this.formFieldInsertToRetry);
    //     }
    //   },
    //   (error) => {
    //     this.revertFormFieldValue(field);
    //     console.log(error);
    //   }
    // );
    console.log({ formIDs, field });
  }

  private revertFormFieldValue(field: FormField) {
    // const currentFormFields = cloneDeep(this.currentFormFields$.getValue());
    // const foundField = this.getNestedFormField(currentFormFields, field.id);

    // if (foundField) {
    //   foundField.value = field.previousValue;
    //   foundField.newValue = field.previousValue;
    //   this.currentFormFields$.next(currentFormFields);
    // }
    console.log({ field });
  }

  private getNestedFormField(fields: FormField[], fieldId: number): FormField | undefined {
    let foundField: FormField;

    for (const field of fields) {
      if (field.id === fieldId) {
        foundField = field;
        break;
      } else {
        if (field.fields && field.fields.length > 0) {
          const f = this.getNestedFormField(field.fields, fieldId);
          if (f) {
            foundField = f;
            break;
          }
        }
      }
    }

    return foundField;
  }

  updateFormState(state: FormState) {
    // const flowId = this.flow$.value.id;
    // const formId = this.currentForm$.value.id;
    // const instanceID = this.currentForm$.value.instanceID;
    // this.formsAPI.updateFormState(state, flowId, formId, instanceID).subscribe((_) => {
    //   const updatedForm = this.currentForm$.value;
    //   updatedForm.state = state;
    //   console.log('reached currentForm$Url.next');
    //   this.currentForm$.next(updatedForm);

    //   // TODO: Respond to the post-logic validations from the server.
    // });
    console.log({ state });
  }

  createCustomFormField(dto: CreateCustomFormFieldDTO) {
    // const flowId = this.flow$.value.id;
    // const formId = this.currentForm$.value.id;
    // const instance = this.currentForm$.value.instance;
    // // const section = this.findFieldObjectByID(this.currentFormFields$.value, dto.reconcileParentSection);

    // // dto.position = section.fields?.length - 1;
    // return this.formsAPI
    //   .createCustomFormField(flowId, formId, dto, instance)
    //   .pipe(map((field) => this.appendCustomFormFieldToDataStructure(field)));
    console.log({ dto });
  }

  appendCustomFormFieldToDataStructure(field: FormField) {
    // const fields = this.currentFormFields$.value;
    // const section = this.findFieldObjectByID(fields, field.reconcileParentSection);
    // if (!section || !section.fields) {
    //   return;
    // }
    // section.fields = [...section.fields, field].sort((a, b) => a.position - b.position);
    // this.currentFormFields$.next(section.fields);
    // return field;
    console.log({ field });
  }

  insertFormFieldValue(field: FormField) {
    // this.formsAPI
    //   .insertFormFieldValue(this.flow$.value.id, this.currentForm$.value.id, field, this.currentForm$.value.instance)
    //   .subscribe(
    //     (response) => {
    //       this.formFieldInsertToRetry = undefined;

    //       if (response.fieldsToUpdate) {
    //         const currentFormFields = this.currentFormFields$.value;
    //         this.updateRequiredFormFields2(currentFormFields, response.fieldsToUpdate);
    //       } else if (!response.actions) {
    //         const clonedField = cloneDeep(field);
    //         clonedField.newValue = field.value;
    //         clonedField.value = field.value;
    //       }

    //       if (response.actions) {
    //         response.actions.forEach((action) => {
    //           this.handleFormFieldAction(action, field);
    //         });
    //       }
    //     },
    //     (error) => {
    //       this.revertFormFieldValue(field);
    //       console.log(error);
    //     }
    //   );
    console.log({ field });
  }

  private updateRequiredFormFields2(currentFormFields: FormField[], fieldsToUpdate: FormFieldToUpdate[]) {
    // fieldsToUpdate.forEach((fieldToUpdate) => {
    //   const foundField = this.findFieldObjectByID(currentFormFields, fieldToUpdate.fieldID);

    //   if (foundField) {
    //     if (foundField.reconcileParentSection) {
    //       const foundParentSection = this.findFieldObjectByID(currentFormFields, foundField.reconcileParentSection);
    //       if (foundParentSection) {
    //         const fieldIndex = foundParentSection.fields?.findIndex((f) => f.id === fieldToUpdate.fieldID);
    //         const clonedField = cloneDeep(foundParentSection.fields[fieldIndex]);
    //         clonedField.newValue = fieldToUpdate.updatedValue;
    //         clonedField.value = fieldToUpdate.updatedValue;
    //         foundParentSection.fields[fieldIndex] = clonedField;
    //       }
    //     } else {
    //       const fieldIndex = currentFormFields.findIndex((f) => f.id === fieldToUpdate.fieldID);
    //       const clonedField = cloneDeep(currentFormFields[fieldIndex]);
    //       clonedField.newValue = fieldToUpdate.updatedValue;
    //       clonedField.value = fieldToUpdate.updatedValue;
    //       currentFormFields[fieldIndex] = clonedField;

    //       this.updatePdfElementValue(currentFormFields[fieldIndex], clonedField);
    //     }
    //   }
    // });
    console.log({ currentFormFields, fieldsToUpdate });
  }

  private updatePdfElementValue(currentFormFields: FormField, clonedField: FormField) {
    const pdfElement = document.getElementById(`pdf-${currentFormFields.id}`) as HTMLInputElement;
    pdfElement.value = clonedField.value;
  }

  private handleFormFieldAction(action: IFTTTAction, field: FormField) {
    // switch (action.type) {
    //   case IFTTTActionType.POPUP:
    //     this.formFieldInsertToRetry = field;
    //     this.presentActionRequiredPopup(action, field);
    //     break;
    //   case IFTTTActionType.SHOWFIELDS:
    //     this.presentFieldsRequired(action);
    //     break;
    //   case IFTTTActionType.HIDEFIELDS:
    //     this.hideFieldsRequired(action);
    //     break;
    //   default:
    //     this.revertFormFieldValue(field);
    //     break;
    // }
    console.log({ action, field });
  }

  private hideFieldsRequired(action: IFTTTAction) {
    // const fields = action.fields.split(',');
    // let elementField;
    // fields.forEach((field) => {
    //   elementField = document.getElementById(`section-${field}`);
    //   elementField.hidden = true;
    // });
    console.log({ action });
  }

  private presentFieldsRequired(action: IFTTTAction) {
    // const fields = action.fields.split(',');
    // let elementField;
    // fields.forEach((field) => {
    //   elementField = document.getElementById(`section-${field}`);
    //   elementField.hidden = false;
    // });
    console.log({ action });
  }

  private presentActionRequiredPopup(action: IFTTTAction, field: FormField) {
    // const ref = this.modalService.open(ActionRequiredModalComponent, { centered: true });
    // ref.componentInstance.action = action;
    // ref.result.then((button: IFTTTActionButton) => this.handleActionRequiredButtonClicked(button, field));
    console.log({ action, field });
  }

  get currentForm(): Form {
    return this.currentForm$.value;
  }

  selectField(field: FormField) {
    const leadFormField: LeadFormField = {
      valueType: field.type,
      year: field.version,
      formId: field.formID,
      formName: `${this.currentForm?.formTaxID} - ${this.currentForm?.name}`,
      fieldId: field.id,
      fieldName: field.name
    };

    this.leadSelectedFormField.selectField(leadFormField);
  }
}
