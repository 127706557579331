<div class="main-form-view-search">
  <div class="container-fluid">
    <div class="row no-gutters">
      <div class="col-12">
        <div class="input-container">
          <app-svg-icon name="search_special" class="search-icon"></app-svg-icon>
          <input type="text" placeholder="חיפוש" (keyup)="searchFieldsKeywordTyped($event.target.value)" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main-form-content">
  <ng-container *ngIf="isLoadingFormData$ | async">
    <div class="loading-form-content">
      <app-loading></app-loading>
    </div>
  </ng-container>
  <ng-container *ngIf="!(isLoadingFormData$ | async)">
    <div class="save-form-bar">
      <div class="title-container">
        <h4 class="title">{{ (currentForm$ | async).formTaxID }} - 
          <span *ngIf="(currentForm$ | async).instance > 0">({{ (currentForm$ | async).instance }}) </span> 
          {{ (currentForm$ | async).name }}</h4>
        <p class="subtitle" [innerHTML]="(currentForm$ | async).subtitle"></p>
      </div>
      <div class="button-container">
        <button
          *ngIf="!(editingPermissions$ | async).isLockedForEditing"
          class="save-form-button btn"
          [class.btn-success]="(currentForm$ | async).state === formState.Draft"
          [class.btn-outline-primary]="(currentForm$ | async).state === formState.Published"
          (click)="changeCurrentFormState()"
        >
          <app-svg-icon
            [name]="(currentForm$ | async).state === formState.Draft ? 'checkmark_special' : 'edit_special'"
          ></app-svg-icon>
          {{ (currentForm$ | async).state === formState.Draft ? 'סיימתי למלא את טופס זה' : 'פתיחת הטופס לעריכה מחדש' }}
        </button>
      </div>
    </div>
    <!--  -->
    <div class="form-content">
      <div class="row no-gutters">
        <div class="col" [hidden]="!showRightSide">
          <div class="container-right">
            <div class="input-group row">
              <div class="col" [hidden]="!showRightSide">
                <div [hidden]="showNotesOrEdit">
                  <app-add-note *ngIf="showAddNewNote" [tempNote]="tempNote" [mentionUsers]="mentionUsers" (noteAdded)="onCircleCreate($event)"></app-add-note>
                  <app-notes-list [mentionUsers]="mentionUsers" (hideRightClickMenu)="removeRightClickMenuComponentRef()" (hideAddNewNote)="showAddNewNote = false;"></app-notes-list> 
                </div>
                <!-- Start fields tree -->
                <app-fields-tree [hidden]="!showNotesOrEdit"
                  [fields]="filteredFields$ | async"
                  [shouldLockFieldsForEditing]="shouldLockFieldsForEditing"
                  [editingPermissions]="editingPermissions$ | async"
                  (didUpdateFormFieldValue)="didUpdateFormFieldValue($event)"
                  (mouseDidEnterFormField)="mouseDidEnterFormField($event)"
                  (mouseDidLeaveFormField)="mouseDidLeaveFormField($event)"
                  (createCustomField)="createCustomField($event)"
                  (deleteFieldButtonClicked)="deleteCustomFormField($event)"
                ></app-fields-tree>
                <!-- End fields tree -->
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class = "collapse-bar-button" (click)="showRightSide = !showRightSide;" ngbTooltip="{{showRightSide? 'הסתר צד ימין':'הצג צד ימין'}}">
            <app-svg-icon name="{{ showRightSide ? 'angle-right_special' : 'angle-left_special' }}" class="arrow-circle-icon"></app-svg-icon>
          </button>
          <button class = "collapse-bar-button" (click)="showNotesOrEdit = !showNotesOrEdit; showRightSide=true" >
            {{showNotesOrEdit? 'הערות' : 'עריכה'}}
          </button>
        </div>
        <div 
              [class.col-12]="!showRightSide" 
              [class.col-8]="!showNotesOrEdit && showRightSide" 
              [class.col-6]="showNotesOrEdit && showRightSide" 
              [class.close-for-edit]="this.currentForm$.value.state === formState.Published" 
              *ngIf="isPdfFormAvailable"
              >
              <!-- [ngStyle]="{'margin-top': !showRightSide ? '-110px': '0px', 'padding-right': '90px'}" -->
          <div class="container-left">
            <div>
              <div class="pdf-container">
                <div class="page-tracker">עמוד {{ currentPage }} מתוך {{ totalPages }}</div>
                <p *ngIf="!showNotesOrEdit && showRightSide" class="alert-text">*נא ללחוץ  על הלחצן הימני בעכבר על מנת לפתוח תפריט פעולות</p>
                <pdf-viewer
                  (contextmenu)="onRightClick($event)"
                  (mousewheel)="onMouseWheel($event)"
                  (DOMMouseScroll)="onMouseWheel($event)"
                  [src]="currentFormPdfURL$ | async"
                  [(page)]="currentPage"
                  (page-rendered)="onPageRendered($event)"
                  (after-load-complete)="onLoadComplete($event)"
                  [original-size]="true"
                  [show-all]="true"
                  [render-text]="false"
                  [autoresize]="true"
                  [zoom-scale]="'page-width'"
                  [show-borders]="false"
                >
                </pdf-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
