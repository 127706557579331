import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class ExternalUserGuard implements CanActivate {
  constructor(private _authenticationService: AuthenticationService) {}
  canActivate(): boolean {
    return this._authenticationService.getCurrentUser().role !== 'external';
  }
}
