import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsService } from '@digital/app/forms/forms.service';
import { AlertModalComponent } from '@digital/app/shared/components/alert-modal/alert-modal.component';
import { FormField } from '@digital/app/_models/form-field.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-field-custom-controls',
  templateUrl: './field-custom-controls.component.html',
  styleUrls: ['./field-custom-controls.component.scss']
})
export class FieldCustomControlsComponent implements OnInit {
  @Input() field: FormField;

  @Output() editFieldButtonClicked = new EventEmitter<FormField>();

  constructor(private formsService: FormsService, private modal: NgbModal) {}

  ngOnInit(): void {}

  deleteFieldButtonClicked() {
    const confirmMessage = `האם ברצונך למחוק את השדה "${this.field.name}"? פעולה זו לא ניתנת לשחזור`;

    const ref = this.modal.open(AlertModalComponent, { centered: true }).componentInstance;
    ref.title = 'מחיקת שדה מותאם אישית';
    ref.message = confirmMessage;
    ref.positiveAction('מחיקה', () => this.formsService.deleteCustomFormField(this.field));
    ref.negativeAction('ביטול', () => {});
  }
}
