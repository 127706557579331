<div class="blog container-fluid">
  <!--  -->
  <div class="page-header">
    <div class="row">
      <div class="col">
        <h5 class="title">תקשורים מקצועיים</h5>
        <p class="description">בלוח תקשורים מקצועיים תוכלו לראות עדכונים ותקשורים חדשים</p>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="empty-state" *ngIf="!posts || posts.length === 0">
    <p>לא קיימים תקשורים. אנא נסו מאוחר יותר</p>
  </div>
  <!--  -->
  <div class="posts-main" *ngIf="posts && posts.length > 0">
    <app-post [post]="posts[0]"></app-post>
  </div>
  <!--  -->
  <div class="posts-grid" *ngIf="posts && posts.length > 1">
    <div class="row">
      <div class="col-6" *ngFor="let post of posts | slice: 1">
        <app-post [post]="post"></app-post>
      </div>
    </div>
  </div>
  <!--  -->
</div>
<app-footer></app-footer>