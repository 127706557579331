import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetworkService } from '@libs/core/network/network.service';
import { GlobalAPI } from '../../shared/constants/index';
import { DuplicateForm, Form } from '../../form/models/form.model';
import { FormFields, OptionField } from '../../form/models/form-fields.model';
import { PullDataParams } from '../../form/models/pull-data-params.model';
import { environment } from '@digital/environments/environment';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Section } from '../../form/models/sections.model';

@Injectable({ providedIn: 'root' })

export class FormsApi {
  private baseUrl: string;
  // Lifecycle
  constructor(private _network: NetworkService, private httpClient: HttpClient, private _authenticationService: AuthenticationService) {
    this.baseUrl = environment.apiUrl;
  }

  public getAllForms(): Observable<Form[]> {
    return this._network.get(this.baseUrl, GlobalAPI.endpoints.forms.base).
      pipe(map(response => (response.data.forms)));
  }

  public getSingleForm(formID: number): Observable<Form> {
    return this._network.get(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/${formID}`)
      .pipe(map(response => (response.data.form)));
  }

  public createNewForm(form: Form) {
    return new Observable((observer: Observer<Form>) => {
      const token = this._authenticationService.getCurrentUser().accessToken;
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.open('POST', `${this.baseUrl}${GlobalAPI.endpoints.forms.base}`, true);
      xhr.setRequestHeader('X-Authorization', token);
      const formData = new FormData();
      formData.append('formTaxID', form.formTaxID.toString());
      formData.append('formYear', form.formYear.toString());
      formData.append('sectionID', form.sectionID.toString());
      formData.append('name', form.name.toString());
      formData.append('file', form.filePath);
      formData.append('type', form.type);
      xhr.send(formData);
      xhr.onload = () => {
        if (xhr.readyState === xhr.DONE) {
          if (xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
          } else {
            observer.error(xhr.response);
          }
        }
      };
    });
  }

  public duplicateForm(duplicateForm: DuplicateForm): Observable<Form> {
    return new Observable((observer: Observer<Form>) => {
      const token = this._authenticationService.getCurrentUser().accessToken;
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.open('POST', `${this.baseUrl}${GlobalAPI.endpoints.forms.base}/${GlobalAPI.endpoints.duplicate.base}`, true);
      xhr.setRequestHeader('X-Authorization', token);
      const formData = new FormData();
      formData.append('formTaxID', duplicateForm.formTaxID.toString());
      formData.append('newFormYear', duplicateForm.newFormYear.toString());
      formData.append('year', duplicateForm.year.toString());
      formData.append('file', duplicateForm.filePath);
      xhr.send(formData);
      xhr.onload = () => {
        if (xhr.readyState === xhr.DONE) {
          if (xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
          } else {
            observer.error(xhr.response);
          }
        }
      };
    });
  }

  public getFormFields(formID: number): Observable<FormFields[]> {
    return this._network.get(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/${formID}/${GlobalAPI.endpoints.formsFields.base}`).
      pipe(map(response => (response.data.formFields)));
  }

  public createFormFields(fields: FormFields, formID: number): Observable<FormFields> {
    return this._network.post(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/${formID}/${GlobalAPI.endpoints.formsFields.base}`, fields)
      .pipe(map(response => (response.data)));
  }

  public createOptionFields(field: OptionField, parentFieldID: number): Observable<OptionField> {
    return this._network.post(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/${GlobalAPI.endpoints.fields.base}/${parentFieldID}/${GlobalAPI.endpoints.option.base}`, field)
      .pipe(map(response => (response.data)));
  }

  public getFormFieldOptions(parentFieldID: number): Observable<OptionField[]> {
    return this._network.get(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/${GlobalAPI.endpoints.fields.base}/${parentFieldID}/${GlobalAPI.endpoints.option.base}`).
      pipe(map(response => (response.data.options)));
  }

  public updateOptionFields(optionField: OptionField, optionFieldID: number) {
    return this._network.patch(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/${GlobalAPI.endpoints.fields.base}/${GlobalAPI.endpoints.option.base}/${optionFieldID}`, optionField);
  }

  public updateFormFields(fields: FormFields, fieldID: number) {
    return this._network.patch(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/${GlobalAPI.endpoints.formsFields.base}/${fieldID}`, fields);
  }

  public getSingleFormField(fieldID: number): Observable<FormFields> {
    return this._network.get(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/field/${fieldID}`)
      .pipe(map(response => (response.data.formFields)));
  }

  public deleteFieldByID(fieldID: number) {
    return this._network.delete(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/fields/${fieldID}`);
  }

  public deleteOptionFieldByID(fieldID: number) {
    return this._network.delete(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/fields/options/${fieldID}`);
  }

  public getPullDataParams(): Observable<PullDataParams> {
    return this._network.get(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/pull_data/params`)
      .pipe(map(response => (response.data.parameters)));
  }

  public getSections(): Observable<Section[]> {
    return this._network.get(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/categories/sections`)
      .pipe(map(response => (response.data.sections)));
  }

  public updateForm(form: Form, formID: number) {
    return this._network.patch(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/${formID}`, form);
  }

  public deleteForm(formID: number) {
    return this._network.delete(this.baseUrl, `${GlobalAPI.endpoints.forms.base}/${formID}`);
  }

}
