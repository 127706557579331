import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-disclaimer-alert-modal',
  templateUrl: './disclaimer-alert-modal.component.html',
  styleUrls: ['./disclaimer-alert-modal.component.scss']
})
export class DisclaimerAlertModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  close(approved) {
    this.activeModal.close(approved);
  }
}
