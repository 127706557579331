import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileService } from '@libs/core/services/file.service';
import { Flow } from '@digital/app/_models/flow.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { FlowService } from '@digital/app/core/services/flow.service';
import { UploadFileType } from '@libs/enums/upload-file-type.enum';
import { StageFile } from '@digital/app/_apis/files.api';
import { ErrorDialogComponent } from '@libs/views/error-dialog/error-dialog.component';
import { PreviewFormModalComponent } from '@digital/app/flow/pages/flow/components/_general/preview-form-modal/preview-form-modal.component';
import { UploadFileStageType } from '@libs/enums/upload-file-stage-type.enum';
import { FlowStage } from '@digital/app/_enums/flow-stages.enum';

enum UploaderType {
  Small = 'small'
}

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  @Input() instructions: string;
  @Input() type: UploaderType;
  @Input() maxFiles: number; // 0 means unlimited
  @Input() files: StageFile[] = [];
  @Input() flow: Flow;

  @Output() containsUploadedFiles = new EventEmitter<boolean>();

  get allowMultipleFilesUpload(): boolean {
    return (this.maxFiles > 1 && this.files.length < this.maxFiles - 1) || this.maxFiles === 0;
  }

  constructor(private _filesService: FileService, private _flowService: FlowService, private modalService: NgbModal) {}

  ngOnInit() {
    this.getFiles();
    this.updateStagesFromConfirmationModal();
  }

  upload(files: File[]) {
    if (this.maxFiles > 0 && files.length > this.maxFiles) {
      this.showMaxFilesReachedErrorDialog();
      return;
    }

    let stageType: UploadFileStageType;
    switch (this.flow.stage.id) {
      case FlowStage.SIGNATURE_IMPLEMENTATION:
        stageType = UploadFileStageType.ClientSignature;
        break;
      case FlowStage.IRS_CONFIRMATION:
        stageType = UploadFileStageType.IrsConfirmation;
        break;
      default:
        break;
    }

    this._filesService
      .upload({
        files: Array.from(files),
        flowID: this.flow.id,
        type: UploadFileType.STAGE,
        stageType,
        stageID: this.flow?.stage.id
      })
      .subscribe(
        (success) => {
          if (success) {
            this.getFiles();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  deleteFile(file: StageFile) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      windowClass: 'confirmation-modal',
      centered: true
    });
    modalRef.componentInstance.title = 'שימו לב!';
    modalRef.componentInstance.content = 'הקובץ ימחק ולא יהיה ניתן לשחזור. האם למחוק?';
    modalRef.result.then((approve) => {
      if (approve) {
        this._filesService
          .delete({
            flowID: this.flow.id,
            originalName: file.fileName
          })
          .subscribe((success) => {
            if (success) {
              this.getFiles();
            }
          });
      }
    });
  }

  getFiles() {
    this._filesService
      .getFiles({
        flowID: this.flow?.id,
        type: UploadFileType.STAGE,
        stageID: this.flow?.stage.id
      })
      .subscribe(
        (files) => {
          this.files = files;
          this.containsUploadedFiles.emit(files.length > 0);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  downloadFile(file: StageFile) {
    this._filesService.download({ fileID: file.id }).subscribe((buffer) => {
      const blob = new Blob([buffer], { type: 'blob' });
      const modalRef = this.modalService.open(PreviewFormModalComponent, {
        windowClass: 'preview-form-modal',
        scrollable: true,
        centered: true,
        size: 'xl'
      });
      modalRef.componentInstance.file = URL.createObjectURL(blob);
      modalRef.componentInstance.name = file.title;
      modalRef.componentInstance.kind = file.kind;
    });
  }

  updateStagesFromConfirmationModal() {
    this._flowService.updateStageFile().subscribe(($response: number) => {
      if ($response === 200) {
        this.getFiles();
      }
    });
  }

  private showMaxFilesReachedErrorDialog() {
    const modalRef = this.modalService.open(ErrorDialogComponent, {
      windowClass: 'confirmation-modal',
      centered: true
    });
    modalRef.componentInstance.title = 'שימו לב!';
    if (this.maxFiles === 1) {
      modalRef.componentInstance.body = 'לא ניתן להעלות יותר מקובץ אחד';
    } else if (this.maxFiles > 1) {
      modalRef.componentInstance.body = `לא ניתן להעלות יותר מ-${this.maxFiles} קבצים`;
    }
  }
}
