import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Routes
const digitalFormRoutes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(digitalFormRoutes)],
  exports: [RouterModule]
})
export class DigitalFormsRoutingModule {}
