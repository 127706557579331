import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseDatePipe'
})
export class ParseDatePipe implements PipeTransform {
  private MONTHS = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'];

  transform(value: any, ...args: any[]): any {
    if (args[0] === 'time') {
      return this.parseHebrewDateTime(new Date(value));
    }
    return this.parseHebrewDate(new Date(value));
  }

  private parseHebrewDate(date: Date) {
    const extDate = date.getDate();
    const extMonth = this.MONTHS[date.getMonth()];
    const extYear = date.getFullYear();

    return `${extDate} ${extMonth}, ${extYear}`;
  }

  private parseHebrewDateTime(date: Date) {
    const extDate = this.parseHebrewDate(date);

    const extHour = this.addLeadingZeroIfNeeded(date.getHours());
    const extMinutes = this.addLeadingZeroIfNeeded(date.getMinutes());
    const extTime = `${extHour}:${extMinutes}`;

    const dateTime = `
    ${extDate}
    שעה:
    ${extTime}`;

    return dateTime;
  }

  private addLeadingZeroIfNeeded(toNumber: number): string {
    return ('0' + toNumber).slice(-2);
  }
}
