import { Component, OnInit, ElementRef } from '@angular/core';
import { FormsService } from '@digital/app/forms/forms.service';
import { FieldBaseComponent } from '../field-base/field-base.component';

@Component({
  selector: 'app-field-title',
  templateUrl: './field-title.component.html',
  styleUrls: ['../field-base/field-base.component.scss', './field-title.component.scss']
})
export class FieldTitleComponent extends FieldBaseComponent implements OnInit {
  /* #region Lifecycle */

  constructor(public _elementRef: ElementRef, public formsService: FormsService) {
    super(_elementRef, formsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /* #endregion */
}
