import { Injectable } from '@angular/core';
import { GlobalAPI } from '@digital/app/_constants';
import { API } from './api';
import { pluck } from 'rxjs/operators';
import { LeadQuery } from '../_models/lead-query';
import { Observable } from 'rxjs';
import { LeadQueryRow } from '../_models/lead-query-row';
import APIResponse from '@libs/core/network/model/api-response.model';
import { LeadQueryCriterion } from '../_models/lead-query-criterion';
import { LeadQueryResult } from '../_models/lead-query-result';

@Injectable({
  providedIn: 'root'
})
export class LeadAPI extends API {
  private queryEndpoint = GlobalAPI.endpoints.lead.query;
  private usersEndpoint = GlobalAPI.endpoints.users.base;

  getQueries(): Observable<LeadQueryRow[]> {
    const id = this._authenticationService.getCurrentUser().id;

    return this._network.get(GlobalAPI.baseURL, `${GlobalAPI.endpoints.lead.queries}/${id}`).pipe(pluck('data'));
  }

  getQuery(id: number): Observable<LeadQuery> {
    return this._network.get(GlobalAPI.baseURL, `${this.queryEndpoint}/${id}`).pipe(pluck('data'));
  }

  addQuery(query: LeadQuery): Observable<number> {
    const apiQuery = this.toApiQuery(query);
    apiQuery.doSave = true;

    return this._network.post(GlobalAPI.baseURL, this.queryEndpoint, apiQuery).pipe(pluck('data'), pluck('id'));
  }

  updateQuery(id: number, query: LeadQuery) {
    const apiQuery = this.toApiQuery(query);
    delete apiQuery.id;
    delete apiQuery.createdAt;
    delete apiQuery.updatedAt;

    return this._network.put(GlobalAPI.baseURL, `${this.queryEndpoint}/${id}`, apiQuery);
  }

  deleteQuery(id: number): Observable<APIResponse> {
    return this._network.delete(GlobalAPI.baseURL, `${this.queryEndpoint}/${id}`);
  }

  getQueryResults(id: number): Observable<APIResponse> {
    return this._network.get(GlobalAPI.baseURL, `${this.queryEndpoint}/execute/${id}`);
  }

  getCriteriaResults(criteria: LeadQueryCriterion[]): Observable<LeadQueryResult[]> {
    const apiCriteria = this.toApiCriteria(criteria);

    const apiQuery = {
      userID: this._authenticationService.getCurrentUser().id.toString(),
      queryName: 'query results',
      years: [],
      queryCriterias: apiCriteria,
      doSave: false
    };

    return this._network
      .post(GlobalAPI.baseURL, this.queryEndpoint, apiQuery)
      .pipe(
        pluck('data'),
        pluck('companiesList'),
        pluck('finalResults')
      );
  }

  shareQuery(queryId: number, userId: number): Observable<APIResponse> {
    return this._network.put(GlobalAPI.baseURL, `${this.queryEndpoint}/share/${queryId}/${userId}`);
  }

  copyQuery(copiedQueryId: number, newQueryName: string): Observable<APIResponse> {
    return this._network.post(GlobalAPI.baseURL, `${this.queryEndpoint}/copy/${copiedQueryId}/${newQueryName}`);
  }

  getYears(): Observable<number[]> {
    return this._network
      .get(GlobalAPI.baseURL, `${GlobalAPI.endpoints.lead.queries}/get-flows-year-range`)
      .pipe(pluck('data'), pluck('years'));
  }

  private toApiQuery(query: LeadQuery): any {
    const apiQuery = { ... query };

    apiQuery.userID = this._authenticationService.getCurrentUser().id.toString();
    apiQuery.queryCriterias = this.toApiCriteria(apiQuery.queryCriterias);

    return apiQuery;
  }

  private toApiCriteria(criteria: any): any {
    return criteria.map((criterionGroup, i) => {
      const apiCriterionGroup = this.toApiCriterion(criterionGroup, 'parentheses', i);
      
      apiCriterionGroup.children = apiCriterionGroup
        .children
        .map((criterion, i) => this.toApiCriterion(criterion, 'fragment', i));

      // The following values have no meaning, and are used only to pass the schema validation of the server.
      apiCriterionGroup.fieldId = -1;
      apiCriterionGroup.formId = -1;
      apiCriterionGroup.comparisonOperator = 'GT';
      apiCriterionGroup.fieldValue = '-1';
      apiCriterionGroup.valueType = 'number';


      return apiCriterionGroup;
    });
  }

  private toApiCriterion(criterion: any, type: string, i: number): any {
    const apiCriterion = { ... criterion };

    apiCriterion.type = type;
    apiCriterion.orderIndex = i;

    return apiCriterion;
  }

  getUsersOfTaxDivision(): Observable<APIResponse> {
    return this._network.get(GlobalAPI.baseURL, `${this.usersEndpoint}/tax-division`);
  }
}
