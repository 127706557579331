import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { FlowService } from '@digital/app/core/services/flow.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { CreateFlow } from '../../models/create-flow.model';
import { Flow, RiskLevelType } from '@digital/app/_models/flow.model';
import { Router, ActivatedRoute } from '@angular/router';
import { FlowType } from '@digital/app/_enums/flow-type.enum';
import { UserService } from '@digital/app/core/services/user.service';
import { AuthenticationService } from '@libs/authentication/authentication.service';

import { GlobalRoutes } from '@libs/constants';
import { DigitalitRoutes } from '@digital/app/_constants';
import { ExternalUser } from '@digital/app/_models/client-users';

@Component({
  selector: 'app-create-flow',
  templateUrl: './create-flow.component.html',
  styleUrls: ['./create-flow.component.scss']
})
export class CreateFlowComponent implements OnInit {
  // Viewchilds
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  private _currentUser = this._authenticationService.getCurrentUser();

  // Flow Type
  flowType: FlowType;
  flowTypeEnum = FlowType;

  //Risk Type
  RiskLevelType = RiskLevelType;

  // Form group
  createFlowForm: FormGroup;
  financialGroup: FormGroup;

  // Class members
  isPermissionDisable: boolean = true;
  subjectPermissionDisable: Subject<boolean> = new Subject();

  newFlow = new CreateFlow();
  isSubmitClick = false;
  isLoading = false;

  // Lifecycle
  constructor(
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _flowService: FlowService,
    private toastr: ToastrService
  ) {
    this.getFlowType();
    this.toastr.toastrConfig.preventDuplicates = true;
  }

  ngOnInit() {
    this._setupFinancialFormBuilder();
    this._setupFormBuilder();
    this._initDisableFieldGroup();
  }

  //getters and setters
  get flowName() {
    const flowType = this.flowTypeEnum[this.flowType];
    switch (flowType) {
      case 'Tax':
        return 'דוח מס';
      case 'Financial':
        return 'דוח כספי';
      case 'SpecialPermit':
        return 'אישור מיוחד';
      default:
        return '';
    }
  }

  get externalUsers() {
    return this.createFlowForm.get('permissions').get('externalUsers') as FormArray;
  }

  // Setup
  private _setupFinancialFormBuilder() {
    this.financialGroup = this._formBuilder.group({
      checkEqcr: [true, Validators.required],
      uncheckEqcr: [false, Validators.required],
      checkConsultation: [true, Validators.required],
      uncheckConsultation: [false, Validators.required]
    });
  }

  private _initDisableFieldGroup() {
    this.disableFieldGroup('taxYear', true, 'details');
    this.disableFieldGroup('managers', true, 'permissions');
  }

  private _setupFormBuilder() {
    this.createFlowForm = this._formBuilder.group({
      details: this._formBuilder.group({
        company: this._formBuilder.group({
          name: ['', Validators.required],
          id: ['', Validators.required]
        }),
        taxYear: ['', Validators.required],
        deadline: ['', Validators.required],
        riskLevel: ['']
      }),
      permissions: this._formBuilder.group({
        managers: this._formBuilder.group({
          partner: ['', Validators.required],
          manager: ['', Validators.required]
        }),
        internalUsers: this._formBuilder.array([new FormControl('')]),
        externalUsers: this._formBuilder.array([
          this._formBuilder.group({
            externalUserType: [''],
            externalUserEmail: [
              '',
              Validators.compose([
                Validators.email,
                Validators.pattern(
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
              ])
            ],
            externalUserFirstName: [''],
            externalUserLastName: ['']
          })
        ])
      })
    });
  }

  // Actions
  getFlowType(): void {
    this.flowType = FlowType[this._route.snapshot.queryParams.type as string];
    if (this.flowType === undefined) {
      this.cancelButtonPressed();
    }
  }

  //form functions
  cancelButtonPressed() {
    this._router.navigate([GlobalRoutes.dtax]);
  }

  submitFlow() {
    this.isSubmitClick = true;
    console.log(this.createFlowForm.get('details'));
    if (!this.createFlowForm.valid) {
      return;
    }
    this.isLoading = true;
    this.newFlow.typeID = 1; //only Tax. can see who to dell with more then tax in 1.1.3 version
    this.newFlow.creatorUserID = this._currentUser.id;
    this.newFlow.externalUsers = this.getEnteredExternalUsers();
    this._flowService
      .createNewFlow(this.newFlow, (flowBack: Flow) => {
        this._onFlowCreated(flowBack);
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
        this.restForm();
      });
  }

  private async _onFlowCreated(flow: Flow) {
    const action = 'יצירת תהליך חדש';
    await this._userService.createNewUserEvent(flow, action);
    this._router.navigate([DigitalitRoutes.flow.main, flow.id]);
  }

  restForm() {
    this.createFlowForm.reset();
    this.isPermissionDisable = true;
    this.isSubmitClick = false;
    this.newFlow.companyID = null;
    this.changeFormFieldState('company.name', 'details', '', false);
    this.changeFormFieldState('company.id', 'details', '', false);
    this.changeFormFieldState('taxYear', 'details', '', true);
    this.changeFormFieldState('managers', 'permissions', '', true);
  }

  //control group functionality
  private disableFieldGroup(fieldName: string, disable: boolean, groupName: string) {
    const field = this.createFlowForm.get(groupName).get(fieldName);
    if (disable) {
      field.disable();
    } else {
      field.enable();
    }
  }

  //help functions
  isTaxYearSet(taxYearSet: boolean) {
    this.isPermissionDisable = !taxYearSet;
    this.subjectPermissionDisable.next(taxYearSet);
  }

  getEnteredExternalUsers(): ExternalUser[] {
    const enteredExternalUsers: ExternalUser[] = [];
    for (let i = 0; i < this.externalUsers.length; i++) {
      const externalUserEmail = this.externalUsers.at(i).get('externalUserEmail').value;
      if (externalUserEmail) {
        const externalUserFirstName = this.externalUsers.at(i).get('externalUserFirstName').value;
        const externalUserLastName = this.externalUsers.at(i).get('externalUserLastName').value;
        const externalUserType = this.externalUsers.at(i).get('externalUserType').value;
        enteredExternalUsers.push({
          email: externalUserEmail,
          firstName: externalUserFirstName,
          lastName: externalUserLastName,
          role: externalUserType
        });
      }
    }
    return enteredExternalUsers;
  }

  changeFormFieldState(fieldName: string, mainGroup: string, value?: string, disable?: boolean) {
    const field = this.createFlowForm.get(mainGroup).get(fieldName);
    if (value !== undefined) {
      field.patchValue(value, { emitEvent: false });
    }
    if (disable !== undefined) {
      disable ? field.disable() : field.enable();
    }
  }
}
