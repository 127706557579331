<div class="flow-tabs-menu">
  <ul class="tabs">
    <li
      *ngFor="let tab of tabs"
      class="tab"
      [class.active]="tab.isActive"
      (click)="sendTabToParent(tab)"
      [class.disabled]="tab.isDisabled"
      [class.invalid]="tab.isInvalidInside"
    >
      <app-svg-icon [name]="tab.iconName" [class.disabled]="tab.isDisabled"></app-svg-icon>
      {{ tab.pageTitle }}
    </li>
  </ul>
</div>
