<div class="criterion-group-container">
  <div *ngFor="let c of criteria; index as i; let last = last">
    <div [id]="id(i)" class="row pb-2 align-items-end" [ngClass]="{ 'selected-field': isSelected(i) }">
      <div class="col">
        <app-lead-query-criterion [form]="criterion(i)" [id]="id(i)"> </app-lead-query-criterion>
      </div>
      <div class="col-1 text-left">
        <button *ngIf="!isLastCriterion" type="button" class="btn btn-danger" aria-label="Close" (click)="onDeleteCriterion(i, last)">X</button>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col">
        <app-lead-logical-operator-button *ngIf="!last" [form]="logicalOperator(i)" [id]="id(i)"> </app-lead-logical-operator-button>
      </div>
    </div>
  </div>
  <div *ngIf="isWaitingFieldLocal" class="row">
    <div class="col text-center">
      <p class="choose-field-tip">בחר שדה מהטופס</p>
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <button
        type="button"
        (click)="onAddCriterion()"
        [disabled]="isWaitingFieldGlobal"
        class="btn btn-primary add-criterion"
      >
        <div class="criterion-button__container">+</div>
      </button>
    </div>
  </div>
</div>
