import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '@digital/app/core/services/user.service';
import { UserEventsService } from '@libs/core/services/user-events.service';
import UserEvents from '@libs/core/network/model/users-events.model';

@Component({
  selector: 'app-flow-history-tab',
  templateUrl: './flow-history-tab.component.html',
  styleUrls: ['./flow-history-tab.component.scss']
})
export class FlowHistoryTabComponent implements OnInit {
  @Input() title: string;
  @Input() flowID: number;
  page: number;
  pageSize: number;
  collectionSize: number;
  userNameInitials: string;
  userEvents = [];

  constructor(private _userEventService: UserEventsService,
              private _userService: UserService) {}

  ngOnInit() {
    this._getUserEvents();
    this.updateEventsLogs();
  }

  private _getUserEvents() {
    this._userEventService.getAllFlowLogs(this.flowID, 1, (events: UserEvents) => this._onGetUserEvents(events, 1));
    // this._userEventService.createLogEvent(this._userService.getCurrentUser().id, UserEventType.ChangeManager);
    // this.userNameInitials = userService.getCurrentUser().getNameInitials();

  }

  private _onGetUserEvents(events: UserEvents, page: number) {
    this.userEvents = events.events;
    this.page = page;
    this.pageSize = 10;
    this.collectionSize = events.pages * 10;
  }

  pageChange(): void {
    this._userEventService.getAllFlowLogs(this.flowID, this.page,
      (events: UserEvents) => this._onGetUserEvents(events, this.page));
  }

   // Listen to change on stage if stage updated the observeble call
   updateEventsLogs() {
    // Oserveble (State managment) when move to new stage.
    this._userService.updateUserEvents().subscribe(($response: string) => {
      if ($response === 'success') {
      this._getUserEvents();
      }
    });
  }


}
