import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule as GlobalCoreModule } from '@libs/core/core.module';
import { ViewsModule as GlobalViewsModule } from '@libs/views/views.module';
import { CoreModule } from '@digital/app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthenticationModule } from '@libs/authentication/authentication.module';
import { HomeModule } from '@digital/app/home/home.module';
import { CreateFlowModule } from '@digital/app/create-flow/create-flow.module';
import { BlogModule } from '@digital/app/blog/blog.module';
import { BridgeLoginComponent } from './bridge-login/bridge-login.component';
import { FlowShellModule } from './flow-shell/flow-shell.module';

import { ErrorDialogService } from '@libs/core/services/errorDialog.service';
import { ErrorDialogComponent } from '@libs/views/error-dialog/error-dialog.component';
import { IconsService } from '@libs/core/services/icons.service';
import { SharedModule } from '@digital/app/shared/shared.module';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';

import { environment } from '@digital/environments/environment';

// Locale
import localeHebrew from '@angular/common/locales/he';
import { APP_ENV } from '@libs/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

//bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//msal
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory
} from './shared/utils/msalInstanceFactory';

import { LeadModule } from './lead/lead.module';

registerLocaleData(localeHebrew);

@NgModule({
  declarations: [AppComponent, ErrorDialogComponent, BridgeLoginComponent],
  imports: [
    LeadModule,
    
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,

    // Shared modules
    CoreModule,
    GlobalCoreModule,
    GlobalViewsModule,
    AuthenticationModule,

    // Internal modules
    HomeModule,
    CreateFlowModule,
    BlogModule,
    SharedModule,
    FlowShellModule,

    // Toastr
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      progressBar: true
    }),

    // @datorama/akita
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule.forRoot(),

    //msal
    MsalModule,

    // Routing
    AppRoutingModule,

    NgbModule
  ],
  entryComponents: [ErrorDialogComponent],
  providers: [
    IconsService,
    ErrorDialogService,
    { provide: LOCALE_ID, useValue: 'he-IL' },

    // @datorama/akita
    { provide: APP_ENV, useValue: environment },
    { provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' } },

    //msal
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
