import { Component, Input, OnInit } from '@angular/core';
import { ReconcileNumberType } from '@digital/app/_models/form-field.model';

interface IFieldInputTagProps {
  title: string;
  styleClass: string;
}

export enum FieldInputTagType {
  None = 'none',
  Both = 'both',
  Add = 'add',
  Subtract = 'subtract'
}

@Component({
  selector: 'app-field-input-tag',
  template: `<div class="input-tag {{ props.styleClass }}">{{ props.title }}</div>`,
  styleUrls: ['./field-input-tag.component.scss']
})
export class FieldInputTagComponent implements OnInit {
  @Input() type: ReconcileNumberType = ReconcileNumberType.None;

  get props(): IFieldInputTagProps {
    let title = '';

    switch (this.type) {
      case ReconcileNumberType.Both:
        title = 'הוסף/הפחת';
        break;
      case ReconcileNumberType.Add:
        title = 'הוסף';
        break;
      case ReconcileNumberType.Subtract:
        title = 'הפחת';
        break;
      default:
        title = '';
        break;
    }

    return {
      title,
      styleClass: this.type
    };
  }

  constructor() {}

  ngOnInit(): void {}
}
