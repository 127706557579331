import { Component, OnInit, Input } from '@angular/core';
import { BlogPost } from '../../models/blog-post.model';
import { DigitalitRoutes } from '@digital/app/_constants';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  @Input() post: BlogPost;

  // Lifecycle
  constructor() { }
  ngOnInit() { }

  // Getters
  get singlePostRoute() {
    return DigitalitRoutes.blog.single;
  }
}
