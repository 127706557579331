import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  // Inputs
  @Input() title: string;
  @Input() body: string;

  // Lifecycle
  constructor(private _activeModal: NgbActiveModal) { }

  ngOnInit() { }

  // Actions
  close() {
    this._activeModal.close();
  }
}
