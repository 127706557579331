import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FlowTabComponent } from '../flow-tab/flow-tab.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-flow-tabs-menu',
  templateUrl: './flow-tabs-menu.component.html',
  styleUrls: ['./flow-tabs-menu.component.scss']
})
export class FlowTabsMenuComponent implements OnInit {
  @Input() tabs: FlowTabComponent[];
  @Output() tabClicked = new EventEmitter();

  constructor(private _router: Router) {}

  ngOnInit() {}

  sendTabToParent(selectedTab: FlowTabComponent) {
    if (selectedTab.navigateToRouterLink) {
      this._router.navigate(selectedTab.navigateToRouterLink);
      return;
    }
    if (selectedTab.isDisabled) {
      return;
    }
    this.tabClicked.emit(selectedTab);
  }
}
