import { Component, OnInit, Input, Output, EventEmitter, ElementRef, OnDestroy, Optional } from '@angular/core';
import { FormsService } from '@digital/app/forms/forms.service';

// Models
import { FormField, FormFieldType, PulledDataType, ReconcileNumberType } from '@digital/app/_models/form-field.model';
import { Form } from '@digital/app/_models/form.model';

export enum FormFieldMode {
  Initial,
  Edit
}

@Component({
  selector: 'app-field-base',
  template: ``,
  styleUrls: ['./field-base.component.scss']
})
export class FieldBaseComponent implements OnInit, OnDestroy {
  @Input() field: FormField;
  @Input() fieldIndex: number;
  @Input() isLockedForEditing: boolean;
  @Input() side: string;
  @Input() isLeadButtonVariant: boolean = false;
  @Input() isPulledDataField: boolean = false;

  @Output() didUpdateFormFieldValue = new EventEmitter<FormField>();
  @Output() mouseDidEnterFormField = new EventEmitter<FormField>();
  @Output() mouseDidLeaveFormField = new EventEmitter<FormField>();
  @Output() editFieldButtonClicked = new EventEmitter<FormField>();
  @Output() deleteFieldButtonClicked = new EventEmitter<FormField>();

  FormFieldType = FormFieldType;
  ReconcileNumberType = ReconcileNumberType;
  FormFieldMode = FormFieldMode;

  fieldMode = FormFieldMode.Initial;

  newFieldName = '';

  get isSecondaryBackgroundColor(): boolean {
    if (this.field.isCustom) {
      return false;
    }

    if (this.formsService.initialSectionID === 7) {
      if (this.field.conditionalLogic) {
        return true;
      }
      return false;
    }

    return this.fieldIndex % 2 === 0;
  }

  get shouldDisplayCustomFieldControls(): boolean {
    return this.field.isCustom && !this.isLockedForEditing;
  }

  shouldEmphasizeDescription = false;

  error: Error;
  errorMessages: string[] = [];

  private _mouseEnterEvent: (event: MouseEvent) => void;
  private _mouseLeaveEvent: (event: MouseEvent) => void;

  /* #region Lifecycle */

  constructor(public _elementRef: ElementRef, public formsService: FormsService) {}

  ngOnInit() {
    this.field.previousValue = this.field.value;
    this.newFieldName = this.field.name || '';

    this._addMouseEvents();

    if (!this.isLockedForEditing && this.field.pulledDataType) {
      this.isLockedForEditing = this.field.pulledDataType !== PulledDataType.None;
    }

    if (this.field.options && this.field.options.length > 0) {
      this.field.options = this.field.options.sort((a, b) => a.position - b.position);
    }

    if (this.field.type === FormFieldType.PullData) {
      this.shouldEmphasizeDescription = true;
    }
  }

  ngAfterViewInit() {
    if (this.formsService.initialSectionID === 7 && this.field.conditionalLogic) {
      if (this.field.value) {
        this.field.newValue = this.field.value;
        this.didUpdateFormFieldValue.emit(this.field);
      }
    }
  }

  ngOnDestroy() {
    this._removeMouseEvents();
  }

  /* #endregion */

  /* #region Events */

  private _addMouseEvents() {
    this._mouseEnterEvent = (event: MouseEvent) => {
      this.mouseDidEnterFormField.emit(this.field);
    };

    this._mouseLeaveEvent = (event: MouseEvent) => {
      this.mouseDidLeaveFormField.emit(this.field);
    };

    this._elementRef.nativeElement.addEventListener('mouseenter', this._mouseEnterEvent);
    this._elementRef.nativeElement.addEventListener('mouseleave', this._mouseLeaveEvent);
  }

  private _removeMouseEvents() {
    this._elementRef.nativeElement.removeEventListener('mouseenter', this._mouseEnterEvent);
    this._elementRef.nativeElement.removeEventListener('mosueleave', this._mouseLeaveEvent);
  }

  /* #endregion */

  /* #region Edit mode */

  startEditMode() {
    if (!this.field.isCustom) {
      return;
    }

    this.fieldMode = FormFieldMode.Edit;
  }

  cancelEditMode() {
    this.fieldMode = FormFieldMode.Initial;
    this.newFieldName = this.field.name || '';
  }

  saveEditMode() {
    const oldFieldName = this.field.name;

    this.field.name = this.newFieldName;

    this.formsService.updateCustomFormField(this.field).subscribe(
      (success) => {
        if (success) {
          this.fieldMode = FormFieldMode.Initial;
        } else {
          this.field.name = oldFieldName;
        }
      },
      (error) => {
        this.field.name = oldFieldName;
      }
    );
  }

  /* #endregion */
}
