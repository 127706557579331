<div id="form-field-{{ field.id }}" class="form-field" [class.nth-child-background]="isSecondaryBackgroundColor" *ngIf="side ==='right'">
    <p class="form-field-title">
        <span class="field-identifier field-number" *ngIf="field.number">{{ field.number }}.</span>
        <span class="field-identifier field-taxCode" *ngIf="field.taxCode">[{{ field.taxCode }}]</span>
        {{ field.name }}
        <app-tooltip-indicator [tooltipTitle]="field.note"></app-tooltip-indicator>
    </p>
    <p class="form-field-description" [class.emphasize]="shouldEmphasizeDescription" [innerHTML]="field.description"></p>
    <div class="form-check" *ngFor="let option of field.options">
        <div class="checkbox">
            <input
                class="form-control form-check-input"
                type="checkbox"
                name="field-{{ field.id }}" id="option-{{ option.id }}"
                [value]="option.id"
                [checked]="isChecked(option)"
                [required]="field.isRequired"
                (change)="updateFieldValue($event.target)"
                [disabled]="isLockedForEditing">
            <span class="checkmark"></span>
        </div>
        <label class="form-check-label" for="option-{{ option.id }}">{{ option.name }}</label>
    </div>
</div>

<ng-container *ngIf="side === 'left'">
    <div *ngFor="let option of field.options">
        <div class="form-check-input-pdf"
            [style.width.px]="option.width"
            [style.height.px]="option.height"
            [ngStyle]="{ transform: 'translate(' + option.positionX + 'px, ' + option.positionY + 'px)' }"
            >
            <input
                type="checkbox"
                class="form-check-input-pdf-hover"
                name="pdf-field-{{ field.id }}" id="pdf-option-{{ option.id }}"
                [value]="option.id"
                [checked]="isChecked(option)"
                [required]="field.isRequired"
                (change)="updateFieldValue($event.target)"
                [disabled]="isLockedForEditing">
            <span class="checkmark"></span>
        </div>
    </div>
</ng-container>
