import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LeadService } from '@digital/app/core/services/lead.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-lead-copy-query-modal',
  templateUrl: './lead-copy-query-modal.component.html',
  styleUrls: ['./lead-copy-query-modal.component.scss']
})
export class LeadCopyQueryModalComponent implements OnInit {
  @Input() queryId: number;
  @Input() queryNameValue: string;

  copyFlowForm: FormGroup;

  isLoading = false;

  constructor(
    private activeModal: NgbActiveModal,
    private leadService: LeadService,
    private _activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this._setupFormBuilder();
    this.copyFlowForm.controls.queryNameField.setValue(this.queryNameValue);
  }

  private _setupFormBuilder() {
    this.copyFlowForm = this._formBuilder.group({
      queryNameField: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(60)]]
    });
  }

  submitOnCopy(): void {
    this.isLoading = true;
    this.leadService
      .copyQuery(this.queryId, this.copyFlowForm.controls.queryNameField.value)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((data) => {
        console.log(data);
      });

    this.activeModal.close();
  }

  closeModal(approved: boolean) {
    this._activeModal.close(approved);
  }
}
