import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Constants
import { GlobalRoutes } from '@libs/constants';

// Modules
import { SharedModule } from './shared/shared.module';

//login
import { BridgeLoginComponent } from './bridge-login/bridge-login.component';

// Error Pages
import { ErrorPageNotFoundComponent } from '@libs/views/pages/errors/error-page-not-found/error-page-not-found.component';
import { ErrorPageForbiddenComponent } from '@libs/views/pages/errors/error-page-forbidden/error-page-forbidden.component';
import { ErrorPageUnauthorizedComponent } from '@libs/views/pages/errors/error-page-unauthorized/error-page-unauthorized.component';
import { ErrorPageBrowserComponent } from '@libs/views/pages/errors/error-page-browser/error-page-browser.component';
import { ErrorPageUnauthorizedToFlowComponent } from '@libs/views/pages/errors/error-page-unauthorized-to-flow/error-page-unauthorized-to-flow.component';
import { MsalGuard } from '@azure/msal-angular';
import { AppModule } from '@admin/app/app.module';

const routes: Routes = [
  {
    path: GlobalRoutes.login,
    component: BridgeLoginComponent,
    canActivate: [MsalGuard]
  },
  // Error Pages
  {
    path: GlobalRoutes.errors.notFound,
    component: ErrorPageNotFoundComponent
  },
  {
    path: GlobalRoutes.errors.forbidden,
    component: ErrorPageForbiddenComponent
  },
  {
    path: GlobalRoutes.errors.unauthorizedToFlow,
    component: ErrorPageUnauthorizedToFlowComponent
  },
  {
    path: GlobalRoutes.errors.unauthorized,
    component: ErrorPageUnauthorizedComponent
  },
  {
    path: GlobalRoutes.errors.browser,
    component: ErrorPageBrowserComponent
  },

  // Wildcard (404)
  {
    path: GlobalRoutes.wildcard,
    redirectTo: GlobalRoutes.errors.notFound
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }),
    AppModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
