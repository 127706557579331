import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { DecimalPipe } from '@angular/common';

// APIs
import { CompaniesAPI } from '@digital/app/_apis/companies.api';
import { FormsAPI } from '@digital/app/_apis/forms.api';
import { FlowsAPI } from '@digital/app/_apis/flows.api';

// Models
import { CompanyInfo } from '@digital/app/_models/company-info.model';
import { Form, FormState, FormType } from '../_models/form.model';
import { FormField, FormFieldToUpdate, FormFieldType } from '../_models/form-field.model';
import {
  IFTTTAction,
  IFTTTActionButton,
  IFTTTActionButtonType,
  IFTTTActionType
} from '../_interfaces/ifttt-action.inteface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionRequiredModalComponent } from './components/action-required-modal/action-required-modal.component';
import { FlowFormsAPI } from '../_apis/flow-forms.api';
import { FlowShellService } from '../flow-shell/flow-shell.service';
import { CreateCustomFormFieldDTO } from '../_dtos/create-custom-field.dto';
import { map } from 'rxjs/operators';
import { Note } from './models/note';
import { NoteComment } from './models/note-comment';

@Injectable({ providedIn: 'root' })
export class FormsService {
  isLoading$ = new BehaviorSubject(false);
  isLoadingFormData$ = new BehaviorSubject(false);
  isLockedForEditingGlobally$ = new BehaviorSubject(false);

  flow$ = this.flowShellService.flow$;
  companyInfo$ = new BehaviorSubject(new CompanyInfo());

  forms$ = new BehaviorSubject([new Form()]);

  currentForm$ = new BehaviorSubject<Form>(null);
  currentFormPdfURL$ = new BehaviorSubject<string>(null);
  currentFormFields$ = new BehaviorSubject<FormField[]>([]);
  notes$ = new BehaviorSubject<Note[]>([]);

  isNestedDataStructure = false;

  initialFormID?: number; // If given, this form ID will be loaded initially
  initialSectionID?: number; // If given, this Section ID will be loaded initially
  initialInstance?: number; // If given, this instance will be loaded initially

  formFieldInsertToRetry?: FormField;

  // Lifecycle
  constructor(
    private formsAPI: FormsAPI,
    private companiesAPI: CompaniesAPI,
    private flowsAPI: FlowsAPI,
    private flowFormsAPI: FlowFormsAPI,
    private flowShellService: FlowShellService,
    private modalService: NgbModal
  ) {}

  // Methods

  resetCurrentForm() {
    this.currentForm$.next(null);
    this.currentFormPdfURL$.next(null);
    this.currentFormFields$.next([]);
  }

  getFlowCompanyInfoAndForms(flowID: number) {
    this.isLoading$.next(true);
    this.flowsAPI.getFlowByID(flowID).subscribe((flow) => {
      this.flow$.next(flow);
      this.isLockedForEditingGlobally$.next(flow.stage.isLockedForEditing);

      // Get company info and forms
      this._getCompanyInfo(flow.company.id, flow.year);
      this._getFlowForms(flow.id);

      this.flowShellService.setAsCurrentEditorIfNeeded();
    });
  }

  private _getCompanyInfo(companyID: number, year: number) {
    this.companiesAPI.getCompanyInfo(companyID, year).subscribe((companyInfo) => {
      this.isLoading$.next(false);
      this.companyInfo$.next(companyInfo);
    });
  }

  private _getFlowForms(flowID: number) {
    this.formsAPI.getFlowForms(flowID).subscribe((forms) => {
      this.isLoading$.next(false);
      this.forms$.next(forms);
    });
  }

  updateCompanyInfoField(field: { companyID: number; year: number; fieldObject: any }) {
    this.companiesAPI.updateCompanyInfo(field.companyID, field.year, field.fieldObject).subscribe((success) => {
      if (success) {
        this.getFormData(this.currentForm$.value);
      }
    });
  }

  getFormData(form: Form) {
    const flowID = this.flow$.value.id;
    this.isLoadingFormData$.next(true);
    this.formsAPI.getFormData(flowID, form.id, form.instance).subscribe((formData) => {
      this.isLoadingFormData$.next(false);
      this.currentFormPdfURL$.next(formData.pdf);
      this.currentFormFields$.next(formData.fields);
      this.notes$.next(formData.notes);
      this.isNestedDataStructure = formData.type === FormType.Reconciliation;
    });
  }

  insertFormFieldValue(field: FormField) {
    this.formsAPI
      .insertFormFieldValue(this.flow$.value.id, this.currentForm$.value.id, field, this.currentForm$.value.instance)
      .subscribe(
        (response) => {
          this.formFieldInsertToRetry = undefined;

          if (response.fieldsToUpdate) {
            const currentFormFields = this.currentFormFields$.value;
            this.updateRequiredFormFields2(currentFormFields, response.fieldsToUpdate);
          } else if (!response.actions) {
            const clonedField = cloneDeep(field);
            clonedField.newValue = field.value;
            clonedField.value = field.value;
          }

          if (response.actions) {
            response.actions.forEach((action) => {
              this.handleFormFieldAction(action, field);
            });
          }
        },
        (error) => {
          this.revertFormFieldValue(field);
          console.log(error);
        }
      );
  }

  private updateRequiredFormFields2(currentFormFields: FormField[], fieldsToUpdate: FormFieldToUpdate[]) {
    fieldsToUpdate.forEach((fieldToUpdate) => {
      const foundField = this.findFieldObjectByID(currentFormFields, fieldToUpdate.fieldID);

      if (foundField) {
        if (foundField.reconcileParentSection) {
          const foundParentSection = this.findFieldObjectByID(currentFormFields, foundField.reconcileParentSection);
          if (foundParentSection) {
            const fieldIndex = foundParentSection.fields?.findIndex((f) => f.id === fieldToUpdate.fieldID);
            const clonedField = cloneDeep(foundParentSection.fields[fieldIndex]);
            clonedField.newValue = fieldToUpdate.updatedValue;
            clonedField.value = fieldToUpdate.updatedValue;
            foundParentSection.fields[fieldIndex] = clonedField;
          }
        } else {
          const fieldIndex = currentFormFields.findIndex((f) => f.id === fieldToUpdate.fieldID);
          const clonedField = cloneDeep(currentFormFields[fieldIndex]);
          clonedField.newValue = fieldToUpdate.updatedValue;
          clonedField.value = fieldToUpdate.updatedValue;
          currentFormFields[fieldIndex] = clonedField;

          this.updatePdfElementValue(currentFormFields[fieldIndex], clonedField);
        }
      }
    });
  }

  private updatePdfElementValue(currentFormFields: FormField, clonedField: FormField) {
    const pdfElement = document.getElementById(`pdf-${currentFormFields.id}`) as HTMLInputElement;
    pdfElement.value = DecimalPipe.prototype.transform(clonedField.value, undefined, 'he-IL');
  }

  private handleFormFieldAction(action: IFTTTAction, field: FormField) {
    switch (action.type) {
      case IFTTTActionType.POPUP:
        this.formFieldInsertToRetry = field;
        this.presentActionRequiredPopup(action, field);
        break;
      case IFTTTActionType.SHOWFIELDS:
        this.presentFieldsRequired(action);
        break;
      case IFTTTActionType.HIDEFIELDS:
        this.hideFieldsRequired(action);
        break;
      default:
        this.revertFormFieldValue(field);
        break;
    }
  }

  private hideFieldsRequired(action: IFTTTAction) {
    const fields = action.fields.split(',');
    let elementField;
    fields.forEach((field) => {
      elementField = document.getElementById(`section-${field}`);
      elementField.hidden = true;
    });
  }

  private presentFieldsRequired(action: IFTTTAction) {
    const fields = action.fields.split(',');
    let elementField;
    fields.forEach((field) => {
      elementField = document.getElementById(`section-${field}`);
      elementField.hidden = false;
    });
  }

  private presentActionRequiredPopup(action: IFTTTAction, field: FormField) {
    const ref = this.modalService.open(ActionRequiredModalComponent, { centered: true });
    ref.componentInstance.action = action;
    ref.result.then((button: IFTTTActionButton) => this.handleActionRequiredButtonClicked(button, field));
  }

  private handleActionRequiredButtonClicked(button: IFTTTActionButton, field: FormField) {
    switch (button.type) {
      case IFTTTActionButtonType.ADD_FORM:
        this.addFormsFromFieldAction([button.formID], field);
        break;
      default:
        this.revertFormFieldValue(field);
        break;
    }
  }

  private addFormsFromFieldAction(formIDs: number[], field: FormField) {
    this.flowFormsAPI.addOrDeleteDigitalForms(this.flow$.value.id, formIDs, [], [], []).subscribe(
      (_) => {
        this._getFlowForms(this.flow$.value.id);
        if (this.formFieldInsertToRetry) {
          this.insertFormFieldValue(this.formFieldInsertToRetry);
        }
      },
      (error) => {
        this.revertFormFieldValue(field);
        console.log(error);
      }
    );
  }

  private revertFormFieldValue(field: FormField) {
    const currentFormFields = cloneDeep(this.currentFormFields$.getValue());
    const foundField = this.getNestedFormField(currentFormFields, field.id);

    if (foundField) {
      foundField.value = field.previousValue;
      foundField.newValue = field.previousValue;
      this.currentFormFields$.next(currentFormFields);
    }
  }

  private getNestedFormField(fields: FormField[], fieldId: number): FormField | undefined {
    let foundField: FormField;

    for (const field of fields) {
      if (field.id === fieldId) {
        foundField = field;
        break;
      } else {
        if (field.fields && field.fields.length > 0) {
          const f = this.getNestedFormField(field.fields, fieldId);
          if (f) {
            foundField = f;
            break;
          }
        }
      }
    }

    return foundField;
  }

  updateFormState(state: FormState) {
    const flowId = this.flow$.value.id;
    const formId = this.currentForm$.value.id;
    const instanceID = this.currentForm$.value.instanceID;
    this.formsAPI.updateFormState(state, flowId, formId, instanceID).subscribe((_) => {
      const updatedForm = this.currentForm$.value;
      updatedForm.state = state;
      this.currentForm$.next(updatedForm);

      // TODO: Respond to the post-logic validations from the server.
    });
  }

  createCustomFormField(dto: CreateCustomFormFieldDTO): Observable<FormField> {
    const flowId = this.flow$.value.id;
    const formId = this.currentForm$.value.id;
    const instance = this.currentForm$.value.instance;
    // const section = this.findFieldObjectByID(this.currentFormFields$.value, dto.reconcileParentSection);

    // dto.position = section.fields?.length - 1;
    return this.formsAPI
      .createCustomFormField(flowId, formId, dto, instance)
      .pipe(map((field) => this.appendCustomFormFieldToDataStructure(field)));
  }

  appendCustomFormFieldToDataStructure(field: FormField): FormField {
    const fields = this.currentFormFields$.value;
    const section = this.findFieldObjectByID(fields, field.reconcileParentSection);
    if (!section || !section.fields) {
      return;
    }

    section.fields = [...section.fields, field].sort((a, b) => a.position - b.position);

    const finalFields = [...fields, ...section.fields].sort((a, b) => a.position - b.position);

    // this.currentFormFields$.next(finalFields); // no need 

    return field;
  }

  deleteCustomFormField(field: FormField) {
    const flowId = this.flow$.value.id;
    const formId = this.currentForm$.value.id;
    this.isLoading$.next(true);

    this.formsAPI.deleteCustomFormField(flowId, formId, field.id).subscribe((response) => {
      this.formFieldInsertToRetry = undefined;
      this.isLoading$.next(false);

      if (response.fieldsToUpdate) {
        const currentFormFields = this.currentFormFields$.value;
        this.updateRequiredFormFields2(currentFormFields, response.fieldsToUpdate);
      }

      if (response.actions) {
        response.actions.forEach((action) => {
          this.handleFormFieldAction(action, field);
        });
      }

      this.removeCustomFormFieldFromDataStructure(field);
    });
  }

  removeCustomFormFieldFromDataStructure(field: FormField): boolean {
    const fields = this.currentFormFields$.value;
    const section = this.findFieldObjectByID(fields, field.reconcileParentSection);

    if (!section || !section.fields) {
      return false;
    }

    const fieldIndex = section.fields.indexOf(field);

    if (!fieldIndex) {
      return false;
    }

    section.fields.splice(fieldIndex, 1);

    return true;
  }

  updateCustomFormField(field: FormField): Observable<boolean> {
    const flowId = this.flow$.value.id;
    const formId = this.currentForm$.value.id;
    return this.formsAPI.updateCustomFormField(flowId, formId, field.id, { name: field.name });
  }

  private findFieldObjectByID(data: FormField[], id: number): FormField | undefined {
    let result: FormField;

    function iter(a: FormField) {
      if (a.id === id) {
        result = a;
        return true;
      }
      return Array.isArray(a.fields) && a.fields.some(iter);
    }

    data.some(iter);
    return result;
  }

  addNote(eventData: Note, sendEmailTo: string[]): void {
    eventData = {
      ...eventData,
      flowId : this.flow$.value.id,
      formId : this.currentForm$.value.id
    }
    this.formsAPI.addNoteAPI(eventData, sendEmailTo)
    .subscribe(result => {
      // Get the current value of notes from the BehaviorSubject
      const currentNotes = this.notes$.value;
      // Retrieve the new note from the result
      const newNoteComments: NoteComment = {
        id: result.id,
        noteId: result.noteId,
        createdBy: result.createdBy,
        createdAt: result.createdAt,
        content: result.content
      };

      const newNote: Note = {
        comments: [newNoteComments],
        id: result.noteId,
        flowId: eventData.flowId,
        formId: eventData.formId,
        status: 'opened',
        page: eventData.page,
        x: eventData.x,
        y: eventData.y,
        mentionUsersList: eventData.mentionUsersList,
        createdAt: result.createdAt,
        updatedAt: result.createdAt
      };

      // Add the new note to the current notes array
      currentNotes.push(newNote);
      // Update the notes array in the BehaviorSubject
      this.notes$.next(currentNotes);   
    })
  }

  updateNotes(updatedItems: Note[]) {
    // Update the notes$ subject with the updated items array
    this.notes$.next(updatedItems);
  }

  addCommentToNote(eventData: NoteComment, sendEmailTo: string[]): void {
    this.formsAPI.addCommentToNoteAPI(eventData, sendEmailTo, this.flow$.value.id, this.currentForm$.value.id)
    .subscribe(result => {
      const currentNotes = this.notes$.value;
      const noteIndex = currentNotes.findIndex((note) => note.id === result.noteId);
      if (noteIndex > -1) {
        const newNoteComment : NoteComment = {
          id: result.id,
          noteId: result.noteId,
          createdBy: result.createdBy,
          createdAt: result.createdAt,
          content: result.content
        }
        // Add the comment to the comments array of the note and update the mentionUsersList
        currentNotes[noteIndex].comments.push(newNoteComment);
        currentNotes[noteIndex].mentionUsersList = eventData.mentionUsersList;
        // Emit the updated notes array
        this.notes$.next(currentNotes);
      }
    })
  }

  onStatusChange(newStatus: string, noteId: number) {
    const eventData = {
      flowId : this.flow$.value.id,
      formId : this.currentForm$.value.id,
      status: newStatus,
      noteId : noteId
    }
    this.formsAPI.onStatusChangeAPI(eventData)
    .subscribe(result => {
    })
  }

  onGetNotClosedNotesByFlowId() {
    const eventData = {
      flowId : this.flow$.value.id,
    }
    return this.formsAPI.onGetNotClosedNotesByFlowIdAPI(eventData)
  }
}
