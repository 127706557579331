import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { LeadSelectedFormField } from '@digital/app/lead/services/lead-selected-form-field.service';
import { LeadFormField } from '@digital/app/_models/lead-form-field';
import { Subscription } from 'rxjs';
import { LeadCriterionGroupFormMappingService } from './lead-criterion-group-form-mapping.service';

@Component({
  selector: 'app-lead-query-criterion-group',
  templateUrl: './lead-query-criterion-group.component.html',
  styleUrls: ['./lead-query-criterion-group.component.scss']
})
export class LeadQueryCriterionGroupComponent implements OnInit, OnDestroy {
  @Input() form: FormArray;
  @Input() isSingle: boolean;
  @Input() index: number;
  @Output() deleted = new EventEmitter();
  isWaitingFieldLocal: boolean = false;
  isWaitingFieldGlobal: boolean = false;
  subscriptions = new Subscription();
  selectedCriterionIndex: number;

  constructor(private selectedFieldService: LeadSelectedFormField,
              private mappingService: LeadCriterionGroupFormMappingService) { }

  ngOnInit(): void {
    if (this.form.length === 0) {
      this.addCriterion();
    }

    const waitingFieldSubscription = this.selectedFieldService
      .isWaitingField()
      .subscribe(isWaitingField => this.isWaitingFieldGlobal = isWaitingField);
    
    this.subscriptions.add(waitingFieldSubscription);
    
    const fieldSubscription = this.selectedFieldService
      .selectedField()
      .subscribe(this.onFieldSelected.bind(this));

    this.subscriptions.add(fieldSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onAddCriterion(): void {
    this.addCriterion();
  }

  onDeleteCriterion(i: number, last: boolean): void {
    if (last && this.isWaitingFieldLocal) {
      this.isWaitingField = false;
    }

    this.form.removeAt(i);

    if (this.form.length === 0) {
      this.deleted.emit();
    }
  }

  addCriterion(): void {
    this.form.push(this.mappingService.toLogicalCriterionForm());
    this.isWaitingField = true;
  }

  onFieldSelected(field: LeadFormField): void {
    if (this.isWaitingFieldLocal) {
      const criterion = this.mappingService.toLogicalCriterionForm(field);
      const lastIndex = this.form.length - 1;

      this.form.setControl(lastIndex, criterion);
      this.isWaitingField = false;

      const id = this.id(lastIndex);
      document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.selectedCriterionIndex = lastIndex;
    }
  }

  get isWaitingField(): boolean {
    return this.isWaitingFieldLocal || this.isWaitingFieldGlobal;
  }

  set isWaitingField(waitingField: boolean) {
    this.isWaitingFieldLocal = waitingField;
    this.selectedFieldService.waitField(waitingField);
  }

  get criteria(): FormGroup[] {
    return this.form.controls as FormGroup[];
  }

  getForm(i: number, name: string): AbstractControl {
    return this.criteria[i].get(name);
  }

  criterion(i: number): FormGroup {
    return this.getForm(i, 'criterion') as FormGroup;
  }

  logicalOperator(i: number): FormControl {
    return this.getForm(i, 'logicalOperator') as FormControl;
  }

  id(i: number): string {
    return `criterion-${this.index}-${i}`;
  }

  isSelected(i: number): boolean {
    return this.selectedCriterionIndex === i;
  }

  get isLastCriterion(): boolean {
    return this.isSingle && this.isWaitingFieldLocal && this.form.length === 1;
  }
}
