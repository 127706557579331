<div class="main-digital-container">
  <div class="main-content">
    <app-message-bar
      *ngIf="showMessageBar"
      [flow]="flow$ | async"
      [title]="messageBarTitle"
      [titleIcon]="messageBarIcon"
      [showTakeOverButton]="(editingPermissions$ | async).canTakeOverEditing"
      (takeOverFlowEmitted)="takeOverFlow()"
    ></app-message-bar>
    <!--  -->
    <div *ngIf="isLoading$ | async">
      <app-loading></app-loading>
    </div>
    <!--  -->
    <app-flow-header
      *ngIf="!(isLoading$ | async)"
      [flow]="flow$ | async"
      [editingPermissions]="editingPermissions$ | async"
      (backButtonClicked)="goBackToFlowPage()"
      (generateKitButtonClicked)="openOrganizeKitModal()"
    >
    </app-flow-header>
    <!--  -->
    <div class="container-fluid">
      <div *ngIf="!(isLoading$ | async)" class="digital-form">
        <div class="title-container">
          <div class="row h-100 row align-items-center">
            <div class="col text-right">
              <h3>עריכת טפסים דיגיטלים</h3>
            </div>
          </div>
        </div>
        <!--  -->
        <app-company-info-form
          [flow]="flow$ | async"
          [companyInfo]="companyInfo$ | async"
          [editingPermissions]="editingPermissions$ | async"
          (didUpdateCompanyInfoField)="didUpdateCompanyInfoField($event)"
        >
        </app-company-info-form>
        <!--  -->
        <app-main-form-view [flow]="flow$ | async"></app-main-form-view>
        <!--  -->
      </div>
      <div class="bottom-scroll-margin"></div>
    </div>
  </div>
</div>
