import { Injectable } from '@angular/core';
import { ApiService } from '@digital/app/core/api/api.service';
import { Comment } from '@digital/app/flow/models/comment.model';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  constructor(private api: ApiService) {}

  async getCommentsByFlow(flowID: number, completion: (comments: Comment[]) => void) {
    try {
      const response = await this.api.getCommentsByFlow(flowID);
      const comments = response.data.comments.map((comment) => new Comment().deserialize(comment));
      completion(comments);
    } catch (error) {
      throw error;
    }
  }

  async getCommentsRepliesByID(flowID: number, commentID: number, completion: (commentsReplies: Comment[]) => void) {
    try {
      const response = await this.api.getCommentsRepliesByID(flowID, commentID);
      const replies = response.data.replies.map((comment) => new Comment().deserialize(comment));
      completion(replies);
    } catch (error) {
      throw error;
    }
  }

  async createNewComment(
    message: string,
    userID: number,
    flowID: number,
    stageID: number,
    parentID: any,
    completion: (commentCreated: Comment) => void
  ) {
    try {
      const response = await this.api.createNewComment(message, userID, flowID, stageID, parentID);
      completion(response);
    } catch (error) {
      throw error;
    }
  }

  async deleteComment(comment: Comment, flowID: number, completion: (success: boolean) => void) {
    try {
      const response = await this.api.deleteComment(comment, flowID);
      completion(response);
    } catch (error) {
      throw error;
    }
  }
}
