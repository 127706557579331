import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPageBaseComponent } from '../error-page-base/error-page-base.component';

@Component({
  selector: 'app-error-page-unauthorized-to-flow',
  templateUrl: './error-page-unauthorized-to-flow.component.html',
  styleUrls: ['../error-page-base/error-page-base.component.scss', './error-page-unauthorized-to-flow.component.scss']
})
export class ErrorPageUnauthorizedToFlowComponent extends ErrorPageBaseComponent implements OnInit {
  constructor(protected router: Router) {
    super(router);
  }
  ngOnInit(): void {}
}
