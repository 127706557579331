import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FlowService } from '@digital/app/core/services/flow.service';
import { UserService } from '@digital/app/core/services/user.service';
import { AlertModalComponent } from '@digital/app/shared/components/alert-modal/alert-modal.component';
import { ExternalUser, ExternalUserType, FullExternalUser } from '@digital/app/_models/client-users';
import { InternalUser, InternalUsers } from '@digital/app/_models/internal-users.model';
import {
  DeloitteTeam,
  ExternalPermissionType,
  Flow,
  FullUserDetails,
  PermissionType
} from '@digital/app/_models/flow.model';
import { ManagersPartners, User } from '@digital/app/_models/managers-partners.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UserRole } from '@libs/authentication/models/user.model';

enum ChangeFlowInfoModalType {
  manager = 1,
  partner = 2
}

@Component({
  selector: 'app-flow-permissions-tab',
  templateUrl: './flow-permissions-tab.component.html',
  styleUrls: ['./flow-permissions-tab.component.scss']
})
export class FlowPermissionsTabComponent implements OnChanges, OnInit {
  @Input() title1: string;
  @Input() title2: string;
  @Input() flow: Flow;

  isLoading: boolean = false;
  deloitteTeam: DeloitteTeam[] = [];
  externalTeam: FullExternalUser[] = [];
  flowPartners: User[];
  flowManagers: User[];
  isEditPartnerPushed: boolean = false;
  isEditManagerPushed: boolean = false;
  isInternalUserSelected: boolean = false;
  addNewInternalUser: boolean = false;
  addNewExternalUser: boolean = false;
  externalUserEmailFocused: boolean = true;
  selectedPartner: User;
  selectedManager: User;
  selectedNewInternalUser: InternalUser;
  prevPartner: User;
  prevManager: User;
  flowDeloitteUsers: InternalUser[];
  permissionType: PermissionType;
  newExternalUserEmail: string = '';
  newExternalUserFirstName: string;
  newExternalUserLastName: string;
  externalUserOptions = Object.keys(ExternalUserType);
  ExternalUserType = ExternalUserType;
  disableExternalUserName = false;
  newExternalUserType: string = '';

  private currentUser = this._userService.getCurrentUser();

  externalPermissionTypeMap = {
    [ExternalPermissionType.signer]: 'חותם',
    [ExternalPermissionType.all]: 'עורך וחותם',
    [ExternalPermissionType.editor]: 'עורך'
  };

  constructor(
    private _flowService: FlowService,
    private _userService: UserService,
    private modal: NgbModal,
    private toastr: ToastrService
  ) {
    this.toastr.toastrConfig.preventDuplicates = true;
  }

  ngOnInit() {
    this.buildDeloitteTeam();
    this.buildExternalTeam();
    this._getManagersAndPartners();
    this._getAllDeloitteUsers();
  }

  ngOnChanges() {}

  ngAfterViewChecked() {
    if (this.addNewInternalUser) {
      document.getElementById('newInternalUserInput').focus();
    }
    if (this.addNewExternalUser && this.externalUserEmailFocused) {
      document.getElementById('newExternalUserInput').focus();
    }
  }

  private _getAllDeloitteUsers() {
    this._flowService.getAllUsersForBanch(this.flow.company.id, this.flow.year, (allUsers) => {
      this._onGetAllDeloitteUsers(allUsers);
    });
  }

  private _onGetAllDeloitteUsers(users: InternalUsers) {
    this.flowDeloitteUsers = users.internalUsers.map((users) => users).sort((a, b) => a.name.localeCompare(b.name));
  }

  private _getManagersAndPartners() {
    this._flowService.getManagersAndPartners(this.flow.company.id, this.flow.year, (managersAndPartners) => {
      this._onGetManagersAndPartners(managersAndPartners);
    });
  }

  private _onGetManagersAndPartners(users: ManagersPartners) {
    this.flowManagers = users.managers.map((managers) => managers).sort((a, b) => a.name.localeCompare(b.name));
    this.flowPartners = users.partners.map((partners) => partners).sort((a, b) => a.name.localeCompare(b.name));
  }

  private async _writeUserEvents(action: string) {
    await this._userService.createNewUserEvent(this.flow, action);
    this._userService.emitDataForUpdateUserEvents('success');
  }

  buildDeloitteTeam() {
    this.deloitteTeam.push({ permissionType: PermissionType.Partner, data: this.flow.partner });
    this.deloitteTeam.push({ permissionType: PermissionType.Manager, data: this.flow.manager });
    this.deloitteTeam.push({ permissionType: PermissionType.Creator, data: this.flow.creator });
    this.flow.permissions.internalUsers.map((user: FullUserDetails) => {
      this.deloitteTeam.push({ permissionType: PermissionType.Editor, data: user });
    });

    this.selectedPartner = {
      name: this.flow.partner.fullName.hebrew,
      email: this.flow.partner.email,
      id: this.flow.partner.id
    };

    this.selectedManager = {
      name: this.flow.manager.fullName.hebrew,
      email: this.flow.manager.email,
      id: this.flow.manager.id
    };
  }

  buildExternalTeam() {
    this.flow.permissions.externalUsers.map((user) => {
      this.externalTeam.push(user);
    });
  }

  //help function
  isUserActive(user: FullUserDetails) {
    return user.status === 'Active';
  }

  isExternalUserActive(user: FullExternalUser) {
    return user.status === 'registered';
  }

  isUserEditor(user: DeloitteTeam): boolean {
    return user.permissionType === PermissionType.Editor;
  }

  isCurrentUserExternal(): boolean {
    return this.currentUser.role === UserRole.EXTERNAL;
  }

  isEditable(user: DeloitteTeam) {
    return (
      (user.permissionType === PermissionType.Manager && !this.isEditManagerPushed) ||
      (user.permissionType === PermissionType.Partner && !this.isEditPartnerPushed)
    );
  }

  isManagerPartner(user: DeloitteTeam) {
    return user.permissionType === PermissionType.Manager || user.permissionType === PermissionType.Partner;
  }

  isPartner(user: DeloitteTeam) {
    return user.permissionType === PermissionType.Partner;
  }

  getExternalPermissionTypeText(permissionType: ExternalPermissionType): string {
    permissionType = ExternalPermissionType[permissionType as unknown as keyof typeof ExternalPermissionType];
    return this.externalPermissionTypeMap[permissionType];
  }

  isExternalUserPending(status) {
    return status === 'pending';
  }

  //html Functions
  deleteInternalUserClicked(user: FullUserDetails, index: number) {
    const confirmMessage = `האם אתה בטוח שאתה רוצה למחוק את המשתמש ?`;

    const ref = this.modal.open(AlertModalComponent, { centered: true }).componentInstance;
    ref.title = 'מחיקת משתמש';
    ref.message = confirmMessage;

    ref.positiveAction('מחיקה', () => {
      this._flowService.deleteInternalEditor(user.id, this.flow.id, (success) => {
        if (success) {
          this._flowService.emitDataFromDynamicFooter(202);
          this.deloitteTeam.splice(index, 1);
        }
      });
    });

    ref.negativeAction('ביטול', () => {});
  }

  deleteExternalUserClicked(user: FullExternalUser, index: number) {
    const confirmMessage = `האם אתה בטוח שאתה רוצה למחוק למשתמש זה את ההרשאות לתיק?`;

    const ref = this.modal.open(AlertModalComponent, { centered: true }).componentInstance;
    ref.title = 'מחיקת הרשאות';
    ref.message = confirmMessage;

    ref.positiveAction('מחיקה', () => {
      this._flowService.deleteExternalUserPermission(user.id, this.flow.id, (success) => {
        if (success) {
          this._flowService.emitDataFromDynamicFooter(202);
          this.externalTeam.splice(index, 1);
          console.log(this.externalTeam);
        }
      });
    });

    ref.negativeAction('ביטול', () => {});
  }

  externalUserPendingClick(user: FullExternalUser) {
    const confirmMessage = `האם אתה בטוח שאתה רוצה לשלוח שוב מייל אקטיבציה ?`;

    const ref = this.modal.open(AlertModalComponent, { centered: true }).componentInstance;
    ref.title = 'שליחת מייל';
    ref.message = confirmMessage;

    ref.positiveAction('שליחה', async () => {
      if (await this._userService.resendActivationEmail(user.email)) {
        this.toastr.success('המייל נשלח בהצלחה!');
      } else {
        this.toastr.error('הפעולה לא הצליחה. יש לפנות למנהל המערכת.');
      }
    });

    ref.negativeAction('ביטול', () => {});
  }

  editUserClicked(user: DeloitteTeam) {
    if (this.isPartner(user)) {
      this.isEditPartnerPushed = true;
    } else {
      this.isEditManagerPushed = true;
    }
  }

  async confirmChange(user: DeloitteTeam) {
    await this._flowService
      .updateManagerOrPartner(
        this.isPartner(user) ? this.selectedPartner.id : this.selectedManager.id,
        this.flow.id,
        this.isPartner(user) ? ChangeFlowInfoModalType.partner : ChangeFlowInfoModalType.manager
      )
      .finally(() => {
        if (this.isPartner(user)) {
          this.isEditPartnerPushed = false;
          this.prevPartner = {
            name: this.flow.partner.fullName.hebrew,
            email: this.flow.partner.email,
            id: this.flow.partner.id
          };
        } else {
          this.isEditManagerPushed = false;
          this.prevManager = {
            name: this.flow.manager.fullName.hebrew,
            email: this.flow.manager.email,
            id: this.flow.manager.id
          };
        }

        this._flowService.emitDataFromDynamicFooter(202);
        console.log(this.prevManager);
        console.log(this.selectedManager);
      });

    let action = `שינוי ${this.isPartner(user) ? 'שותף/ה ' : 'מנהל/ת התיק'}`;
    this._writeUserEvents(action);
  }

  abortEdit(user: DeloitteTeam, index: number) {
    if (this.isPartner(user)) {
      this.isEditPartnerPushed = false;
      this.selectedPartner = this.prevPartner;
      this.deloitteTeam[index].data.email = this.prevPartner.email;
    } else {
      this.isEditManagerPushed = false;
      this.selectedManager = this.prevManager;
      this.deloitteTeam[index].data.email = this.prevManager.email;
    }
  }

  onSelectPartnerOrManager(user: User, isPartner: boolean, index: number) {
    if (isPartner) {
      this.prevPartner = {
        name: this.flow.partner.fullName.hebrew,
        email: this.flow.partner.email,
        id: this.flow.partner.id
      };

      this.selectedPartner = user;
    } else {
      this.prevManager = {
        name: this.flow.manager.fullName.hebrew,
        email: this.flow.manager.email,
        id: this.flow.manager.id
      };
      this.selectedManager = user;
    }

    this.deloitteTeam[index].data.email = user.email;
  }

  onSelectNewInternalUser(user: InternalUser) {
    const confirmMessage = `האם אתה בטוח שאתה רוצה לתת הרשאה למשתמש ${user.name} ?`;

    const ref = this.modal.open(AlertModalComponent, { centered: true }).componentInstance;
    ref.title = 'הוספת משתמש';
    ref.message = confirmMessage;

    ref.positiveAction('הוספה', () => {
      this._flowService.createInternalEditor(user, this.flow.id, (addedUser: FullUserDetails) => {
        if (addedUser) {
          this.resetNewInternalUser();
          this.deloitteTeam.push({ permissionType: PermissionType.Editor, data: addedUser });
          let action = `הוספת הרשאת עורך למשתמש ${user.name}`;
          this._writeUserEvents(action);
        }
      });
    });

    ref.negativeAction('ביטול', () => {});
  }

  isValidNewExternalUser() {
    return (
      this.newExternalUserEmail !== '' &&
      this.newExternalUserFirstName !== '' &&
      this.newExternalUserLastName !== '' &&
      this.newExternalUserType !== ''
    );
  }

  submitNewExternalUser() {
    if (this.isValidNewExternalUser()) {
      const confirmMessage = `האם אתה בטוח שאתה רוצה לתת הרשאה למשתמש ${this.newExternalUserFirstName} ?`;

      const ref = this.modal.open(AlertModalComponent, { centered: true }).componentInstance;
      ref.title = 'הוספת משתמש';
      ref.message = confirmMessage;

      ref.positiveAction('הוספה', () => {
        this.isLoading = true;
        const user: ExternalUser = {
          firstName: this.newExternalUserFirstName,
          lastName: this.newExternalUserLastName,
          email: this.newExternalUserEmail,
          role: ExternalUserType[this.newExternalUserType.charAt(0).toUpperCase() + this.newExternalUserType.slice(1)]
        };
        this._flowService.createExternalUser(user, this.flow.id, (addedUser: FullExternalUser) => {
          if (addedUser) {
            this.resetNewExternalUser();
            this.externalTeam.push(addedUser);
            this.isLoading = false;
            let action = `הוספת הרשאת ${addedUser.role} למשתמש ${addedUser.firstName + ' ' + addedUser.lastName}`;
            this._writeUserEvents(action);
          }
        });
      });

      ref.negativeAction('ביטול', () => {});
    } else {
      if (this.newExternalUserFirstName === '') {
        this.toastr.error('יש להכניס שם פרטי');
      }
      if (this.newExternalUserLastName === '') {
        this.toastr.error('יש להכניס שם משפחה');
      }
      if (this.newExternalUserType === '') {
        this.toastr.error('יש להכניס סוג הרשאה');
      }
      if (this.newExternalUserEmail === '') {
        this.toastr.error('יש להכניס כתובת מייל');
      }
    }
  }

  addNewEditor() {
    this.addNewInternalUser = true;
  }

  addNewExternalUserToTable() {
    this.addNewExternalUser = true;
  }

  onExternalUserEmailBlur() {
    this.externalUserEmailFocused = false;
    if (
      this.newExternalUserEmail !== '' &&
      this.newExternalUserEmail
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      this._getIfExternalUserExistsByEmail();
    } else if (this.newExternalUserEmail !== '') {
      this.toastr.error('נא להכניס מייל תקין');
      this.newExternalUserEmail = '';
    }
  }

  private _getIfExternalUserExistsByEmail() {
    this._flowService.getExternalUserByEmail(this.newExternalUserEmail, (user) => {
      if (user) {
        this._onGetExternalUserExists(user);
      }
    });
  }

  private _onGetExternalUserExists(user: ExternalUser) {
    this.newExternalUserFirstName = user.firstName;
    this.newExternalUserLastName = user.lastName;
    this.disableExternalUserName = true;
  }

  resetNewInternalUser() {
    this.isInternalUserSelected = false;
    this.addNewInternalUser = false;
    this.selectedNewInternalUser = undefined;
  }

  resetNewExternalUser() {
    this.addNewExternalUser = false;
    this.newExternalUserEmail = '';
    this.newExternalUserFirstName = '';
    this.newExternalUserLastName = '';
    this.newExternalUserType = '';
    this.disableExternalUserName = false;
    this.externalUserEmailFocused = true;
  }

  //autoComplete section
  deloittePartnersAutoComplete = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$).pipe(
      map((term) =>
        (term === ''
          ? this.flowPartners.filter((user: User) => user.id !== this.flow.partner.id).map((user: any) => user)
          : this.flowPartners
              .filter(
                (user: User) =>
                  user.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                  user.email.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .map((user: any) => user)
        ).slice(0, 7)
      )
    );
  };

  deloitteManagersAutoComplete = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$).pipe(
      map((term) =>
        (term === ''
          ? this.flowManagers.filter((user: User) => user.id !== this.flow.manager.id).map((user: any) => user)
          : this.flowManagers
              .filter(
                (user: User) =>
                  user.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                  user.email.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .map((user: any) => user)
        ).slice(0, 7)
      )
    );
  };

  deloitteUsersAutoComplete = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$).pipe(
      map((term) =>
        (term === ''
          ? this.flowDeloitteUsers
              .filter(
                (user: InternalUser) =>
                  user.id !== this.flow.creator.id &&
                  user.id !== this.flow.manager.id &&
                  user.id !== this.flow.partner.id &&
                  !this.deloitteTeam.find((du) => du.data.id === user.id) &&
                  !this.flow.permissions.internalUsers.find((du) => du.id === user.id)
              )
              .map((user: any) => user)
          : this.flowDeloitteUsers
              .filter(
                (user: InternalUser) =>
                  (user.name.toLowerCase().split(' ').some(word => word.startsWith(term.toLowerCase())) ||
                    user.email.toLowerCase().indexOf(term.toLowerCase()) > -1) &&
                  !this.deloitteTeam.find((du) => du.data.id === user.id) &&
                  !this.flow.permissions.internalUsers.find((du) => du.id === user.id) &&
                  user.id !== this.flow.creator.id &&
                  user.id !== this.flow.manager.id &&
                  user.id !== this.flow.partner.id
              )
              .map((user: any) => user)
        ).slice(0, 4)
      )
    );
  };

  formatter = (user: { name: string }) => user.name;

  getHebrewUserType(externalUserOption) {
    switch (externalUserOption) {
      case 'Editor':
        return 'עורך';
      case 'Signer':
        return 'חותם';
      case 'All':
        return 'עורך וחותם';
      default:
        return '';
    }
  }

  get PermissionType() {
    return PermissionType;
  }
}
