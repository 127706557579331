import { Component, OnInit, Input } from '@angular/core';
import { CommentsService } from '@digital/app/core/services/comments.service';
import { Comment, Stage, CommentType } from '@digital/app/flow/models/comment.model';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { ID } from '@datorama/akita';

@Component({
  selector: 'app-flow-comment',
  templateUrl: './flow-comment.component.html',
  styleUrls: ['./flow-comment.component.scss']
})
export class FlowCommentComponent implements OnInit {
  @Input() flowID: number;
  @Input() stage: Stage;
  public comments: Comment[];

  private _currentUser = this._authenticationService.getCurrentUser();

  currentInitialName: string;
  currentUserID: ID;
  commentsIds = [];

  constructor(private _authenticationService: AuthenticationService, private _commentsService: CommentsService) {}

  ngOnInit() {
    this._fetchComments();
    this._getUserDetails();
  }

  private _fetchComments(): void {
    this._commentsService.getCommentsByFlow(this.flowID, (comments: Comment[]) => this.onGetComments(comments));
  }

  private _getUserDetails(): void {
    this.currentInitialName = this._currentUser.nameInitials;
    this.currentUserID = this._currentUser.id;
  }

  // All Flow Comments
  onGetComments(comments: Comment[]) {
    this.comments = comments;
  }

  closeReplyComment(parentID: number) {
    this.comments.map((comment) => {
      if (comment.id === parentID) {
        comment.replies = undefined;
      }
    });
    this.commentsIds = this.commentsIds.filter((id) => id !== parentID);
  }

  toggleOpenCommentButton(commentID: number) {
    this.commentsIds = [...this.commentsIds, commentID];
  }

  onCommentCreated(createdComment: Comment) {
    switch (createdComment.type) {
      case CommentType.Comments:
        // If this is a Comment, then let's append it to beginning of the existing array
        this.comments = [createdComment, ...this.comments];
        break;
      case CommentType.Replies:
        // If this is a Reply, then let's find the parent Comment and append it to it's `replies` array
        const parentComment = this.comments.filter((comment) => comment.id === createdComment.parentID)[0];
        if (parentComment.replies) {
          parentComment.replies = [...parentComment.replies, createdComment];
          parentComment.replyCount++;
        } else {
          parentComment.replies = [createdComment];
          parentComment.replyCount++;
        }
        break;
      default:
        break;
    }
  }
}
