<div class="create-new-form">
  <div class="form-container">
    <div class="container">
      <div class="row justify-content-center">
          <h1>יצירת טופס חדש</h1>
      </div>

      <form [formGroup]="createNewForm" (submit)="onSubmitFormButtonClicked()" class="new-form-details" *ngIf="!isLoading">
        <div class="form-group">
          <label for="id">מספר הטופס ע״פ מס הכנסה</label>
          <input type="number" class="form-control" formControlName="formTaxID">
        </div>

        <div class="form-group">
          <label for="name">שם הטופס ע״פ מס הכנסה</label>
          <input type="text" class="form-control" formControlName="name">
        </div>

        <div class="form-group">
          <label for="year">שנת המס הרלוונטית</label>
          <input type="number" class="form-control" formControlName="formYear">
        </div>

        <div class="form-group">
          <label for="exampleFormControlFile1">העלאת קובץ PDF</label>
          <input type="file" class="form-control" formControlName='file' id="file" (change)="handleFileUploadChange($event.target.files)">
        </div>

        <div class="form-group" *ngIf="section$ | async as sections">
          <label for="type">קטגוריה</label>
          <select class="form-control" formControlName="sectionID" required>
            <ng-container *ngFor="let section of sections">
              <option [value]='section.id'>
                {{section.name}}</option>
            </ng-container>
          </select>
        </div>

        <div class="form-group">
          <label for="type">סוג</label>
          <select class="form-control" formControlName="type" required>
            <ng-container *ngFor="let type of formType">
              <option [value]='type'>
                {{FormTypeLabel[type]}}
              </option>
            </ng-container>
          </select>
        </div>

        <div class="row justify-content-center new-form-buttons">
          <div class="col text-right">
            <button type="button" class="btn btn-outline-secondary" routerLink="{{homeRoute}}">
              <app-svg-icon name="angle-right_special"></app-svg-icon>
              חזרה לעמוד ראשי
            </button>
          </div>
          <div class="col text-left">
            <button type="submit" class="btn btn-primary">
              <app-svg-icon name="plus_special"></app-svg-icon>
              יצירת טופס חדש
            </button>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>

<div class="loading-state" *ngIf="isLoading">
  <app-loading></app-loading>
</div>
