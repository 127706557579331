import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { KitAPI } from '@digital/app/flow/apis/kit.api';
import { FlowStage } from '@digital/app/_enums/flow-stages.enum';
import { IFlowStageFile } from '@digital/app/_interfaces/flow-stage-file.interface';
import { Flow } from '@digital/app/_models/flow.model';
import { FileService } from '@libs/core/services/file.service';
import { UploadFileStageType } from '@libs/enums/upload-file-stage-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

export interface IMessageBarDownloadButton {
  title: string;
  file?: IFlowStageFile;
  action?: () => void;
}

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss']
})
export class MessageBarComponent implements OnInit {
  @Input() title: string;
  @Input() titleIcon: string;
  @Input() flow: Flow;
  @Input() showTakeOverButton = false;
  @Input() buttons: IMessageBarDownloadButton[];

  @Output() takeOverFlowEmitted = new EventEmitter();

  constructor(private filesService: FileService, private kitAPI: KitAPI, private modal: NgbModal) {}
  ngOnInit(): void {}

  takeOverButtonClicked(event: any) {
    const ref = this.modal.open(AlertModalComponent, { centered: true }).componentInstance;
    ref.title = 'השתלטות על עריכת תהליך';
    ref.message = 'התהליך ינעל לעריכה עבור העורך הנוכחי כל עוד התהליך בעריכה אצלך. האם ברצונך להמשיך?';
    ref.positiveAction('כן, השתלט', () => this.takeOverFlowEmitted.emit(event));
    ref.negativeAction('ביטול', () => {});
  }

  buttonClicked(button: IMessageBarDownloadButton) {
    switch (button.file.stageType) {
      case UploadFileStageType.ClientSignature:
      case UploadFileStageType.IrsConfirmation:
        this.handleStageFileDownload(button.file);
        break;
      case UploadFileStageType.FinalKit:
        if (button.action) {
          button.action();
        }
        break;
      default:
        break;
    }
  }

  private handleStageFileDownload(file: IFlowStageFile) {
    let fileNameAffix = '';

    switch (file.stageType) {
      case UploadFileStageType.ClientSignature:
        fileNameAffix = 'חתימת לקוח';
        break;
      case UploadFileStageType.IrsConfirmation:
        fileNameAffix = 'אישור_מס_הכנסה';
        break;
      default:
        break;
    }

    this.filesService
      .download({
        fileID: file.id
      })
      .subscribe((buffer) => {
        const blob = new Blob([buffer], { type: 'blob' });
        const fileName = `${this.flow?.year}_${this.flow?.company.nameHebrew}_${fileNameAffix}.pdf`;
        saveAs(blob, fileName);
      });
  }

  private handleSignedKitDownload() {
    this.kitAPI.generateSigned(this.flow?.id).subscribe((buffer) => {
      const blob = new Blob([buffer], { type: 'blob' });
      const fileName = `${this.flow?.year}_${this.flow?.company.nameHebrew}_חתום.pdf`;
      saveAs(blob, fileName);
    });
  }
}
