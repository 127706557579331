import { ElementRef } from '@angular/core';
import { Conditions } from './conditions.model';
import { FormFieldOption } from '@digital/app/_models/form-field-option.model';

export class FormFields {
  id?: number;
  formID?: number;
  version?: number;
  formPage?: number;
  position?: number;
  name?: string;
  type?: string;
  description?: string;
  note?: string;
  number?: string;
  taxCode?: string;
  isRequired?: boolean;
  isDecimal?: boolean;
  positionX: number;
  positionY: number;
  width: number;
  height: number;
  pulledDataType?: string;
  pulledColumnName?: string;
  pulledCalculatedFieldsIDs?: {
    ids: number[];
    formula: string;
    defaultValue: number;
    useDefaultValueWhen: string;
  };
  pulledFieldID?: number;
  enableNegativeNumber?: number;
  conditionalLogic?: any;
  defaultValue?: string;

  options?: OptionField[];
  createdAt?: Date;
  updatedAt?: Date;
}

export class OptionField {
  id?: number;
  formFieldID?: number;
  name?: string;
  type?: string;
  position?: number;
  positionX?: number;
  positionY?: number;
  width?: number;
  height?: number;
  updatedAt?: Date;
  createdAt?: Date;
}

export interface FieldEvent {
  component?: ElementRef;
  field: FormFields;
}
