<div class="row">
  <div class="col">
    <div *ngFor="let c of criteria; index as i; let last = last">
      <div class="row mt-2 mb-2">
        <div class="col">
          <app-lead-query-criterion-group [form]="criterionGroup(i)" [isSingle]="hasSingleGroup" [index]="i" (deleted)="onCriterionGroupDeleted(i)">
          </app-lead-query-criterion-group>
        </div>
      </div>
      <div class="row mt-2 mb-2">
        <div class="col">
          <app-lead-logical-operator-button *ngIf="!last" [form]="logicalOperator(i)" [id]="operatorId(i)">
          </app-lead-logical-operator-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mr-4">
        <span *ngIf="!(isWaitingField | async)" class="add-criterion-group" (click)="onAddCriterionGroup()">
          + הוסף קבוצת קריטריונים
        </span>
      </div>
    </div>
  </div>
</div>
