import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lead-query-properties',
  templateUrl: './lead-query-properties.component.html',
  styleUrls: ['./lead-query-properties.component.scss']
})
export class LeadQueryPropertiesComponent implements OnInit, OnDestroy {
  @Input() years: number[];
  @Input() form: FormGroup;
  @Output() yearChange: EventEmitter<number> = new EventEmitter();
  placeholder: string = 'הקלד ערך';
  subscriptions: Subscription = new Subscription();

  constructor() { }

  ngOnInit(): void {
    this.years.reverse();

    this.yearChange.emit(this.selectedYear);
    
    const yearSubscription = this.yearControl
      .valueChanges
      .subscribe(year => this.yearChange.emit(year));
    
    this.subscriptions.add(yearSubscription);
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }

  get yearControl() {
    return this.form.get('year');
  }

  get selectedYear(): number {
    return this.yearControl.value;
  }
}
