<div
  style="
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 20px;
  "
>
  <div class="loading-state">
    <app-loading></app-loading>
  </div>
</div>
