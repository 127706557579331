import { Component, OnInit, Input } from '@angular/core';
import { GridData } from '@digital/app/_models/grid-data';
import columnsJson from './columns.json';
import { LeadQueryResultsState } from '@digital/app/_models/lead-query-result-state';
import { OrganizeKitFilesModalComponent } from '@digital/app/flow/pages/flow/components/_tax/organize-kit-files-modal/organize-kit-files-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlowAPI } from '@digital/app/_apis/flow.api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lead-query-results-table',
  templateUrl: './lead-query-results-table.component.html',
  styleUrls: ['./lead-query-results-table.component.scss']
})
export class LeadQueryResultsTableComponent implements OnInit {
  columns: GridData[] = columnsJson;
  @Input() state: LeadQueryResultsState;

  isLoading: boolean = false;

  private subscriptions = new Subscription();

  constructor(private modalService: NgbModal, private flowAPI: FlowAPI) {}

  ngOnInit() {}

  openOrganizeKitModal(flowId: number) {
    const subscription = this.flowAPI.getBaseFlowByID(flowId).subscribe((flow) => {
      const modelRef = this.modalService.open(OrganizeKitFilesModalComponent, {
        windowClass: 'organize-kit-files-modal',
        centered: true,
        size: 'xl'
      });
      modelRef.componentInstance.flow = flow;
    });

    this.subscriptions.add(subscription);
  }
}
