import { Component, OnInit } from '@angular/core';
import { LeadQueriesState } from '@digital/app/_models/lead-queries-state';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LeadService } from '@digital/app/core/services/lead.service';
import { GlobalRoutes } from '@libs/constants';

@Component({
  selector: 'app-lead-home',
  templateUrl: './lead-home.component.html',
  styleUrls: ['./lead-home.component.scss']
})
export class LeadHomeComponent implements OnInit {
  state: Observable<LeadQueriesState>;
  queryRoute: string = GlobalRoutes.qtax.query;

  constructor(private leadService: LeadService) { }

  ngOnInit(): void {
    this.state = this.leadService.getQueries().pipe(
      map(queries => ({queries: queries})),
      startWith({isLoading: true})
    );
  }
}
