import { Deserializable } from '@libs/core/network/model/deserializable.model';
import { UserEvent } from './user-event.model';

export default class UserEvents implements Deserializable {
  pages: number;
  events: UserEvent[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
