import { Injectable } from '@angular/core';
import { NetworkService } from '@libs/core/network/network.service';
import { environment } from '@digital/environments/environment';
import { Flows } from '@digital/app/_models/flows.model';
import { Flow, RiskLevelType, Permissions, FullUserDetails } from '@digital/app/_models/flow.model';
import { CreateFlow } from '@digital/app/create-flow/models/create-flow.model';
import { Scenario } from '@digital/app/flow/models';
import { ManagersPartners } from '@digital/app/_models/managers-partners.model';
import { HttpParams } from '@angular/common/http';
import { Comment } from '@digital/app/flow/models/comment.model';
import { BlogPosts, BlogPost } from '@digital/app/blog/models/blog-post.model';
import { Section } from '@digital/app/flow/models/section.model';
import { CreateUserEvent } from '@libs/core/network/model/user-event.model';
import { EmailerFlow } from '@digital/app/flow/models/emailer-flow.model';
import { Consultation } from '@digital/app/flow/models/consultation.model';
import { User } from '@digital/app/_models/user.model';
import { ID } from '@datorama/akita';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { InternalUser, InternalUsers } from '@digital/app/_models/internal-users.model';
import { ExternalUser, FullExternalUser } from '@digital/app/_models/client-users';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // Class members
  private FLOWS_API = 'flows';
  private USERS_API = 'users';
  private COMPANIES_API = 'companies';
  private UPDATE_MANAGERS_PARTNERS = 'update_managers_partners';
  private SCENARIO = 'scenario';
  private DELETE_REQUEST = 'delete_request';
  private CHECK_IF_CURRENT_EDITOR = 'check_if_current_editor';
  private SET_CURRENT_EDITOR = 'set_current_editor';
  private REMOVE_CURRENT_EDITOR = 'remove_current_editor';
  private REMOVE_USER_ALL_CURRENT_EDITOR = 'remove_user_all_current_editor';
  private COMMENTS = 'comments';
  private INTERNAL_USERS = 'internal_users';
  private EXTERNAL_USERS_PERMISSIONS = 'external_user_permissions';
  private EXTERNAL_MARK_AS_IS_DISCLAIMER = 'external_mark_as_is_disclaimer';
  private REPLIES = 'replies';
  private BLOG_POSTS = 'blog/posts';
  private SECTIONS = 'sections';
  private LOG_USER_EVENT = 'log_event';
  private SET_STAGE = 'set_stage';
  private DISAPPROVE_SIGNATURES = 'disapprove_signatures';
  private CREATE_CONSULTATION = 'create_consultation';
  private GET_ALL_CONSULTATION = 'get_all_consultation';
  private DELETE_CONSULTATION = 'delete_consultation';
  private UPDATE_RISK_LEVEL = 'update_risk_level';
  private MANAGERS_AND_PARTNERS = 'managers_and_partners';
  private ALL_USERS = 'all_users';
  private Tax_Year = 'taxYear';
  private GET_EXTERNAL_USER = 'get_external_user';
  private RESEND_ACTIVATION_EMAIL = 'resend_activation_email';
  private baseUrl: string;

  // Lifecycle
  constructor(private network: NetworkService, private _authenticationService: AuthenticationService) {
    this.baseUrl = environment.apiUrl;
  }

  // API methods
  async getFlows(page: number): Promise<Flows> {
    const response = await this.network.get(this.baseUrl, `${this.FLOWS_API}?page=${page}&limit=10`).toPromise();
    const flows = new Flows().deserialize(response.data);
    return flows;
  }

  async sortFlows(page: number, orderBy: string, orderDirection: string): Promise<Flows> {
    const response = await this.network
      .get(this.baseUrl, `${this.FLOWS_API}?page=${page}&limit=10&orderBy=${orderBy}&orderDirection=${orderDirection}`)
      .toPromise();
    const flows = new Flows().deserialize(response.data);
    return flows;
  }

  async filterFlows(page: number, type: string): Promise<Flows> {
    const response = await this.network
      .get(this.baseUrl, `${this.FLOWS_API}?page=${page}&limit=10&type=${type}`)
      .toPromise();
    const flows = new Flows().deserialize(response.data);
    return flows;
  }

  async searchFlows(searchValue: string): Promise<Flows> {
    const response = await this.network
      .get(this.baseUrl, `${this.FLOWS_API}/search?keyword=${searchValue}&page=1&limit=10`)
      .toPromise();
    const flows = new Flows().deserialize(response.data);
    return flows;
  }

  async deleteFlowRequest(id: number, isAwaitingDeletion: boolean, reason: string) {
    const response = await this.network
      .post(this.baseUrl, `${this.FLOWS_API}/${id}/${this.DELETE_REQUEST}`, { isAwaitingDeletion, reason })
      .toPromise();
    return response;
  }

  async createInternalEditor(internalUser: InternalUser, flowID: number): Promise<FullUserDetails> {
    const response = await this.network
      .post(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.INTERNAL_USERS}`, internalUser)
      .toPromise();
    const users = new Permissions().deserialize(response.data);
    return users.internalUsers[0];
  }

  async deleteInternalEditor(userID: number, flowID: number) {
    const response = await this.network
      .delete(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.INTERNAL_USERS}/${userID}`)
      .toPromise();
    return response.code === 202;
  }

  async createExternalUser(externalUser: ExternalUser, flowID: number): Promise<FullExternalUser> {
    const response = await this.network
      .post(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.EXTERNAL_USERS_PERMISSIONS}`, externalUser)
      .toPromise();
    const users = new Permissions().deserialize(response.data);
    console.log(users);
    return users.externalUsers[0];
  }

  async deleteExternalUserPermission(userID: number, flowID: number) {
    const response = await this.network
      .delete(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.EXTERNAL_USERS_PERMISSIONS}/${userID}`)
      .toPromise();
    return response.code === 202;
  }

  async deleteFlow(id: number, action: string) {
    const httpParams = new HttpParams().set('action', action);
    return await this.network.delete(this.baseUrl, `${this.FLOWS_API}/${id}`, httpParams).toPromise();
  }

  async getFlowByID(id: number): Promise<Flow> {
    const response = await this.network.get(this.baseUrl, `${this.FLOWS_API}/${id}`).toPromise();
    const flow = new Flow().deserialize(response.data.flow);
    return flow;
  }

  async getScenario(flowID: number): Promise<Scenario> {
    const response = await this.network.get(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.SCENARIO}`).toPromise();
    const scenario = new Scenario().deserialize(response.data.scenario);
    return scenario;
  }

  async getCompanies(userID: ID) {
    const response = await this.network
      .get(this.baseUrl, `${this.USERS_API}/${userID}/${this.COMPANIES_API}`)
      .toPromise();
    const companies = response.data;
    return companies;
  }

  async getManagersAndPartners(companyID: number, taxYear: number): Promise<ManagersPartners> {
    const response = await this.network
      .get(this.baseUrl, `${this.COMPANIES_API}/${companyID}/${this.MANAGERS_AND_PARTNERS}/${taxYear}`)
      .toPromise();
    const users = new ManagersPartners().deserialize(response.data);
    return users;
  }

  async getAllUsersForBanch(companyID: number, taxYear: number): Promise<InternalUsers> {
    const response = await this.network
      .get(this.baseUrl, `${this.COMPANIES_API}/${companyID}/${this.ALL_USERS}/${taxYear}`)
      .toPromise();
    const users = new InternalUsers().deserialize(response.data);
    return users;
  }

  async getTaxYears(companyID: number): Promise<number[]> {
    const response = await this.network
      .get(this.baseUrl, `${this.COMPANIES_API}/${companyID}/${this.Tax_Year}`)
      .toPromise();
    const taxYears = response.data.map((taxYear) => taxYear.TaxYear);
    return taxYears;
  }

  async updateManagerOrPartner(userID: number, flowID: number, userIDType: number) {
    return await this.network
      .patch(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.UPDATE_MANAGERS_PARTNERS}`, { userID, userIDType })
      .toPromise();
  }

  async updateRiskLevel(flowID: number, riskLevel: RiskLevelType) {
    return await this.network
      .patch(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.UPDATE_RISK_LEVEL}`, { riskLevel })
      .toPromise();
  }

  async createNewFlow(flow: CreateFlow): Promise<Flow> {
    const response = await this.network.post(this.baseUrl, `${this.FLOWS_API}`, flow).toPromise();
    const createdFlow = new Flow().deserialize(response.data.flow);
    return createdFlow;
  }

  async setCurrentUserEditor(flowID: number): Promise<any> {
    const response = await this.network
      .patch(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.SET_CURRENT_EDITOR}`)
      .toPromise();
    return response;
  }

  async checkIfCurrentEditor(flowID: number): Promise<any> {
    const response = await this.network
      .get(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.CHECK_IF_CURRENT_EDITOR}`)
      .toPromise();
    return response;
  }

  async removeUserAllCurrentEditor(): Promise<any> {
    const response = await this.network
      .post(this.baseUrl, `${this.FLOWS_API}/${this.REMOVE_USER_ALL_CURRENT_EDITOR}`)
      .toPromise();
    return response;
  }

  async getBlogPosts(page: number): Promise<any> {
    const response = await this.network.get(this.baseUrl, `${this.BLOG_POSTS}?page=${page}&limit=10`).toPromise();
    const posts = new BlogPosts().deserialize(response.data);
    return posts;
  }

  async getSingleBlogPost(id: number): Promise<any> {
    const response = await this.network.get(this.baseUrl, `${this.BLOG_POSTS}/${id}`).toPromise();
    const post = new BlogPost().deserialize(response.data.post);
    return post;
  }

  // Must be synchronous for handle close tab
  removeCurrentEditor(flowID: number) {
    const token = this._authenticationService.getCurrentUser().accessToken;
    const apiUrl = `${this.baseUrl}/${this.FLOWS_API}/${flowID}/${this.REMOVE_CURRENT_EDITOR}`;

    fetch(apiUrl, {
      keepalive: true,
      method: 'PATCH',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-Authorization': `${token}`
      }
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        const xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.open('PATCH', this.baseUrl + this.FLOWS_API + '/' + flowID + '/' + this.REMOVE_CURRENT_EDITOR, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.setRequestHeader('X-Authorization', token);
        xhr.send();
      });
  }

  async getFlowSections(flowID: number): Promise<Section[]> {
    const response = await this.network.get(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.SECTIONS}`).toPromise();
    const sections = response.data.sections;
    return sections;
  }

  async getCommentsByFlow(flowID: number): Promise<any> {
    const response = await this.network.get(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.COMMENTS}`).toPromise();
    return response;
  }

  async getCommentsRepliesByID(flowID: number, commentID: number): Promise<any> {
    const response = await this.network
      .get(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.COMMENTS}/${commentID}/${this.REPLIES}`)
      .toPromise();
    return response;
  }

  async createNewComment(
    message: string,
    userID: number,
    flowID: number,
    stageID: number,
    parentID: any
  ): Promise<Comment> {
    const response = await this.network
      .post(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.COMMENTS}`, {
        message,
        userID,
        flowID,
        stageID,
        parentID
      })
      .toPromise();
    const createdComment = new Comment().deserialize(response.data.comment);
    return createdComment;
  }

  async deleteComment(comment: Comment, flowID: number): Promise<boolean> {
    const response = await this.network
      .delete(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.COMMENTS}/${comment.id}`)
      .toPromise();
    return response.code === 202;
  }

  async createNewUserEvent(userID: ID, event: CreateUserEvent): Promise<any> {
    const response = await this.network
      .post(this.baseUrl, `${this.USERS_API}/${userID}/${this.LOG_USER_EVENT}`, event)
      .toPromise();
    return response;
  }

  async setStage(flowID: number, stagePosition: number, emailerFlow: EmailerFlow) {
    return this.network
      .patch(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.SET_STAGE}`, { stagePosition, emailerFlow })
      .toPromise();
  }

  async disapproveSignaturesApi(flowId: number, userId: ID) {
    return this.network
      .patch(this.baseUrl, `${this.FLOWS_API}/${flowId}/${this.DISAPPROVE_SIGNATURES}`, { userId })
      .toPromise();
  }

  async createNewConsultation(consultation: Consultation) {
    return await this.network
      .post(this.baseUrl, `${this.FLOWS_API}/${this.CREATE_CONSULTATION}`, consultation)
      .toPromise();
  }

  async getConsultationsForFlow(flowID: number): Promise<Consultation> {
    const response = await this.network
      .get(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.GET_ALL_CONSULTATION}`)
      .toPromise();
    return response.data;
  }

  async getUserDetails(userID: number): Promise<User> {
    const response = await this.network.get(this.baseUrl, `${this.USERS_API}/${userID}`).toPromise();
    return response.data;
  }

  async getExternalUserByEmail(email: string): Promise<ExternalUser> {
    const response = await this.network
      .get(this.baseUrl, `${this.USERS_API}/${email}/${this.GET_EXTERNAL_USER}`)
      .toPromise();
    return response.data;
  }

  async deleteConsultation(flowID: number, stageID: number) {
    const response = await this.network
      .delete(this.baseUrl, `${this.FLOWS_API}/${flowID}/${this.DELETE_CONSULTATION}/${stageID}`)
      .toPromise();
    return response;
  }

  async getFlowsYearsByID(companyID: number): Promise<any> {
    const response = await this.network.get(this.baseUrl, `${this.FLOWS_API}/${companyID}/years`).toPromise();
    const years = response.data;
    return years;
  }

  async resendActivationEmail(email: string): Promise<boolean> {
    const response = await this.network
      .get(this.baseUrl, `${this.USERS_API}/${email}/${this.RESEND_ACTIVATION_EMAIL}`)
      .toPromise();
    console.log(response);
    console.log(response.code);
    return response.code === 200;
  }

  async markAsIsDisclaimerAPI() {
    const currentUser = this._authenticationService.getCurrentUser();
    const response = await this.network
      .post(this.baseUrl, `${this.USERS_API}/${currentUser.id}/${this.EXTERNAL_MARK_AS_IS_DISCLAIMER}`)
      .toPromise();
    return response.code === 200;
  }
}
