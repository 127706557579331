import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { LeadQueryComponent } from './lead-query.component';
import { LeadSelectedFormField } from '../services/lead-selected-form-field.service';

@Injectable({
  providedIn: 'root'
})
export class LeadQueryGuard implements CanDeactivate<unknown> {
  constructor(private selectedFieldService: LeadSelectedFormField) {}

  canDeactivate(component: LeadQueryComponent): boolean {
    this.selectedFieldService.waitField(false);

    return true;
  }
  
}
