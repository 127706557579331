import { Component, OnInit, ElementRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FieldBaseComponent } from '../field-base/field-base.component';
import { FormFieldOption, FormFieldOptionType } from '@digital/app/_models/form-field-option.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from '@libs/views/datepicker/datepicker-adapter';
import { FormFieldType } from '@digital/app/_models/form-field.model';
import { FormsService } from '@digital/app/forms/forms.service';

interface OptionValue {
  type: FormFieldOptionType;
  value: any;
}

@Component({
  selector: 'app-field-free-options',
  templateUrl: './field-free-options.component.html',
  styleUrls: ['../field-base/field-base.component.scss', './field-free-options.component.scss']
})
export class FieldFreeOptionsComponent extends FieldBaseComponent implements OnInit {
  fieldOptionType = FormFieldOptionType;

  /* #region Lifecycle */

  constructor(public _elementRef: ElementRef, public formsService: FormsService) {
    super(_elementRef, formsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /* #endregion */

  /* #region Methods */

  updateFieldValue($target: any, option: FormFieldOption) {
    const finalValues = this.field.value || {};

    let optionValue: OptionValue;

    const pdfElement = document.getElementById(`option-${option.id}`) as HTMLInputElement;
    if (pdfElement) {
      pdfElement.value = $target.value;
    }

    const fieldElement = document.getElementById(`option-${option.id}`) as HTMLInputElement;
    fieldElement.value = $target.value;

    if (option.type === FormFieldOptionType.Number) {
      if (isNaN($target.value)) {
        fieldElement.value = '';
        pdfElement.value = '';
        $target.value = '';
      }
    }

    if (option.type === FormFieldOptionType.Date) {
      optionValue = this.getDateOptionValue($target, option);
    } else {
      optionValue = {
        type: option.type, // TODO: Might cause issues later on. Should refactor.
        value: option.type === FormFieldOptionType.Checkbox ? $target.checked : $target.value
      };
    }

    const jsonOptionID = `option_${option.id}`;
    finalValues[jsonOptionID] = optionValue;

    this.field.newValue = finalValues;
    this.didUpdateFormFieldValue.emit(this.field);

    // After the new value has been emitted, set the new value as the current value so the next field update would send a PATCH instead of POST
    this.field.value = finalValues;
  }

  getDateOptionValue(date: NgbDate, option: FormFieldOption): OptionValue {
    const dateParser = new CustomDateParserFormatter();
    const formattedDate = dateParser.format(date);

    return {
      type: option.type,
      value: formattedDate
    };
  }

  getOptionValue(option: FormFieldOption): any {
    if (!this.field.value || !this.field.value[`option_${option.id}`]) {
      return null;
    }

    let value = this.field.value[`option_${option.id}`].value;

    if (option.type === FormFieldOptionType.Number) {
      value = this._getFormattedValue(value);
    }

    return value;
  }

  /* #endregion */

  private _getFormattedValue(value: any): string {
    if (this.field.type === FormFieldType.Text) {
      return value;
    }

    value.replace(',', '');

    return DecimalPipe.prototype.transform(value, undefined, 'he-IL');
  }
}
