import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormsApi } from '../core/api/forms.api';
import { AdminPanelRoutes } from '../shared/constants';
import { Form } from './models/form.model';

@Injectable({ providedIn: 'root' })
export class FormsFacade {
  forms$ = new BehaviorSubject([new Form()]);
  form$ = new BehaviorSubject(new Form());

  // Lifecycle
  constructor(private _formsAPI: FormsApi, private _router: Router) {}

  // Methods
  getAllForms() {
    this._formsAPI.getAllForms().subscribe((forms) => {
      this.forms$.next(forms);
    });
  }

  getSingleForm(formID: number) {
    this._formsAPI.getSingleForm(formID).subscribe((form) => {
      this.form$.next(form);
    });
  }

  updateForm(form: Form, formID: number) {
    this._formsAPI.updateForm(form, formID).subscribe((response) => {
      if (response.code === 202) {
        this.getSingleForm(formID);
      }
    });
  }

  deleteForm(formID: number) {
    this._formsAPI.deleteForm(formID).subscribe((response) => {
      this._router.navigate([AdminPanelRoutes.home]);
      return response;
    });
  }
}
