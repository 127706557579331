import { Component, OnInit, Input } from '@angular/core';
import { BlogPost } from '../../models/blog-post.model';
import { BlogService } from '@digital/app/core/services/blog.service';
import { ActivatedRoute } from '@angular/router';
import { DigitalitRoutes } from '@digital/app/_constants';

@Component({
  selector: 'app-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit {
  @Input() post: BlogPost;

  // Lifecycle
  constructor(private _route: ActivatedRoute,
              private _blogService: BlogService) { }

  ngOnInit() {
    this._getPost(this._route.snapshot.params.id);
  }

  // Getters
  get blogRoute() {
    return ['/' + DigitalitRoutes.blog.main];
  }

  // Networking
  private _getPost(id: number) {
    this._blogService.getSingleBlogPost(id, ((post: BlogPost) => this._onGetBlogPost(post)));
  }

  private _onGetBlogPost(post: BlogPost) {
    this.post = post;
  }
}
