import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LeadFormService } from '../lead-form.service';

@Component({
  selector: 'lead-query-pdf-form-wrapper',
  templateUrl: './lead-query-pdf-form-wrapper.component.html',
  styleUrls: ['./lead-query-pdf-form-wrapper.component.scss']
})
export class LeadQueryPdfFormWrapperComponent implements OnInit, OnChanges {
  @Input() year: number;
  forms$ = this.formService.forms$;
  currentForm$ = this.formService.currentForm$;

  constructor(private formService: LeadFormService) {}

  ngOnInit(): void {
    this.formService.getAllFormsForSpecifiedYear(this.year);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formService.getAllFormsForSpecifiedYear(this.year);
  }
}
