import { Component, OnInit, ElementRef, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { FormFields, OptionField, FieldEvent } from '../../models/form-fields.model';

@Component({
  selector: 'app-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.scss']
})
export class AnnotationComponent implements OnInit, AfterViewInit {
  @Input() field: any;
  @Input() position: any;

  @Output() fieldsUpdated = new EventEmitter<FormFields>();
  @Output() optionFieldsUpdated = new EventEmitter<OptionField>();
  @Output() fieldDeleted = new EventEmitter<FieldEvent>();
  @Output() modalOpen = new EventEmitter<FieldEvent>();

  @Output() didClickDeleteButton = new EventEmitter<FieldEvent>();
  @Output() didClickDuplicateButton = new EventEmitter<FieldEvent>();

  isResizing = false;
  isDragging = false;
  isOption = false;

  annotationPosition = { x: 300, y: 100 };
  private minimumSize = 10;
  private annotationWidth = 0;
  private annotationHeight = 0;
  private elementPositionX = 0;
  private elementPositionY = 0;
  private mouseX = 0;
  private mouseY = 0;
  private _resizeMouseEventMove: (event: MouseEvent) => void;
  private _resizeMouseEventUp: (event: MouseEvent) => void;
  private positionXAfterResizing: number;
  private positionYAfterResizing: number;

  // Lifecycle
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.addEventListeners();
    this.makeResizableDiv(this.elementRef.nativeElement.firstChild);
  }

  // Controls Popover
  deleteButtonClicked() {
    const message = `למחוק את השדה "${this.field.name}"?`;
    if (confirm(message)) {
      this.didClickDeleteButton.emit({
        component: this.elementRef,
        field: this.field
      });
    }
  }

  duplicateButtonClicked() {
    this.didClickDuplicateButton.emit({
      component: this.elementRef,
      field: this.field
    });
  }

  editButtonClicked() {
    this.openFormFieldsModal();
  }

  // Events
  onDragEnded(event) {
    if (this.field.hasOwnProperty('formPage')) {
      this.annotationPosition = event.source.getFreeDragPosition();
      this.field.positionX = this.positionXAfterResizing ? this.positionXAfterResizing + this.annotationPosition.x : this.annotationPosition.x;
      this.field.positionY = this.positionYAfterResizing ? this.positionYAfterResizing + this.annotationPosition.y : this.annotationPosition.y;
      const fieldsUpdated = new FormFields();
      fieldsUpdated.positionX = this.positionXAfterResizing ? this.positionXAfterResizing + this.annotationPosition.x : this.annotationPosition.x;
      fieldsUpdated.positionY = this.positionYAfterResizing ? this.positionYAfterResizing + this.annotationPosition.y : this.annotationPosition.y;
      this.fieldsUpdated.emit(fieldsUpdated);
      // Else is option field
    } else {
      this.annotationPosition = event.source.getFreeDragPosition();
      this.field.positionX = this.positionXAfterResizing ? this.positionXAfterResizing + this.annotationPosition.x : this.annotationPosition.x;
      this.field.positionY = this.positionYAfterResizing ? this.positionYAfterResizing + this.annotationPosition.y : this.annotationPosition.y;
      const fieldsUpdated = new OptionField();
      fieldsUpdated.positionX = this.positionXAfterResizing ? this.positionXAfterResizing + this.annotationPosition.x : this.annotationPosition.x;
      fieldsUpdated.positionY = this.positionYAfterResizing ? this.positionYAfterResizing + this.annotationPosition.y : this.annotationPosition.y;
      this.optionFieldsUpdated.emit(fieldsUpdated);
    }
    this.isDragging = false;
  }

  addEventListeners() {
    this.elementRef.nativeElement.addEventListener('mouseenter', (event: MouseEvent) => {

    });

    this.elementRef.nativeElement.addEventListener('mouseleave', (event: MouseEvent) => {

    });
  }

  makeResizableDiv(element) {
    const resizers = element.querySelectorAll('.resizer');
    resizers.forEach(resizer => {
      resizer.addEventListener('mousedown', (event: MouseEvent) => {
        this.calculateResizerAttributes(event, element);

        this._resizeMouseEventMove = (evt: MouseEvent) => this.resize(evt, element, resizer);
        window.addEventListener('mousemove', this._resizeMouseEventMove);

        this._resizeMouseEventUp = (evt: MouseEvent) => this.stopResizing(element);
        window.addEventListener('mouseup', this._resizeMouseEventUp);
        window.addEventListener('mouseup', (ev: MouseEvent) => this.stopResizing(element));
      });
    });
  }

  calculateResizerAttributes(event: MouseEvent, element) {
    this.annotationWidth = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
    this.annotationHeight = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
    this.elementPositionX = element.offsetLeft;
    this.elementPositionY = element.offsetTop;
    this.mouseX = event.pageX;
    this.mouseY = event.pageY;
    this.isResizing = true;
  }

  resize(event: MouseEvent, element?, resizer?) {
    if (!this.isResizing) {
      return;
    }

    if (resizer.classList.contains('bottom-right')) {
      const width = this.annotationWidth + (event.pageX - this.mouseX);
      const height = this.annotationHeight + (event.pageY - this.mouseY);
      if (width > this.minimumSize) {
        element.style.width = width + 'px';
        element.style.right = this.elementPositionX + (event.pageX - this.mouseX) + 'px';
        this.field.width = width;
      }
      if (height > this.minimumSize) {
        element.style.height = height + 'px';
        this.field.height = height;
      }
    } else if (resizer.classList.contains('bottom-left')) {
      const height = this.annotationHeight + (event.pageY - this.mouseY);
      const width = this.annotationWidth - (event.pageX - this.mouseX);
      if (width > this.minimumSize) {
        element.style.width = width + 'px';
        element.style.left = this.elementPositionX + (event.pageX - this.mouseX) + 'px';
        this.field.width = width;
        // Get TranslateX value for compute the position x after resizing
        const elementStyle = window.getComputedStyle(element);
        // tslint:disable-next-line: deprecation
        const translateX = new WebKitCSSMatrix(elementStyle.webkitTransform);
        this.field.positionX = translateX.m41 + parseInt(element.style.left, 10);
        this.positionXAfterResizing = parseInt(element.style.left, 10);
      }
      if (height > this.minimumSize) {
        element.style.height = height + 'px';
        this.field.height = height;
      }
    } else if (resizer.classList.contains('top-right')) {
      const width = this.annotationWidth + (event.pageX - this.mouseX);
      const height = this.annotationHeight - (event.pageY - this.mouseY);
      if (width > this.minimumSize) {
        element.style.width = width + 'px';
        this.field.width = width;
      }
      if (height > this.minimumSize) {
        element.style.height = height + 'px';
        element.style.top = this.elementPositionY + (event.pageY - this.mouseY) + 'px';
        this.field.height = height;
        const elementStyle = window.getComputedStyle(element);
        // tslint:disable-next-line: deprecation
        const translateY = new WebKitCSSMatrix(elementStyle.webkitTransform);
        this.field.positionY = translateY.m42 + parseInt(element.style.top, 10);
        this.positionYAfterResizing =  parseInt(element.style.top, 10);
      }
    } else {
      const width = this.annotationWidth - (event.pageX - this.mouseX);
      const height = this.annotationHeight - (event.pageY - this.mouseY);
      if (width > this.minimumSize) {
        element.style.width = width + 'px';
        element.style.left = this.elementPositionX + (event.pageX - this.mouseX) + 'px';
        this.field.width = width;
        // Get TranslateX value for compute the position x after resizing
        const elementStyle = window.getComputedStyle(element);
        // tslint:disable-next-line: deprecation
        const translateX = new WebKitCSSMatrix(elementStyle.webkitTransform);
        this.field.positionX = translateX.m41 + parseInt(element.style.left, 10);
        this.positionXAfterResizing = parseInt(element.style.left, 10);
      }
      if (height > this.minimumSize) {
        element.style.height = height + 'px';
        element.style.top = this.elementPositionY + (event.pageY - this.mouseY) + 'px';
        this.field.height = height;
        const elementStyle = window.getComputedStyle(element);
        // tslint:disable-next-line: deprecation
        const translateY = new WebKitCSSMatrix(elementStyle.webkitTransform);
        this.field.positionY = translateY.m42 + parseInt(element.style.top, 10);
        this.positionYAfterResizing =  parseInt(element.style.top, 10);
      }
    }
  }

  stopResizing(event: MouseEvent) {
    this.isResizing = false;
    window.removeEventListener('mousemove', this._resizeMouseEventMove);
    window.removeEventListener('mouseup', this._resizeMouseEventUp);
    if (this.field.hasOwnProperty('formPage')) {
      const fieldUpdated = new FormFields();
      fieldUpdated.width = this.field.width;
      fieldUpdated.height = this.field.height;
      fieldUpdated.positionY = this.field.positionY;
      fieldUpdated.positionX = this.field.positionX;
      this.fieldsUpdated.emit(fieldUpdated);
      // Else is option field
    } else {
      const optionField = new OptionField();
      optionField.width = this.field.width;
      optionField.height = this.field.height;
      optionField.positionX = this.field.positionX;
      optionField.positionY = this.field.positionY;
      this.optionFieldsUpdated.emit(optionField);
    }
  }

  openFormFieldsModal() {
    if (this.field.hasOwnProperty('formPage')) {
      this.modalOpen.emit({
        component: this.elementRef,
        field: this.field
      });
    }
  }

  get isOptionField() {
    if (this.field.hasOwnProperty('formPage')) {
      return false;
    } else {
      return true;
    }
  }

  get getFieldName() {
    return this.field.name;
  }
}
