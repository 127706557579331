import { Component } from '@angular/core';
import { Constants } from '@digital/app/_constants';
import { AuthenticationService } from '@libs/authentication/authentication.service';
import { environment } from '@digital/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // Getters
  get languageDirection() {
    return Constants.languageDirection;
  }

  // Lifecycle
  constructor(private _authenticationService: AuthenticationService) {
    this._authenticationService.initWithEnvironment(environment).authenticate();
  }
}
